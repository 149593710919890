/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** An ISO 8601-encoded date */
  Date: { input: string; output: string }
  /** An ISO 8601-encoded datetime */
  DateTime: { input: string; output: string }
  /** Represents untyped JSON */
  JSON: { input: Record<string, unknown>; output: Record<string, unknown> }
}

/** Autogenerated return type of AcceptBooking. */
export type AcceptBookingPayload = {
  __typename: 'AcceptBookingPayload'
  booking: Maybe<Booking>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of AcceptHomeApplication. */
export type AcceptHomeApplicationPayload = {
  __typename: 'AcceptHomeApplicationPayload'
  errors: Maybe<Array<Validation>>
  homeApplication: Maybe<HomeApplication>
}

/** Autogenerated return type of AcceptHomeApplications. */
export type AcceptHomeApplicationsPayload = {
  __typename: 'AcceptHomeApplicationsPayload'
  errors: Maybe<Array<Validation>>
  success: Scalars['Boolean']['output']
}

/** Autogenerated return type of AcceptOffering. */
export type AcceptOfferingPayload = {
  __typename: 'AcceptOfferingPayload'
  offering: Offering
}

/** Autogenerated return type of AcceptShortcutProposal. */
export type AcceptShortcutProposalPayload = {
  __typename: 'AcceptShortcutProposalPayload'
  homeId: Scalars['ID']['output']
}

export type AccessToken = {
  __typename: 'AccessToken'
  expiresAt: Maybe<Scalars['DateTime']['output']>
  impersonatedByAdmin: Scalars['Boolean']['output']
  token: Scalars['String']['output']
  uid: Scalars['String']['output']
}

export type AccessTokenQueryResult =
  | AccessToken
  | AuthenticationError
  | AuthorizationError
  | NotFoundError
  | RateLimitExceeded
  | ValidationError

export type AccountResource = {
  __typename: 'AccountResource'
  currentState: AccountResourceStateEnum
  id: Scalars['ID']['output']
  url: Scalars['String']['output']
  user: User
}

export type AccountResourceUrlArgs = {
  refreshUrl: Scalars['String']['input']
  returnUrl: Scalars['String']['input']
}

export enum AccountResourceStateEnum {
  actions_required = 'actions_required',
  created = 'created',
  payouts_enabled = 'payouts_enabled',
}

export enum ActiveStorageServiceEnum {
  default = 'default',
  /** public */
  public = 'public',
}

/** Autogenerated return type of AddHomeApplicationsToBoardList. */
export type AddHomeApplicationsToBoardListPayload = {
  __typename: 'AddHomeApplicationsToBoardListPayload'
  errors: Maybe<Array<Validation>>
  homeApplicationsFailed: Maybe<Array<HomeApplication>>
  homeApplicationsSuccessful: Array<HomeApplication>
}

export type AdminAccessTokenQueryResult = AccessToken | AuthorizationError | NotFoundError | ValidationError

export type AdminFields = {
  __typename: 'AdminFields'
  adminNotes: Maybe<Array<AdminNote>>
  onfidoApplicantId: Maybe<Scalars['String']['output']>
}

export type AdminInfo = {
  __typename: 'AdminInfo'
  email: Scalars['String']['output']
  name: Scalars['String']['output']
  picture: Maybe<Scalars['String']['output']>
}

export type AdminNote = {
  __typename: 'AdminNote'
  author: Maybe<User>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  note: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export enum AdminNoteRecordTypeEnum {
  Contract = 'Contract',
  Invoice = 'Invoice',
  User = 'User',
}

export type AffiliateCode = {
  __typename: 'AffiliateCode'
  affiliateCodeType: AffiliateCodeTypeEnum
  code: Scalars['String']['output']
  discountFor: AffiliateCodeDiscountForEnum
  discountType: AffiliateCodeDiscountTypeEnum
  discountValue: Scalars['Int']['output']
  expirationDate: Scalars['DateTime']['output']
  partner: Scalars['String']['output']
}

export enum AffiliateCodeDiscountForEnum {
  insurance_cost = 'insurance_cost',
  landlord_base_fee = 'landlord_base_fee',
  other_cost = 'other_cost',
  qasa_guarantee_cost = 'qasa_guarantee_cost',
  tenant_base_fee = 'tenant_base_fee',
}

export enum AffiliateCodeDiscountTypeEnum {
  fixed = 'fixed',
  other = 'other',
  percentage = 'percentage',
}

export enum AffiliateCodeTypeEnum {
  auto_certified = 'auto_certified',
  contract_fee_discount = 'contract_fee_discount',
  contract_fee_fixed = 'contract_fee_fixed',
  manual_repayment = 'manual_repayment',
}

export type ApplicationBoardCardInfo = {
  __typename: 'ApplicationBoardCardInfo'
  home: Home
  id: Scalars['ID']['output']
  showEmployerCertificate: Scalars['Boolean']['output']
  showIdentification: Scalars['Boolean']['output']
  showIncome: Scalars['Boolean']['output']
  showLastMessage: Scalars['Boolean']['output']
  showMatchingScore: Scalars['Boolean']['output']
  showQueueDays: Scalars['Boolean']['output']
  showStudyCertificate: Scalars['Boolean']['output']
  showUc: Scalars['Boolean']['output']
}

export type ApplicationBoardList = {
  __typename: 'ApplicationBoardList'
  applicationCount: Scalars['Int']['output']
  defaultType: Maybe<ApplicationBoardListDefaultTypeEnum>
  home: Home
  id: Scalars['ID']['output']
  isDefault: Scalars['Boolean']['output']
  name: Maybe<Scalars['String']['output']>
  order: Scalars['Int']['output']
}

export enum ApplicationBoardListDefaultTypeEnum {
  completed = 'completed',
  ongoing = 'ongoing',
  pending = 'pending',
}

export enum ApplicationSelectionTypeEnum {
  automatic = 'automatic',
  automatic_strict = 'automatic_strict',
  external = 'external',
  manual = 'manual',
  manual_lock = 'manual_lock',
}

export enum ApplicationSortingTypeEnum {
  queue_points = 'queue_points',
  random = 'random',
  timestamp = 'timestamp',
}

export type ApplicationTimeSeries = {
  __typename: 'ApplicationTimeSeries'
  cumulativeApplications: Array<Scalars['Int']['output']>
  dailyApplications: Array<Scalars['Int']['output']>
  timeInterval: Array<Scalars['DateTime']['output']>
}

/** Autogenerated return type of ApproveDepositPayout. */
export type ApproveDepositPayoutPayload = {
  __typename: 'ApproveDepositPayoutPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of ApproveIdentification. */
export type ApproveIdentificationPayload = {
  __typename: 'ApproveIdentificationPayload'
  errors: Maybe<Array<Validation>>
  success: Scalars['Boolean']['output']
}

/** Autogenerated return type of ArchiveConversation. */
export type ArchiveConversationPayload = {
  __typename: 'ArchiveConversationPayload'
  conversation: Conversation
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of ArchiveHome. */
export type ArchiveHomePayload = {
  __typename: 'ArchiveHomePayload'
  home: Maybe<Home>
}

export type AreaParamsInput = {
  city?: InputMaybe<Scalars['String']['input']>
  countryCode?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

/** Autogenerated return type of AssignTags. */
export type AssignTagsPayload = {
  __typename: 'AssignTagsPayload'
  taggable: Maybe<TaggableUnion>
}

export type AttributeError = {
  __typename: 'AttributeError'
  attribute: Scalars['String']['output']
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type AuthenticationError = Error & {
  __typename: 'AuthenticationError'
  message: Scalars['String']['output']
}

export type AuthorizationError = Error & {
  __typename: 'AuthorizationError'
  message: Scalars['String']['output']
}

export type BankAccount = {
  __typename: 'BankAccount'
  active: Scalars['Boolean']['output']
  bankLocation: Maybe<Location>
  bankName: Maybe<Scalars['String']['output']>
  bic: Maybe<Scalars['String']['output']>
  clearingNumber: Maybe<Scalars['String']['output']>
  countryCode: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  email: Maybe<Scalars['String']['output']>
  iban: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  location: Maybe<Location>
  name: Maybe<Scalars['String']['output']>
  number: Maybe<Scalars['String']['output']>
  ownerRelationType: Maybe<BankAccountOwnerRelationTypeEnum>
  type: BankAccountTypeEnum
  updatedAt: Scalars['DateTime']['output']
  user: User
  verified: Scalars['Boolean']['output']
  verifiedAt: Maybe<Scalars['DateTime']['output']>
}

export enum BankAccountOwnerRelationTypeEnum {
  family = 'family',
  partner = 'partner',
}

export type BankAccountType = {
  __typename: 'BankAccountType'
  number: Scalars['String']['output']
  type: BankAccountTypeTypeEnum
}

export enum BankAccountTypeEnum {
  address = 'address',
  bank_account = 'bank_account',
  bank_giro = 'bank_giro',
  international = 'international',
  plus_giro = 'plus_giro',
}

export enum BankAccountTypeTypeEnum {
  bank_account = 'bank_account',
  bank_giro = 'bank_giro',
  international = 'international',
  plus_giro = 'plus_giro',
}

export type Blob = {
  __typename: 'Blob'
  byteSize: Maybe<Scalars['Int']['output']>
  contentType: Scalars['String']['output']
  filename: Scalars['String']['output']
  id: Scalars['ID']['output']
  metadata: Maybe<Scalars['JSON']['output']>
  url: Maybe<Scalars['String']['output']>
}

export type BlockListingPayload = {
  __typename: 'BlockListingPayload'
  location: Location
  maxRent: Scalars['Int']['output']
  maxRoomCount: Scalars['Int']['output']
  maxSquareMeters: Scalars['Int']['output']
  maxTenantCount: Scalars['Int']['output']
  minRent: Scalars['Int']['output']
  minRoomCount: Scalars['Int']['output']
  minSquareMeters: Scalars['Int']['output']
  minTenantCount: Scalars['Int']['output']
  numberOfHomes: Scalars['Int']['output']
  uploads: Array<Upload>
}

export type Booking = {
  __typename: 'Booking'
  actionDeadline: Maybe<Scalars['DateTime']['output']>
  bookingOffers: Array<BookingOffer>
  canceledAt: Maybe<Scalars['DateTime']['output']>
  checkIn: Scalars['Date']['output']
  checkOut: Scalars['Date']['output']
  contract: Maybe<Contract>
  conversation: Maybe<Conversation>
  createdAt: Scalars['DateTime']['output']
  home: Home
  id: Scalars['ID']['output']
  landlord: User
  pricingInfo: PricePreview
  refundAction: Maybe<RefundActionEnum>
  status: BookingStatusEnum
  tenant: User
  updatedAt: Scalars['DateTime']['output']
}

export enum BookingEmailTemplateEnum {
  landlord_booking_confirmed = 'landlord_booking_confirmed',
  tenant_booking_confirmed = 'tenant_booking_confirmed',
}

export type BookingOffer = {
  __typename: 'BookingOffer'
  allowedTransitions: Array<BookingOfferStatesEnum>
  checkIn: Scalars['Date']['output']
  checkOut: Scalars['Date']['output']
  createdAt: Scalars['DateTime']['output']
  createdFromBooking: Scalars['Boolean']['output']
  currentState: BookingOfferStatesEnum
  id: Scalars['ID']['output']
  pricingInfo: PricePreview
  updatedAt: Scalars['DateTime']['output']
}

export enum BookingOfferStatesEnum {
  accepted = 'accepted',
  canceled = 'canceled',
  canceled_by_other_booking = 'canceled_by_other_booking',
  canceled_by_other_offer = 'canceled_by_other_offer',
  canceled_due_to_inactivity = 'canceled_due_to_inactivity',
  draft = 'draft',
  invalidated = 'invalidated',
  pending = 'pending',
  rejected = 'rejected',
}

export enum BookingStatusEnum {
  accepted = 'accepted',
  awaiting_payment = 'awaiting_payment',
  awaiting_review = 'awaiting_review',
  canceled = 'canceled',
  completed = 'completed',
  confirmed = 'confirmed',
  declined = 'declined',
  declined_due_to_inactivity = 'declined_due_to_inactivity',
  declined_due_to_landlord_inactivity = 'declined_due_to_landlord_inactivity',
  declined_due_to_late_payment = 'declined_due_to_late_payment',
  declined_due_to_tenant_inactivity = 'declined_due_to_tenant_inactivity',
  landlord_did_not_confirm_before_check_in = 'landlord_did_not_confirm_before_check_in',
  ongoing = 'ongoing',
  pending = 'pending',
  revoked = 'revoked',
  tenant_did_not_confirm_before_check_in = 'tenant_did_not_confirm_before_check_in',
}

export enum BrandControllerEnum {
  qasa_ab = 'qasa_ab',
  qasa_oy = 'qasa_oy',
  schibsted = 'schibsted',
}

export type Building = {
  __typename: 'Building'
  buildYear: Maybe<Scalars['Int']['output']>
  buildingName: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  descriptionBuilding: Maybe<Scalars['String']['output']>
  descriptionTransportation: Maybe<Scalars['String']['output']>
  energyClass: Maybe<Scalars['String']['output']>
  homeTemplateCount: Scalars['Int']['output']
  homeTemplates: Array<HomeTemplate>
  id: Scalars['ID']['output']
  location: Location
  organization: Maybe<Organization>
  updatedAt: Scalars['DateTime']['output']
  uploads: Array<Upload>
  user: User
}

/** The connection type for Building. */
export type BuildingConnection = {
  __typename: 'BuildingConnection'
  /** A list of edges. */
  edges: Array<BuildingEdge>
  /** A list of nodes. */
  nodes: Array<Building>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type BuildingEdge = {
  __typename: 'BuildingEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Building
}

/** Autogenerated return type of BumpListing. */
export type BumpListingPayload = {
  __typename: 'BumpListingPayload'
  home: Home
}

/** Autogenerated return type of BumpVacationAd. */
export type BumpVacationAdPayload = {
  __typename: 'BumpVacationAdPayload'
  errors: Maybe<Array<Validation>>
  home: Maybe<Home>
}

/** Autogenerated return type of CancelBooking. */
export type CancelBookingPayload = {
  __typename: 'CancelBookingPayload'
  booking: Maybe<Booking>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of CancelContractSigning. */
export type CancelContractSigningPayload = {
  __typename: 'CancelContractSigningPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of CancelViewing. */
export type CancelViewingPayload = {
  __typename: 'CancelViewingPayload'
  errors: Maybe<Array<Validation>>
  viewing: Maybe<Viewing>
}

export type CancellationPolicy = {
  __typename: 'CancellationPolicy'
  fullRefundDays: Maybe<Scalars['Int']['output']>
  landlordFullRefundDays: Maybe<Scalars['Int']['output']>
  partialRefundDays: Maybe<Scalars['Int']['output']>
  partialRefundFactor: Maybe<Scalars['Float']['output']>
  policyName: Maybe<CancellationPolicyNameEnum>
}

export enum CancellationPolicyNameEnum {
  flexible = 'flexible',
  medium = 'medium',
  strict = 'strict',
}

export type Certificate = {
  __typename: 'Certificate'
  certifiedAt: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  evaluatedAt: Maybe<Scalars['DateTime']['output']>
  expiresAt: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  numberOfComplaints: Maybe<Scalars['Int']['output']>
  status: CertificateStatusEnum
  sumPrivatePublicDebt: Maybe<Scalars['Int']['output']>
  updatedAt: Scalars['DateTime']['output']
  user: User
}

export enum CertificateStatusEnum {
  certified = 'certified',
  denied_bad_credit = 'denied_bad_credit',
  denied_blocked = 'denied_blocked',
  denied_id_not_found = 'denied_id_not_found',
  denied_unavailable = 'denied_unavailable',
  expired = 'expired',
  in_review = 'in_review',
  payment_accepted = 'payment_accepted',
  payment_error = 'payment_error',
  payment_required = 'payment_required',
}

/** Autogenerated return type of ClaimContractInvitation. */
export type ClaimContractInvitationPayload = {
  __typename: 'ClaimContractInvitationPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of CloseConversation. */
export type CloseConversationPayload = {
  __typename: 'CloseConversationPayload'
  conversation: Conversation
  errors: Maybe<Array<Validation>>
}

export type CodesWithMessage = {
  __typename: 'CodesWithMessage'
  code: Scalars['String']['output']
  message: Maybe<Scalars['String']['output']>
}

export type CompensationClaim = {
  __typename: 'CompensationClaim'
  amount: Scalars['Int']['output']
  balance: Scalars['Int']['output']
  cap: Scalars['Int']['output']
  contract: Contract
  coverages: Array<CompensationClaimCoverageType>
  id: Scalars['ID']['output']
  reservations: Array<CompensationClaimReservationType>
}

export type CompensationClaimCoverageType = {
  __typename: 'CompensationClaimCoverageType'
  amount: Scalars['Int']['output']
  id: Scalars['ID']['output']
  status: Scalars['String']['output']
}

export type CompensationClaimReservationType = {
  __typename: 'CompensationClaimReservationType'
  amount: Scalars['Int']['output']
  id: Scalars['ID']['output']
  invoice: Maybe<Invoice>
  payoutTo: Maybe<User>
  status: Scalars['String']['output']
}

export type CompensationReservationInput = {
  amount: Scalars['Int']['input']
  payoutToUid?: InputMaybe<Scalars['ID']['input']>
}

/** Autogenerated return type of CompleteOffering. */
export type CompleteOfferingPayload = {
  __typename: 'CompleteOfferingPayload'
  offering: Offering
}

/** Autogenerated return type of ConfirmEmail. */
export type ConfirmEmailPayload = {
  __typename: 'ConfirmEmailPayload'
  errors: Maybe<Array<Validation>>
  user: Maybe<User>
}

export type ConnectHubHomeQBonusPointsType = {
  __typename: 'ConnectHubHomeQBonusPointsType'
  companyId: Scalars['Int']['output']
  companyName: Scalars['String']['output']
  points: Scalars['Int']['output']
}

export type ConnectHubHomeQClaimAttributesType = {
  __typename: 'ConnectHubHomeQClaimAttributesType'
  description: Maybe<Scalars['String']['output']>
  duration: Maybe<ConnectHubHomeQClaimDurationEnum>
  orgType: Maybe<ConnectHubHomeQClaimOrgTypeEnum>
}

export enum ConnectHubHomeQClaimDurationEnum {
  EMPLOYMENT_FIXED = 'EMPLOYMENT_FIXED',
  EMPLOYMENT_TEMPORARY = 'EMPLOYMENT_TEMPORARY',
}

export enum ConnectHubHomeQClaimFileClassificationEnum {
  EMPLOYMENT_CONTRACT = 'EMPLOYMENT_CONTRACT',
  EMPLOYMENT_PARENTAL_BENEFITS = 'EMPLOYMENT_PARENTAL_BENEFITS',
  EMPLOYMENT_PAYSLIP = 'EMPLOYMENT_PAYSLIP',
  ENTREPRENEUR_FINANCIAL_REPORT = 'ENTREPRENEUR_FINANCIAL_REPORT',
  ENTREPRENEUR_PAYSLIP = 'ENTREPRENEUR_PAYSLIP',
  ENTREPRENEUR_REGISTRATION_CERTIFICATE = 'ENTREPRENEUR_REGISTRATION_CERTIFICATE',
  ENTREPRENEUR_SHARES = 'ENTREPRENEUR_SHARES',
  NOT_CLASSIFIED = 'NOT_CLASSIFIED',
  RETIRED_DISABLED_CERTIFICATE = 'RETIRED_DISABLED_CERTIFICATE',
  STUDENT_CERTIFICATE = 'STUDENT_CERTIFICATE',
}

export type ConnectHubHomeQClaimFileType = {
  __typename: 'ConnectHubHomeQClaimFileType'
  classification: ConnectHubHomeQClaimFileClassificationEnum
  file: Maybe<Scalars['String']['output']>
  id: Maybe<Scalars['ID']['output']>
  reference: Maybe<Scalars['String']['output']>
}

export enum ConnectHubHomeQClaimIncomeSourceEnum {
  documents = 'documents',
  not_applicable = 'not_applicable',
  tink = 'tink',
}

export enum ConnectHubHomeQClaimOrgTypeEnum {
  LIMITED_COMPANY = 'LIMITED_COMPANY',
  OTHER_COMPANY = 'OTHER_COMPANY',
}

export enum ConnectHubHomeQClaimStatusEnum {
  approved = 'approved',
  deleted = 'deleted',
  draft = 'draft',
  processing = 'processing',
  rejected = 'rejected',
}

export enum ConnectHubHomeQClaimTypeEnum {
  employment = 'employment',
  entrepreneur = 'entrepreneur',
  other = 'other',
  parental_leave = 'parental_leave',
  retired = 'retired',
  retired_disabled = 'retired_disabled',
  student = 'student',
  unemployed = 'unemployed',
}

/** Autogenerated return type of ConnectHubHomeQConnectUser. */
export type ConnectHubHomeQConnectUserPayload = {
  __typename: 'ConnectHubHomeQConnectUserPayload'
  errors: Maybe<Array<Validation>>
  success: Maybe<Scalars['Boolean']['output']>
}

/** Autogenerated return type of ConnectHubHomeQCreateApplication. */
export type ConnectHubHomeQCreateApplicationPayload = {
  __typename: 'ConnectHubHomeQCreateApplicationPayload'
  errors: Maybe<Array<Validation>>
  homeApplication: Maybe<ConnectHubHomeQHomeApplicationType>
}

/** Autogenerated return type of ConnectHubHomeQCreateFileReference. */
export type ConnectHubHomeQCreateFileReferencePayload = {
  __typename: 'ConnectHubHomeQCreateFileReferencePayload'
  errors: Maybe<Array<Validation>>
  fileReference: Maybe<ConnectHubHomeQFileReferenceType>
}

export type ConnectHubHomeQCurrentClaimType = {
  __typename: 'ConnectHubHomeQCurrentClaimType'
  attributes: ConnectHubHomeQClaimAttributesType
  dateCreated: Scalars['Date']['output']
  dateProcessed: Maybe<Scalars['Date']['output']>
  expiryDate: Maybe<Scalars['Date']['output']>
  files: Maybe<Array<ConnectHubHomeQClaimFileType>>
  id: Scalars['ID']['output']
  income: Scalars['Int']['output']
  incomeSource: ConnectHubHomeQClaimIncomeSourceEnum
  mergedFile: Maybe<Scalars['String']['output']>
  originalClaimId: Maybe<Scalars['ID']['output']>
  reason: Maybe<Scalars['String']['output']>
  renewed: Scalars['Boolean']['output']
  status: ConnectHubHomeQClaimStatusEnum
  type: ConnectHubHomeQClaimTypeEnum
}

/** Autogenerated return type of ConnectHubHomeQDeleteApplicationInterest. */
export type ConnectHubHomeQDeleteApplicationInterestPayload = {
  __typename: 'ConnectHubHomeQDeleteApplicationInterestPayload'
  errors: Maybe<Array<Validation>>
  success: Maybe<Scalars['Boolean']['output']>
}

/** Autogenerated return type of ConnectHubHomeQDeleteClaim. */
export type ConnectHubHomeQDeleteClaimPayload = {
  __typename: 'ConnectHubHomeQDeleteClaimPayload'
  errors: Maybe<Array<Validation>>
  success: Maybe<Scalars['Boolean']['output']>
}

/** Autogenerated return type of ConnectHubHomeQDisconnectUser. */
export type ConnectHubHomeQDisconnectUserPayload = {
  __typename: 'ConnectHubHomeQDisconnectUserPayload'
  success: Maybe<Scalars['Boolean']['output']>
}

export enum ConnectHubHomeQEntityTypeEnum {
  user_claim = 'user_claim',
  user_claim_merged = 'user_claim_merged',
}

export type ConnectHubHomeQFileReferenceHeadersType = {
  __typename: 'ConnectHubHomeQFileReferenceHeadersType'
  contentMd5: Scalars['String']['output']
  contentType: Scalars['String']['output']
  xAmzAcl: Scalars['String']['output']
}

export type ConnectHubHomeQFileReferenceType = {
  __typename: 'ConnectHubHomeQFileReferenceType'
  fileReference: Maybe<Scalars['String']['output']>
  headers: Maybe<ConnectHubHomeQFileReferenceHeadersType>
  resourceUri: Maybe<Scalars['String']['output']>
  signedUrl: Maybe<Scalars['String']['output']>
}

export type ConnectHubHomeQHomeApplicationPreconditions = {
  __typename: 'ConnectHubHomeQHomeApplicationPreconditions'
  activeAd: Scalars['Boolean']['output']
  allowsWarrantor: Scalars['Boolean']['output']
  applicantGroupAlreadyApplied: Scalars['Boolean']['output']
  applicantGroupContainsLocked: Scalars['Boolean']['output']
  applicantGroupExceedLimit: Scalars['Boolean']['output']
  applicantIncompleteProfile: Scalars['Boolean']['output']
  applicantMissingOccupation: Scalars['Boolean']['output']
  canApply: Scalars['Boolean']['output']
  claimedWarrantor: Scalars['Boolean']['output']
  coApplicantMissingInfo: Scalars['Boolean']['output']
  isMainApplicant: Scalars['Boolean']['output']
  restrictedDueToSeniorStatus: Scalars['Boolean']['output']
  restrictedDueToStudentStatus: Scalars['Boolean']['output']
  restrictedDueToYouthStatus: Scalars['Boolean']['output']
  warrantorMissingInfo: Scalars['Boolean']['output']
}

export type ConnectHubHomeQHomeApplicationType = {
  __typename: 'ConnectHubHomeQHomeApplicationType'
  /** @deprecated Use 'interests' instead */
  accessOnAgreement: Scalars['Boolean']['output']
  /** @deprecated Use 'interests' instead */
  area: Scalars['String']['output']
  /** @deprecated Use 'interests' instead */
  city: Scalars['String']['output']
  /** @deprecated Use 'interests' instead */
  countsToLimit: Scalars['Boolean']['output']
  /** @deprecated Use 'interests' instead */
  dateAccess: Scalars['Date']['output']
  /** @deprecated Use 'interests' instead */
  dateCreated: Scalars['DateTime']['output']
  /** @deprecated Use 'interests' instead */
  displayImage: Scalars['String']['output']
  /** @deprecated Use 'interests' instead */
  floor: Scalars['Int']['output']
  /** @deprecated Use 'interests' instead */
  home: Maybe<Home>
  id: Scalars['ID']['output']
  /** @deprecated Use 'interests' instead */
  interestId: Scalars['Int']['output']
  interests: Array<ConnectHubHomeQInterestType>
  /** @deprecated Use 'interests' instead */
  rent: Scalars['Int']['output']
  /** @deprecated Use 'interests' instead */
  responseDeadline: Maybe<Scalars['String']['output']>
  /** @deprecated Use 'interests' instead */
  rooms: Scalars['String']['output']
  /** @deprecated Use 'interests' instead */
  status: ConnectHubHomeqHomeApplicationStatusEnum
  /** @deprecated Use 'interests' instead */
  street: Scalars['String']['output']
  viewing: Maybe<ConnectHubHomeQViewingType>
}

export type ConnectHubHomeQHomeApplicationsType = {
  __typename: 'ConnectHubHomeQHomeApplicationsType'
  applications: Array<ConnectHubHomeQHomeApplicationType>
  /** The maximum number of applications the user can have */
  limit: Scalars['Int']['output']
}

export type ConnectHubHomeQInterestType = {
  __typename: 'ConnectHubHomeQInterestType'
  accessOnAgreement: Scalars['Boolean']['output']
  area: Scalars['String']['output']
  city: Scalars['String']['output']
  countsToLimit: Scalars['Boolean']['output']
  dateAccess: Scalars['Date']['output']
  dateCreated: Scalars['DateTime']['output']
  displayImage: Scalars['String']['output']
  floor: Scalars['Int']['output']
  home: Maybe<Home>
  id: Scalars['ID']['output']
  landlordObjectId: Scalars['String']['output']
  rent: Scalars['Int']['output']
  responseDeadline: Maybe<Scalars['DateTime']['output']>
  rooms: Scalars['String']['output']
  status: ConnectHubHomeqInterestStatusEnum
  street: Scalars['String']['output']
}

export type ConnectHubHomeQLandlordCompanyType = {
  __typename: 'ConnectHubHomeQLandlordCompanyType'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type ConnectHubHomeQPointsType = {
  __typename: 'ConnectHubHomeQPointsType'
  bonusPoints: Array<ConnectHubHomeQBonusPointsType>
  queuePoints: Scalars['Int']['output']
}

/** Autogenerated return type of ConnectHubHomeQRenewClaim. */
export type ConnectHubHomeQRenewClaimPayload = {
  __typename: 'ConnectHubHomeQRenewClaimPayload'
  claim: Maybe<ConnectHubHomeQCurrentClaimType>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of ConnectHubHomeQUpdateClaim. */
export type ConnectHubHomeQUpdateClaimPayload = {
  __typename: 'ConnectHubHomeQUpdateClaimPayload'
  errors: Maybe<Array<Validation>>
  success: Maybe<Scalars['Boolean']['output']>
}

export type ConnectHubHomeQUserClaimsType = {
  __typename: 'ConnectHubHomeQUserClaimsType'
  allowedClaimTypes: Array<ConnectHubHomeQClaimTypeEnum>
  currentClaims: Array<ConnectHubHomeQCurrentClaimType>
}

export type ConnectHubHomeQUserType = {
  __typename: 'ConnectHubHomeQUserType'
  dateOfBirth: Scalars['String']['output']
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  points: ConnectHubHomeQPointsType
}

export type ConnectHubHomeQViewingType = {
  __typename: 'ConnectHubHomeQViewingType'
  rsvpStatus: ConnectHubHomeqViewingResponseStatusEnum
  viewingDatetime: Maybe<Scalars['String']['output']>
}

export enum ConnectHubHomeqHomeApplicationStatusEnum {
  APPLIED = 'APPLIED',
  CONTRACT = 'CONTRACT',
  DELETED = 'DELETED',
  OFFER = 'OFFER',
  OFFER_ACCEPTED = 'OFFER_ACCEPTED',
  RESERVED = 'RESERVED',
  RESERVED_FOR_SOMEONE_ELSE = 'RESERVED_FOR_SOMEONE_ELSE',
}

export enum ConnectHubHomeqInterestStatusEnum {
  APPLIED = 'APPLIED',
  CONTRACT = 'CONTRACT',
  DELETED = 'DELETED',
  OFFER = 'OFFER',
  OFFER_ACCEPTED = 'OFFER_ACCEPTED',
  RESERVED = 'RESERVED',
  RESERVED_FOR_SOMEONE_ELSE = 'RESERVED_FOR_SOMEONE_ELSE',
}

export enum ConnectHubHomeqViewingResponseStatusEnum {
  NO = 'NO',
  PENDING = 'PENDING',
  YES = 'YES',
}

export type ConnectHubInputObjectsCreateFileReferenceInput = {
  contentLength: Scalars['Int']['input']
  contentMd5: Scalars['String']['input']
  entityType: ConnectHubHomeQEntityTypeEnum
  mimeType: Scalars['String']['input']
}

export type ConnectHubInputObjectsUpdateClaimFileInput = {
  classification?: InputMaybe<ConnectHubHomeQClaimFileClassificationEnum>
  reference?: InputMaybe<Scalars['String']['input']>
}

export type ConnectHubInputObjectsUpdateClaimInput = {
  description?: InputMaybe<Scalars['String']['input']>
  duration?: InputMaybe<ConnectHubHomeQClaimDurationEnum>
  files?: InputMaybe<Array<ConnectHubInputObjectsUpdateClaimFileInput>>
  incomeSource?: InputMaybe<ConnectHubHomeQClaimIncomeSourceEnum>
  moveToProcessing?: InputMaybe<Scalars['Boolean']['input']>
}

export type Contract = {
  __typename: 'Contract'
  acceptsCosignatures: Maybe<Scalars['Boolean']['output']>
  adminNotes: Array<AdminNote>
  annualRentIncrease: Scalars['Float']['output']
  apartmentNumber: Maybe<Scalars['String']['output']>
  availablePreviewLanguages: Array<ContractLanguageEnum>
  booking: Maybe<Booking>
  canUse: Array<Scalars['String']['output']>
  cleaningFee: Scalars['Int']['output']
  contractDetails: Maybe<ContractDetails>
  conversation: Maybe<Conversation>
  cosigningTenants: Maybe<Array<User>>
  createdAt: Scalars['DateTime']['output']
  currency: CurrencyEnum
  currentUserSignatory: Signatory
  depositFree: Maybe<Scalars['Boolean']['output']>
  depositFreeCost: Maybe<Scalars['Int']['output']>
  depositPayoutApprovedAt: Maybe<Scalars['DateTime']['output']>
  depositPayoutDeniedAt: Maybe<Scalars['DateTime']['output']>
  duration: Maybe<Duration>
  electricityFee: Maybe<ElectricityFeeType>
  evictionProcess: Maybe<EvictionProcess>
  extensionContract: Maybe<Contract>
  externalContractTakeover: Maybe<ExternalContractTakeover>
  extraCosts: Maybe<Array<ExtraCost>>
  fee: Maybe<Scalars['Int']['output']>
  /** @deprecated DEPRECATED: Use finance_invoices instead */
  financeDepositInvoices: Array<FinanceDepositInvoice>
  financeInvoices: Array<InvoiceInterface>
  financePayouts: Array<FinancePayoutType>
  /** @deprecated DEPRECATED: Use finance_invoices instead */
  financeRentInvoices: Array<FinanceMonthlyRentInvoice>
  furniture: Maybe<Scalars['Boolean']['output']>
  home: Maybe<Home>
  id: Scalars['ID']['output']
  ifInsurancePromotion: Maybe<IfInsurancePromotion>
  inspectionProtocol: Maybe<Blob>
  insurance: Maybe<HomeInsuranceEnum>
  insuranceCost: Maybe<Scalars['Int']['output']>
  insuranceRecord: Maybe<Insurance>
  insuranceResponsibility: Maybe<InsuranceResponsibilityEnum>
  inventory: Array<Scalars['String']['output']>
  inventoryLists: Maybe<Array<Blob>>
  invoicedThrough: Maybe<Scalars['DateTime']['output']>
  invoices: Array<Invoice>
  landlord: User
  landlordAffiliateCode: Maybe<AffiliateCode>
  landlordBaseFee: Maybe<Scalars['Int']['output']>
  landlordContactInfo: Maybe<LandlordContactInfo>
  landlordFees: ContractLandlordFees
  landlordHasKeys: Maybe<Scalars['Boolean']['output']>
  landlordPostSignDocuments: Maybe<Array<Blob>>
  landlordServices: ContractLandlordServices
  language: ContractLanguageEnum
  market: Market
  marketDetails: Maybe<MarketDetailsUnion>
  motherContract: Maybe<Contract>
  numberOfTenants: Maybe<Scalars['Int']['output']>
  originalFee: Maybe<Scalars['Int']['output']>
  otherLandlordDocuments: Array<Blob>
  overnightStay: Maybe<Scalars['Boolean']['output']>
  ownHome: Maybe<Scalars['Boolean']['output']>
  paymentDay: Scalars['Int']['output']
  paymentInAdvance: Scalars['Boolean']['output']
  payoutDay: Scalars['Int']['output']
  payoutInAdvance: Scalars['Boolean']['output']
  pendingTerminationEndDate: Maybe<Scalars['DateTime']['output']>
  pets: Maybe<Scalars['Boolean']['output']>
  postCleaning: Maybe<ContractPostCleaningEnum>
  preCleaning: Maybe<ContractPreCleaningEnum>
  pricingModel: PricingModel
  qasaGuarantee: Maybe<Scalars['Boolean']['output']>
  qasaGuaranteeCost: Maybe<Scalars['Int']['output']>
  qasaGuaranteeMonths: Maybe<Scalars['Int']['output']>
  renovations: Maybe<Scalars['Boolean']['output']>
  rent: Maybe<Scalars['Int']['output']>
  rentExcludes: Array<Scalars['String']['output']>
  rentIncludes: Array<Scalars['String']['output']>
  rentalInsuranceMilaRequest: Maybe<FranceInsuranceRequestType>
  rentalType: ContractRentalTypeEnum
  scriveId: Maybe<Scalars['ID']['output']>
  signUrl: Maybe<Scalars['String']['output']>
  signatories: Array<Signatory>
  signedAt: Maybe<Scalars['DateTime']['output']>
  signingProcess: SigningProcessEnum
  smallRepairs: Maybe<ContractSmallRepairsEnum>
  smoker: Maybe<Scalars['Boolean']['output']>
  status: ContractStatusEnum
  subletception: Maybe<Scalars['Boolean']['output']>
  technicalDiagnoses: Maybe<Array<Blob>>
  temporaryInstallations: Maybe<Scalars['Boolean']['output']>
  tenant: Maybe<User>
  tenantAffiliateCode: Maybe<AffiliateCode>
  tenantBaseFee: Maybe<Scalars['Int']['output']>
  tenantContactInfo: Maybe<TenantContactInfo>
  tenantFees: ContractTenantFees
  tenantInvitations: Maybe<Array<ContractTenantInvitation>>
  tenantPostSignDocuments: Maybe<Array<Blob>>
  tenantServices: ContractTenantServices
  tenureType: Maybe<TenureTypeEnum>
  terminationNotice: Maybe<Scalars['Boolean']['output']>
  termsUrl: Maybe<Scalars['String']['output']>
  type: ContractTypeEnum
  updatedAt: Maybe<Scalars['DateTime']['output']>
  upload: Maybe<Upload>
  user: User
}

/** The connection type for Contract. */
export type ContractConnection = {
  __typename: 'ContractConnection'
  /** A list of edges. */
  edges: Array<ContractEdge>
  /** A list of nodes. */
  nodes: Array<Contract>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

export type ContractDetails = {
  __typename: 'ContractDetails'
  additionalInformation: Maybe<Scalars['String']['output']>
  bathroomShared: Maybe<Scalars['Boolean']['output']>
  bindingTime: Maybe<Scalars['Int']['output']>
  cadastralReference: Maybe<Scalars['String']['output']>
  canBeTerminated: Maybe<Scalars['Boolean']['output']>
  codeOfConduct: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  keySpecification: Maybe<Scalars['String']['output']>
  kitchenShared: Maybe<Scalars['Boolean']['output']>
  knownDamages: Maybe<Scalars['String']['output']>
  legalType: Maybe<LegalTypeEnum>
  municipality: Maybe<Scalars['String']['output']>
  rentedAsIs: Maybe<Scalars['Boolean']['output']>
  sectionNumber: Maybe<Scalars['String']['output']>
  shorterTerminationExplanation: Maybe<Scalars['String']['output']>
  tenantEmail: Maybe<Scalars['String']['output']>
  tenantFamilyName: Maybe<Scalars['String']['output']>
  tenantFirstName: Maybe<Scalars['String']['output']>
  tenantPhoneNumber: Maybe<Scalars['String']['output']>
  terminationNoticeMonths: Maybe<Scalars['Int']['output']>
  titleNumber: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

/** An edge in a connection. */
export type ContractEdge = {
  __typename: 'ContractEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Contract
}

export type ContractInput = {
  furniture: Scalars['Boolean']['input']
  recoverableCharges: FranceRecoverableChargesInput
  scriveId: Scalars['String']['input']
}

export type ContractLandlordFees = {
  __typename: 'ContractLandlordFees'
  base: Fee
}

export type ContractLandlordServices = {
  __typename: 'ContractLandlordServices'
  insurance: Service
  qasaGuarantee: Service
}

export enum ContractLanguageEnum {
  en = 'en',
  fi = 'fi',
  fr = 'fr',
  no = 'no',
  sv = 'sv',
}

export enum ContractPostCleaningEnum {
  post_cleaning_landlord = 'post_cleaning_landlord',
  post_cleaning_tenant = 'post_cleaning_tenant',
}

export enum ContractPreCleaningEnum {
  pre_cleaning_landlord = 'pre_cleaning_landlord',
  pre_cleaning_tenant = 'pre_cleaning_tenant',
}

export type ContractPreview = {
  __typename: 'ContractPreview'
  errors: Maybe<Array<Validation>>
  previewSource: Maybe<Scalars['String']['output']>
}

export enum ContractRentalTypeEnum {
  long_term = 'long_term',
  vacation = 'vacation',
}

export enum ContractSmallRepairsEnum {
  small_repairs_landlord = 'small_repairs_landlord',
  small_repairs_tenant = 'small_repairs_tenant',
}

export enum ContractStatusEnum {
  awaiting_insurance_approval = 'awaiting_insurance_approval',
  canceled = 'canceled',
  canceled_bad_certificate = 'canceled_bad_certificate',
  draft = 'draft',
  ended = 'ended',
  extended = 'extended',
  final_draft = 'final_draft',
  pending = 'pending',
  pending_aprila_application = 'pending_aprila_application',
  pending_aprila_rental_agreement = 'pending_aprila_rental_agreement',
  pending_tenant_credit_check = 'pending_tenant_credit_check',
  rejected = 'rejected',
  signed = 'signed',
  timedout = 'timedout',
}

export type ContractTemplate = {
  __typename: 'ContractTemplate'
  canUse: Maybe<Array<Scalars['String']['output']>>
  landlordHasKeys: Maybe<Scalars['Boolean']['output']>
  rentExcludes: Maybe<Array<Scalars['String']['output']>>
  rentIncludes: Maybe<Array<Scalars['String']['output']>>
  smallRepairs: Maybe<ContractSmallRepairsEnum>
}

export type ContractTemplateInput = {
  canUse?: InputMaybe<Array<Scalars['String']['input']>>
  landlordHasKeys?: InputMaybe<Scalars['Boolean']['input']>
  rentExcludes?: InputMaybe<Array<Scalars['String']['input']>>
  rentIncludes?: InputMaybe<Array<Scalars['String']['input']>>
  smallRepairs?: InputMaybe<ContractSmallRepairsEnum>
}

export type ContractTenantFees = {
  __typename: 'ContractTenantFees'
  base: Fee
  monthlyElectricityFee: Maybe<Fee>
  waterFeePerTenant: Fee
}

export type ContractTenantInvitation = {
  __typename: 'ContractTenantInvitation'
  claimKey: Maybe<Scalars['String']['output']>
  claimable: Scalars['Boolean']['output']
  home: Maybe<Home>
  invitedBy: Maybe<User>
  recipientEmail: Maybe<Scalars['String']['output']>
  tenantType: TenantTypeEnum
}

export type ContractTenantServices = {
  __typename: 'ContractTenantServices'
  depositFree: Service
}

export enum ContractTypeEnum {
  extension = 'extension',
  normal = 'normal',
}

export type ContractsByStatusCounts = {
  __typename: 'ContractsByStatusCounts'
  awaitingInsuranceApproval: Scalars['Int']['output']
  canceled: Scalars['Int']['output']
  canceledBadCertificate: Scalars['Int']['output']
  draft: Scalars['Int']['output']
  ended: Scalars['Int']['output']
  extended: Scalars['Int']['output']
  finalDraft: Scalars['Int']['output']
  pending: Scalars['Int']['output']
  pendingAprilaApplication: Scalars['Int']['output']
  pendingAprilaRentalAgreement: Scalars['Int']['output']
  pendingTenantCreditCheck: Scalars['Int']['output']
  rejected: Scalars['Int']['output']
  signed: Scalars['Int']['output']
  timedout: Scalars['Int']['output']
}

export type ContractsCounts = {
  __typename: 'ContractsCounts'
  awaitingInsuranceApproval: Scalars['Int']['output']
  canceled: Scalars['Int']['output']
  canceledBadCertificate: Scalars['Int']['output']
  draft: Scalars['Int']['output']
  ended: Scalars['Int']['output']
  extended: Scalars['Int']['output']
  finalDraft: Scalars['Int']['output']
  longTerm: ContractsByStatusCounts
  pending: Scalars['Int']['output']
  pendingAprilaApplication: Scalars['Int']['output']
  pendingAprilaRentalAgreement: Scalars['Int']['output']
  pendingTenantCreditCheck: Scalars['Int']['output']
  rejected: Scalars['Int']['output']
  signed: Scalars['Int']['output']
  timedout: Scalars['Int']['output']
  total: ContractsByStatusCounts
  vacation: ContractsByStatusCounts
}

export type Conversation = {
  __typename: 'Conversation'
  archivedAt: Maybe<Scalars['DateTime']['output']>
  booking: Maybe<Booking>
  conversationUserRelations: Array<ConversationUserRelation>
  createdAt: Scalars['DateTime']['output']
  currentConversationState: ConversationStateEnum
  home: Home
  homeApplication: Maybe<HomeApplication>
  id: Scalars['ID']['output']
  initiator: Scalars['Boolean']['output']
  lastMessage: Maybe<ConversationMessage>
  /** @deprecated Use last_seen_message_ids instead */
  lastSeenMessageId: Maybe<Scalars['ID']['output']>
  lastSeenMessageIds: Array<LastSeenMessage>
  messages: ConversationMessageConnection
  read: Scalars['Boolean']['output']
  role: ConversationRoleTypeEnum
  starMarked: Scalars['Boolean']['output']
  status: ConversationStatusEnum
  statusChangedAt: Maybe<Scalars['DateTime']['output']>
  statusChanger: Maybe<User>
  unreadMessagesCount: Scalars['Int']['output']
  users: Array<User>
  viewing: Maybe<Viewing>
}

export type ConversationMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The connection type for Conversation. */
export type ConversationConnection = {
  __typename: 'ConversationConnection'
  /** A list of edges. */
  edges: Array<ConversationEdge>
  /** A list of nodes. */
  nodes: Array<Conversation>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type ConversationEdge = {
  __typename: 'ConversationEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Conversation
}

export type ConversationMessage = {
  __typename: 'ConversationMessage'
  conversation: Conversation
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  message: Maybe<Scalars['String']['output']>
  messageType: ConversationMessageTypeEnum
  reference: Maybe<ConversationMessageReferenceUnion>
  updatedAt: Scalars['DateTime']['output']
  upload: Maybe<Upload>
  user: User
}

/** The connection type for ConversationMessage. */
export type ConversationMessageConnection = {
  __typename: 'ConversationMessageConnection'
  /** A list of edges. */
  edges: Array<ConversationMessageEdge>
  /** A list of nodes. */
  nodes: Array<ConversationMessage>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type ConversationMessageEdge = {
  __typename: 'ConversationMessageEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ConversationMessage
}

export type ConversationMessageReferenceUnion =
  | BookingOffer
  | ContractTenantInvitation
  | HomeApplication
  | Shortcut

export enum ConversationMessageTypeEnum {
  booking_accepted = 'booking_accepted',
  booking_canceled = 'booking_canceled',
  booking_canceled_by_admin = 'booking_canceled_by_admin',
  booking_confirmed = 'booking_confirmed',
  booking_declined = 'booking_declined',
  booking_declined_check_in_passed = 'booking_declined_check_in_passed',
  booking_declined_due_to_inactivity = 'booking_declined_due_to_inactivity',
  booking_declined_due_to_late_payment = 'booking_declined_due_to_late_payment',
  booking_offer_accepted = 'booking_offer_accepted',
  booking_offer_canceled = 'booking_offer_canceled',
  booking_offer_canceled_by_other_booking = 'booking_offer_canceled_by_other_booking',
  booking_offer_proposed = 'booking_offer_proposed',
  booking_offer_rejected = 'booking_offer_rejected',
  booking_payment_initiated = 'booking_payment_initiated',
  booking_requested = 'booking_requested',
  booking_revoked = 'booking_revoked',
  contract_canceled_bad_certificate = 'contract_canceled_bad_certificate',
  contract_draft_created = 'contract_draft_created',
  contract_landlord_signed = 'contract_landlord_signed',
  contract_tenant_signed = 'contract_tenant_signed',
  conversation_closed = 'conversation_closed',
  conversation_reopened = 'conversation_reopened',
  cosigning_tenant_accepted = 'cosigning_tenant_accepted',
  cosigning_tenant_invited = 'cosigning_tenant_invited',
  cosigning_tenant_removed = 'cosigning_tenant_removed',
  home_application_declined = 'home_application_declined',
  home_application_in_contact = 'home_application_in_contact',
  home_application_revoked = 'home_application_revoked',
  home_archived = 'home_archived',
  home_rented_out = 'home_rented_out',
  image = 'image',
  offering_received = 'offering_received',
  shortcut_canceled_new_conversation = 'shortcut_canceled_new_conversation',
  shortcut_canceled_old_conversation = 'shortcut_canceled_old_conversation',
  shortcut_completed_new_conversation = 'shortcut_completed_new_conversation',
  shortcut_completed_old_conversation = 'shortcut_completed_old_conversation',
  shortcut_new_home_application = 'shortcut_new_home_application',
  tenant_wants_contract = 'tenant_wants_contract',
  text = 'text',
  undo_home_application_declined = 'undo_home_application_declined',
  undo_home_application_revoked = 'undo_home_application_revoked',
  user_suspended = 'user_suspended',
  viewing_confirmed = 'viewing_confirmed',
  viewing_detected = 'viewing_detected',
  viewing_reminder = 'viewing_reminder',
}

export enum ConversationMessageTypeInputEnum {
  image = 'image',
  tenant_wants_contract = 'tenant_wants_contract',
  text = 'text',
}

export enum ConversationRoleTypeEnum {
  landlord = 'landlord',
  tenant = 'tenant',
}

export enum ConversationStateEnum {
  contract_draft_created = 'contract_draft_created',
  contract_landlord_sign_pending = 'contract_landlord_sign_pending',
  contract_other = 'contract_other',
  contract_signed = 'contract_signed',
  contract_tenant_sign_pending = 'contract_tenant_sign_pending',
  waiting_for_contract = 'waiting_for_contract',
}

export enum ConversationStatusEnum {
  closed = 'closed',
  open = 'open',
}

export type ConversationUserRelation = {
  __typename: 'ConversationUserRelation'
  archivedAt: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  lastSeenMessageId: Maybe<Scalars['Int']['output']>
  read: Scalars['Boolean']['output']
  role: ConversationRoleTypeEnum
  starMarked: Scalars['Boolean']['output']
  user: User
}

export type CoordinatesStatistics = {
  __typename: 'CoordinatesStatistics'
  /** users count of tenant_ads_in_location + saved_searches_in_location */
  interestedInLocation: Scalars['Int']['output']
  latitude: Maybe<Scalars['Float']['output']>
  longitude: Maybe<Scalars['Float']['output']>
  /** only long_term */
  recentlyPublishedHomes: Scalars['Int']['output']
  savedSearchUsers: Scalars['Int']['output']
  tenantAdsUsers: Scalars['Int']['output']
}

export type Cost = {
  __typename: 'Cost'
  factor: Maybe<Scalars['Float']['output']>
  fixed: Maybe<Scalars['Int']['output']>
  isFree: Scalars['Boolean']['output']
  total: Scalars['Int']['output']
}

export enum CountryCodeEnum {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  XK = 'XK',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

export type CreateAdminNoteInput = {
  note: Scalars['String']['input']
  recordId?: InputMaybe<Scalars['ID']['input']>
  recordType?: InputMaybe<AdminNoteRecordTypeEnum>
  uid?: InputMaybe<Scalars['ID']['input']>
}

/** Autogenerated return type of CreateAdminNote. */
export type CreateAdminNotePayload = {
  __typename: 'CreateAdminNotePayload'
  adminNote: Maybe<AdminNote>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of CreateApplicationBoardList. */
export type CreateApplicationBoardListPayload = {
  __typename: 'CreateApplicationBoardListPayload'
  applicationBoardList: Maybe<ApplicationBoardList>
  errors: Maybe<Array<Validation>>
}

export type CreateBlobInput = {
  byteSize: Scalars['Int']['input']
  checksum: Scalars['String']['input']
  contentType: Scalars['String']['input']
  filename: Scalars['String']['input']
  key?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['JSON']['input']>
  serviceName?: InputMaybe<ActiveStorageServiceEnum>
}

/** Autogenerated return type of CreateBlob. */
export type CreateBlobPayload = {
  __typename: 'CreateBlobPayload'
  headers: Scalars['JSON']['output']
  id: Scalars['ID']['output']
  signedUrl: Scalars['String']['output']
}

/** Autogenerated return type of CreateBookingOfferFromBooking. */
export type CreateBookingOfferFromBookingPayload = {
  __typename: 'CreateBookingOfferFromBookingPayload'
  bookingOffer: Maybe<BookingOffer>
  errors: Maybe<Array<Validation>>
}

export type CreateBookingOfferInput = {
  bookingId: Scalars['ID']['input']
  checkIn: Scalars['Date']['input']
  checkOut: Scalars['Date']['input']
  rentPrice: Scalars['Int']['input']
}

/** Autogenerated return type of CreateBookingOffer. */
export type CreateBookingOfferPayload = {
  __typename: 'CreateBookingOfferPayload'
  bookingOffer: Maybe<BookingOffer>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of CreateCertificate. */
export type CreateCertificatePayload = {
  __typename: 'CreateCertificatePayload'
  certificate: Maybe<Certificate>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of CreateClaim. */
export type CreateClaimPayload = {
  __typename: 'CreateClaimPayload'
  claim: Maybe<ConnectHubHomeQCurrentClaimType>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of CreateCompensationClaim. */
export type CreateCompensationClaimPayload = {
  __typename: 'CreateCompensationClaimPayload'
  compensationClaim: CompensationClaim
}

/** Autogenerated return type of CreateCompensationReservations. */
export type CreateCompensationReservationsPayload = {
  __typename: 'CreateCompensationReservationsPayload'
  compensationClaim: Maybe<CompensationClaim>
}

export type CreateConversationMessageInput = {
  conversationId: Scalars['ID']['input']
  message?: InputMaybe<Scalars['String']['input']>
  messageType?: InputMaybe<ConversationMessageTypeInputEnum>
  uploadId?: InputMaybe<Scalars['ID']['input']>
}

/** Autogenerated return type of CreateConversationMessage. */
export type CreateConversationMessagePayload = {
  __typename: 'CreateConversationMessagePayload'
  errors: Maybe<Array<Validation>>
  message: Maybe<ConversationMessage>
}

/** Autogenerated return type of CreateCreditReport. */
export type CreateCreditReportPayload = {
  __typename: 'CreateCreditReportPayload'
  creditReport: Maybe<CreditReport>
  errors: Maybe<Array<Validation>>
}

export type CreateDurationInput = {
  durationPreference?: InputMaybe<CreateDurationPreferenceInput>
  endOptimal?: InputMaybe<Scalars['DateTime']['input']>
  endUfn?: InputMaybe<Scalars['Boolean']['input']>
  possibilityOfExtension?: InputMaybe<Scalars['Boolean']['input']>
  startAsap?: InputMaybe<Scalars['Boolean']['input']>
  startOptimal?: InputMaybe<Scalars['DateTime']['input']>
}

export type CreateDurationPreferenceInput = {
  endEarliest?: InputMaybe<Scalars['DateTime']['input']>
  longestDurationDays?: InputMaybe<Scalars['Int']['input']>
  shortestDurationDays?: InputMaybe<Scalars['Int']['input']>
  startLatest?: InputMaybe<Scalars['DateTime']['input']>
}

export type CreateExternalContractTakeoverInput = {
  contract: ContractInput
  duration: FranceExternalContractTakeoverDurationInput
  landlordUid: Scalars['ID']['input']
  location: LocationInput
  milaId: Scalars['String']['input']
  tenant: TenantInput
}

/** Autogenerated return type of CreateHomeFromTemplate. */
export type CreateHomeFromTemplatePayload = {
  __typename: 'CreateHomeFromTemplatePayload'
  errors: Maybe<Array<Validation>>
  home: Maybe<Home>
}

export type CreateHomeInput = {
  apartmentNumber?: InputMaybe<Scalars['String']['input']>
  applicationDeadline?: InputMaybe<Scalars['DateTime']['input']>
  bathroomRenovationYear?: InputMaybe<Scalars['Int']['input']>
  bedCount?: InputMaybe<Scalars['Int']['input']>
  bedroomCount?: InputMaybe<Scalars['Int']['input']>
  buildYear?: InputMaybe<Scalars['Int']['input']>
  buildingFloors?: InputMaybe<Scalars['Int']['input']>
  cancellationPolicyName?: InputMaybe<CancellationPolicyNameEnum>
  corporateHome?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  descriptionBuilding?: InputMaybe<Scalars['String']['input']>
  descriptionContract?: InputMaybe<Scalars['String']['input']>
  descriptionTransportation?: InputMaybe<Scalars['String']['input']>
  duration: CreateDurationInput
  electricityFee?: InputMaybe<ElectricityFeeInput>
  energyClass?: InputMaybe<Scalars['String']['input']>
  firsthand?: InputMaybe<Scalars['Boolean']['input']>
  floor?: InputMaybe<Scalars['Int']['input']>
  hasKitchen?: InputMaybe<Scalars['Boolean']['input']>
  homeTemplateIds?: InputMaybe<Array<Scalars['ID']['input']>>
  houseRules?: InputMaybe<Scalars['String']['input']>
  housingAssociation?: InputMaybe<Scalars['String']['input']>
  instantBooking?: InputMaybe<Scalars['Boolean']['input']>
  insurance?: InputMaybe<HomeInsuranceEnum>
  kitchenRenovationYear?: InputMaybe<Scalars['Int']['input']>
  location: CreateLocationInput
  matterportEmbedUrl?: InputMaybe<Scalars['String']['input']>
  maxRent?: InputMaybe<Scalars['Int']['input']>
  maxRoomCount?: InputMaybe<Scalars['Float']['input']>
  maxSquareMeters?: InputMaybe<Scalars['Int']['input']>
  maxTenantCount?: InputMaybe<Scalars['Int']['input']>
  minRent?: InputMaybe<Scalars['Int']['input']>
  minRoomCount?: InputMaybe<Scalars['Float']['input']>
  minSquareMeters?: InputMaybe<Scalars['Int']['input']>
  minTenantCount?: InputMaybe<Scalars['Int']['input']>
  numberOfHomes?: InputMaybe<Scalars['Int']['input']>
  organizationUid?: InputMaybe<Scalars['ID']['input']>
  origin?: InputMaybe<Scalars['String']['input']>
  ownHome?: InputMaybe<Scalars['Boolean']['input']>
  qasaGuarantee?: InputMaybe<Scalars['Boolean']['input']>
  rent: Scalars['Int']['input']
  rentalType?: InputMaybe<HomeRentalTypeEnum>
  roomCount?: InputMaybe<Scalars['Float']['input']>
  seniorHome?: InputMaybe<Scalars['Boolean']['input']>
  shared: Scalars['Boolean']['input']
  squareMeters?: InputMaybe<Scalars['Int']['input']>
  studentHome?: InputMaybe<Scalars['Boolean']['input']>
  tenantCount: Scalars['Int']['input']
  tenureType?: InputMaybe<TenureTypeEnum>
  title?: InputMaybe<Scalars['String']['input']>
  toiletCount?: InputMaybe<Scalars['Int']['input']>
  traits?: InputMaybe<Array<TraitTypeEnum>>
  translatedDescriptions?: InputMaybe<Array<TranslatedDescriptionInput>>
  type: HomeTypeEnum
  uploadIds?: InputMaybe<Array<Scalars['ID']['input']>>
  waterFeePerTenant?: InputMaybe<Scalars['Int']['input']>
}

/** Autogenerated return type of CreateHome. */
export type CreateHomePayload = {
  __typename: 'CreateHomePayload'
  errors: Maybe<Array<Validation>>
  home: Maybe<Home>
}

export type CreateInstantSignContractInput = {
  durationAttributes?: InputMaybe<InstantSignDurationInput>
  homeId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateInstantSignContract. */
export type CreateInstantSignContractPayload = {
  __typename: 'CreateInstantSignContractPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

export type CreateLandlordHomeApplicationInput = {
  homeId: Scalars['ID']['input']
  /** @deprecated Not used */
  matchInterest?: InputMaybe<Scalars['String']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  tenantUid: Scalars['ID']['input']
}

/** Autogenerated return type of CreateLandlordHomeApplication. */
export type CreateLandlordHomeApplicationPayload = {
  __typename: 'CreateLandlordHomeApplicationPayload'
  errors: Maybe<Array<Validation>>
  homeApplication: Maybe<HomeApplication>
}

/** Autogenerated return type of CreateListingPlatformSelectorEvent. */
export type CreateListingPlatformSelectorEventPayload = {
  __typename: 'CreateListingPlatformSelectorEventPayload'
  success: Scalars['Boolean']['output']
}

export type CreateLocationInput = {
  country?: InputMaybe<Scalars['String']['input']>
  countryCode?: InputMaybe<Scalars['String']['input']>
  formattedAddress?: InputMaybe<Scalars['String']['input']>
  /** @deprecated Use `placeId` instead */
  googleMapsPlaceId?: InputMaybe<Scalars['String']['input']>
  granularity?: InputMaybe<LocationGranularityEnum>
  latitude?: InputMaybe<Scalars['Float']['input']>
  locality?: InputMaybe<Scalars['String']['input']>
  longitude?: InputMaybe<Scalars['Float']['input']>
  placeId?: InputMaybe<Scalars['String']['input']>
  postalCode?: InputMaybe<Scalars['String']['input']>
  route?: InputMaybe<Scalars['String']['input']>
  shortName?: InputMaybe<Scalars['String']['input']>
  streetNumber?: InputMaybe<Scalars['String']['input']>
  sublocality?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of CreateMatchingScoreFeedback. */
export type CreateMatchingScoreFeedbackPayload = {
  __typename: 'CreateMatchingScoreFeedbackPayload'
  errors: Maybe<Array<Validation>>
  success: Scalars['Boolean']['output']
}

export type CreateOfferingInput = {
  homeApplicationId: Scalars['ID']['input']
  homeTemplateId?: InputMaybe<Scalars['ID']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  showingAt?: InputMaybe<Scalars['DateTime']['input']>
  showingContactInformation?: InputMaybe<Scalars['String']['input']>
  showingStatus: ShowingStatusEnum
}

/** Autogenerated return type of CreateOffering. */
export type CreateOfferingPayload = {
  __typename: 'CreateOfferingPayload'
  errors: Maybe<Array<Validation>>
  offering: Maybe<Offering>
}

/** Autogenerated return type of CreateOnfidoVerificationLink. */
export type CreateOnfidoVerificationLinkPayload = {
  __typename: 'CreateOnfidoVerificationLinkPayload'
  errors: Maybe<Array<Validation>>
  url: Maybe<Scalars['String']['output']>
}

export type CreatePublicDocumentResult = AuthorizationError | NotFoundError | PublicDocument | ValidationError

export type CreateSavedSearchInput = {
  monitoring?: InputMaybe<Scalars['Boolean']['input']>
  rentalType?: InputMaybe<HomeRentalTypeEnum>
  searchParams: HomeSearchParamsInput
  title: Scalars['String']['input']
  visibility?: InputMaybe<SavedSearchVisibilityEnum>
}

/** Autogenerated return type of CreateSavedSearch. */
export type CreateSavedSearchPayload = {
  __typename: 'CreateSavedSearchPayload'
  errors: Maybe<Array<Validation>>
  savedSearch: SavedSearch
}

/** Autogenerated return type of CreateSavingsVerification. */
export type CreateSavingsVerificationPayload = {
  __typename: 'CreateSavingsVerificationPayload'
  savingsVerification: SavingsVerification
}

/** Autogenerated return type of CreateSubUser. */
export type CreateSubUserPayload = {
  __typename: 'CreateSubUserPayload'
  errors: Maybe<Array<Validation>>
  success: Scalars['Boolean']['output']
}

export type CreateTenantHomeApplicationInput = {
  counterOffer: Scalars['Int']['input']
  endOptimal?: InputMaybe<Scalars['DateTime']['input']>
  homeId: Scalars['ID']['input']
  homeTemplateIds?: InputMaybe<Array<Scalars['ID']['input']>>
  message?: InputMaybe<Scalars['String']['input']>
  startOptimal?: InputMaybe<Scalars['DateTime']['input']>
}

/** Autogenerated return type of CreateTenantHomeApplication. */
export type CreateTenantHomeApplicationPayload = {
  __typename: 'CreateTenantHomeApplicationPayload'
  errors: Maybe<Array<Validation>>
  homeApplication: Maybe<HomeApplication>
}

export type CreateTenantInvitationInput = {
  contractId: Scalars['ID']['input']
  email?: InputMaybe<Scalars['String']['input']>
  tenantType: TenantTypeEnum
}

/** Autogenerated return type of CreateTenantInvitation. */
export type CreateTenantInvitationPayload = {
  __typename: 'CreateTenantInvitationPayload'
  errors: Maybe<Array<Validation>>
  tenantInvitation: Maybe<ContractTenantInvitation>
}

export type CreateUpload = {
  __typename: 'CreateUpload'
  action: Scalars['String']['output']
  fields: Scalars['JSON']['output']
  id: Scalars['ID']['output']
}

export type CreateUploadInput = {
  contentLength: Scalars['Int']['input']
  contentType: Scalars['String']['input']
  metadata?: InputMaybe<UploadMetadataInput>
  ownerId: Scalars['String']['input']
  ownerType: OwnerTypeEnum
  title: Scalars['String']['input']
  uploadType: UploadTypeEnum
}

/** Autogenerated return type of CreateUpload. */
export type CreateUploadPayload = {
  __typename: 'CreateUploadPayload'
  errors: Maybe<Array<Validation>>
  upload: Maybe<CreateUpload>
}

export type CreateUserNoteInput = {
  note: Scalars['String']['input']
  recordId: Scalars['ID']['input']
  recordType: UserNoteRecordTypeEnum
}

/** Autogenerated return type of CreateUserNote. */
export type CreateUserNotePayload = {
  __typename: 'CreateUserNotePayload'
  errors: Maybe<Array<Validation>>
  userNote: Maybe<UserNote>
}

/** Autogenerated return type of CreateViewing. */
export type CreateViewingPayload = {
  __typename: 'CreateViewingPayload'
  errors: Maybe<Array<Validation>>
  homeApplication: Maybe<HomeApplication>
  viewing: Maybe<Viewing>
}

export type CreditReport = {
  __typename: 'CreditReport'
  createdAt: Scalars['DateTime']['output']
  currentAddress: Maybe<CurrentAddressType>
  debtSanitization: Maybe<Scalars['Boolean']['output']>
  expiresOn: Scalars['Date']['output']
  fetchStatus: CreditReportFetchStatusEnum
  guardianship: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  idNumber: Scalars['String']['output']
  latestYearlyIncome: Maybe<Scalars['Float']['output']>
  moveInfo: Maybe<MoveInfoType>
  numberPaymentRemarks: Maybe<Scalars['Int']['output']>
  provider: CreditReportProviderEnum
  seizureAttempt: Maybe<Scalars['Boolean']['output']>
  totalDebt: Maybe<Scalars['Int']['output']>
  updatedAt: Scalars['DateTime']['output']
  user: User
  yearlyIncomeStatements: Maybe<Array<YearlyIncomeStatementsType>>
}

export enum CreditReportFetchStatusEnum {
  downloaded = 'downloaded',
  errored = 'errored',
  pending = 'pending',
  unobtainable = 'unobtainable',
}

export enum CreditReportProviderEnum {
  valitive = 'valitive',
}

export type CronofyElementToken = {
  __typename: 'CronofyElementToken'
  origin: Scalars['String']['output']
  permissions: Array<Scalars['String']['output']>
  subs: Array<Scalars['String']['output']>
  token: Scalars['String']['output']
}

export enum CurrencyEnum {
  EUR = 'EUR',
  NOK = 'NOK',
  SEK = 'SEK',
}

export type CurrentAddressType = {
  __typename: 'CurrentAddressType'
  city: Scalars['String']['output']
  number: Scalars['String']['output']
  postalCode: Scalars['String']['output']
  street: Scalars['String']['output']
}

export type CurrentUserResolverResult =
  | AuthorizationError
  | Employee
  | LoginUrl
  | NotFoundError
  | ValidationError

export type DateRange = {
  __typename: 'DateRange'
  from: Maybe<Scalars['Date']['output']>
  to: Maybe<Scalars['Date']['output']>
}

export type DateRangeInput = {
  from?: InputMaybe<Scalars['Date']['input']>
  to?: InputMaybe<Scalars['Date']['input']>
}

export type DateTimeRangeInput = {
  from?: InputMaybe<Scalars['DateTime']['input']>
  to?: InputMaybe<Scalars['DateTime']['input']>
}

export enum DayOfTheWeekTypeEnum {
  friday = 'friday',
  monday = 'monday',
  saturday = 'saturday',
  sunday = 'sunday',
  thursday = 'thursday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
}

/** Autogenerated return type of DeclineHomeApplication. */
export type DeclineHomeApplicationPayload = {
  __typename: 'DeclineHomeApplicationPayload'
  errors: Maybe<Array<Validation>>
  homeApplication: Maybe<HomeApplication>
}

/** Autogenerated return type of DeclineHomeApplications. */
export type DeclineHomeApplicationsPayload = {
  __typename: 'DeclineHomeApplicationsPayload'
  errors: Maybe<Array<Validation>>
  success: Scalars['Boolean']['output']
}

/** Autogenerated return type of DeclineOffering. */
export type DeclineOfferingPayload = {
  __typename: 'DeclineOfferingPayload'
  offering: Offering
}

/** Autogenerated return type of DeclineOrRevokeBooking. */
export type DeclineOrRevokeBookingPayload = {
  __typename: 'DeclineOrRevokeBookingPayload'
  booking: Maybe<Booking>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of DeclineShortcutProposal. */
export type DeclineShortcutProposalPayload = {
  __typename: 'DeclineShortcutProposalPayload'
  homeId: Scalars['ID']['output']
}

/** Autogenerated return type of DeleteHome. */
export type DeleteHomePayload = {
  __typename: 'DeleteHomePayload'
  errors: Maybe<Array<Validation>>
  homeId: Maybe<Scalars['ID']['output']>
}

export type DeliveryTrace = {
  __typename: 'DeliveryTrace'
  channel: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  metadata: Scalars['JSON']['output']
  subject: Scalars['String']['output']
}

/** The connection type for DeliveryTrace. */
export type DeliveryTraceConnection = {
  __typename: 'DeliveryTraceConnection'
  /** A list of edges. */
  edges: Array<DeliveryTraceEdge>
  /** A list of nodes. */
  nodes: Array<DeliveryTrace>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type DeliveryTraceEdge = {
  __typename: 'DeliveryTraceEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: DeliveryTrace
}

/** Autogenerated return type of DenyDepositPayout. */
export type DenyDepositPayoutPayload = {
  __typename: 'DenyDepositPayoutPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of DestroyAdminNote. */
export type DestroyAdminNotePayload = {
  __typename: 'DestroyAdminNotePayload'
  id: Scalars['ID']['output']
}

/** Autogenerated return type of DestroyApplicationBoardList. */
export type DestroyApplicationBoardListPayload = {
  __typename: 'DestroyApplicationBoardListPayload'
  id: Maybe<Scalars['ID']['output']>
}

/** Autogenerated return type of DestroyCreditReport. */
export type DestroyCreditReportPayload = {
  __typename: 'DestroyCreditReportPayload'
  creditReport: CreditReport
}

export type DestroyEmployeeGroupResult = AuthorizationError | NotFoundError | Success | ValidationError

/** Autogenerated return type of DestroyHomeAvailability. */
export type DestroyHomeAvailabilityPayload = {
  __typename: 'DestroyHomeAvailabilityPayload'
  dates: Array<Scalars['Date']['output']>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of DestroyIncomeVerification. */
export type DestroyIncomeVerificationPayload = {
  __typename: 'DestroyIncomeVerificationPayload'
  success: Scalars['Boolean']['output']
}

/** Autogenerated return type of DestroyMessageMacro. */
export type DestroyMessageMacroPayload = {
  __typename: 'DestroyMessageMacroPayload'
  success: Scalars['Boolean']['output']
}

/** Autogenerated return type of DestroyOccupation. */
export type DestroyOccupationPayload = {
  __typename: 'DestroyOccupationPayload'
  success: Scalars['Boolean']['output']
}

/** Autogenerated return type of DestroyOccupationVerification. */
export type DestroyOccupationVerificationPayload = {
  __typename: 'DestroyOccupationVerificationPayload'
  success: Scalars['Boolean']['output']
}

export type DestroyPublicDocumentResult = AuthorizationError | NotFoundError | Success | ValidationError

/** Autogenerated return type of DestroySavedSearch. */
export type DestroySavedSearchPayload = {
  __typename: 'DestroySavedSearchPayload'
  id: Scalars['ID']['output']
}

/** Autogenerated return type of DestroyUpload. */
export type DestroyUploadPayload = {
  __typename: 'DestroyUploadPayload'
  id: Scalars['ID']['output']
}

/** Autogenerated return type of DestroyUserNote. */
export type DestroyUserNotePayload = {
  __typename: 'DestroyUserNotePayload'
  id: Scalars['ID']['output']
}

/** Autogenerated return type of DestroyUser. */
export type DestroyUserPayload = {
  __typename: 'DestroyUserPayload'
  /** @deprecated Is always null */
  errors: Maybe<Array<Validation>>
  /** @deprecated Will be removed in favor of `uid` */
  success: Scalars['Boolean']['output']
  uid: Scalars['ID']['output']
}

export type DestroyViewingSlotsResult =
  | AuthorizationError
  | NotFoundError
  | ValidationError
  | ViewingSlotConnection

/** Autogenerated return type of DiscardIdentification. */
export type DiscardIdentificationPayload = {
  __typename: 'DiscardIdentificationPayload'
  success: Scalars['Boolean']['output']
}

/** Autogenerated return type of DuplicateHome. */
export type DuplicateHomePayload = {
  __typename: 'DuplicateHomePayload'
  errors: Maybe<Array<Validation>>
  home: Maybe<Home>
}

export type Duration = {
  __typename: 'Duration'
  createdAt: Maybe<Scalars['DateTime']['output']>
  durationPreference: Maybe<DurationPreference>
  endOptimal: Maybe<Scalars['DateTime']['output']>
  endUfn: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  possibilityOfExtension: Scalars['Boolean']['output']
  startAsap: Scalars['Boolean']['output']
  startOptimal: Maybe<Scalars['DateTime']['output']>
  updatedAt: Maybe<Scalars['DateTime']['output']>
}

export type DurationPreference = {
  __typename: 'DurationPreference'
  duration: Duration
  endEarliest: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  longestDurationDays: Maybe<Scalars['Int']['output']>
  shortestDurationDays: Maybe<Scalars['Int']['output']>
  startLatest: Maybe<Scalars['DateTime']['output']>
}

export type ElectricityFeeInput = {
  monthlyFee?: InputMaybe<Scalars['Int']['input']>
  paymentPlan: ElectricityFeePaymentOptionEnum
}

export enum ElectricityFeePaymentOptionEnum {
  fixed_fee = 'fixed_fee',
  included_in_rent = 'included_in_rent',
  not_included_in_rent = 'not_included_in_rent',
  tenant_managed = 'tenant_managed',
}

export type ElectricityFeeType = {
  __typename: 'ElectricityFeeType'
  monthlyFee: Maybe<Scalars['Int']['output']>
  paymentPlan: ElectricityFeePaymentOptionEnum
}

export type Employee = {
  __typename: 'Employee'
  compiledPermissions: Array<EmployeePermission>
  email: Scalars['String']['output']
  groups: EmployeeGroupConnection
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  profilePictureUrl: Maybe<Scalars['String']['output']>
  roles: EmployeeRoleConnection
}

export type EmployeeGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type EmployeeRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The connection type for Employee. */
export type EmployeeConnection = {
  __typename: 'EmployeeConnection'
  /** A list of edges. */
  edges: Array<EmployeeEdge>
  /** A list of nodes. */
  nodes: Array<Employee>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type EmployeeEdge = {
  __typename: 'EmployeeEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Employee
}

export type EmployeeGroup = {
  __typename: 'EmployeeGroup'
  employees: EmployeeConnection
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  roles: EmployeeRoleConnection
}

export type EmployeeGroupEmployeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type EmployeeGroupRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The connection type for EmployeeGroup. */
export type EmployeeGroupConnection = {
  __typename: 'EmployeeGroupConnection'
  /** A list of edges. */
  edges: Array<EmployeeGroupEdge>
  /** A list of nodes. */
  nodes: Array<EmployeeGroup>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type EmployeeGroupEdge = {
  __typename: 'EmployeeGroupEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: EmployeeGroup
}

export type EmployeeGroupsResolverResult =
  | AuthorizationError
  | EmployeeGroupConnection
  | NotFoundError
  | ValidationError

export type EmployeePermission = {
  __typename: 'EmployeePermission'
  actions: Array<EmployeePermissionActionEnum>
  objectType: Scalars['String']['output']
}

export enum EmployeePermissionActionEnum {
  destroy = 'destroy',
  log_in_as = 'log_in_as',
  market_access = 'market_access',
  show = 'show',
  show_sensitive = 'show_sensitive',
  update = 'update',
}

/** The connection type for EmployeePermission. */
export type EmployeePermissionConnection = {
  __typename: 'EmployeePermissionConnection'
  /** A list of edges. */
  edges: Array<EmployeePermissionEdge>
  /** A list of nodes. */
  nodes: Array<EmployeePermission>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type EmployeePermissionEdge = {
  __typename: 'EmployeePermissionEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: EmployeePermission
}

export type EmployeeResolverResult = AuthorizationError | Employee | NotFoundError | ValidationError

export type EmployeeRole = {
  __typename: 'EmployeeRole'
  description: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  permissions: EmployeePermissionConnection
}

export type EmployeeRolePermissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The connection type for EmployeeRole. */
export type EmployeeRoleConnection = {
  __typename: 'EmployeeRoleConnection'
  /** A list of edges. */
  edges: Array<EmployeeRoleEdge>
  /** A list of nodes. */
  nodes: Array<EmployeeRole>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type EmployeeRoleEdge = {
  __typename: 'EmployeeRoleEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: EmployeeRole
}

export type EmployeeRolesResolverResult =
  | AuthorizationError
  | EmployeeRoleConnection
  | NotFoundError
  | ValidationError

export type EmploymentVerification = {
  __typename: 'EmploymentVerification'
  createdAt: Scalars['DateTime']['output']
  employerName: Scalars['String']['output']
  id: Scalars['ID']['output']
  role: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
  upload: Maybe<Upload>
}

export type EnrollHomeInShortcutInput = {
  homeId: Scalars['ID']['input']
}

/** Autogenerated return type of EnrollHomeInShortcut. */
export type EnrollHomeInShortcutPayload = {
  __typename: 'EnrollHomeInShortcutPayload'
  shortcut: Maybe<Shortcut>
}

export type Error = {
  message: Scalars['String']['output']
}

/** Autogenerated return type of EvaluateHomeForShortcut. */
export type EvaluateHomeForShortcutPayload = {
  __typename: 'EvaluateHomeForShortcutPayload'
  errors: Maybe<Array<Validation>>
  /** home status changed if shortcut was proposed */
  home: Home
}

export type EvictionProcess = Node & {
  __typename: 'EvictionProcess'
  billectaInvoiceNumber: Maybe<Scalars['String']['output']>
  closed: Scalars['Boolean']['output']
  comments: Array<EvictionProcessComment>
  contract: Contract
  createdAt: Scalars['DateTime']['output']
  currentState: EvictionProcessState
  /** ID of the object. */
  id: Scalars['ID']['output']
  invoice: Invoice
  landlord: KanyeUser
  messageToSocPdf: Maybe<Blob>
  socInfo: EvictionProcessSocInfo
  updatedAt: Scalars['DateTime']['output']
  user: KanyeUser
}

export type EvictionProcessAttributes = {
  /** Blob id of an uploaded pdf */
  messageToSocPdf?: InputMaybe<Scalars['ID']['input']>
}

export type EvictionProcessComment = {
  __typename: 'EvictionProcessComment'
  body: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  processState: Scalars['String']['output']
  user: KanyeUser
}

/** The connection type for EvictionProcess. */
export type EvictionProcessConnection = {
  __typename: 'EvictionProcessConnection'
  /** A list of edges. */
  edges: Array<EvictionProcessEdge>
  /** A list of nodes. */
  nodes: Array<EvictionProcess>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type EvictionProcessEdge = {
  __typename: 'EvictionProcessEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: EvictionProcess
}

export type EvictionProcessSocInfo = {
  __typename: 'EvictionProcessSocInfo'
  nextMonth: Scalars['String']['output']
  qasaInfo: SocEvictionEmailQasaInfo
}

export enum EvictionProcessState {
  awaiting_reply_from_kronofogden = 'awaiting_reply_from_kronofogden',
  awaiting_reply_from_landlord_for_delgivning = 'awaiting_reply_from_landlord_for_delgivning',
  awaiting_reply_from_socialtjansten = 'awaiting_reply_from_socialtjansten',
  cancelled_automatically = 'cancelled_automatically',
  delgivning_approved_awaiting_eviction_date = 'delgivning_approved_awaiting_eviction_date',
  delgivning_denied = 'delgivning_denied',
  done = 'done',
  eviction_date_received = 'eviction_date_received',
  not_started = 'not_started',
  report_to_kronofogden = 'report_to_kronofogden',
  report_to_socialtjansten = 'report_to_socialtjansten',
}

/** Autogenerated return type of ExchangeLoginToken. */
export type ExchangeLoginTokenPayload = {
  __typename: 'ExchangeLoginTokenPayload'
  accessToken: Scalars['String']['output']
}

/** Autogenerated return type of ExchangeSignInCode. */
export type ExchangeSignInCodePayload = {
  __typename: 'ExchangeSignInCodePayload'
  accessToken: Maybe<Scalars['String']['output']>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of ExtendContract. */
export type ExtendContractPayload = {
  __typename: 'ExtendContractPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

export type ExternalContractTakeover = {
  __typename: 'ExternalContractTakeover'
  currentState: ExternalContractTakeoverStateEnum
}

export enum ExternalContractTakeoverStateEnum {
  completed = 'completed',
  create_tenant = 'create_tenant',
  initiated = 'initiated',
  mila_add_to_policy = 'mila_add_to_policy',
  sign_contract = 'sign_contract',
  sync_with_mila_data = 'sync_with_mila_data',
}

export type ExtraCost = {
  __typename: 'ExtraCost'
  amount: Maybe<Scalars['Int']['output']>
  costFor: ExtraCostForEnum
  description: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  plan: ExtraCostPlanEnum
}

export enum ExtraCostForEnum {
  electricity = 'electricity',
  gas = 'gas',
  heating = 'heating',
  internet = 'internet',
  other = 'other',
  tv = 'tv',
  water = 'water',
}

export type ExtraCostInput = {
  amount?: InputMaybe<Scalars['Int']['input']>
  costFor: ExtraCostForEnum
  description?: InputMaybe<Scalars['String']['input']>
  plan: ExtraCostPlanEnum
}

export enum ExtraCostPlanEnum {
  included = 'included',
  monthly = 'monthly',
  monthly_per_person = 'monthly_per_person',
  not_available = 'not_available',
  not_included = 'not_included',
  tenant_responsibility = 'tenant_responsibility',
  usage = 'usage',
}

export type FamilyMember = {
  __typename: 'FamilyMember'
  birthDate: Maybe<Scalars['DateTime']['output']>
  firstName: Maybe<Scalars['String']['output']>
  gender: Maybe<GenderEnum>
}

export type FamilyMemberInput = {
  birthDate?: InputMaybe<Scalars['DateTime']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  gender?: InputMaybe<GenderEnum>
}

/** Autogenerated return type of FavoriteMarkHome. */
export type FavoriteMarkHomePayload = {
  __typename: 'FavoriteMarkHomePayload'
  home: Home
}

export type Fee = {
  __typename: 'Fee'
  availability: FeeAvailabilityEnum
  cost: Cost
}

export enum FeeAvailabilityEnum {
  mandatory = 'mandatory',
  optional = 'optional',
  unavailable = 'unavailable',
}

/** Autogenerated return type of FinalizeUpload. */
export type FinalizeUploadPayload = {
  __typename: 'FinalizeUploadPayload'
  errors: Maybe<Array<Validation>>
  upload: Maybe<Upload>
}

export type FinanceChargeType = {
  __typename: 'FinanceChargeType'
  amount: Money
  id: Scalars['ID']['output']
  productName: ProductEnum
  vat: Money
  vatPercent: Scalars['String']['output']
}

export type FinanceDepositInvoice = InvoiceInterface & {
  __typename: 'FinanceDepositInvoice'
  /** Charges that are to be withdrawn from the invoice at the time of payout to the landlord. */
  charges: Array<FinanceChargeType>
  createdAt: Scalars['DateTime']['output']
  currentState: InvoiceStateEnum
  /** The date by which the invoice must be paid. */
  dueDate: Scalars['Date']['output']
  /** The database ID of the invoice. */
  id: Scalars['ID']['output']
  /** Items that are to be paid by the tenant. */
  items: Array<FinanceItemType>
  /** Indicates whether the invoice is overdue. */
  overdue: Scalars['Boolean']['output']
  paidAt: Maybe<Scalars['DateTime']['output']>
  paymentAction: Maybe<InvoicePaymentActionUnion>
  payout: Maybe<FinancePayoutType>
  /** The receipt of the invoice if the invoice is paid. */
  receipt: Maybe<Blob>
  /** A globally unique reference that is Luhn-10 compliant. */
  reference: Scalars['ID']['output']
  requiredUserActions: Array<UserAction>
  sumAmount: Money
  sumVat: Money
}

export type FinanceGenericInvoice = InvoiceInterface & {
  __typename: 'FinanceGenericInvoice'
  /** Charges that are to be withdrawn from the invoice at the time of payout to the landlord. */
  charges: Array<FinanceChargeType>
  createdAt: Scalars['DateTime']['output']
  currentState: InvoiceStateEnum
  /** The date by which the invoice must be paid. */
  dueDate: Scalars['Date']['output']
  /** The database ID of the invoice. */
  id: Scalars['ID']['output']
  /** Items that are to be paid by the tenant. */
  items: Array<FinanceItemType>
  /** Indicates whether the invoice is overdue. */
  overdue: Scalars['Boolean']['output']
  paidAt: Maybe<Scalars['DateTime']['output']>
  paymentAction: Maybe<InvoicePaymentActionUnion>
  payout: Maybe<FinancePayoutType>
  periodEnd: Maybe<Scalars['Date']['output']>
  periodStart: Maybe<Scalars['Date']['output']>
  /** The receipt of the invoice if the invoice is paid. */
  receipt: Maybe<Blob>
  /** A globally unique reference that is Luhn-10 compliant. */
  reference: Scalars['ID']['output']
  requiredUserActions: Array<UserAction>
  sumAmount: Money
  sumVat: Money
}

export type FinanceItemType = {
  __typename: 'FinanceItemType'
  amount: Money
  id: Scalars['ID']['output']
  periodStart: Maybe<Scalars['Date']['output']>
  productName: ProductEnum
  vat: Money
  vatPercent: Scalars['String']['output']
}

export type FinanceMonthlyRentInvoice = InvoiceInterface & {
  __typename: 'FinanceMonthlyRentInvoice'
  /** Charges that are to be withdrawn from the invoice at the time of payout to the landlord. */
  charges: Array<FinanceChargeType>
  createdAt: Scalars['DateTime']['output']
  currentState: InvoiceStateEnum
  /** The date by which the invoice must be paid. */
  dueDate: Scalars['Date']['output']
  /** The database ID of the invoice. */
  id: Scalars['ID']['output']
  /** Items that are to be paid by the tenant. */
  items: Array<FinanceItemType>
  /** Indicates whether the invoice is overdue. */
  overdue: Scalars['Boolean']['output']
  paidAt: Maybe<Scalars['DateTime']['output']>
  paymentAction: Maybe<InvoicePaymentActionUnion>
  payout: Maybe<FinancePayoutType>
  periodEnd: Scalars['Date']['output']
  periodStart: Scalars['Date']['output']
  /** The receipt of the invoice if the invoice is paid. */
  receipt: Maybe<Blob>
  /** A globally unique reference that is Luhn-10 compliant. */
  reference: Scalars['ID']['output']
  requiredUserActions: Array<UserAction>
  sumAmount: Money
  sumVat: Money
  /**
   * An object that holds information about the upcoming payout to the landlord for this invoice.
   * Returns `null` if the payout is completed.
   *
   */
  upcomingPayout: Maybe<UpcomingPayoutType>
}

export type FinancePayoutType = {
  __typename: 'FinancePayoutType'
  amount: Money
  charges: Array<FinanceChargeType>
  createdAt: Scalars['DateTime']['output']
  currentState: PayoutStateEnum
  id: Scalars['ID']['output']
  paidAt: Maybe<Scalars['DateTime']['output']>
  periodEnd: Maybe<Scalars['DateTime']['output']>
  periodStart: Maybe<Scalars['DateTime']['output']>
  receipt: Maybe<Blob>
  reference: Scalars['String']['output']
}

/** Autogenerated return type of FinanceTestModePayInvoice. */
export type FinanceTestModePayInvoicePayload = {
  __typename: 'FinanceTestModePayInvoicePayload'
  success: Scalars['Boolean']['output']
}

export type FinlandMarketDetails = {
  __typename: 'FinlandMarketDetails'
  id: Scalars['ID']['output']
  minimumStayMonths: Maybe<Scalars['Int']['output']>
  rentIndexation: Maybe<FinlandRentIndexationType>
}

export type FinlandMarketDetailsInput = {
  minimumStayMonths?: InputMaybe<Scalars['Int']['input']>
  rentIndexation?: InputMaybe<FinlandRentIndexationInput>
}

export type FinlandRentIndexationInput = {
  fixedPercentage?: InputMaybe<Scalars['Float']['input']>
  indexationOption: FinlandRentIndexationOptionEnum
}

export enum FinlandRentIndexationOptionEnum {
  consumer_price_index = 'consumer_price_index',
  cost_of_living_index = 'cost_of_living_index',
  fixed = 'fixed',
}

export type FinlandRentIndexationType = {
  __typename: 'FinlandRentIndexationType'
  fixedPercentage: Maybe<Scalars['Float']['output']>
  indexationOption: FinlandRentIndexationOptionEnum
}

export type FinlandUpsertContractInput = {
  acceptsCosignatures?: InputMaybe<Scalars['Boolean']['input']>
  apartmentNumber?: InputMaybe<Scalars['String']['input']>
  canUse?: InputMaybe<Array<Scalars['String']['input']>>
  duration?: InputMaybe<CreateDurationInput>
  electricityFee?: InputMaybe<ElectricityFeeInput>
  furniture?: InputMaybe<Scalars['Boolean']['input']>
  homeId?: InputMaybe<Scalars['ID']['input']>
  insuranceResponsibility?: InputMaybe<InsuranceResponsibilityEnum>
  inventory?: InputMaybe<Array<Scalars['String']['input']>>
  landlordHasKeys?: InputMaybe<Scalars['Boolean']['input']>
  marketDetails?: InputMaybe<FinlandMarketDetailsInput>
  numberOfTenants?: InputMaybe<Scalars['Int']['input']>
  paymentDay?: InputMaybe<Scalars['Int']['input']>
  pets?: InputMaybe<Scalars['Boolean']['input']>
  qasaGuarantee?: InputMaybe<Scalars['Boolean']['input']>
  rent?: InputMaybe<Scalars['Int']['input']>
  rentExcludes?: InputMaybe<Array<Scalars['String']['input']>>
  rentIncludes?: InputMaybe<Array<Scalars['String']['input']>>
  smoker?: InputMaybe<Scalars['Boolean']['input']>
  tenantUid?: InputMaybe<Scalars['ID']['input']>
  waterFeePerTenant?: InputMaybe<Scalars['Int']['input']>
}

/** Autogenerated return type of FinlandUpsertContract. */
export type FinlandUpsertContractPayload = {
  __typename: 'FinlandUpsertContractPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of FranceCancelContractDraft. */
export type FranceCancelContractDraftPayload = {
  __typename: 'FranceCancelContractDraftPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

export type FranceCellarInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>
  number?: InputMaybe<Scalars['String']['input']>
}

export type FranceCellarType = {
  __typename: 'FranceCellarType'
  available: Maybe<Scalars['Boolean']['output']>
  number: Maybe<Scalars['String']['output']>
}

export enum FranceConstructionPeriodEnum {
  after_1990 = 'after_1990',
  before_1946 = 'before_1946',
  between_1946_and_1970 = 'between_1946_and_1970',
  between_1971_and_1990 = 'between_1971_and_1990',
}

/** Autogenerated return type of FranceCreateExternalContractTakeover. */
export type FranceCreateExternalContractTakeoverPayload = {
  __typename: 'FranceCreateExternalContractTakeoverPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

export type FranceCreateInsuranceRequestInput = {
  guarantor?: InputMaybe<FranceGuarantorInput>
  tenantAdditionalIncomes?: InputMaybe<Array<FranceTenantAdditionalIncomeEnum>>
  tenantProfessionalSituation: FranceTenantProfessionalSituationEnum
}

/** Autogenerated return type of FranceCreateInsuranceRequest. */
export type FranceCreateInsuranceRequestPayload = {
  __typename: 'FranceCreateInsuranceRequestPayload'
  errors: Maybe<Array<Validation>>
  insuranceRequest: Maybe<FranceInsuranceRequestType>
}

export enum FranceDocumentRequestConcernedBusinessRoleEnum {
  owner = 'owner',
  risk = 'risk',
  surety = 'surety',
  tenant = 'tenant',
}

export enum FranceDocumentRequestStatusEnum {
  accepted = 'accepted',
  canceled = 'canceled',
  refused = 'refused',
  required = 'required',
  uploaded = 'uploaded',
}

export type FranceDocumentRequestType = {
  __typename: 'FranceDocumentRequestType'
  concernedBusinessRole: FranceDocumentRequestConcernedBusinessRoleEnum
  documentDescription: Maybe<Scalars['String']['output']>
  documentType: FranceDocumentRequestTypeEnum
  documents: Array<Blob>
  evaluatorComment: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  status: FranceDocumentRequestStatusEnum
}

export enum FranceDocumentRequestTypeEnum {
  fr_2_derniers_bilans = 'fr_2_derniers_bilans',
  fr_3_derniers_bulletins_salaire = 'fr_3_derniers_bulletins_salaire',
  fr_acte_cautionnement = 'fr_acte_cautionnement',
  fr_attestation_caf = 'fr_attestation_caf',
  fr_attestation_caisse_retraite = 'fr_attestation_caisse_retraite',
  fr_attestation_cpam = 'fr_attestation_cpam',
  fr_autres_documents = 'fr_autres_documents',
  fr_carte_gestion_adb = 'fr_carte_gestion_adb',
  fr_carte_identite_adb = 'fr_carte_identite_adb',
  fr_carte_identite_dirigeant = 'fr_carte_identite_dirigeant',
  fr_certificat_scolarite_apprentissage = 'fr_certificat_scolarite_apprentissage',
  fr_conditions_particulieres_signees = 'fr_conditions_particulieres_signees',
  fr_contrat_travail_attestation = 'fr_contrat_travail_attestation',
  fr_contrat_travail_attestation_titularisation = 'fr_contrat_travail_attestation_titularisation',
  fr_decompte_locataire_a_jour = 'fr_decompte_locataire_a_jour',
  fr_dernier_avis_imposition = 'fr_dernier_avis_imposition',
  fr_dernier_justificatif_versement_pole_emploi = 'fr_dernier_justificatif_versement_pole_emploi',
  fr_jugement_divorce = 'fr_jugement_divorce',
  fr_justificatif_autres_revenus = 'fr_justificatif_autres_revenus',
  fr_justificatif_identite_sociale = 'fr_justificatif_identite_sociale',
  fr_justificatif_retraite = 'fr_justificatif_retraite',
  fr_kbis = 'fr_kbis',
  fr_kbis_adb = 'fr_kbis_adb',
  fr_ouverture_droits_allocation_chomage = 'fr_ouverture_droits_allocation_chomage',
  fr_piece_identite = 'fr_piece_identite',
  fr_proposition_adhesion_signee = 'fr_proposition_adhesion_signee',
  fr_simulation_caf_aide_logement = 'fr_simulation_caf_aide_logement',
  fr_statuts_entreprise = 'fr_statuts_entreprise',
}

export type FranceDurationInput = {
  shortened?: InputMaybe<Scalars['Boolean']['input']>
  shortenedEndDate?: InputMaybe<Scalars['DateTime']['input']>
  shortenedJustification?: InputMaybe<Scalars['String']['input']>
  startDate: Scalars['DateTime']['input']
}

export type FranceDurationType = {
  __typename: 'FranceDurationType'
  shortened: Maybe<Scalars['Boolean']['output']>
  shortenedEndDate: Maybe<Scalars['DateTime']['output']>
  shortenedJustification: Maybe<Scalars['String']['output']>
  startDate: Scalars['DateTime']['output']
}

export enum FranceEquipmentEnum {
  air_conditioning = 'air_conditioning',
}

export type FranceEquipmentInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>
  name: FranceEquipmentEnum
}

export type FranceEquipmentType = {
  __typename: 'FranceEquipmentType'
  available: Maybe<Scalars['Boolean']['output']>
  name: Maybe<FranceEquipmentEnum>
}

export type FranceExternalContractTakeoverDurationInput = {
  shortenedEndDate: Scalars['DateTime']['input']
  startDate: Scalars['DateTime']['input']
}

export enum FranceFacilityEnum {
  attic = 'attic',
  balcony = 'balcony',
  bicycle_room = 'bicycle_room',
  concierge = 'concierge',
  elevator = 'elevator',
  ev_charger = 'ev_charger',
  pool = 'pool',
  terrace = 'terrace',
  trash_room = 'trash_room',
}

export type FranceFacilityInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>
  name: FranceFacilityEnum
}

export type FranceFacilityType = {
  __typename: 'FranceFacilityType'
  available: Maybe<Scalars['Boolean']['output']>
  name: Maybe<FranceFacilityEnum>
}

export type FranceGuarantorInput = {
  familyName: Scalars['String']['input']
  firstName: Scalars['String']['input']
  professionalSituation: FranceGuarantorProfessionalSituationEnum
}

export enum FranceGuarantorProfessionalSituationEnum {
  fixed_term_employee = 'fixed_term_employee',
  job_seeker = 'job_seeker',
  other_benefits = 'other_benefits',
  permanent_employee = 'permanent_employee',
  retiree = 'retiree',
  self_employed_professional = 'self_employed_professional',
}

export type FranceGuarantorType = {
  __typename: 'FranceGuarantorType'
  familyName: Scalars['String']['output']
  firstName: Scalars['String']['output']
  professionalSituation: FranceGuarantorProfessionalSituationEnum
}

export enum FranceHeatingProductionEnum {
  collective = 'collective',
  individual = 'individual',
}

export type FranceHeatingProductionInput = {
  description?: InputMaybe<Scalars['String']['input']>
  mode: FranceHeatingProductionEnum
}

export type FranceHeatingProductionType = {
  __typename: 'FranceHeatingProductionType'
  description: Maybe<Scalars['String']['output']>
  mode: Maybe<FranceHeatingProductionEnum>
}

export enum FranceHotWaterProductionEnum {
  collective = 'collective',
  individual = 'individual',
}

export type FranceHotWaterProductionInput = {
  description?: InputMaybe<Scalars['String']['input']>
  mode: FranceHotWaterProductionEnum
}

export type FranceHotWaterProductionType = {
  __typename: 'FranceHotWaterProductionType'
  description: Maybe<Scalars['String']['output']>
  mode: Maybe<FranceHotWaterProductionEnum>
}

export enum FranceHousingTypeEnum {
  collective = 'collective',
  individual = 'individual',
}

/** Autogenerated return type of FranceInitiateContractSigning. */
export type FranceInitiateContractSigningPayload = {
  __typename: 'FranceInitiateContractSigningPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

export enum FranceInsuranceRequestStateEnum {
  added_to_policy = 'added_to_policy',
  approved = 'approved',
  awaiting_contract_signing = 'awaiting_contract_signing',
  awaiting_decision = 'awaiting_decision',
  awaiting_documents = 'awaiting_documents',
  canceled = 'canceled',
  contract_signed = 'contract_signed',
  draft = 'draft',
  fetching_required_documents = 'fetching_required_documents',
  manually_approved = 'manually_approved',
  pending_rejection = 'pending_rejection',
  registering_entities = 'registering_entities',
  rejected = 'rejected',
  sending_request_to_mila = 'sending_request_to_mila',
  uploading_required_documents = 'uploading_required_documents',
}

export type FranceInsuranceRequestType = {
  __typename: 'FranceInsuranceRequestType'
  currentState: Maybe<FranceInsuranceRequestStateEnum>
  documentRequests: Maybe<Array<FranceDocumentRequestType>>
  guarantor: Maybe<FranceGuarantorType>
  guarantorDocumentRequests: Maybe<Array<FranceDocumentRequestType>>
  id: Scalars['ID']['output']
  milaId: Maybe<Scalars['String']['output']>
  tenantAdditionalIncomes: Maybe<Array<FranceTenantAdditionalIncomeEnum>>
  tenantDocumentRequests: Maybe<Array<FranceDocumentRequestType>>
  tenantProfessionalSituation: Maybe<FranceTenantProfessionalSituationEnum>
}

export type FranceInternetConnectionInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>
}

export type FranceInternetConnectionType = {
  __typename: 'FranceInternetConnectionType'
  available: Maybe<Scalars['Boolean']['output']>
}

export enum FranceLandlordTypeEnum {
  legal_entity = 'legal_entity',
  natural_person = 'natural_person',
}

export type FranceLegalEntityDetailsInput = {
  name?: InputMaybe<Scalars['String']['input']>
  siretNumber?: InputMaybe<Scalars['String']['input']>
  type: FranceLegalEntityTypeEnum
}

export type FranceLegalEntityDetailsType = {
  __typename: 'FranceLegalEntityDetailsType'
  name: Maybe<Scalars['String']['output']>
  siretNumber: Maybe<Scalars['String']['output']>
  type: FranceLegalEntityTypeEnum
}

export enum FranceLegalEntityTypeEnum {
  family_sci = 'family_sci',
}

export type FranceMarketDetails = {
  __typename: 'FranceMarketDetails'
  buildYear: Maybe<Scalars['Int']['output']>
  cellar: Maybe<FranceCellarType>
  constructionPeriod: Maybe<FranceConstructionPeriodEnum>
  depositMonths: Maybe<Scalars['Int']['output']>
  duration: Maybe<FranceDurationType>
  equipment: Maybe<Array<FranceEquipmentType>>
  facilities: Maybe<Array<FranceFacilityType>>
  fiscalIdNumber: Maybe<Scalars['String']['output']>
  heatingProduction: Maybe<FranceHeatingProductionType>
  hotWaterProduction: Maybe<FranceHotWaterProductionType>
  housingType: Maybe<FranceHousingTypeEnum>
  id: Scalars['ID']['output']
  internetConnection: Maybe<FranceInternetConnectionType>
  landlordAddress: Maybe<Scalars['String']['output']>
  landlordType: Maybe<FranceLandlordTypeEnum>
  legalEntityDetails: Maybe<FranceLegalEntityDetailsType>
  otherOwners: Maybe<Array<FranceOtherOwnerType>>
  ownershipType: Maybe<FranceOwnershipEnum>
  parking: Maybe<FranceParkingType>
  previousRental: Maybe<FrancePreviousRentalType>
  privateGarage: Maybe<FrancePrivateGarageType>
  privateGarden: Maybe<FrancePrivateGardenType>
  recoverableCharges: Maybe<FranceRecoverableChargesType>
  rentControl: Maybe<FranceRentControlType>
  tenantInvitationEmail: Maybe<Scalars['String']['output']>
  tenantInvitationName: Maybe<Scalars['String']['output']>
  tvConnection: Maybe<FranceTvConnectionType>
}

export type FranceMarketDetailsInput = {
  buildYear?: InputMaybe<Scalars['Int']['input']>
  cellar?: InputMaybe<FranceCellarInput>
  constructionPeriod?: InputMaybe<FranceConstructionPeriodEnum>
  depositMonths?: InputMaybe<Scalars['Int']['input']>
  duration?: InputMaybe<FranceDurationInput>
  equipment?: InputMaybe<Array<FranceEquipmentInput>>
  facilities?: InputMaybe<Array<FranceFacilityInput>>
  fiscalIdNumber?: InputMaybe<Scalars['String']['input']>
  /** Should always send both values, even if description is null. */
  heatingProduction?: InputMaybe<FranceHeatingProductionInput>
  /** Should always send both values, even if description is null. */
  hotWaterProduction?: InputMaybe<FranceHotWaterProductionInput>
  housingType?: InputMaybe<FranceHousingTypeEnum>
  internetConnection?: InputMaybe<FranceInternetConnectionInput>
  landlordAddress?: InputMaybe<Scalars['String']['input']>
  landlordType?: InputMaybe<FranceLandlordTypeEnum>
  legalEntityDetails?: InputMaybe<FranceLegalEntityDetailsInput>
  otherOwners?: InputMaybe<Array<FranceOtherOwnerInput>>
  ownershipType?: InputMaybe<FranceOwnershipEnum>
  parking?: InputMaybe<FranceParkingInput>
  previousRental?: InputMaybe<FrancePreviousRentalInput>
  privateGarage?: InputMaybe<FrancePrivateGarageInput>
  privateGarden?: InputMaybe<FrancePrivateGardenInput>
  recoverableCharges?: InputMaybe<FranceRecoverableChargesInput>
  rentControl?: InputMaybe<FranceRentControlInput>
  tenantInvitationEmail?: InputMaybe<Scalars['String']['input']>
  tenantInvitationName?: InputMaybe<Scalars['String']['input']>
  tvConnection?: InputMaybe<FranceTvConnectionInput>
}

export type FranceOtherOwnerInput = {
  familyName: Scalars['String']['input']
  firstName: Scalars['String']['input']
}

export type FranceOtherOwnerType = {
  __typename: 'FranceOtherOwnerType'
  familyName: Scalars['String']['output']
  firstName: Scalars['String']['output']
}

export enum FranceOwnershipEnum {
  joint = 'joint',
  single = 'single',
}

export type FranceParkingInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>
  number?: InputMaybe<Scalars['String']['input']>
}

export type FranceParkingType = {
  __typename: 'FranceParkingType'
  available: Maybe<Scalars['Boolean']['output']>
  number: Maybe<Scalars['String']['output']>
}

export type FrancePreviousRentalInput = {
  increasedRentJustification?: InputMaybe<Scalars['String']['input']>
  lastRent?: InputMaybe<Scalars['Int']['input']>
  lastRentPaidAt?: InputMaybe<Scalars['DateTime']['input']>
  lastRentReviewedAt?: InputMaybe<Scalars['DateTime']['input']>
  rentedOutRecently: Scalars['Boolean']['input']
}

export type FrancePreviousRentalType = {
  __typename: 'FrancePreviousRentalType'
  increasedRentJustification: Maybe<Scalars['String']['output']>
  lastRent: Maybe<Scalars['Int']['output']>
  lastRentPaidAt: Maybe<Scalars['DateTime']['output']>
  lastRentReviewedAt: Maybe<Scalars['DateTime']['output']>
  rentedOutRecently: Scalars['Boolean']['output']
}

export type FrancePrivateGarageInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>
  number?: InputMaybe<Scalars['String']['input']>
}

export type FrancePrivateGarageType = {
  __typename: 'FrancePrivateGarageType'
  available: Maybe<Scalars['Boolean']['output']>
  number: Maybe<Scalars['String']['output']>
}

export type FrancePrivateGardenInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>
  squareMeters?: InputMaybe<Scalars['Int']['input']>
}

export type FrancePrivateGardenType = {
  __typename: 'FrancePrivateGardenType'
  available: Maybe<Scalars['Boolean']['output']>
  squareMeters: Maybe<Scalars['Int']['output']>
}

export type FranceRecoverableChargesInput = {
  monthlyCost?: InputMaybe<Scalars['Int']['input']>
  paymentPlan: FranceRecoverableChargesPaymentPlanEnum
}

export enum FranceRecoverableChargesPaymentPlanEnum {
  fixed = 'fixed',
  included = 'included',
  provision = 'provision',
}

export type FranceRecoverableChargesType = {
  __typename: 'FranceRecoverableChargesType'
  monthlyCost: Maybe<Scalars['Int']['output']>
  paymentPlan: FranceRecoverableChargesPaymentPlanEnum
}

export type FranceRentControlInput = {
  increasedReferenceRent?: InputMaybe<Scalars['Float']['input']>
  increasedRentJustification?: InputMaybe<Scalars['String']['input']>
  locatedInTenseArea: Scalars['Boolean']['input']
  referenceRent?: InputMaybe<Scalars['Float']['input']>
}

export type FranceRentControlType = {
  __typename: 'FranceRentControlType'
  increasedReferenceRent: Maybe<Scalars['Float']['output']>
  increasedRentJustification: Maybe<Scalars['String']['output']>
  locatedInTenseArea: Scalars['Boolean']['output']
  referenceRent: Maybe<Scalars['Float']['output']>
}

export type FranceTvConnectionInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>
}

export type FranceTvConnectionType = {
  __typename: 'FranceTVConnectionType'
  available: Maybe<Scalars['Boolean']['output']>
}

export enum FranceTenantAdditionalIncomeEnum {
  aah = 'aah',
  aeeh = 'aeeh',
  af = 'af',
  alimony_less_2_years = 'alimony_less_2_years',
  alimony_more_2_years = 'alimony_more_2_years',
  asf = 'asf',
  asi = 'asi',
  aspa = 'aspa',
  autres_revenus = 'autres_revenus',
  child_care = 'child_care',
  disability_pension = 'disability_pension',
  housing_assistance = 'housing_assistance',
  real_estate = 'real_estate',
  sick_leave = 'sick_leave',
  widowhood_allowance = 'widowhood_allowance',
}

export enum FranceTenantProfessionalSituationEnum {
  fixed_term_employee = 'fixed_term_employee',
  job_seeker = 'job_seeker',
  other_benefits = 'other_benefits',
  permanent_employee = 'permanent_employee',
  retiree = 'retiree',
  self_employed_professional = 'self_employed_professional',
  student_or_apprentice_with_guarantor = 'student_or_apprentice_with_guarantor',
}

/** Autogenerated return type of FranceTransitionInsuranceRequest. */
export type FranceTransitionInsuranceRequestPayload = {
  __typename: 'FranceTransitionInsuranceRequestPayload'
  errors: Maybe<Array<Validation>>
  insuranceRequest: Maybe<FranceInsuranceRequestType>
}

export type FranceUpdateDocumentRequestInput = {
  documents: Array<Scalars['ID']['input']>
}

/** Autogenerated return type of FranceUpdateDocumentRequest. */
export type FranceUpdateDocumentRequestPayload = {
  __typename: 'FranceUpdateDocumentRequestPayload'
  documentRequest: Maybe<FranceDocumentRequestType>
  errors: Maybe<Array<Validation>>
}

export type FranceUpsertContractInput = {
  furniture?: InputMaybe<Scalars['Boolean']['input']>
  homeId?: InputMaybe<Scalars['ID']['input']>
  inventoryLists?: InputMaybe<Array<Scalars['ID']['input']>>
  marketDetails?: InputMaybe<FranceMarketDetailsInput>
  otherLandlordDocuments?: InputMaybe<Array<Scalars['ID']['input']>>
  rent?: InputMaybe<Scalars['Int']['input']>
  technicalDiagnoses?: InputMaybe<Array<Scalars['ID']['input']>>
  tenantUid?: InputMaybe<Scalars['ID']['input']>
}

/** Autogenerated return type of FranceUpsertContract. */
export type FranceUpsertContractPayload = {
  __typename: 'FranceUpsertContractPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

export enum GenderEnum {
  female = 'female',
  male = 'male',
  other_gender = 'other_gender',
}

export type GeoPoint = {
  __typename: 'GeoPoint'
  lat: Scalars['Float']['output']
  lon: Scalars['Float']['output']
}

export type Home = {
  __typename: 'Home'
  acceptedOfferingsCount: Scalars['Int']['output']
  activeContract: Maybe<Contract>
  adPaidAt: Maybe<Scalars['DateTime']['output']>
  /** @deprecated Use `landlordFees.listingPublicationFee` instead */
  adPayment: StripePayment
  affiliateCode: Maybe<Scalars['String']['output']>
  apartmentNumber: Maybe<Scalars['String']['output']>
  applicationBoardCardInfo: Maybe<ApplicationBoardCardInfo>
  applicationBoardLists: Array<ApplicationBoardList>
  applicationDeadline: Maybe<Scalars['DateTime']['output']>
  applicationForecast: Maybe<Scalars['Int']['output']>
  applicationSelectionType: Maybe<ApplicationSelectionTypeEnum>
  applicationSortingType: Maybe<ApplicationSortingTypeEnum>
  applicationStats: HomeApplicationStats
  applicationTimeSeries: ApplicationTimeSeries
  archiveReason: Maybe<HomeArchiveReasonEnum>
  archivedBecauseOfApplicationDeadlinePassed: Scalars['Boolean']['output']
  /** Only available on homeSearch and homeCoordsSearch */
  averagePricePerNight: Maybe<Scalars['Int']['output']>
  bathroomRenovationYear: Maybe<Scalars['Int']['output']>
  bedCount: Maybe<Scalars['Int']['output']>
  bedroomCount: Maybe<Scalars['Int']['output']>
  buildYear: Maybe<Scalars['Int']['output']>
  /** Total floors of the entire building */
  buildingFloors: Maybe<Scalars['Int']['output']>
  canBeDeleted: Scalars['Boolean']['output']
  canBePublished: Scalars['Boolean']['output']
  cancellationOptions: Maybe<Array<CancellationPolicy>>
  cleaningFee: Scalars['Int']['output']
  contractTemplate: Maybe<ContractTemplate>
  conversations: ConversationConnection
  corporateHome: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  currency: CurrencyEnum
  currentCancellationPolicy: Maybe<CancellationPolicy>
  dashboardStatus: HomeDashboardStatusEnum
  description: Maybe<Scalars['String']['output']>
  descriptionBuilding: Maybe<Scalars['String']['output']>
  descriptionContract: Maybe<Scalars['String']['output']>
  descriptionFeatures: Maybe<Scalars['String']['output']>
  descriptionLayout: Maybe<Scalars['String']['output']>
  descriptionTransportation: Maybe<Scalars['String']['output']>
  displayStreetNumber: Scalars['Boolean']['output']
  duration: Duration
  electricityFee: Maybe<ElectricityFeeType>
  energyClass: Maybe<Scalars['String']['output']>
  /** Archived after date passed */
  expiresAt: Maybe<Scalars['DateTime']['output']>
  external: Scalars['Boolean']['output']
  externalAdId: Maybe<Scalars['Int']['output']>
  externalApplicationUrl: Maybe<Scalars['String']['output']>
  externalEmail: Maybe<Scalars['String']['output']>
  externalInfoPage: Maybe<Scalars['String']['output']>
  extraCosts: Maybe<Array<ExtraCost>>
  favoriteMarkedByUser: Scalars['Boolean']['output']
  favoriteMarkedCount: Scalars['Int']['output']
  fee: Maybe<Scalars['Int']['output']>
  firsthand: Scalars['Boolean']['output']
  floor: Maybe<Scalars['Int']['output']>
  handleByAgent: Scalars['Boolean']['output']
  hasKitchen: Scalars['Boolean']['output']
  homeAvailabilitySettings: Maybe<HomeAvailabilitySettings>
  homeTemplates: Array<HomeTemplate>
  houseRules: Maybe<Scalars['String']['output']>
  housingAssociation: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  inspection: Maybe<Inspection>
  /** @deprecated Move towards using market.instant_sign settings */
  instantSignSetting: Maybe<InstantSignSetting>
  insurance: Maybe<HomeInsuranceEnum>
  insuranceCost: Maybe<Scalars['Int']['output']>
  kitchenRenovationYear: Maybe<Scalars['Int']['output']>
  landlord: User
  landlordBaseFee: Maybe<Scalars['Int']['output']>
  landlordFees: HomeLandlordFees
  landlordServices: HomeLandlordServices
  landlordUnitId: Maybe<Scalars['String']['output']>
  lastBumpedAt: Maybe<Scalars['DateTime']['output']>
  latestViewing: Maybe<Viewing>
  links: Array<Link>
  /** @deprecated DEPRECATED: Use landlordFees.listingBumpFee instead */
  listingBumpPayment: StripePayment
  location: Location
  market: Market
  matchingTenantsCount: Scalars['Int']['output']
  matterportEmbedUrl: Maybe<Scalars['String']['output']>
  maxRent: Maybe<Scalars['Int']['output']>
  maxRoomCount: Maybe<Scalars['Float']['output']>
  maxSquareMeters: Maybe<Scalars['Int']['output']>
  maxTenantCount: Maybe<Scalars['Int']['output']>
  /** Only available on homeSearch and homeCoordsSearch */
  maximumPricePerNight: Maybe<Scalars['Int']['output']>
  messagesCount: Scalars['Int']['output']
  minRent: Maybe<Scalars['Int']['output']>
  minRoomCount: Maybe<Scalars['Float']['output']>
  minSquareMeters: Maybe<Scalars['Int']['output']>
  minTenantCount: Maybe<Scalars['Int']['output']>
  /** Only available on homeSearch and homeCoordsSearch */
  minimumPricePerNight: Maybe<Scalars['Int']['output']>
  monthlyCostCents: Maybe<Scalars['Int']['output']>
  numberOfHomes: Maybe<Scalars['Int']['output']>
  openForSigning: Scalars['Boolean']['output']
  organization: Maybe<Organization>
  origin: Maybe<Scalars['String']['output']>
  ownHome: Maybe<Scalars['Boolean']['output']>
  pageViews: Scalars['Int']['output']
  pendingOfferingsCount: Scalars['Int']['output']
  pricingModel: PricingModel
  pricingModelName: PricingModelNameEnum
  proLandlord: Scalars['Boolean']['output']
  property: Maybe<Property>
  publishedAt: Maybe<Scalars['DateTime']['output']>
  qasaGuarantee: Maybe<Scalars['Boolean']['output']>
  qasaGuaranteeCost: Maybe<Scalars['Int']['output']>
  recommendedRent: Maybe<Scalars['Int']['output']>
  recommendedRentNew: Maybe<Scalars['Int']['output']>
  rent: Maybe<Scalars['Int']['output']>
  rentalRequirement: Maybe<RentalRequirement>
  rentalType: HomeRentalTypeEnum
  responsibleForCleaning: Maybe<ResponsibleForCleaningEnum>
  roomCount: Maybe<Scalars['Float']['output']>
  safeRental: Maybe<Scalars['Boolean']['output']>
  seniorHome: Scalars['Boolean']['output']
  shared: Maybe<Scalars['Boolean']['output']>
  shortcut: Maybe<Shortcut>
  showtime: Maybe<Scalars['Boolean']['output']>
  /** A rough overview of where the home is in its life span. */
  simplifiedStatus: SimplifiedHomeStatusEnum
  squareMeters: Maybe<Scalars['Int']['output']>
  status: HomeStatusEnum
  studentHome: Scalars['Boolean']['output']
  tenantBaseFee: Maybe<Scalars['Int']['output']>
  tenantCount: Maybe<Scalars['Int']['output']>
  tenantFees: HomeTenantFees
  tenureType: Maybe<TenureTypeEnum>
  title: Maybe<Scalars['String']['output']>
  toiletCount: Maybe<Scalars['Int']['output']>
  traits: Array<Trait>
  translatedDescriptions: Maybe<Array<TranslatedDescription>>
  type: HomeTypeEnum
  unreadConversationsCount: Scalars['Int']['output']
  unreadMessagesCount: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
  uploads: Array<Upload>
  user: User
  userApplication: Maybe<HomeApplication>
  videos: Array<Blob>
  viewingCalendar: Maybe<ViewingCalendar>
}

export type HomeConversationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  homeId?: InputMaybe<Array<Scalars['ID']['input']>>
  last?: InputMaybe<Scalars['Int']['input']>
  lastMessageFrom?: InputMaybe<ConversationRoleTypeEnum>
  onlyActiveApplications?: Scalars['Boolean']['input']
  read?: InputMaybe<Scalars['Boolean']['input']>
  status?: InputMaybe<Array<ConversationStatusEnum>>
}

export type HomeRecommendedRentNewArgs = {
  forceSync?: InputMaybe<Scalars['Boolean']['input']>
}

export type HomeApplication = {
  __typename: 'HomeApplication'
  applicationBoardListPosition: Maybe<Scalars['Int']['output']>
  contract: Maybe<Contract>
  conversation: Maybe<Conversation>
  counterOffer: Maybe<Scalars['Int']['output']>
  createdAt: Scalars['DateTime']['output']
  currency: Scalars['String']['output']
  endOptimal: Maybe<Scalars['DateTime']['output']>
  endUfn: Scalars['Boolean']['output']
  home: Home
  homeTemplates: Array<HomeTemplate>
  id: Scalars['ID']['output']
  inContactAt: Maybe<Scalars['DateTime']['output']>
  /** @deprecated Not used */
  matchInterest: Maybe<Scalars['String']['output']>
  message: Maybe<Scalars['String']['output']>
  offering: Maybe<Offering>
  origin: HomeApplicationOriginEnum
  seen: Scalars['Boolean']['output']
  starMarkedByLandlord: Scalars['Boolean']['output']
  startOptimal: Maybe<Scalars['DateTime']['output']>
  status: HomeApplicationStatusEnum
  tenant: User
  tenantEmail: Scalars['String']['output']
  tenantFamilyName: Scalars['String']['output']
  tenantIdNumber: Maybe<Scalars['String']['output']>
  tenantPhoneNumber: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
  userNotes: Array<UserNote>
  viewing: Maybe<Viewing>
}

/** The connection type for HomeApplication. */
export type HomeApplicationConnection = {
  __typename: 'HomeApplicationConnection'
  /** A list of edges. */
  edges: Array<HomeApplicationEdge>
  /** A list of nodes. */
  nodes: Array<HomeApplication>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type HomeApplicationEdge = {
  __typename: 'HomeApplicationEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: HomeApplication
}

export enum HomeApplicationOrderEnum {
  BEST_MATCH = 'BEST_MATCH',
  LAST_CHAT_ACTIVITY = 'LAST_CHAT_ACTIVITY',
  NEWEST_FIRST = 'NEWEST_FIRST',
  NOT_STARRED = 'NOT_STARRED',
  OLDEST_FIRST = 'OLDEST_FIRST',
  POSITION = 'POSITION',
  STARRED = 'STARRED',
  WAITING_TIME = 'WAITING_TIME',
}

export enum HomeApplicationOriginEnum {
  landlord_application = 'landlord_application',
  tenant_application = 'tenant_application',
}

export type HomeApplicationStats = {
  __typename: 'HomeApplicationStats'
  applicationCount: Scalars['Int']['output']
  declinedCount: Scalars['Int']['output']
  inContactCount: Scalars['Int']['output']
  tenantApplicationCount: Scalars['Int']['output']
}

export enum HomeApplicationStatusEnum {
  closed = 'closed',
  declined = 'declined',
  in_contact = 'in_contact',
  pending = 'pending',
  revoked = 'revoked',
}

export enum HomeArchiveReasonEnum {
  accidental_publish = 'accidental_publish',
  application_deadline_passed = 'application_deadline_passed',
  changed_plans = 'changed_plans',
  dissatisfying_applications = 'dissatisfying_applications',
  dont_want_additional_costs = 'dont_want_additional_costs',
  end_date_passed = 'end_date_passed',
  expired = 'expired',
  inactivity = 'inactivity',
  lease_agreement_too_difficult = 'lease_agreement_too_difficult',
  needs_verification = 'needs_verification',
  other_reason = 'other_reason',
  review_denied = 'review_denied',
  shortcut_cancelled = 'shortcut_cancelled',
  shortcut_completed = 'shortcut_completed',
  suspected_scam = 'suspected_scam',
  tenant_found_in_bbq = 'tenant_found_in_bbq',
  tenant_from_platform = 'tenant_from_platform',
  tenant_outside_platform = 'tenant_outside_platform',
  token_archive = 'token_archive',
  user_removed = 'user_removed',
  using_broker_instead = 'using_broker_instead',
}

export type HomeAvailability = {
  __typename: 'HomeAvailability'
  booking: Maybe<Booking>
  date: Maybe<Scalars['Date']['output']>
  home: Home
  id: Scalars['ID']['output']
  price: Scalars['Int']['output']
  status: HomeAvailabilityStatusEnum
}

export type HomeAvailabilitySettings = {
  __typename: 'HomeAvailabilitySettings'
  defaultPricePerNight: Maybe<Scalars['Int']['output']>
  home: Home
  minNightsStay: Scalars['Int']['output']
  minPrice: Scalars['Int']['output']
  noCheckinDays: Array<DayOfTheWeekTypeEnum>
  rentOnlyWeekly: Scalars['Boolean']['output']
}

export enum HomeAvailabilityStatusEnum {
  available = 'available',
  no_checkin = 'no_checkin',
  only_checkout = 'only_checkout',
  unavailable = 'unavailable',
}

/** The connection type for Home. */
export type HomeConnection = {
  __typename: 'HomeConnection'
  /** A list of edges. */
  edges: Array<HomeEdge>
  /** A list of nodes. */
  nodes: Array<Home>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

export type HomeCoords = {
  __typename: 'HomeCoords'
  cost: Scalars['Int']['output']
  currency: CurrencyEnum
  firsthand: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  latitude: Scalars['String']['output']
  longitude: Scalars['String']['output']
  professional: Scalars['Boolean']['output']
  rent: Scalars['Int']['output']
  tenantBaseFee: Scalars['Int']['output']
}

/** The connection type for HomeCoords. */
export type HomeCoordsConnection = {
  __typename: 'HomeCoordsConnection'
  /** A list of edges. */
  edges: Array<HomeCoordsEdge>
  /** A list of nodes. */
  nodes: Array<HomeCoords>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type HomeCoordsEdge = {
  __typename: 'HomeCoordsEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: HomeCoords
}

export enum HomeDashboardStatusEnum {
  archived = 'archived',
  contract_ended = 'contract_ended',
  draft = 'draft',
  in_review = 'in_review',
  published = 'published',
  rented_out = 'rented_out',
  review_denied = 'review_denied',
  shortcut = 'shortcut',
}

export type HomeDocument = {
  __typename: 'HomeDocument'
  bedroomCount: Maybe<Scalars['Int']['output']>
  blockListing: Maybe<Scalars['Boolean']['output']>
  corporateHome: Maybe<Scalars['Boolean']['output']>
  currency: Maybe<Scalars['String']['output']>
  description: Maybe<Scalars['String']['output']>
  displayStreetNumber: Maybe<Scalars['Boolean']['output']>
  endDate: Maybe<Scalars['DateTime']['output']>
  firstHand: Maybe<Scalars['Boolean']['output']>
  furnished: Maybe<Scalars['Boolean']['output']>
  homeType: Maybe<Scalars['String']['output']>
  householdSize: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  instantSign: Maybe<Scalars['Boolean']['output']>
  landlordUid: Maybe<Scalars['String']['output']>
  lastBumpedAt: Maybe<Scalars['DateTime']['output']>
  location: Maybe<HomeDocumentLocationType>
  market: Maybe<Scalars['String']['output']>
  monthlyCost: Maybe<Scalars['Int']['output']>
  monthlyCostCents: Maybe<Scalars['Int']['output']>
  origin: Maybe<Scalars['String']['output']>
  petsAllowed: Maybe<Scalars['Boolean']['output']>
  platform: Maybe<Scalars['String']['output']>
  publishedAt: Maybe<Scalars['DateTime']['output']>
  publishedOrBumpedAt: Maybe<Scalars['DateTime']['output']>
  rent: Maybe<Scalars['Int']['output']>
  rentalLengthSeconds: Maybe<Scalars['Float']['output']>
  roomCount: Maybe<Scalars['Int']['output']>
  seniorHome: Maybe<Scalars['Boolean']['output']>
  shared: Maybe<Scalars['Boolean']['output']>
  shortcutHome: Maybe<Scalars['Boolean']['output']>
  smokingAllowed: Maybe<Scalars['Boolean']['output']>
  sortingScore: Maybe<Scalars['Float']['output']>
  squareMeters: Maybe<Scalars['Int']['output']>
  startDate: Maybe<Scalars['DateTime']['output']>
  studentHome: Maybe<Scalars['Boolean']['output']>
  tenantBaseFee: Maybe<Scalars['Int']['output']>
  title: Maybe<Scalars['String']['output']>
  uploads: Maybe<Array<HomeDocumentUploadType>>
  wheelchairAccessible: Maybe<Scalars['Boolean']['output']>
}

export type HomeDocumentLocationType = {
  __typename: 'HomeDocumentLocationType'
  countryCode: Maybe<Scalars['String']['output']>
  id: Maybe<Scalars['Int']['output']>
  locality: Maybe<Scalars['String']['output']>
  point: Maybe<GeoPoint>
  route: Maybe<Scalars['String']['output']>
  streetNumber: Maybe<Scalars['String']['output']>
}

export type HomeDocumentOffsetLimit = {
  __typename: 'HomeDocumentOffsetLimit'
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  nodes: Array<HomeDocument>
  pagesCount: Scalars['Int']['output']
  totalCount: Scalars['Int']['output']
}

export type HomeDocumentUploadType = {
  __typename: 'HomeDocumentUploadType'
  id: Maybe<Scalars['Int']['output']>
  order: Maybe<Scalars['Int']['output']>
  type: Maybe<Scalars['String']['output']>
  url: Maybe<Scalars['String']['output']>
}

/** An edge in a connection. */
export type HomeEdge = {
  __typename: 'HomeEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Home
}

export type HomeEvaluation = {
  __typename: 'HomeEvaluation'
  suggestedDescription: Maybe<Scalars['String']['output']>
  suggestedImageUrl: Maybe<Scalars['String']['output']>
}

export enum HomeIndexSearchOrderByEnum {
  end_date = 'end_date',
  monthly_cost = 'monthly_cost',
  monthly_cost_cents = 'monthly_cost_cents',
  published_or_bumped_at = 'published_or_bumped_at',
  rent = 'rent',
  room_count = 'room_count',
  sorting_score = 'sorting_score',
  square_meters = 'square_meters',
  start_date = 'start_date',
}

export enum HomeIndexSearchOrderDirectionEnum {
  ascending = 'ascending',
  descending = 'descending',
}

export type HomeIndexSearchOrderInput = {
  direction: HomeIndexSearchOrderDirectionEnum
  orderBy: HomeIndexSearchOrderByEnum
}

export type HomeIndexSearchQuery = {
  __typename: 'HomeIndexSearchQuery'
  documents: HomeDocumentOffsetLimit
}

export type HomeIndexSearchQueryDocumentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export enum HomeInsuranceEnum {
  insurance_landlord = 'insurance_landlord',
  insurance_tenant = 'insurance_tenant',
}

export type HomeLandlordFees = {
  __typename: 'HomeLandlordFees'
  base: Fee
  listingBumpFee: Fee
  listingPublicationFee: Fee
}

export type HomeLandlordServices = {
  __typename: 'HomeLandlordServices'
  insurance: Service
  qasaGuarantee: Service
}

export type HomeOffsetLimit = {
  __typename: 'HomeOffsetLimit'
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  nodes: Array<Home>
  pagesCount: Scalars['Int']['output']
  totalCount: Scalars['Int']['output']
}

export enum HomeRentalTypeEnum {
  long_term = 'long_term',
  vacation = 'vacation',
}

export type HomeSearch = {
  __typename: 'HomeSearch'
  filterHomes: HomeConnection
  filterHomesOffset: HomeOffsetLimit
  queryId: Scalars['ID']['output']
  searchId: Scalars['ID']['output']
  similarHomes: HomeConnection
}

export type HomeSearchFilterHomesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type HomeSearchFilterHomesOffsetArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type HomeSearchSimilarHomesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type HomeSearchCoords = {
  __typename: 'HomeSearchCoords'
  filterHomes: HomeCoordsConnection
  filterHomesCount: Scalars['Int']['output']
  filterHomesRaw: Scalars['JSON']['output']
  similarHomes: HomeCoordsConnection
  similarHomesRaw: Scalars['JSON']['output']
}

export type HomeSearchCoordsFilterHomesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type HomeSearchCoordsSimilarHomesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export enum HomeSearchOrderByEnum {
  CREATED_AT = 'CREATED_AT',
  FAVORITE_MARKED_AT = 'FAVORITE_MARKED_AT',
  MONTHLY_COST = 'MONTHLY_COST',
  MOVE_IN = 'MOVE_IN',
  MOVE_OUT = 'MOVE_OUT',
  PUBLISHED_AT = 'PUBLISHED_AT',
  RENT = 'RENT',
  ROOM_COUNT = 'ROOM_COUNT',
  ROUTE = 'ROUTE',
  SORTING_SCORE = 'SORTING_SCORE',
  SQUARE_METERS = 'SQUARE_METERS',
  UPDATED_AT = 'UPDATED_AT',
}

export enum HomeSearchOrderEnum {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export type HomeSearchParams = {
  __typename: 'HomeSearchParams'
  areaIdentifier: Maybe<Array<Scalars['ID']['output']>>
  checkIn: Maybe<Scalars['Date']['output']>
  checkOut: Maybe<Scalars['Date']['output']>
  corporateHome: Maybe<Scalars['Boolean']['output']>
  countryCode: Maybe<Scalars['String']['output']>
  currency: Maybe<Scalars['String']['output']>
  firsthand: Maybe<Scalars['Boolean']['output']>
  furniture: Maybe<Scalars['Boolean']['output']>
  hasKitchen: Maybe<Scalars['Boolean']['output']>
  homeType: Maybe<Array<HomeTypeEnum>>
  householdSize: Maybe<Scalars['Int']['output']>
  maxMonthlyCost: Maybe<Scalars['Int']['output']>
  maxPricePerNight: Maybe<Scalars['Int']['output']>
  maxRentalLength: Maybe<Scalars['Int']['output']>
  maxRoomCount: Maybe<Scalars['Int']['output']>
  maxSquareMeters: Maybe<Scalars['Int']['output']>
  minBedCount: Maybe<Scalars['Int']['output']>
  minBedroomCount: Maybe<Scalars['Int']['output']>
  minMonthlyCost: Maybe<Scalars['Int']['output']>
  minPricePerNight: Maybe<Scalars['Int']['output']>
  minRentalLength: Maybe<Scalars['Int']['output']>
  minRoomCount: Maybe<Scalars['Int']['output']>
  minSquareMeters: Maybe<Scalars['Int']['output']>
  minToiletCount: Maybe<Scalars['Int']['output']>
  moveInEarliest: Maybe<Scalars['DateTime']['output']>
  moveInLatest: Maybe<Scalars['DateTime']['output']>
  moveOutEarliest: Maybe<Scalars['DateTime']['output']>
  moveOutLatest: Maybe<Scalars['DateTime']['output']>
  pets: Maybe<Scalars['Boolean']['output']>
  safeRental: Maybe<Scalars['Boolean']['output']>
  searchAreas: Maybe<Array<SearchArea>>
  seniorHome: Maybe<Scalars['Boolean']['output']>
  shared: Maybe<Scalars['Boolean']['output']>
  smoker: Maybe<Scalars['Boolean']['output']>
  studentHome: Maybe<Scalars['Boolean']['output']>
  traits: Maybe<Array<HomeTraitEnum>>
  user: User
  wheelchairAccessible: Maybe<Scalars['Boolean']['output']>
}

export type HomeSearchParamsInput = {
  areaId?: InputMaybe<Scalars['ID']['input']>
  areaIdentifier?: InputMaybe<Array<Scalars['ID']['input']>>
  checkIn?: InputMaybe<Scalars['Date']['input']>
  checkOut?: InputMaybe<Scalars['Date']['input']>
  corporateHome?: InputMaybe<Scalars['Boolean']['input']>
  countryCode?: InputMaybe<Scalars['String']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  firsthand?: InputMaybe<Scalars['Boolean']['input']>
  furniture?: InputMaybe<Scalars['Boolean']['input']>
  hasKitchen?: InputMaybe<Scalars['Boolean']['input']>
  homeType?: InputMaybe<Array<HomeTypeEnum>>
  householdSize?: InputMaybe<Scalars['Int']['input']>
  leaseType?: InputMaybe<Array<LeaseTypeEnum>>
  market?: InputMaybe<MarketNameTypeEnum>
  matchingArea?: InputMaybe<Scalars['JSON']['input']>
  matchingAreaMetadata?: InputMaybe<Array<MatchingAreaMetadataInput>>
  maxMonthlyCost?: InputMaybe<Scalars['Int']['input']>
  maxPricePerNight?: InputMaybe<Scalars['Int']['input']>
  maxRent?: InputMaybe<Scalars['Int']['input']>
  maxRentalLength?: InputMaybe<Scalars['Int']['input']>
  maxRoomCount?: InputMaybe<Scalars['Int']['input']>
  maxSquareMeters?: InputMaybe<Scalars['Int']['input']>
  minBedCount?: InputMaybe<Scalars['Int']['input']>
  minBedroomCount?: InputMaybe<Scalars['Int']['input']>
  minMonthlyCost?: InputMaybe<Scalars['Int']['input']>
  minPricePerNight?: InputMaybe<Scalars['Int']['input']>
  minRent?: InputMaybe<Scalars['Int']['input']>
  minRentalLength?: InputMaybe<Scalars['Int']['input']>
  minRoomCount?: InputMaybe<Scalars['Int']['input']>
  minSquareMeters?: InputMaybe<Scalars['Int']['input']>
  minToiletCount?: InputMaybe<Scalars['Int']['input']>
  moveInEarliest?: InputMaybe<Scalars['DateTime']['input']>
  moveInLatest?: InputMaybe<Scalars['DateTime']['input']>
  moveOutEarliest?: InputMaybe<Scalars['DateTime']['input']>
  moveOutLatest?: InputMaybe<Scalars['DateTime']['input']>
  pets?: InputMaybe<Scalars['Boolean']['input']>
  platform?: InputMaybe<PlatformEnum>
  rentalType?: InputMaybe<Array<HomeRentalTypeEnum>>
  safeRental?: InputMaybe<Scalars['Boolean']['input']>
  seniorHome?: InputMaybe<Scalars['Boolean']['input']>
  shared?: InputMaybe<Scalars['Boolean']['input']>
  shortcutHome?: InputMaybe<Scalars['Boolean']['input']>
  smoker?: InputMaybe<Scalars['Boolean']['input']>
  studentHome?: InputMaybe<Scalars['Boolean']['input']>
  /** If this list is not-empty, the query will ignore the arguments: furniture, pets, smoker, wheelchair_accessible */
  traits?: InputMaybe<Array<HomeTraitEnum>>
  uids?: InputMaybe<Array<Scalars['ID']['input']>>
  wheelchairAccessible?: InputMaybe<Scalars['Boolean']['input']>
  withinDistanceFrom?: InputMaybe<WithinDistanceFromInput>
}

export type HomeStats = {
  __typename: 'HomeStats'
  acceptedOfferingsCount: Scalars['Int']['output']
  applicationsCount: Scalars['Int']['output']
  applicationsInContactCount: Scalars['Int']['output']
  cumulativePageViews: Array<Scalars['Int']['output']>
  cumulativeUniquePageViews: Array<Scalars['Int']['output']>
  declinedOfferingsCount: Scalars['Int']['output']
  offeringsCount: Scalars['Int']['output']
  pageViews: Scalars['Int']['output']
  pendingOfferingsCount: Scalars['Int']['output']
  periods: Array<Scalars['DateTime']['output']>
  revokedOfferingsCount: Scalars['Int']['output']
  uniquePageViews: Scalars['Int']['output']
}

export enum HomeStatusEnum {
  archived = 'archived',
  contract_ended = 'contract_ended',
  hidden = 'hidden',
  in_review = 'in_review',
  in_shortcut = 'in_shortcut',
  normal = 'normal',
  pending = 'pending',
  pending_shortcut = 'pending_shortcut',
  proposed_shortcut = 'proposed_shortcut',
  rented_out = 'rented_out',
}

export type HomeTemplate = {
  __typename: 'HomeTemplate'
  acceptedOfferingsCount: Scalars['Int']['output']
  apartmentNumber: Maybe<Scalars['String']['output']>
  bathroomRenovationYear: Maybe<Scalars['Int']['output']>
  building: Maybe<Building>
  corporateHome: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  description: Maybe<Scalars['String']['output']>
  firsthand: Scalars['Boolean']['output']
  floor: Maybe<Scalars['Int']['output']>
  homeLayoutPictures: Array<Upload>
  homes: Array<Home>
  id: Scalars['ID']['output']
  kitchenRenovationYear: Maybe<Scalars['Int']['output']>
  landlord: User
  location: Maybe<Location>
  organization: Maybe<Organization>
  pendingOfferingsCount: Scalars['Int']['output']
  publishedHomes: Array<Home>
  rent: Maybe<Scalars['Int']['output']>
  roomCount: Maybe<Scalars['Float']['output']>
  seniorHome: Scalars['Boolean']['output']
  shared: Maybe<Scalars['Boolean']['output']>
  squareMeters: Maybe<Scalars['Int']['output']>
  studentHome: Scalars['Boolean']['output']
  tenantCount: Maybe<Scalars['Int']['output']>
  tenureType: Maybe<Scalars['String']['output']>
  traits: Array<Trait>
  type: HomeTypeEnum
  updatedAt: Scalars['DateTime']['output']
  uploads: Array<Upload>
}

export type HomeTemplateAcceptedOfferingsCountArgs = {
  homeId?: InputMaybe<Scalars['ID']['input']>
}

export type HomeTemplateHomesArgs = {
  statuses?: InputMaybe<Array<HomeStatusEnum>>
}

export type HomeTemplatePendingOfferingsCountArgs = {
  homeId?: InputMaybe<Scalars['ID']['input']>
}

/** The connection type for HomeTemplate. */
export type HomeTemplateConnection = {
  __typename: 'HomeTemplateConnection'
  /** A list of edges. */
  edges: Array<HomeTemplateEdge>
  /** A list of nodes. */
  nodes: Array<HomeTemplate>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type HomeTemplateEdge = {
  __typename: 'HomeTemplateEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: HomeTemplate
}

export enum HomeTemplateOrderEnum {
  BUILDING_NAME = 'BUILDING_NAME',
  IS_LISTED = 'IS_LISTED',
  UPDATED_LATEST = 'UPDATED_LATEST',
}

export type HomeTenantFees = {
  __typename: 'HomeTenantFees'
  base: Fee
  monthlyElectricityFee: Maybe<Fee>
  waterFeePerTenant: Fee
}

export enum HomeTraitEnum {
  archipelago = 'archipelago',
  balcony = 'balcony',
  bathtub = 'bathtub',
  bed_sheets = 'bed_sheets',
  bike_room = 'bike_room',
  bikes = 'bikes',
  boat_kayak = 'boat_kayak',
  coffee_brewer = 'coffee_brewer',
  communal_sauna = 'communal_sauna',
  cot = 'cot',
  dish_washer = 'dish_washer',
  drying_room = 'drying_room',
  electric_car_charger = 'electric_car_charger',
  electricity_included = 'electricity_included',
  elevator = 'elevator',
  extra_blankets_and_pillows = 'extra_blankets_and_pillows',
  fire_extinguisher = 'fire_extinguisher',
  first_aid_kit = 'first_aid_kit',
  freezer = 'freezer',
  french_balcony = 'french_balcony',
  fridge = 'fridge',
  furniture = 'furniture',
  garage = 'garage',
  garage_included = 'garage_included',
  garden = 'garden',
  grill = 'grill',
  grill_essentials = 'grill_essentials',
  hair_dryer = 'hair_dryer',
  heat_included = 'heat_included',
  high_chair = 'high_chair',
  indoor_fireplace = 'indoor_fireplace',
  inhome_sauna = 'inhome_sauna',
  internet = 'internet',
  internet_included = 'internet_included',
  iron = 'iron',
  jacuzzi = 'jacuzzi',
  kettle = 'kettle',
  kitchen_appliances = 'kitchen_appliances',
  kitchenette = 'kitchenette',
  microwave_oven = 'microwave_oven',
  near_beach = 'near_beach',
  near_fishing = 'near_fishing',
  near_golf = 'near_golf',
  near_hiking_routes = 'near_hiking_routes',
  near_restaurants = 'near_restaurants',
  outdoor_furniture = 'outdoor_furniture',
  outdoor_shower = 'outdoor_shower',
  outhouse = 'outhouse',
  oven = 'oven',
  overnight_stay = 'overnight_stay',
  parking = 'parking',
  parking_included = 'parking_included',
  patio = 'patio',
  pets = 'pets',
  pool = 'pool',
  porcelain_glasses_cutlery = 'porcelain_glasses_cutlery',
  recycling = 'recycling',
  remote = 'remote',
  security_door = 'security_door',
  shared_washing_area = 'shared_washing_area',
  shower = 'shower',
  shower_supplies = 'shower_supplies',
  ski_in_out = 'ski_in_out',
  smoke_alarm = 'smoke_alarm',
  smoker = 'smoker',
  sound_system = 'sound_system',
  storage = 'storage',
  stove = 'stove',
  stroller_room = 'stroller_room',
  television = 'television',
  television_included = 'television_included',
  toilet = 'toilet',
  towels = 'towels',
  tumble_dryer = 'tumble_dryer',
  washing_machine = 'washing_machine',
  water_included = 'water_included',
  wheelchair_accessible = 'wheelchair_accessible',
}

export enum HomeTypeEnum {
  apartment = 'apartment',
  corridor = 'corridor',
  cottage = 'cottage',
  duplex = 'duplex',
  house = 'house',
  loft = 'loft',
  other = 'other',
  own = 'own',
  room = 'room',
  terrace_house = 'terrace_house',
}

export enum HomeUserRoleEnum {
  landlord = 'landlord',
  tenant = 'tenant',
}

export type HousingSituation = {
  __typename: 'HousingSituation'
  agreementType: Maybe<HousingSituationAgreementTypeEnum>
  createdAt: Scalars['DateTime']['output']
  externalLandlordReference: Maybe<Scalars['String']['output']>
  hasReference: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  landlordCity: Maybe<Scalars['String']['output']>
  landlordName: Maybe<Scalars['String']['output']>
  landlordPhoneNumber: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export enum HousingSituationAgreementTypeEnum {
  co_living = 'co_living',
  first_hand_renting = 'first_hand_renting',
  living_with_family = 'living_with_family',
  other_agreement_type = 'other_agreement_type',
  own_condominium = 'own_condominium',
  own_house = 'own_house',
  owning = 'owning',
  second_hand_renting = 'second_hand_renting',
  student_housing = 'student_housing',
}

export type HousingSituationInput = {
  agreementType?: InputMaybe<HousingSituationAgreementTypeEnum>
  externalLandlordReference?: InputMaybe<Scalars['String']['input']>
  landlordCity?: InputMaybe<Scalars['String']['input']>
  landlordName?: InputMaybe<Scalars['String']['input']>
  landlordPhoneNumber?: InputMaybe<Scalars['String']['input']>
}

export type IdNumberFieldsInput = {
  idNumber: Scalars['String']['input']
  idNumberType: IdNumberTypeEnum
}

export enum IdNumberTypeEnum {
  finnish_personal_number = 'finnish_personal_number',
  norwegian_personal_number = 'norwegian_personal_number',
  other = 'other',
  swedish_personal_number = 'swedish_personal_number',
}

export type Identification = {
  __typename: 'Identification'
  familyName: Maybe<Scalars['String']['output']>
  firstName: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  idNumber: Maybe<Scalars['String']['output']>
  identificationStatus: IdentificationStatusEnum
  identificationType: IdentificationTypeEnum
  identityConfirmed: Scalars['Boolean']['output']
  identityConfirmedAt: Maybe<Scalars['DateTime']['output']>
}

export enum IdentificationStatusEnum {
  accepted = 'accepted',
  invalidated = 'invalidated',
  pending = 'pending',
  rejected = 'rejected',
  rejected_minor = 'rejected_minor',
}

export enum IdentificationTypeEnum {
  fi_tupas = 'fi_tupas',
  manual = 'manual',
  no_bank_id = 'no_bank_id',
  onfido = 'onfido',
  se_bank_id = 'se_bank_id',
  tink = 'tink',
}

export type IdentifyInput = {
  path?: InputMaybe<Scalars['String']['input']>
  /**
   * Including
   *       https:// (or http:// for localhost), without trailing slash. E.g.
   *       https://example.com. If not passed it will use the environment default.
   */
  redirectHost?: InputMaybe<Scalars['String']['input']>
  type: IdentificationTypeEnum
}

/** Autogenerated return type of Identify. */
export type IdentifyPayload = {
  __typename: 'IdentifyPayload'
  accessUrl: Maybe<Scalars['String']['output']>
  errors: Maybe<Array<Validation>>
  success: Scalars['Boolean']['output']
}

export type IfInsurancePromotion = {
  __typename: 'IfInsurancePromotion'
  signedUp: Maybe<Scalars['Boolean']['output']>
}

/** Autogenerated return type of IfInsurancePromotionSignUp. */
export type IfInsurancePromotionSignUpPayload = {
  __typename: 'IfInsurancePromotionSignUpPayload'
  errors: Maybe<Array<Validation>>
  ifInsurancePromotion: Maybe<IfInsurancePromotion>
}

/** Autogenerated return type of ImportTinkBankAccount. */
export type ImportTinkBankAccountPayload = {
  __typename: 'ImportTinkBankAccountPayload'
  bankAccount: Maybe<BankAccount>
  errors: Maybe<Array<Validation>>
  isIdNumberMismatch: Scalars['Boolean']['output']
}

export enum InboxQueryOrderByEnum {
  latest_update = 'latest_update',
  star_and_latest_update = 'star_and_latest_update',
}

export type IncomeVerification = {
  __typename: 'IncomeVerification'
  /** @deprecated Use mean_benefits instead */
  benefitsMean: Maybe<Scalars['Int']['output']>
  /** @deprecated Use mean_cash_deposits instead */
  cashDepositsMean: Maybe<Scalars['Int']['output']>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  meanBenefits: Maybe<Money>
  meanCashDeposits: Maybe<Money>
  meanOtherIncomes: Maybe<Money>
  meanPension: Maybe<Money>
  meanSalary: Maybe<Money>
  meanTotalIncome: Money
  /** @deprecated Use mean_other_incomes instead */
  otherIncomesMean: Maybe<Scalars['Int']['output']>
  /** @deprecated Use mean_pension instead */
  pensionMean: Maybe<Scalars['Int']['output']>
  /** @deprecated Use mean_salary instead */
  salaryMean: Maybe<Scalars['Int']['output']>
  status: IncomeVerificationStatusEnum
  /** @deprecated Use mean_total_income instead */
  totalIncomeMean: Scalars['Int']['output']
  transactionGroups: Array<TinkTransactionGroupType>
  transactions: Array<TinkTransactionType>
  updatedAt: Scalars['DateTime']['output']
  uploads: Array<Upload>
  user: User
  verifiedAt: Maybe<Scalars['DateTime']['output']>
}

export type IncomeVerificationInput = {
  benefitsMean?: InputMaybe<Scalars['Int']['input']>
  cashDepositsMean?: InputMaybe<Scalars['Int']['input']>
  currency?: InputMaybe<CurrencyEnum>
  otherIncomesMean?: InputMaybe<Scalars['Int']['input']>
  pensionMean?: InputMaybe<Scalars['Int']['input']>
  salaryMean?: InputMaybe<Scalars['Int']['input']>
}

export enum IncomeVerificationStatusEnum {
  approved = 'approved',
  pending = 'pending',
  rejected = 'rejected',
}

export type InitiateBookingCardPaymentInput = {
  invoiceId: Scalars['ID']['input']
  stripeParams: StripeCheckoutInput
}

/** Autogenerated return type of InitiateBookingCardPayment. */
export type InitiateBookingCardPaymentPayload = {
  __typename: 'InitiateBookingCardPaymentPayload'
  errors: Maybe<Array<Validation>>
  sessionId: Maybe<Scalars['ID']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated return type of InitiateContractSigning. */
export type InitiateContractSigningPayload = {
  __typename: 'InitiateContractSigningPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

export type InitiateListingBumpPaymentInput = {
  homeId: Scalars['ID']['input']
  stripeParams: StripeCheckoutInput
}

/** Autogenerated return type of InitiateListingBumpPayment. */
export type InitiateListingBumpPaymentPayload = {
  __typename: 'InitiateListingBumpPaymentPayload'
  errors: Maybe<Array<Validation>>
  sessionId: Maybe<Scalars['ID']['output']>
  success: Scalars['Boolean']['output']
}

export type InitiateListingPublicationFeePaymentInput = {
  homeId: Scalars['ID']['input']
  stripeParams: StripeCheckoutInput
}

/** Autogenerated return type of InitiateListingPublicationFeePayment. */
export type InitiateListingPublicationFeePaymentPayload = {
  __typename: 'InitiateListingPublicationFeePaymentPayload'
  sessionId: Maybe<Scalars['ID']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated return type of InitiateOnfidoCheck. */
export type InitiateOnfidoCheckPayload = {
  __typename: 'InitiateOnfidoCheckPayload'
  success: Scalars['Boolean']['output']
}

export type InitiateSwishPaymentInput = {
  invoiceId: Scalars['ID']['input']
  phoneNumber: Scalars['String']['input']
}

/** Autogenerated return type of InitiateSwishPayment. */
export type InitiateSwishPaymentPayload = {
  __typename: 'InitiateSwishPaymentPayload'
  errors: Maybe<Array<Validation>>
  swishPayment: Maybe<SwishPayment>
}

/** Autogenerated return type of InitiateTinkIncomeCheck. */
export type InitiateTinkIncomeCheckPayload = {
  __typename: 'InitiateTinkIncomeCheckPayload'
  errors: Maybe<Array<Validation>>
  incomeVerification: Maybe<IncomeVerification>
}

export type Inspection = {
  __typename: 'Inspection'
  id: Scalars['ID']['output']
  observations: Maybe<Array<InspectionObservation>>
}

export type InspectionObservation = {
  __typename: 'InspectionObservation'
  description: Maybe<Scalars['String']['output']>
  section: InspectionRoomsEnum
}

export type InspectionObservationInput = {
  description: Scalars['String']['input']
  section: InspectionRoomsEnum
}

export enum InspectionRoomsEnum {
  bathroom = 'bathroom',
  bedroom = 'bedroom',
  hallway = 'hallway',
  kitchen = 'kitchen',
  living_room = 'living_room',
  other = 'other',
}

export type InstantSignDurationInput = {
  startOptimal?: InputMaybe<Scalars['Date']['input']>
}

export type InstantSignSetting = {
  __typename: 'InstantSignSetting'
  enabled: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  landlordHoursToSign: Scalars['Int']['output']
  tenantMinutesToSign: Scalars['Int']['output']
}

export type InstantSignSettingInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type InstantSignType = {
  __typename: 'InstantSignType'
  enabled: Scalars['Boolean']['output']
  landlordTimeToSignMinutes: Maybe<Scalars['Int']['output']>
  tenantTimeToSignMinutes: Maybe<Scalars['Int']['output']>
}

export type Insurance = {
  __typename: 'Insurance'
  claims: Scalars['Boolean']['output']
  hedvigId: Maybe<Scalars['String']['output']>
  omocomId: Maybe<Scalars['String']['output']>
  status: Scalars['String']['output']
}

export enum InsuranceResponsibilityEnum {
  insurance_responsibility_landlord = 'insurance_responsibility_landlord',
  insurance_responsibility_tenant = 'insurance_responsibility_tenant',
}

export type Invoice = {
  __typename: 'Invoice'
  adminNotes: Array<AdminNote>
  amount: Scalars['Int']['output']
  batch: Scalars['Int']['output']
  billectaId: Maybe<Scalars['ID']['output']>
  contract: Contract
  createdAt: Scalars['DateTime']['output']
  creditedAmount: Maybe<Scalars['Int']['output']>
  currency: CurrencyEnum
  dueAt: Scalars['DateTime']['output']
  guaranteed: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  invoiceEntries: Maybe<Array<InvoiceEntry>>
  paidAt: Maybe<Scalars['DateTime']['output']>
  periodEndsAt: Scalars['DateTime']['output']
  periodStartsAt: Scalars['DateTime']['output']
  remindersDisabledAt: Maybe<Scalars['DateTime']['output']>
  sendAt: Scalars['DateTime']['output']
  sentAt: Maybe<Scalars['DateTime']['output']>
  swishPayments: Array<Maybe<SwishPayment>>
  type: InvoiceTypeEnum
  updatedAt: Scalars['DateTime']['output']
  url: Maybe<Scalars['String']['output']>
}

export type InvoiceEntry = {
  __typename: 'InvoiceEntry'
  amount: Scalars['Int']['output']
  costType: InvoiceEntryCostTypeEnum
  creditedAmount: Scalars['Int']['output']
  currency: CurrencyEnum
  entryType: InvoiceEntryTypeEnum
  id: Scalars['ID']['output']
  invoiceId: Scalars['ID']['output']
  paidAt: Maybe<Scalars['DateTime']['output']>
}

export enum InvoiceEntryCostTypeEnum {
  deduction = 'deduction',
  surcharge = 'surcharge',
}

export enum InvoiceEntryTypeEnum {
  cleaning_fee_payment = 'cleaning_fee_payment',
  cleaning_fee_payout = 'cleaning_fee_payout',
  deposit_compensation_deposit_free_payout = 'deposit_compensation_deposit_free_payout',
  deposit_compensation_payment = 'deposit_compensation_payment',
  deposit_compensation_payout = 'deposit_compensation_payout',
  deposit_free = 'deposit_free',
  deposit_payment = 'deposit_payment',
  deposit_payout = 'deposit_payout',
  electricity_fee_payment = 'electricity_fee_payment',
  electricity_fee_payout = 'electricity_fee_payout',
  insurance_landlord = 'insurance_landlord',
  insurance_tenant = 'insurance_tenant',
  landlord_base_fee = 'landlord_base_fee',
  landlord_base_fee_adjustment = 'landlord_base_fee_adjustment',
  qasa_guarantee = 'qasa_guarantee',
  rental_payment = 'rental_payment',
  rental_payment_adjustment = 'rental_payment_adjustment',
  rental_payout = 'rental_payout',
  rental_payout_adjustment = 'rental_payout_adjustment',
  tenant_base_fee = 'tenant_base_fee',
  tenant_base_fee_adjustment = 'tenant_base_fee_adjustment',
  water_cost_payment = 'water_cost_payment',
  water_cost_payout = 'water_cost_payout',
}

export type InvoiceInterface = {
  /** Charges that are to be withdrawn from the invoice at the time of payout to the landlord. */
  charges: Array<FinanceChargeType>
  createdAt: Scalars['DateTime']['output']
  currentState: InvoiceStateEnum
  /** The date by which the invoice must be paid. */
  dueDate: Scalars['Date']['output']
  /** The database ID of the invoice. */
  id: Scalars['ID']['output']
  /** Items that are to be paid by the tenant. */
  items: Array<FinanceItemType>
  /** Indicates whether the invoice is overdue. */
  overdue: Scalars['Boolean']['output']
  paidAt: Maybe<Scalars['DateTime']['output']>
  paymentAction: Maybe<InvoicePaymentActionUnion>
  payout: Maybe<FinancePayoutType>
  /** The receipt of the invoice if the invoice is paid. */
  receipt: Maybe<Blob>
  /** A globally unique reference that is Luhn-10 compliant. */
  reference: Scalars['ID']['output']
  requiredUserActions: Array<UserAction>
  sumAmount: Money
  sumVat: Money
}

export type InvoicePaymentActionUnion = StripeSepaTransferMoneyAction

export type InvoiceSettingsType = {
  __typename: 'InvoiceSettingsType'
  daysBeforeReminderDelay: Scalars['Int']['output']
}

export enum InvoiceStateEnum {
  cancelled = 'cancelled',
  created = 'created',
  credited = 'credited',
  paid = 'paid',
  partially_paid = 'partially_paid',
  pending = 'pending',
  pending_cancel = 'pending_cancel',
  refunded = 'refunded',
}

export enum InvoiceTypeEnum {
  invoice_deposit = 'invoice_deposit',
  invoice_deposit_compensation = 'invoice_deposit_compensation',
  invoice_rent = 'invoice_rent',
  selfinvoice_deposit = 'selfinvoice_deposit',
  selfinvoice_deposit_compensation = 'selfinvoice_deposit_compensation',
  selfinvoice_rent = 'selfinvoice_rent',
  verification_fee = 'verification_fee',
}

export type KanyeInputObjectsCreatePublicDocumentInput = {
  /** Blob id of the uploaded file */
  file: Scalars['ID']['input']
  /** Name of the document */
  name: Scalars['String']['input']
}

export type KanyeUser = {
  __typename: 'KanyeUser'
  /** latest approved identifications of all types */
  activeIdentifications: Maybe<Array<Identification>>
  adminFields: Maybe<AdminFields>
  adminNotes: Array<AdminNote>
  affiliateCode: Maybe<AffiliateCode>
  age: Maybe<Scalars['Int']['output']>
  applicationStats: UserApplicationStats
  bio: UserBio
  bookings: Array<Booking>
  certificate: Maybe<Certificate>
  companyName: Maybe<Scalars['String']['output']>
  contactLocation: Maybe<Location>
  contracts: Array<Contract>
  contractsCount: Scalars['Int']['output']
  /** conversation object for a specific home, if any */
  conversationForHome: Maybe<Conversation>
  createdAt: Scalars['DateTime']['output']
  creditReport: Maybe<CreditReport>
  /** latest approved or latest created */
  currentIdentification: Maybe<Identification>
  deliveryTraces: DeliveryTraceConnection
  doNotContact: Maybe<Scalars['Boolean']['output']>
  doNotSms: Maybe<Scalars['Boolean']['output']>
  doNotVacationSms: Maybe<Scalars['Boolean']['output']>
  emailConfirmed: Scalars['Boolean']['output']
  employmentVerification: Maybe<EmploymentVerification>
  evictionProcesses: EvictionProcessConnection
  firstName: Maybe<Scalars['String']['output']>
  /** Home applications done by this user for homes where the current user is landlord */
  homeApplicationsToMyHomes: Array<HomeApplication>
  homes: Array<Home>
  householdMembersBirthYears: Maybe<Array<Scalars['Int']['output']>>
  housingSituation: Maybe<HousingSituation>
  /** Unmasked id number */
  idNumber: Maybe<Scalars['String']['output']>
  /** @deprecated Use `currentIdentification` instead */
  idNumberConfirmed: Scalars['Boolean']['output']
  /** Use `currentIdentification` instead */
  identityConfirmed: Scalars['Boolean']['output']
  /** Use `currentIdentification` instead */
  identityConfirmedAt: Maybe<Scalars['DateTime']['output']>
  incomeVerifiedAt: Maybe<Scalars['DateTime']['output']>
  landlord: Scalars['Boolean']['output']
  landlordApplicationResponseRate: Maybe<Scalars['Float']['output']>
  landlordApplicationResponseTimeHours: Maybe<Scalars['Int']['output']>
  locale: Maybe<Scalars['String']['output']>
  mainPlatform: PlatformEnum
  matchingProfile: UserMatchingProfile
  movingReason: Maybe<Scalars['String']['output']>
  notificationSpan: Maybe<NotificationSpanEnum>
  nrFirsthandHousingUnits: Maybe<NrFirsthandHousingUnitsEnum>
  occupations: Maybe<Array<Occupation>>
  onfidoApplicantId: Maybe<Scalars['String']['output']>
  pep: Maybe<Scalars['Boolean']['output']>
  premium: Scalars['Boolean']['output']
  private: UserPrivate
  proAgent: Scalars['Boolean']['output']
  proPilot: Scalars['Boolean']['output']
  proUser: Scalars['Boolean']['output']
  professional: Scalars['Boolean']['output']
  profilePicture: Maybe<Upload>
  /** Deprecated, never used */
  prospectOnly: Scalars['Boolean']['output']
  publishedHomes: Array<Home>
  rca: Maybe<Scalars['Boolean']['output']>
  /** @deprecated Feature is deprecated */
  referenceCheck: Maybe<Scalars['String']['output']>
  removed: Scalars['Boolean']['output']
  removedAt: Maybe<Scalars['DateTime']['output']>
  rentingOutFirsthandHomes: Maybe<Scalars['Boolean']['output']>
  reportCount: Scalars['Int']['output']
  safeRental: Maybe<Scalars['Boolean']['output']>
  seenAt: Maybe<Scalars['DateTime']['output']>
  sendMonitoringEvents: Scalars['Boolean']['output']
  showAge: Maybe<Scalars['Boolean']['output']>
  smoker: Scalars['Boolean']['output']
  studentVerification: Maybe<StudentVerification>
  suspended: Scalars['Boolean']['output']
  tenant: Scalars['Boolean']['output']
  tenantAd: Maybe<TenantAd>
  tenantProfileCompleteness: TenantProfileCompleteness
  uid: Scalars['ID']['output']
  updatedAt: Scalars['DateTime']['output']
  /** Home application notes between user and user who sent the request */
  userNotes: Array<UserNote>
  userReports: UserReportConnection
}

export type KanyeUserBookingsArgs = {
  homeId?: InputMaybe<Scalars['ID']['input']>
}

export type KanyeUserContractsArgs = {
  rentalType?: InputMaybe<Array<ContractRentalTypeEnum>>
  status?: InputMaybe<Array<ContractStatusEnum>>
}

export type KanyeUserContractsCountArgs = {
  rentalType?: InputMaybe<Array<ContractRentalTypeEnum>>
  status?: InputMaybe<Array<ContractStatusEnum>>
}

export type KanyeUserConversationForHomeArgs = {
  homeId: Scalars['ID']['input']
}

export type KanyeUserDeliveryTracesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type KanyeUserEvictionProcessesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type KanyeUserHomeApplicationsToMyHomesArgs = {
  homeIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type KanyeUserUserReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The connection type for KanyeUser. */
export type KanyeUserConnection = {
  __typename: 'KanyeUserConnection'
  /** A list of edges. */
  edges: Array<KanyeUserEdge>
  /** A list of nodes. */
  nodes: Array<KanyeUser>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type KanyeUserEdge = {
  __typename: 'KanyeUserEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: KanyeUser
}

export type LandlordContactInfo = {
  __typename: 'LandlordContactInfo'
  familyName: Maybe<Scalars['String']['output']>
  phoneNumber: Maybe<Scalars['String']['output']>
}

export type LastSeenMessage = {
  __typename: 'LastSeenMessage'
  messageId: Maybe<Scalars['ID']['output']>
  uid: Scalars['ID']['output']
}

export enum LeaseTypeEnum {
  corporate_home = 'corporate_home',
  firsthand = 'firsthand',
  senior_home = 'senior_home',
  shared = 'shared',
  student_home = 'student_home',
}

export enum LegalTypeEnum {
  egen_bolig = 'egen_bolig',
  enkelt_beboelserom = 'enkelt_beboelserom',
  hus_leilighet = 'hus_leilighet',
  loft_sokkenbolig = 'loft_sokkenbolig',
}

export type Link = {
  __typename: 'Link'
  locale: Scalars['ID']['output']
  url: Scalars['String']['output']
}

export enum ListingPlatformSelectorEventTypeEnum {
  oikotie_opt_in_oikotie_selector = 'oikotie_opt_in_oikotie_selector',
  page_view = 'page_view',
  page_view_oikotie_selector = 'page_view_oikotie_selector',
  qasa_opt_in = 'qasa_opt_in',
  qasa_opt_in_oikotie_selector = 'qasa_opt_in_oikotie_selector',
  tori_opt_in = 'tori_opt_in',
  tori_tenant_opt_in = 'tori_tenant_opt_in',
  vacation_home_opt_in_oikotie_selector = 'vacation_home_opt_in_oikotie_selector',
}

export type Localization = {
  __typename: 'Localization'
  availableLanguages: Array<Scalars['String']['output']>
  countryCode: Scalars['String']['output']
  currency: Scalars['String']['output']
  defaultLanguage: Scalars['String']['output']
}

export type Location = {
  __typename: 'Location'
  country: Maybe<Scalars['String']['output']>
  countryCode: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  formattedAddress: Maybe<Scalars['String']['output']>
  /** @deprecated Moved to locationIQ, use place_id instead. */
  googleMapsPlaceId: Scalars['String']['output']
  granularity: LocationGranularityEnum
  id: Scalars['ID']['output']
  latitude: Maybe<Scalars['Float']['output']>
  locality: Maybe<Scalars['String']['output']>
  longitude: Maybe<Scalars['Float']['output']>
  placeId: Scalars['String']['output']
  pointsOfInterest: PointOfInterestConnection
  postalCode: Maybe<Scalars['String']['output']>
  route: Maybe<Scalars['String']['output']>
  shortName: Maybe<Scalars['String']['output']>
  streetNumber: Maybe<Scalars['String']['output']>
  sublocality: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type LocationPointsOfInterestArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  categories?: InputMaybe<Array<PointOfInterestEnum>>
  distanceMeters?: Scalars['Int']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export enum LocationGranularityEnum {
  city = 'city',
  country = 'country',
  exact = 'exact',
  formatted_address = 'formatted_address',
  manual_address = 'manual_address',
  street = 'street',
  sublocality = 'sublocality',
}

export type LocationInput = {
  locality: Scalars['String']['input']
  postalCode: Scalars['String']['input']
  route: Scalars['String']['input']
  streetNumber: Scalars['String']['input']
}

export type LoginPayload = {
  __typename: 'LoginPayload'
  accessToken: Scalars['String']['output']
  user: User
}

export type LoginUrl = {
  __typename: 'LoginUrl'
  path: Scalars['String']['output']
}

export type MagicNumberTooLowError = Error & {
  __typename: 'MagicNumberTooLowError'
  message: Scalars['String']['output']
}

/** Autogenerated return type of MarkApplicationAsSeen. */
export type MarkApplicationAsSeenPayload = {
  __typename: 'MarkApplicationAsSeenPayload'
  homeApplication: Maybe<HomeApplication>
}

export type MarkAsPaidError = Error & {
  __typename: 'MarkAsPaidError'
  message: Scalars['String']['output']
}

export type MarkAsPaidResult =
  | AuthorizationError
  | Invoice
  | MarkAsPaidError
  | NotFoundError
  | ValidationError

export type MarkConversationInput = {
  conversationId: Scalars['ID']['input']
  lastSeenMessageId?: InputMaybe<Scalars['ID']['input']>
  read?: InputMaybe<Scalars['Boolean']['input']>
}

/** Autogenerated return type of MarkConversation. */
export type MarkConversationPayload = {
  __typename: 'MarkConversationPayload'
  conversation: Conversation
  errors: Maybe<Array<Validation>>
}

export type Market = {
  __typename: 'Market'
  currency: MarketCurrencyEnum
  id: Scalars['ID']['output']
  instantSign: InstantSignType
  invoiceSettings: InvoiceSettingsType
  name: MarketNameEnum
  timeZone: Scalars['String']['output']
}

export enum MarketCurrencyEnum {
  EUR = 'EUR',
  NOK = 'NOK',
  SEK = 'SEK',
}

export type MarketDetailsUnion = FinlandMarketDetails | FranceMarketDetails

export enum MarketNameEnum {
  finland = 'finland',
  france = 'france',
  norway = 'norway',
  sweden = 'sweden',
}

export enum MarketNameTypeEnum {
  finland = 'finland',
  france = 'france',
  norway = 'norway',
  sweden = 'sweden',
}

export type MarketSettings = {
  __typename: 'MarketSettings'
  id: Scalars['ID']['output']
  marketName: MarketNameTypeEnum
  shortcutEnlistNewHomes: Scalars['Boolean']['output']
  shortcutMaxPendingListings: Scalars['Int']['output']
  shortcutStartRange: Maybe<DateRange>
}

export type MatchingArea = {
  __typename: 'MatchingArea'
  geojson: Maybe<Scalars['JSON']['output']>
  travelTime: Maybe<Scalars['Int']['output']>
  travelType: Maybe<MatchingAreaTravelTypeEnum>
  type: MatchingAreaTypeEnum
  uid: Scalars['ID']['output']
}

export type MatchingAreaMetadata = {
  __typename: 'MatchingAreaMetadata'
  city: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  osmId: Maybe<Scalars['ID']['output']>
  polygonCount: Scalars['Int']['output']
}

export type MatchingAreaMetadataInput = {
  city?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  osmId?: InputMaybe<Scalars['ID']['input']>
  polygonCount: Scalars['Int']['input']
}

export enum MatchingAreaTravelTypeEnum {
  cycling_regular = 'cycling_regular',
  driving_car = 'driving_car',
  public_transportation = 'public_transportation',
}

export enum MatchingAreaTypeEnum {
  isochrone = 'isochrone',
  region = 'region',
  user_made = 'user_made',
}

export type MatchingScore = {
  __typename: 'MatchingScore'
  homeId: Scalars['ID']['output']
  score: Maybe<Scalars['Int']['output']>
  uid: Scalars['ID']['output']
}

export enum MatchingScoreFeedbackReasonsEnum {
  dont_want_to_contact = 'dont_want_to_contact',
  missing_information = 'missing_information',
  not_ready_to_contact = 'not_ready_to_contact',
  other = 'other',
  tenant_not_interested = 'tenant_not_interested',
  unsuitable_tenant = 'unsuitable_tenant',
}

export type MessageMacro = {
  __typename: 'MessageMacro'
  description: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  text: Scalars['String']['output']
  title: Scalars['String']['output']
}

/** The connection type for MessageMacro. */
export type MessageMacroConnection = {
  __typename: 'MessageMacroConnection'
  /** A list of edges. */
  edges: Array<MessageMacroEdge>
  /** A list of nodes. */
  nodes: Array<MessageMacro>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type MessageMacroEdge = {
  __typename: 'MessageMacroEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: MessageMacro
}

/** Autogenerated return type of MessagesSubscription. */
export type MessagesSubscriptionPayload = {
  __typename: 'MessagesSubscriptionPayload'
  unreadMessages: Maybe<Array<ConversationMessage>>
}

export type MetadataAddressType = {
  __typename: 'MetadataAddressType'
  countryCode: Scalars['String']['output']
  locality: Scalars['String']['output']
  postalCode: Scalars['String']['output']
  streetAddress: Scalars['String']['output']
}

export type MetadataFreeFormatAddressType = {
  __typename: 'MetadataFreeFormatAddressType'
  address: Scalars['String']['output']
  countryCode: Scalars['String']['output']
  locality: Scalars['String']['output']
}

export enum MilaTestCommand {
  accept = 'accept',
  incomplete = 'incomplete',
  refuse = 'refuse',
}

export type MilaTestCommandResult = AuthorizationError | NotFoundError | Success | ValidationError

/** An object representing money */
export type Money = {
  __typename: 'Money'
  currency: CurrencyEnum
  decimalMark: Scalars['String']['output']
  fractional: Scalars['Int']['output']
  humanized: Scalars['String']['output']
  symbol: Scalars['String']['output']
  symbolFirst: Scalars['Boolean']['output']
  thousandsSeparator: Scalars['String']['output']
}

export enum MonthEnum {
  april = 'april',
  august = 'august',
  december = 'december',
  february = 'february',
  january = 'january',
  july = 'july',
  june = 'june',
  march = 'march',
  may = 'may',
  november = 'november',
  october = 'october',
  september = 'september',
}

export type MoveInfoType = {
  __typename: 'MoveInfoType'
  count: Scalars['Int']['output']
  years: Scalars['Int']['output']
}

export type MutationRoot = {
  __typename: 'MutationRoot'
  /** @deprecated Use `createBookingOfferFromBooking` instead */
  acceptBooking: Maybe<AcceptBookingPayload>
  acceptHomeApplication: Maybe<AcceptHomeApplicationPayload>
  acceptHomeApplications: Maybe<AcceptHomeApplicationsPayload>
  acceptOffering: Maybe<AcceptOfferingPayload>
  acceptShortcutProposal: Maybe<AcceptShortcutProposalPayload>
  addHomeApplicationsToBoardList: Maybe<AddHomeApplicationsToBoardListPayload>
  approveDepositPayout: Maybe<ApproveDepositPayoutPayload>
  approveIdentification: Maybe<ApproveIdentificationPayload>
  archiveConversation: Maybe<ArchiveConversationPayload>
  archiveHome: Maybe<ArchiveHomePayload>
  assignTag: Maybe<AssignTagsPayload>
  bumpListing: Maybe<BumpListingPayload>
  /** @deprecated Use `bumpListing` instead */
  bumpListingForPremiumUser: Maybe<BumpListingPayload>
  bumpVacationAd: Maybe<BumpVacationAdPayload>
  cancelBooking: Maybe<CancelBookingPayload>
  cancelContractSigning: Maybe<CancelContractSigningPayload>
  cancelViewing: Maybe<CancelViewingPayload>
  claimContractInvitation: Maybe<ClaimContractInvitationPayload>
  closeConversation: Maybe<CloseConversationPayload>
  completeOffering: Maybe<CompleteOfferingPayload>
  confirmEmail: Maybe<ConfirmEmailPayload>
  connectHomeqUser: Maybe<ConnectHubHomeQConnectUserPayload>
  createAdminNote: Maybe<CreateAdminNotePayload>
  createApplicationBoardList: Maybe<CreateApplicationBoardListPayload>
  createBlob: Maybe<CreateBlobPayload>
  createBookingOffer: Maybe<CreateBookingOfferPayload>
  createBookingOfferFromBooking: Maybe<CreateBookingOfferFromBookingPayload>
  createCertificate: Maybe<CreateCertificatePayload>
  createCompensationClaim: Maybe<CreateCompensationClaimPayload>
  createCompensationReservations: Maybe<CreateCompensationReservationsPayload>
  createConversationMessage: Maybe<CreateConversationMessagePayload>
  createCreditReport: Maybe<CreateCreditReportPayload>
  createExternalContractTakeover: Maybe<FranceCreateExternalContractTakeoverPayload>
  createHome: Maybe<CreateHomePayload>
  createHomeFromTemplate: Maybe<CreateHomeFromTemplatePayload>
  createHomeqApplication: Maybe<ConnectHubHomeQCreateApplicationPayload>
  createHomeqClaim: Maybe<CreateClaimPayload>
  createHomeqFileReference: Maybe<ConnectHubHomeQCreateFileReferencePayload>
  createInstantSignContract: Maybe<CreateInstantSignContractPayload>
  createLandlordHomeApplication: Maybe<CreateLandlordHomeApplicationPayload>
  createListingPlatformSelectorEvent: Maybe<CreateListingPlatformSelectorEventPayload>
  createMatchingScoreFeedback: Maybe<CreateMatchingScoreFeedbackPayload>
  createOffering: Maybe<CreateOfferingPayload>
  createOnfidoVerificationLink: Maybe<CreateOnfidoVerificationLinkPayload>
  createPublicDocument: CreatePublicDocumentResult
  createSavedSearch: Maybe<CreateSavedSearchPayload>
  createSavingsVerification: Maybe<CreateSavingsVerificationPayload>
  createSubUser: Maybe<CreateSubUserPayload>
  createTenantHomeApplication: Maybe<CreateTenantHomeApplicationPayload>
  createTenantInvitation: Maybe<CreateTenantInvitationPayload>
  createUpload: Maybe<CreateUploadPayload>
  createUserNote: Maybe<CreateUserNotePayload>
  createViewing: Maybe<CreateViewingPayload>
  declineHomeApplication: Maybe<DeclineHomeApplicationPayload>
  declineHomeApplications: Maybe<DeclineHomeApplicationsPayload>
  declineOffering: Maybe<DeclineOfferingPayload>
  declineOrRevokeBooking: Maybe<DeclineOrRevokeBookingPayload>
  declineShortcutProposal: Maybe<DeclineShortcutProposalPayload>
  deleteHome: Maybe<DeleteHomePayload>
  deleteHomeqApplicationInterest: Maybe<ConnectHubHomeQDeleteApplicationInterestPayload>
  deleteHomeqClaim: Maybe<ConnectHubHomeQDeleteClaimPayload>
  denyDepositPayout: Maybe<DenyDepositPayoutPayload>
  destroyAdminNote: Maybe<DestroyAdminNotePayload>
  destroyApplicationBoardList: Maybe<DestroyApplicationBoardListPayload>
  destroyCreditReport: Maybe<DestroyCreditReportPayload>
  destroyEmployeeGroup: DestroyEmployeeGroupResult
  destroyHomeAvailability: Maybe<DestroyHomeAvailabilityPayload>
  destroyIncomeVerification: Maybe<DestroyIncomeVerificationPayload>
  destroyMessageMacro: Maybe<DestroyMessageMacroPayload>
  destroyOccupation: Maybe<DestroyOccupationPayload>
  destroyOccupationVerification: Maybe<DestroyOccupationVerificationPayload>
  destroyPublicDocument: DestroyPublicDocumentResult
  destroySavedSearch: Maybe<DestroySavedSearchPayload>
  destroyUpload: Maybe<DestroyUploadPayload>
  destroyUser: Maybe<DestroyUserPayload>
  destroyUserNote: Maybe<DestroyUserNotePayload>
  destroyViewingSlots: DestroyViewingSlotsResult
  discardIdentification: Maybe<DiscardIdentificationPayload>
  disconnectHomeqUser: Maybe<ConnectHubHomeQDisconnectUserPayload>
  duplicateHome: Maybe<DuplicateHomePayload>
  enrollHomeInShortcut: Maybe<EnrollHomeInShortcutPayload>
  evaluateHomeForShortcut: Maybe<EvaluateHomeForShortcutPayload>
  exchangeLoginToken: Maybe<ExchangeLoginTokenPayload>
  exchangeSignInCode: Maybe<ExchangeSignInCodePayload>
  extendContract: Maybe<ExtendContractPayload>
  favoriteMarkHome: Maybe<FavoriteMarkHomePayload>
  finalizeUpload: Maybe<FinalizeUploadPayload>
  financeTestModePayInvoice: Maybe<FinanceTestModePayInvoicePayload>
  finlandUpsertContract: Maybe<FinlandUpsertContractPayload>
  franceCancelContractDraft: Maybe<FranceCancelContractDraftPayload>
  franceCreateInsuranceRequest: Maybe<FranceCreateInsuranceRequestPayload>
  /** @deprecated Not used, signing is initiated automatically */
  franceInitiateContractSigning: Maybe<FranceInitiateContractSigningPayload>
  franceTransitionInsuranceRequest: Maybe<FranceTransitionInsuranceRequestPayload>
  franceUpdateDocumentRequest: Maybe<FranceUpdateDocumentRequestPayload>
  franceUpsertContract: Maybe<FranceUpsertContractPayload>
  identify: Maybe<IdentifyPayload>
  /** User signs up to the company if's insurance promotion */
  ifInsurancePromotionSignUp: Maybe<IfInsurancePromotionSignUpPayload>
  importTinkBankAccount: Maybe<ImportTinkBankAccountPayload>
  initiateBookingCardPayment: Maybe<InitiateBookingCardPaymentPayload>
  initiateContractSigning: Maybe<InitiateContractSigningPayload>
  initiateListingBumpPayment: Maybe<InitiateListingBumpPaymentPayload>
  initiateListingPublicationFeePayment: Maybe<InitiateListingPublicationFeePaymentPayload>
  initiateOnfidoCheck: Maybe<InitiateOnfidoCheckPayload>
  initiateSwishPayment: Maybe<InitiateSwishPaymentPayload>
  initiateTinkIncomeCheck: Maybe<InitiateTinkIncomeCheckPayload>
  markApplicationAsSeen: Maybe<MarkApplicationAsSeenPayload>
  markConversation: Maybe<MarkConversationPayload>
  markInvoiceAsPaid: MarkAsPaidResult
  milaTestCommand: MilaTestCommandResult
  openConversation: Maybe<OpenConversationPayload>
  payoutCompensationClaimReservation: Maybe<PayoutCompensationClaimReservationPayload>
  proContactForm: Maybe<ProContactFormPayload>
  publishHome: Maybe<PublishHomePayload>
  publishHomeV2: PublishHomeV2Result
  publishTenantAd: Maybe<PublishTenantAdPayload>
  rejectIdentification: Maybe<RejectIdentificationPayload>
  removeCosigningTenant: Maybe<RemoveCosigningTenantPayload>
  removeHomeApplicationsFromBoardList: Maybe<RemoveHomeApplicationsFromBoardListPayload>
  renewHomeqClaim: Maybe<ConnectHubHomeQRenewClaimPayload>
  reportUser: Maybe<ReportUserPayload>
  resendConfirmBankAccountEmail: Maybe<ResendConfirmBankAccountEmailPayload>
  revokeHomeApplication: Maybe<RevokeHomeApplicationPayload>
  revokeOffering: Maybe<RevokeOfferingPayload>
  schibstedLogin: Maybe<SchibstedLoginPayload>
  sendBookingEmails: Maybe<SendBookingEmailsPayload>
  sendEmailConfirmationToken: Maybe<SendEmailConfirmationTokenPayload>
  /** Send a notification to Socialtjänsten that we are about to evict the tenant associated with an eviction process */
  sendEvictionEmailToSoc: SendEvictionEmailToSocResult
  sendMassMessage: Maybe<SendMassMessagePayload>
  sendSignInCode: Maybe<SendSignInCodePayload>
  setActiveBankAccount: Maybe<SetActiveBankAccountPayload>
  setDepositFree: Maybe<SetDepositFreePayload>
  /** @deprecated Contract creation is deprecated for Finn. */
  setScriveFields: Maybe<SetScriveFieldsPayload>
  /** @deprecated Use `transitionBookingOffer` instead */
  signVacationContract: Maybe<SignVacationContractPayload>
  starMarkApplication: Maybe<StarMarkApplicationPayload>
  starMarkConversation: Maybe<StarMarkConversationPayload>
  startBankIdTransaction: Maybe<StartBankIdTransactionPayload>
  subUserCreateResetPasswordToken: Maybe<SubUserCreateResetPasswordTokenPayload>
  subUserLogin: Maybe<SubUserLoginPayload>
  subUserResetPassword: Maybe<SubUserResetPasswordPayload>
  terminateContract: Maybe<TerminateContractPayload>
  /** Dummy mutation for testing new format of errors */
  testErrors: TestErrorsResult
  transitionBookingOffer: Maybe<TransitionBookingOfferPayload>
  transitionShortcut: Maybe<TransitionShortcutPayload>
  unarchiveConversation: Maybe<UnarchiveConversationPayload>
  unmarkFavoriteHome: Maybe<UnmarkFavoriteHomePayload>
  unpublishTenantAd: Maybe<UnpublishTenantAdPayload>
  updateApplicationBoardList: Maybe<UpdateApplicationBoardListPayload>
  updateApplicationBoardListOrder: Maybe<UpdateApplicationBoardListOrderPayload>
  updateContractScriveId: Maybe<UpdateContractScriveIdPayload>
  updateContractWaterFee: Maybe<UpdateContractWaterFeePayload>
  updateEmployee: UpdateEmployeeResult
  /** Update an eviction process */
  updateEvictionProcess: UpdateEvictionProcessResult
  updateHome: Maybe<UpdateHomePayload>
  updateHomeApplicationPosition: Maybe<UpdateHomeApplicationPositionPayload>
  updateHomeqClaim: Maybe<ConnectHubHomeQUpdateClaimPayload>
  updateInstantSignContract: Maybe<UpdateInstantSignContractPayload>
  updateLandlordPostSignDocuments: Maybe<UpdateLandlordPostSignDocumentsPayload>
  updateMarketSettings: Maybe<UpdateMarketSettingsPayload>
  /** @deprecated Use `updateSavedSearch` instead */
  updateSavedHomeSearch: Maybe<UpdateSavedHomeSearchPayload>
  updateSavedSearch: Maybe<UpdateSavedSearchPayload>
  updateShortcut: Maybe<UpdateShortcutPayload>
  updateTenantPostSignDocuments: Maybe<UpdateTenantPostSignDocumentsPayload>
  updateUpload: Maybe<UpdateUploadPayload>
  updateUser: Maybe<UpdateUserPayload>
  upsertApplicationBoardCardInfo: Maybe<UpsertApplicationBoardCardInfoPayload>
  upsertBankAccount: Maybe<UpsertBankAccountPayload>
  upsertBooking: Maybe<UpsertBookingPayload>
  upsertBuilding: Maybe<UpsertBuildingPayload>
  upsertContract: Maybe<UpsertContractPayload>
  upsertEmployeeGroup: UpsertEmployeeGroupResult
  upsertEmploymentVerification: Maybe<UpsertEmploymentVerificationPayload>
  upsertHomeAvailability: Maybe<UpsertHomeAvailabilityPayload>
  upsertHomeAvailabilitySettings: Maybe<UpsertHomeAvailabilitySettingsPayload>
  upsertHomeTemplate: Maybe<UpsertHomeTemplatePayload>
  upsertIncomeVerification: Maybe<UpsertIncomeVerificationPayload>
  upsertInspection: Maybe<UpsertInspectionPayload>
  /** @deprecated Use `createInstantSignContract` or `updateInstantSignContract` instead */
  upsertInstantSignContract: TemporaryContract
  upsertMessageMacro: Maybe<UpsertMessageMacroPayload>
  upsertOccupation: Maybe<UpsertOccupationPayload>
  upsertOccupationVerification: Maybe<UpsertOccupationVerificationPayload>
  upsertRentalRequirement: Maybe<UpsertRentalRequirementPayload>
  upsertStudentVerification: Maybe<UpsertStudentVerificationPayload>
  upsertTenantAd: Maybe<UpsertTenantAdPayload>
  upsertViewingCalendar: Maybe<UpsertViewingCalendarPayload>
  verifyBankAccount: Maybe<VerifyBankAccountPayload>
}

export type MutationRootAcceptBookingArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootAcceptHomeApplicationArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootAcceptHomeApplicationsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationRootAcceptOfferingArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootAcceptShortcutProposalArgs = {
  homeId: Scalars['ID']['input']
}

export type MutationRootAddHomeApplicationsToBoardListArgs = {
  applicationBoardListId: Scalars['ID']['input']
  homeApplicationIds: Array<Scalars['ID']['input']>
}

export type MutationRootApproveDepositPayoutArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootApproveIdentificationArgs = {
  idNumberFields?: InputMaybe<IdNumberFieldsInput>
  uid: Scalars['ID']['input']
}

export type MutationRootArchiveConversationArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootArchiveHomeArgs = {
  archiveReason?: InputMaybe<HomeArchiveReasonEnum>
  homeId: Scalars['ID']['input']
}

export type MutationRootAssignTagArgs = {
  taggable?: InputMaybe<TaggableInput>
  tagsInput: TagInput
}

export type MutationRootBumpListingArgs = {
  homeId: Scalars['ID']['input']
}

export type MutationRootBumpListingForPremiumUserArgs = {
  homeId: Scalars['ID']['input']
}

export type MutationRootBumpVacationAdArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootCancelBookingArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootCancelContractSigningArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootCancelViewingArgs = {
  bookingId: Scalars['ID']['input']
}

export type MutationRootClaimContractInvitationArgs = {
  claimKey: Scalars['String']['input']
}

export type MutationRootCloseConversationArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootCompleteOfferingArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootConfirmEmailArgs = {
  token: Scalars['String']['input']
}

export type MutationRootCreateAdminNoteArgs = {
  input: CreateAdminNoteInput
}

export type MutationRootCreateApplicationBoardListArgs = {
  homeId: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export type MutationRootCreateBlobArgs = {
  input: CreateBlobInput
}

export type MutationRootCreateBookingOfferArgs = {
  input: CreateBookingOfferInput
}

export type MutationRootCreateBookingOfferFromBookingArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootCreateCompensationClaimArgs = {
  contractId: Scalars['ID']['input']
}

export type MutationRootCreateCompensationReservationsArgs = {
  contractId: Scalars['ID']['input']
  reservations: Array<CompensationReservationInput>
}

export type MutationRootCreateConversationMessageArgs = {
  input: CreateConversationMessageInput
}

export type MutationRootCreateCreditReportArgs = {
  authToken: Scalars['String']['input']
  provider?: InputMaybe<CreditReportProviderEnum>
}

export type MutationRootCreateExternalContractTakeoverArgs = {
  input: CreateExternalContractTakeoverInput
}

export type MutationRootCreateHomeArgs = {
  input: CreateHomeInput
}

export type MutationRootCreateHomeFromTemplateArgs = {
  homeTemplateId: Scalars['ID']['input']
}

export type MutationRootCreateHomeqApplicationArgs = {
  homeId: Scalars['ID']['input']
}

export type MutationRootCreateHomeqClaimArgs = {
  orgType?: InputMaybe<ConnectHubHomeQClaimOrgTypeEnum>
  type: ConnectHubHomeQClaimTypeEnum
}

export type MutationRootCreateHomeqFileReferenceArgs = {
  input: ConnectHubInputObjectsCreateFileReferenceInput
}

export type MutationRootCreateInstantSignContractArgs = {
  input: CreateInstantSignContractInput
}

export type MutationRootCreateLandlordHomeApplicationArgs = {
  input: CreateLandlordHomeApplicationInput
}

export type MutationRootCreateListingPlatformSelectorEventArgs = {
  eventType: ListingPlatformSelectorEventTypeEnum
}

export type MutationRootCreateMatchingScoreFeedbackArgs = {
  freeText?: InputMaybe<Scalars['String']['input']>
  homeId: Scalars['ID']['input']
  reasons?: InputMaybe<Array<MatchingScoreFeedbackReasonsEnum>>
  score: Scalars['Int']['input']
  tenantUid: Scalars['ID']['input']
}

export type MutationRootCreateOfferingArgs = {
  input: CreateOfferingInput
}

export type MutationRootCreateOnfidoVerificationLinkArgs = {
  uid: Scalars['ID']['input']
}

export type MutationRootCreatePublicDocumentArgs = {
  input: KanyeInputObjectsCreatePublicDocumentInput
}

export type MutationRootCreateSavedSearchArgs = {
  input: CreateSavedSearchInput
}

export type MutationRootCreateSubUserArgs = {
  email: Scalars['String']['input']
}

export type MutationRootCreateTenantHomeApplicationArgs = {
  input: CreateTenantHomeApplicationInput
}

export type MutationRootCreateTenantInvitationArgs = {
  input: CreateTenantInvitationInput
}

export type MutationRootCreateUploadArgs = {
  input: CreateUploadInput
}

export type MutationRootCreateUserNoteArgs = {
  input: CreateUserNoteInput
}

export type MutationRootCreateViewingArgs = {
  homeId: Scalars['ID']['input']
  startsAt: Scalars['DateTime']['input']
  tenantUid: Scalars['ID']['input']
}

export type MutationRootDeclineHomeApplicationArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootDeclineHomeApplicationsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationRootDeclineOfferingArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootDeclineOrRevokeBookingArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootDeclineShortcutProposalArgs = {
  declineReason?: InputMaybe<ShortcutProposalDeclineReasonEnum>
  homeId: Scalars['ID']['input']
}

export type MutationRootDeleteHomeArgs = {
  homeId: Scalars['ID']['input']
}

export type MutationRootDeleteHomeqApplicationInterestArgs = {
  interestId: Scalars['Int']['input']
}

export type MutationRootDeleteHomeqClaimArgs = {
  claimId: Scalars['ID']['input']
  renewed?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationRootDenyDepositPayoutArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootDestroyAdminNoteArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootDestroyApplicationBoardListArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootDestroyCreditReportArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootDestroyEmployeeGroupArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootDestroyHomeAvailabilityArgs = {
  dates: Array<Scalars['Date']['input']>
  homeId: Scalars['ID']['input']
}

export type MutationRootDestroyIncomeVerificationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationRootDestroyMessageMacroArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootDestroyOccupationArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootDestroyOccupationVerificationArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootDestroyPublicDocumentArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootDestroySavedSearchArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootDestroyUploadArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootDestroyUserArgs = {
  uid: Scalars['ID']['input']
}

export type MutationRootDestroyUserNoteArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootDestroyViewingSlotsArgs = {
  homeId: Scalars['ID']['input']
  slots: Array<ViewingSlotInput>
}

export type MutationRootDiscardIdentificationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationRootDuplicateHomeArgs = {
  id: Scalars['ID']['input']
  input?: InputMaybe<UpdateHomeInput>
}

export type MutationRootEnrollHomeInShortcutArgs = {
  input: EnrollHomeInShortcutInput
}

export type MutationRootEvaluateHomeForShortcutArgs = {
  homeId: Scalars['ID']['input']
}

export type MutationRootExchangeLoginTokenArgs = {
  token: Scalars['String']['input']
}

export type MutationRootExchangeSignInCodeArgs = {
  code: Scalars['String']['input']
}

export type MutationRootExtendContractArgs = {
  endOptimal?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
  rent?: InputMaybe<Scalars['Int']['input']>
}

export type MutationRootFavoriteMarkHomeArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootFinalizeUploadArgs = {
  etag: Scalars['String']['input']
  id: Scalars['ID']['input']
}

export type MutationRootFinanceTestModePayInvoiceArgs = {
  invoiceId: Scalars['ID']['input']
}

export type MutationRootFinlandUpsertContractArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  input: FinlandUpsertContractInput
}

export type MutationRootFranceCancelContractDraftArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootFranceCreateInsuranceRequestArgs = {
  contractId: Scalars['ID']['input']
  input: FranceCreateInsuranceRequestInput
}

export type MutationRootFranceInitiateContractSigningArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootFranceTransitionInsuranceRequestArgs = {
  id: Scalars['ID']['input']
  toState: FranceInsuranceRequestStateEnum
}

export type MutationRootFranceUpdateDocumentRequestArgs = {
  id: Scalars['ID']['input']
  input: FranceUpdateDocumentRequestInput
}

export type MutationRootFranceUpsertContractArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  input: FranceUpsertContractInput
}

export type MutationRootIdentifyArgs = {
  input: IdentifyInput
}

export type MutationRootIfInsurancePromotionSignUpArgs = {
  contractId: Scalars['ID']['input']
  signedUp: Scalars['Boolean']['input']
}

export type MutationRootImportTinkBankAccountArgs = {
  accountVerificationReportId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationRootInitiateBookingCardPaymentArgs = {
  input: InitiateBookingCardPaymentInput
}

export type MutationRootInitiateContractSigningArgs = {
  basicType?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  landlordInvitationMethod?: InputMaybe<ScriveInvitationOptionEnum>
  sendInitialInvitation?: InputMaybe<Scalars['Boolean']['input']>
  tenantInvitationMethod?: InputMaybe<ScriveInvitationOptionEnum>
}

export type MutationRootInitiateListingBumpPaymentArgs = {
  input: InitiateListingBumpPaymentInput
}

export type MutationRootInitiateListingPublicationFeePaymentArgs = {
  input: InitiateListingPublicationFeePaymentInput
}

export type MutationRootInitiateOnfidoCheckArgs = {
  reverify?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationRootInitiateSwishPaymentArgs = {
  input: InitiateSwishPaymentInput
}

export type MutationRootInitiateTinkIncomeCheckArgs = {
  tinkId: Scalars['ID']['input']
}

export type MutationRootMarkApplicationAsSeenArgs = {
  id: Scalars['ID']['input']
  seen: Scalars['Boolean']['input']
}

export type MutationRootMarkConversationArgs = {
  input: MarkConversationInput
}

export type MutationRootMarkInvoiceAsPaidArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootMilaTestCommandArgs = {
  command: MilaTestCommand
  milaId: Scalars['ID']['input']
}

export type MutationRootOpenConversationArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootPayoutCompensationClaimReservationArgs = {
  payoutToUid: Scalars['ID']['input']
  reservationId: Scalars['ID']['input']
}

export type MutationRootProContactFormArgs = {
  input: ProContactFormInput
}

export type MutationRootPublishHomeArgs = {
  id: Scalars['ID']['input']
  onlyValidate?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationRootPublishHomeV2Args = {
  id: Scalars['ID']['input']
}

export type MutationRootRejectIdentificationArgs = {
  uid: Scalars['ID']['input']
}

export type MutationRootRemoveCosigningTenantArgs = {
  contractId: Scalars['ID']['input']
  uid: Scalars['ID']['input']
}

export type MutationRootRemoveHomeApplicationsFromBoardListArgs = {
  homeApplicationIds: Array<Scalars['ID']['input']>
}

export type MutationRootRenewHomeqClaimArgs = {
  claimId: Scalars['ID']['input']
}

export type MutationRootReportUserArgs = {
  reason: Scalars['String']['input']
  reportType?: InputMaybe<ReportTypeEnum>
  uid: Scalars['ID']['input']
}

export type MutationRootResendConfirmBankAccountEmailArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootRevokeHomeApplicationArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootRevokeOfferingArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootSchibstedLoginArgs = {
  input: SchibstedLoginInput
}

export type MutationRootSendBookingEmailsArgs = {
  bookingId: Scalars['ID']['input']
  templateName: BookingEmailTemplateEnum
}

export type MutationRootSendEmailConfirmationTokenArgs = {
  redirectPath?: InputMaybe<Scalars['String']['input']>
}

export type MutationRootSendEvictionEmailToSocArgs = {
  processId?: InputMaybe<Scalars['ID']['input']>
  recipient: Scalars['String']['input']
}

export type MutationRootSendMassMessageArgs = {
  conversationIds: Array<Scalars['ID']['input']>
  message: Scalars['String']['input']
}

export type MutationRootSendSignInCodeArgs = {
  email: Scalars['String']['input']
  platform: PlatformEnum
}

export type MutationRootSetActiveBankAccountArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootSetDepositFreeArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootSetScriveFieldsArgs = {
  contractId: Scalars['ID']['input']
  input: SetScriveFieldsInput
  userType: Scalars['String']['input']
}

export type MutationRootSignVacationContractArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootStarMarkApplicationArgs = {
  id: Scalars['ID']['input']
  starMarkedByLandlord: Scalars['Boolean']['input']
}

export type MutationRootStarMarkConversationArgs = {
  id: Scalars['ID']['input']
  starMarked: Scalars['Boolean']['input']
}

export type MutationRootSubUserCreateResetPasswordTokenArgs = {
  email: Scalars['String']['input']
}

export type MutationRootSubUserLoginArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type MutationRootSubUserResetPasswordArgs = {
  password: Scalars['String']['input']
  resetPasswordToken: Scalars['String']['input']
}

export type MutationRootTerminateContractArgs = {
  endAt: Scalars['DateTime']['input']
  id: Scalars['ID']['input']
}

export type MutationRootTestErrorsArgs = {
  input: TestErrorsInput
}

export type MutationRootTransitionBookingOfferArgs = {
  input: TransitionBookingOfferInput
}

export type MutationRootTransitionShortcutArgs = {
  input: TransitionShortcutInput
}

export type MutationRootUnarchiveConversationArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootUnmarkFavoriteHomeArgs = {
  id: Scalars['ID']['input']
}

export type MutationRootUpdateApplicationBoardListArgs = {
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export type MutationRootUpdateApplicationBoardListOrderArgs = {
  id: Scalars['ID']['input']
  orderBy?: InputMaybe<HomeApplicationOrderEnum>
}

export type MutationRootUpdateContractScriveIdArgs = {
  id: Scalars['ID']['input']
  scriveId: Scalars['String']['input']
}

export type MutationRootUpdateContractWaterFeeArgs = {
  id: Scalars['ID']['input']
  input: UpdateWaterFeeInput
}

export type MutationRootUpdateEmployeeArgs = {
  groupIds: Array<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  roleIds: Array<Scalars['ID']['input']>
}

export type MutationRootUpdateEvictionProcessArgs = {
  attributes: EvictionProcessAttributes
  processId: Scalars['ID']['input']
  regeneratePdf?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationRootUpdateHomeArgs = {
  id: Scalars['ID']['input']
  input: UpdateHomeInput
}

export type MutationRootUpdateHomeApplicationPositionArgs = {
  applicationBoardListId: Scalars['ID']['input']
  homeApplicationId: Scalars['ID']['input']
  newPosition: Scalars['Int']['input']
}

export type MutationRootUpdateHomeqClaimArgs = {
  claimId: Scalars['ID']['input']
  input: ConnectHubInputObjectsUpdateClaimInput
  renewed?: Scalars['Boolean']['input']
}

export type MutationRootUpdateInstantSignContractArgs = {
  id: Scalars['ID']['input']
  input: UpdateInstantSignContractInput
}

export type MutationRootUpdateLandlordPostSignDocumentsArgs = {
  id: Scalars['ID']['input']
  input: UpdateLandlordPostSignDocumentsInput
}

export type MutationRootUpdateMarketSettingsArgs = {
  marketName: MarketNameTypeEnum
  settingsInput: UpdateMarketSettingsInput
}

export type MutationRootUpdateSavedHomeSearchArgs = {
  input: HomeSearchParamsInput
}

export type MutationRootUpdateSavedSearchArgs = {
  id: Scalars['ID']['input']
  input: UpdateSavedSearchInput
}

export type MutationRootUpdateShortcutArgs = {
  id: Scalars['ID']['input']
  input: UpdateShortcutInput
}

export type MutationRootUpdateTenantPostSignDocumentsArgs = {
  id: Scalars['ID']['input']
  input: UpdateTenantPostSignDocumentsInput
}

export type MutationRootUpdateUploadArgs = {
  id: Scalars['ID']['input']
  input: UpdateUploadInput
}

export type MutationRootUpdateUserArgs = {
  input: UpdateUserInput
  uid: Scalars['ID']['input']
}

export type MutationRootUpsertApplicationBoardCardInfoArgs = {
  homeId: Scalars['ID']['input']
  params?: InputMaybe<UpsertApplicationBoardCardInfoInput>
}

export type MutationRootUpsertBankAccountArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  input: UpsertBankAccountInput
}

export type MutationRootUpsertBookingArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  input: UpsertBookingInput
}

export type MutationRootUpsertBuildingArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  input: UpsertBuildingInput
}

export type MutationRootUpsertContractArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  input: UpsertContractInput
}

export type MutationRootUpsertEmployeeGroupArgs = {
  employeeIds: Array<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  name: Scalars['String']['input']
  roleIds: Array<Scalars['ID']['input']>
}

export type MutationRootUpsertEmploymentVerificationArgs = {
  input: UpsertEmploymentVerificationInput
}

export type MutationRootUpsertHomeAvailabilityArgs = {
  availability: Array<UpsertHomeAvailabilityInput>
  homeId: Scalars['ID']['input']
}

export type MutationRootUpsertHomeAvailabilitySettingsArgs = {
  input: UpsertHomeAvailabilitySettingsInput
}

export type MutationRootUpsertHomeTemplateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  input: UpsertHomeTemplateInput
}

export type MutationRootUpsertIncomeVerificationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  input: IncomeVerificationInput
}

export type MutationRootUpsertInspectionArgs = {
  homeId: Scalars['ID']['input']
  observations?: InputMaybe<Array<InspectionObservationInput>>
}

export type MutationRootUpsertInstantSignContractArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  input: UpsertInstantSignInput
}

export type MutationRootUpsertMessageMacroArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  input: UpsertMessageMacroInput
}

export type MutationRootUpsertOccupationArgs = {
  input: UpsertOccupationInput
}

export type MutationRootUpsertOccupationVerificationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  input: UpsertOccupationVerificationInput
}

export type MutationRootUpsertRentalRequirementArgs = {
  homeId: Scalars['ID']['input']
  input: UpsertRentalRequirementInput
}

export type MutationRootUpsertStudentVerificationArgs = {
  input: UpsertStudentVerificationInput
}

export type MutationRootUpsertTenantAdArgs = {
  input: TenantAdInput
}

export type MutationRootUpsertViewingCalendarArgs = {
  availability?: InputMaybe<DateTimeRangeInput>
  homeId: Scalars['ID']['input']
  information?: InputMaybe<Scalars['String']['input']>
}

export type MutationRootVerifyBankAccountArgs = {
  verificationTokenId: Scalars['ID']['input']
}

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID']['output']
}

export type NotFoundError = Error & {
  __typename: 'NotFoundError'
  message: Scalars['String']['output']
}

export enum NotificationSpanEnum {
  EVERY_HOUR = 'EVERY_HOUR',
  EVERY_THIRD_HOUR = 'EVERY_THIRD_HOUR',
  INSTANT = 'INSTANT',
  ONCE_A_DAY = 'ONCE_A_DAY',
}

export enum NrFirsthandHousingUnitsEnum {
  option_1_24 = 'option_1_24',
  option_25_49 = 'option_25_49',
  option_50_99 = 'option_50_99',
  option_greater_100 = 'option_greater_100',
}

export type Occupation = {
  __typename: 'Occupation'
  current: Maybe<Scalars['Boolean']['output']>
  endedAt: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  occupationScheduleType: Maybe<OccupationScheduleTypeEnum>
  occupationType: OccupationTypeEnum
  occupationVerifications: Maybe<Array<OccupationVerification>>
  startedAt: Scalars['DateTime']['output']
  subtitle: Maybe<Scalars['String']['output']>
  title: Maybe<Scalars['String']['output']>
}

export enum OccupationEnum {
  fixed_term = 'fixed_term',
  freelance = 'freelance',
  intermittent = 'intermittent',
  permanent = 'permanent',
  probationary = 'probationary',
  project = 'project',
  retired = 'retired',
  student = 'student',
  temporary = 'temporary',
  unemployed = 'unemployed',
}

export enum OccupationScheduleTypeEnum {
  full_time = 'full_time',
  part_time = 'part_time',
}

export enum OccupationTypeEnum {
  other = 'other',
  retired = 'retired',
  student = 'student',
  work = 'work',
}

export type OccupationVerification = {
  __typename: 'OccupationVerification'
  document: Maybe<Blob>
  id: Scalars['ID']['output']
  verificationType: VerificationTypeEnum
}

export type Offering = {
  __typename: 'Offering'
  createdAt: Scalars['DateTime']['output']
  home: Home
  homeApplication: HomeApplication
  homeTemplate: Maybe<HomeTemplate>
  id: Scalars['ID']['output']
  landlord: User
  message: Maybe<Scalars['String']['output']>
  offeringStatus: OfferingStatusEnum
  showingAt: Maybe<Scalars['DateTime']['output']>
  showingContactInformation: Maybe<Scalars['String']['output']>
  showingStatus: ShowingStatusEnum
  tenant: User
}

/** The connection type for Offering. */
export type OfferingConnection = {
  __typename: 'OfferingConnection'
  /** A list of edges. */
  edges: Array<OfferingEdge>
  /** A list of nodes. */
  nodes: Array<Offering>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type OfferingEdge = {
  __typename: 'OfferingEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Offering
}

export enum OfferingOrderEnum {
  BEST_MATCH = 'BEST_MATCH',
  HOME_APPLICATION_NOT_STARRED = 'HOME_APPLICATION_NOT_STARRED',
  HOME_APPLICATION_STARRED = 'HOME_APPLICATION_STARRED',
  LAST_CHAT_ACTIVITY = 'LAST_CHAT_ACTIVITY',
  NEWEST_FIRST = 'NEWEST_FIRST',
  OLDEST_FIRST = 'OLDEST_FIRST',
  WAITING_TIME = 'WAITING_TIME',
}

export enum OfferingStatusEnum {
  accepted = 'accepted',
  completed = 'completed',
  declined = 'declined',
  pending = 'pending',
  revoked = 'revoked',
}

/** Autogenerated return type of OpenConversation. */
export type OpenConversationPayload = {
  __typename: 'OpenConversationPayload'
  conversation: Conversation
  errors: Maybe<Array<Validation>>
}

export type Organization = {
  __typename: 'Organization'
  buildings: BuildingConnection
  homeTemplates: HomeTemplateConnection
  homes: HomeConnection
  name: Scalars['String']['output']
  uid: Scalars['String']['output']
}

export type OrganizationBuildingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  searchString?: InputMaybe<Scalars['String']['input']>
}

export type OrganizationHomeTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  buildingId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  maxRoomCount?: InputMaybe<Scalars['Int']['input']>
  maxSquareMeters?: InputMaybe<Scalars['Int']['input']>
  minRoomCount?: InputMaybe<Scalars['Int']['input']>
  minSquareMeters?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<HomeTemplateOrderEnum>
  searchString?: InputMaybe<Scalars['String']['input']>
}

export type OrganizationHomesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  excludeApplicationDeadlinePassed?: InputMaybe<Scalars['Boolean']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  homeStatuses?: InputMaybe<Array<HomeStatusEnum>>
  last?: InputMaybe<Scalars['Int']['input']>
  maxRoomCount?: InputMaybe<Scalars['Int']['input']>
  maxSquareMeters?: InputMaybe<Scalars['Int']['input']>
  minRoomCount?: InputMaybe<Scalars['Int']['input']>
  minSquareMeters?: InputMaybe<Scalars['Int']['input']>
  onlyApplicationDeadlinePassed?: InputMaybe<Scalars['Boolean']['input']>
  order?: InputMaybe<HomeSearchOrderEnum>
  orderBy?: InputMaybe<HomeSearchOrderByEnum>
  rentalTypes?: InputMaybe<Array<HomeRentalTypeEnum>>
  searchString?: InputMaybe<Scalars['String']['input']>
}

export enum OwnerTypeEnum {
  AREA_UPLOAD = 'AREA_UPLOAD',
  BUILDING = 'BUILDING',
  CERTIFICATE = 'CERTIFICATE',
  CONTRACT = 'CONTRACT',
  CONVERSATION = 'CONVERSATION',
  CO_APPLICANT = 'CO_APPLICANT',
  EMPLOYMENT_VERIFICATION = 'EMPLOYMENT_VERIFICATION',
  HOME = 'HOME',
  HOME_TEMPLATE = 'HOME_TEMPLATE',
  INCOME_VERIFICATION = 'INCOME_VERIFICATION',
  REFERENCE_CHECK = 'REFERENCE_CHECK',
  SAVINGS_VERIFICATION = 'SAVINGS_VERIFICATION',
  STUDENT_VERIFICATION = 'STUDENT_VERIFICATION',
  USER = 'USER',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>
}

/** Autogenerated return type of PayoutCompensationClaimReservation. */
export type PayoutCompensationClaimReservationPayload = {
  __typename: 'PayoutCompensationClaimReservationPayload'
  reservation: Maybe<CompensationClaimReservationType>
}

export type PayoutOnboardingUrlQueryInput = {
  /** Including 'https://' */
  refreshUrl: Scalars['String']['input']
  /** Including 'https://' */
  returnUrl: Scalars['String']['input']
}

export enum PayoutStateEnum {
  blocked = 'blocked',
  cancelled = 'cancelled',
  created = 'created',
  paid = 'paid',
  pending = 'pending',
  processing = 'processing',
}

export type PlatformConfiguration = {
  __typename: 'PlatformConfiguration'
  localization: Localization
  platformName: Scalars['String']['output']
}

export enum PlatformEnum {
  blocket = 'blocket',
  dotcom = 'dotcom',
  finn = 'finn',
  homeq = 'homeq',
  qasa = 'qasa',
  qasa_finland = 'qasa_finland',
  qasa_france = 'qasa_france',
}

export type PointOfInterest = {
  __typename: 'PointOfInterest'
  category: PointOfInterestEnum
  distance: Maybe<Scalars['Float']['output']>
  latitude: Scalars['Float']['output']
  longitude: Scalars['Float']['output']
  metadata: Scalars['JSON']['output']
  name: Scalars['String']['output']
}

/** The connection type for PointOfInterest. */
export type PointOfInterestConnection = {
  __typename: 'PointOfInterestConnection'
  /** A list of edges. */
  edges: Array<PointOfInterestEdge>
  /** A list of nodes. */
  nodes: Array<PointOfInterest>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type PointOfInterestEdge = {
  __typename: 'PointOfInterestEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: PointOfInterest
}

export enum PointOfInterestEnum {
  metro_station = 'metro_station',
}

export type PricePreview = {
  __typename: 'PricePreview'
  averagePricePerNight: Scalars['Int']['output']
  cleaningFee: Scalars['Int']['output']
  landlordFee: Scalars['Int']['output']
  rentPrice: Scalars['Int']['output']
  tenantFee: Scalars['Int']['output']
  totalTenantPrice: Scalars['Int']['output']
}

export type PricingModel = {
  __typename: 'PricingModel'
  depositFreeCostFactor: Scalars['Float']['output']
  depositFreeIncluded: Scalars['Boolean']['output']
  insuranceCostFactor: Scalars['Float']['output']
  landlordBaseFeeFactor: Scalars['Float']['output']
  listingBumpFee: Scalars['Int']['output']
  listingPublicationFee: Scalars['Int']['output']
  modelName: PricingModelNameEnum
  notCreditWorthyDepositMonths: Scalars['Int']['output']
  qasaGuaranteeCostFactor: Scalars['Float']['output']
  tenantBaseFeeFactor: Scalars['Float']['output']
}

export enum PricingModelNameEnum {
  landlord_fees_effective = 'landlord_fees_effective',
  landlord_fees_flat = 'landlord_fees_flat',
  landlord_fees_three_point_zero = 'landlord_fees_three_point_zero',
  landlord_fees_three_with_insurance = 'landlord_fees_three_with_insurance',
  landlord_fees_two_point_five = 'landlord_fees_two_point_five',
  low_landlord_fees = 'low_landlord_fees',
  no_landlord_fees = 'no_landlord_fees',
  no_landlord_fees_lower_tenant_fees = 'no_landlord_fees_lower_tenant_fees',
  publication_fee = 'publication_fee',
  publication_fee_deposit_free = 'publication_fee_deposit_free',
  publication_fee_higher_tenant_fees = 'publication_fee_higher_tenant_fees',
  shortcut_landlord_monthly_fee = 'shortcut_landlord_monthly_fee',
  vacation_rental_fees = 'vacation_rental_fees',
  vacation_zero_landlord_fee = 'vacation_zero_landlord_fee',
}

export type PrivacyControllerByPlatform = {
  __typename: 'PrivacyControllerByPlatform'
  isTransactionCustomer: Maybe<Scalars['Boolean']['output']>
  marketplaceController: BrandControllerEnum
  marketplaceTerms: Scalars['String']['output']
  transactionController: BrandControllerEnum
  transactionTerms: Scalars['String']['output']
}

export type ProContactFormInput = {
  companyName: Scalars['String']['input']
  email: Scalars['String']['input']
  numberOfHomes: Scalars['String']['input']
  other?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of ProContactForm. */
export type ProContactFormPayload = {
  __typename: 'ProContactFormPayload'
  success: Scalars['Boolean']['output']
}

export enum ProductEnum {
  DEPOSIT = 'DEPOSIT',
  INSURANCE_FEE = 'INSURANCE_FEE',
  MONTHLY_FEE = 'MONTHLY_FEE',
  MONTHLY_RENT = 'MONTHLY_RENT',
  RECOVERABLE_CHARGES = 'RECOVERABLE_CHARGES',
}

export type Property = {
  __typename: 'Property'
  id: Scalars['ID']['output']
  maxRent: Maybe<Scalars['Int']['output']>
  maxRoomCount: Maybe<Scalars['Float']['output']>
  maxSquareMeters: Maybe<Scalars['Int']['output']>
  minRent: Maybe<Scalars['Int']['output']>
  minRoomCount: Maybe<Scalars['Float']['output']>
  minSquareMeters: Maybe<Scalars['Int']['output']>
  numberOfHomes: Maybe<Scalars['Int']['output']>
}

export type PublicDocument = {
  __typename: 'PublicDocument'
  createdAt: Scalars['DateTime']['output']
  fileUrl: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

/** The connection type for PublicDocument. */
export type PublicDocumentConnection = {
  __typename: 'PublicDocumentConnection'
  /** A list of edges. */
  edges: Array<PublicDocumentEdge>
  /** A list of nodes. */
  nodes: Array<PublicDocument>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type PublicDocumentEdge = {
  __typename: 'PublicDocumentEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: PublicDocument
}

/** Autogenerated return type of PublishHome. */
export type PublishHomePayload = {
  __typename: 'PublishHomePayload'
  errors: Maybe<Array<Validation>>
  home: Maybe<Home>
}

export type PublishHomeV2Result = AuthorizationError | Home | NotFoundError | ValidationError

/** Autogenerated return type of PublishTenantAd. */
export type PublishTenantAdPayload = {
  __typename: 'PublishTenantAdPayload'
  errors: Maybe<Array<Validation>>
  tenantAd: Maybe<TenantAd>
}

export type QueryRoot = {
  __typename: 'QueryRoot'
  accessToken: AccessTokenQueryResult
  adminAccessToken: AdminAccessTokenQueryResult
  adminContracts: ContractConnection
  adminSession: SessionResolverResult
  /** @deprecated Deprecated, use the bookings field on the current user instead  */
  bookings: Array<Booking>
  building: Building
  buildings: BuildingConnection
  checkoutToken: Scalars['String']['output']
  compensationClaim: Maybe<CompensationClaim>
  contract: Contract
  contractTenantInvitation: ContractTenantInvitation
  contracts: ContractConnection
  contractsCounts: ContractsCounts
  conversation: Maybe<Conversation>
  conversations: ConversationConnection
  coordinatesStatistics: CoordinatesStatistics
  cronofyElementToken: CronofyElementToken
  employee: EmployeeResolverResult
  employeeGroups: EmployeeGroupsResolverResult
  employeeRoles: EmployeeRolesResolverResult
  employees: EmployeeConnection
  evictionProcess: Array<EvictionProcess>
  /** Return favorite homes */
  favoriteHomes: HomeSearch
  generateBlockListingPayload: Maybe<BlockListingPayload>
  generateContractPreviewPdf: ContractPreview
  home: Maybe<Home>
  homeApplication: HomeApplication
  homeApplications: HomeApplicationConnection
  homeApplicationsByBoardList: HomeApplicationConnection
  homeAvailabilities: Array<HomeAvailability>
  homeAvailabilitySettingsQuery: Maybe<HomeAvailabilitySettings>
  homeEvaluation: HomeEvaluation
  /** Return the IDs of all currently published homes */
  homeIds: Array<Scalars['ID']['output']>
  /** Returns Home::Documents from Elasticsearch */
  homeIndexSearch: HomeIndexSearchQuery
  /** Return home search results */
  homeSearch: HomeSearch
  /** Return home search results for map */
  homeSearchCoords: HomeSearchCoords
  homeStatsQuery: Maybe<HomeStats>
  homeTemplate: HomeTemplate
  homeTemplates: HomeTemplateConnection
  homeqHomeApplicationPreconditions: Maybe<ConnectHubHomeQHomeApplicationPreconditions>
  homeqHomeApplications: ConnectHubHomeQHomeApplicationsType
  homeqLandlordCompanies: Maybe<Array<ConnectHubHomeQLandlordCompanyType>>
  homeqUser: Maybe<ConnectHubHomeQUserType>
  homeqUserClaims: Maybe<ConnectHubHomeQUserClaimsType>
  homeqUserTokenState: Scalars['String']['output']
  homes: HomeConnection
  identificationStatus: Maybe<IdentificationStatusEnum>
  identificationToken: Scalars['String']['output']
  incomeVerification: Maybe<IncomeVerification>
  instantMatches: TenantAdOffsetLimit
  kanyeCurrentUser: CurrentUserResolverResult
  kanyeUser: KanyeUser
  /** Specific market */
  marketSettings: MarketSettings
  /** All markets */
  marketsSettings: Maybe<Array<MarketSettings>>
  matchingScoreAws: MatchingScore
  me: Maybe<User>
  messageMacros: MessageMacroConnection
  newInbox: ConversationConnection
  onfidoSdkToken: Scalars['String']['output']
  organization: Maybe<Organization>
  payoutOnboardingUrl: Scalars['String']['output']
  platformConfiguration: PlatformConfiguration
  pricePreview: PricePreview
  privacyControllerByPlatform: PrivacyControllerByPlatform
  /** Temporary query before we have an organization structure in place */
  proPilotConnectedUids: Array<Scalars['ID']['output']>
  proPilotUsers: UserConnection
  publicDocuments: PublicDocumentConnection
  rentHistogram: Array<RentHistogram>
  savedSearch: Array<SavedSearch>
  /** Return home search results for map */
  searchArea: Array<SearchArea>
  searchAreaHierarchy: Maybe<SearchArea>
  searchAreaKeyword: Array<SearchAreaMatcher>
  sentOfferings: OfferingConnection
  /** Returns similar homes given a home ID */
  similarHomes: SimilarHomes
  tenantAd: Maybe<TenantAd>
  tenantAds: TenantAdOffsetLimit
  /** Only for use in pro app */
  unreadConversationsCount: UnreadConversationsCount
  user: Maybe<User>
  userContracts: UserContracts
  /** Return a list of user ID:s */
  userIds: Array<Scalars['ID']['output']>
  users: KanyeUserConnection
  viewingCalendar: ViewingCalendar
}

export type QueryRootAccessTokenArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
  platform: PlatformEnum
}

export type QueryRootAdminAccessTokenArgs = {
  uid: Scalars['ID']['input']
}

export type QueryRootAdminContractsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  rentalType?: InputMaybe<ContractRentalTypeEnum>
  status?: InputMaybe<Array<ContractStatusEnum>>
  uid: Scalars['ID']['input']
}

export type QueryRootBookingsArgs = {
  homeId: Scalars['ID']['input']
  status?: InputMaybe<Array<BookingStatusEnum>>
}

export type QueryRootBuildingArgs = {
  id: Scalars['ID']['input']
}

export type QueryRootBuildingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  searchString?: InputMaybe<Scalars['String']['input']>
}

export type QueryRootCheckoutTokenArgs = {
  contractId: Scalars['ID']['input']
}

export type QueryRootCompensationClaimArgs = {
  contractId: Scalars['ID']['input']
}

export type QueryRootContractArgs = {
  id: Scalars['ID']['input']
}

export type QueryRootContractTenantInvitationArgs = {
  claimKey: Scalars['String']['input']
}

export type QueryRootContractsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  homeId?: InputMaybe<Scalars['ID']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  signingProcess?: InputMaybe<Array<SigningProcessEnum>>
  status?: InputMaybe<Array<ContractStatusEnum>>
}

export type QueryRootConversationArgs = {
  id: Scalars['ID']['input']
}

export type QueryRootConversationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  homeId?: InputMaybe<Array<Scalars['ID']['input']>>
  last?: InputMaybe<Scalars['Int']['input']>
  lastMessageFrom?: InputMaybe<ConversationRoleTypeEnum>
  onlyActiveApplications?: Scalars['Boolean']['input']
  read?: InputMaybe<Scalars['Boolean']['input']>
  status?: InputMaybe<Array<ConversationStatusEnum>>
}

export type QueryRootCoordinatesStatisticsArgs = {
  latitude: Scalars['Float']['input']
  longitude: Scalars['Float']['input']
}

export type QueryRootCronofyElementTokenArgs = {
  homeId: Scalars['ID']['input']
  origin: Scalars['String']['input']
}

export type QueryRootEmployeeArgs = {
  id: Scalars['ID']['input']
}

export type QueryRootEmployeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryRootEvictionProcessArgs = {
  closed?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  state?: InputMaybe<Array<EvictionProcessState>>
  userUid?: InputMaybe<Scalars['ID']['input']>
}

export type QueryRootFavoriteHomesArgs = {
  order?: InputMaybe<HomeSearchOrderEnum>
  orderBy?: InputMaybe<HomeSearchOrderByEnum>
  rentalTypes?: InputMaybe<Array<HomeRentalTypeEnum>>
}

export type QueryRootGenerateBlockListingPayloadArgs = {
  homeTemplateIds: Array<Scalars['ID']['input']>
}

export type QueryRootGenerateContractPreviewPdfArgs = {
  id: Scalars['ID']['input']
  language?: InputMaybe<ContractLanguageEnum>
}

export type QueryRootHomeArgs = {
  id: Scalars['ID']['input']
}

export type QueryRootHomeApplicationArgs = {
  id: Scalars['ID']['input']
}

export type QueryRootHomeApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  contacted?: InputMaybe<Scalars['Boolean']['input']>
  earliestExpectedStudyFinish?: InputMaybe<Scalars['DateTime']['input']>
  excludeOfferingStatuses?: InputMaybe<Array<OfferingStatusEnum>>
  first?: InputMaybe<Scalars['Int']['input']>
  fulfillsRentalRequirements?: InputMaybe<Scalars['Boolean']['input']>
  homeId?: InputMaybe<Array<Scalars['ID']['input']>>
  interest?: InputMaybe<Array<Scalars['String']['input']>>
  last?: InputMaybe<Scalars['Int']['input']>
  maxIncome?: InputMaybe<Scalars['Int']['input']>
  minIncome?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<HomeApplicationOrderEnum>
  origin?: InputMaybe<Array<HomeApplicationOriginEnum>>
  searchString?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Array<HomeApplicationStatusEnum>>
  tenantVerifications?: InputMaybe<Array<TenantVerificationEnum>>
  userRole?: InputMaybe<HomeUserRoleEnum>
}

export type QueryRootHomeApplicationsByBoardListArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  fulfillsRentalRequirements?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  last?: InputMaybe<Scalars['Int']['input']>
  maxIncome?: InputMaybe<Scalars['Int']['input']>
  minIncome?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<HomeApplicationOrderEnum>
  searchString?: InputMaybe<Scalars['String']['input']>
  tenantVerifications?: InputMaybe<Array<TenantVerificationEnum>>
}

export type QueryRootHomeAvailabilitiesArgs = {
  homeId: Scalars['ID']['input']
}

export type QueryRootHomeAvailabilitySettingsQueryArgs = {
  homeId: Scalars['ID']['input']
}

export type QueryRootHomeEvaluationArgs = {
  homeId: Scalars['ID']['input']
}

export type QueryRootHomeIdsArgs = {
  market?: InputMaybe<MarketNameTypeEnum>
  platform?: InputMaybe<PlatformEnum>
}

export type QueryRootHomeIndexSearchArgs = {
  order?: InputMaybe<HomeIndexSearchOrderInput>
  params?: InputMaybe<HomeSearchParamsInput>
}

export type QueryRootHomeSearchArgs = {
  filterOnArea?: InputMaybe<Scalars['Boolean']['input']>
  market?: InputMaybe<MarketNameTypeEnum>
  order?: InputMaybe<HomeSearchOrderEnum>
  orderBy?: InputMaybe<HomeSearchOrderByEnum>
  platform?: InputMaybe<PlatformEnum>
  searchParams?: InputMaybe<HomeSearchParamsInput>
}

export type QueryRootHomeSearchCoordsArgs = {
  filterOnArea?: InputMaybe<Scalars['Boolean']['input']>
  market?: InputMaybe<MarketNameTypeEnum>
  searchParams?: InputMaybe<HomeSearchParamsInput>
}

export type QueryRootHomeStatsQueryArgs = {
  id: Scalars['ID']['input']
}

export type QueryRootHomeTemplateArgs = {
  id: Scalars['ID']['input']
}

export type QueryRootHomeTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  buildingId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  homeStatuses?: InputMaybe<Array<HomeStatusEnum>>
  last?: InputMaybe<Scalars['Int']['input']>
  maxRoomCount?: InputMaybe<Scalars['Int']['input']>
  maxSquareMeters?: InputMaybe<Scalars['Int']['input']>
  minRoomCount?: InputMaybe<Scalars['Int']['input']>
  minSquareMeters?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<HomeTemplateOrderEnum>
  searchString?: InputMaybe<Scalars['String']['input']>
}

export type QueryRootHomeqHomeApplicationPreconditionsArgs = {
  homeId: Scalars['ID']['input']
}

export type QueryRootHomeqLandlordCompaniesArgs = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type QueryRootHomesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  excludeApplicationDeadlinePassed?: InputMaybe<Scalars['Boolean']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  homeStatuses?: InputMaybe<Array<HomeStatusEnum>>
  last?: InputMaybe<Scalars['Int']['input']>
  maxRoomCount?: InputMaybe<Scalars['Int']['input']>
  maxSquareMeters?: InputMaybe<Scalars['Int']['input']>
  minRoomCount?: InputMaybe<Scalars['Int']['input']>
  minSquareMeters?: InputMaybe<Scalars['Int']['input']>
  onlyApplicationDeadlinePassed?: InputMaybe<Scalars['Boolean']['input']>
  order?: InputMaybe<HomeSearchOrderEnum>
  orderBy?: InputMaybe<HomeSearchOrderByEnum>
  rentalTypes?: InputMaybe<Array<HomeRentalTypeEnum>>
  searchString?: InputMaybe<Scalars['String']['input']>
}

export type QueryRootIdentificationStatusArgs = {
  externalId: Scalars['ID']['input']
}

export type QueryRootIncomeVerificationArgs = {
  uid: Scalars['ID']['input']
}

export type QueryRootInstantMatchesArgs = {
  homeId: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryRootKanyeUserArgs = {
  uid: Scalars['ID']['input']
}

export type QueryRootMarketSettingsArgs = {
  marketName: MarketNameTypeEnum
}

export type QueryRootMatchingScoreAwsArgs = {
  homeId: Scalars['ID']['input']
  tenantUid: Scalars['ID']['input']
}

export type QueryRootMessageMacrosArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryRootNewInboxArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  archived?: InputMaybe<Scalars['Boolean']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  homeId?: InputMaybe<Array<Scalars['ID']['input']>>
  last?: InputMaybe<Scalars['Int']['input']>
  onlyActiveConversations?: InputMaybe<Scalars['Boolean']['input']>
  orderBy?: InputMaybe<InboxQueryOrderByEnum>
  read?: InputMaybe<Scalars['Boolean']['input']>
  searchString?: InputMaybe<Scalars['String']['input']>
  starMarked?: InputMaybe<Scalars['Boolean']['input']>
  status?: InputMaybe<Array<ConversationStatusEnum>>
}

export type QueryRootOrganizationArgs = {
  uid: Scalars['ID']['input']
}

export type QueryRootPayoutOnboardingUrlArgs = {
  input: PayoutOnboardingUrlQueryInput
}

export type QueryRootPlatformConfigurationArgs = {
  platform: PlatformEnum
}

export type QueryRootPricePreviewArgs = {
  checkIn: Scalars['DateTime']['input']
  checkOut: Scalars['DateTime']['input']
  homeId: Scalars['ID']['input']
}

export type QueryRootProPilotConnectedUidsArgs = {
  uid: Scalars['ID']['input']
}

export type QueryRootProPilotUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryRootPublicDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryRootSavedSearchArgs = {
  rentalType?: InputMaybe<HomeRentalTypeEnum>
  uid?: InputMaybe<Scalars['ID']['input']>
}

export type QueryRootSearchAreaArgs = {
  areaParams?: InputMaybe<Array<AreaParamsInput>>
  identifierParams?: InputMaybe<Array<Array<Scalars['String']['input']>>>
  platform?: InputMaybe<PlatformEnum>
  searchStrings?: InputMaybe<Array<Scalars['String']['input']>>
}

export type QueryRootSearchAreaHierarchyArgs = {
  countryCodes: Array<CountryCodeEnum>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type QueryRootSearchAreaKeywordArgs = {
  countryCodes?: InputMaybe<Array<Scalars['String']['input']>>
  platform?: InputMaybe<PlatformEnum>
  searchString: Scalars['String']['input']
}

export type QueryRootSentOfferingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  earliestExpectedStudyFinish?: InputMaybe<Scalars['DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  homeId?: InputMaybe<Scalars['ID']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  maxIncome?: InputMaybe<Scalars['Int']['input']>
  minIncome?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<OfferingOrderEnum>
  searchString?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Array<OfferingStatusEnum>>
  tenantVerifications?: InputMaybe<Array<TenantVerificationEnum>>
}

export type QueryRootSimilarHomesArgs = {
  count?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
}

export type QueryRootTenantAdArgs = {
  id: Scalars['ID']['input']
}

export type QueryRootTenantAdsArgs = {
  homeId?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  platform?: InputMaybe<Array<PlatformEnum>>
  scoreThreshold?: InputMaybe<Scalars['Int']['input']>
  sorting?: InputMaybe<TenantAdSortingEnum>
}

export type QueryRootUserArgs = {
  uid: Scalars['ID']['input']
}

export type QueryRootUserIdsArgs = {
  platform: PlatformEnum
  professional?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryRootUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  input: UsersSearchInput
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryRootViewingCalendarArgs = {
  homeId: Scalars['ID']['input']
}

export type RateLimitExceeded = Error & {
  __typename: 'RateLimitExceeded'
  message: Scalars['String']['output']
}

export enum RefundActionEnum {
  full_refund = 'full_refund',
  landlord_fined = 'landlord_fined',
  manual_refund = 'manual_refund',
  no_refund = 'no_refund',
  partial_refund = 'partial_refund',
}

/** Autogenerated return type of RejectIdentification. */
export type RejectIdentificationPayload = {
  __typename: 'RejectIdentificationPayload'
  success: Scalars['Boolean']['output']
}

/** Autogenerated return type of RemoveCosigningTenant. */
export type RemoveCosigningTenantPayload = {
  __typename: 'RemoveCosigningTenantPayload'
  uid: Scalars['ID']['output']
}

/** Autogenerated return type of RemoveHomeApplicationsFromBoardList. */
export type RemoveHomeApplicationsFromBoardListPayload = {
  __typename: 'RemoveHomeApplicationsFromBoardListPayload'
  errors: Maybe<Array<Validation>>
  homeApplications: Array<HomeApplication>
}

export type RentHistogram = {
  __typename: 'RentHistogram'
  count: Scalars['Int']['output']
  normalizedCount: Scalars['Float']['output']
  rent: Scalars['Int']['output']
}

export type RentalRequirement = {
  __typename: 'RentalRequirement'
  approvedCreditCheck: Maybe<Scalars['Boolean']['output']>
  createdAt: Scalars['DateTime']['output']
  home: Home
  id: Scalars['ID']['output']
  rentMultiplier: Maybe<Scalars['Float']['output']>
  studentVerification: Maybe<Scalars['Boolean']['output']>
  updatedAt: Scalars['DateTime']['output']
  verifiedIdNumber: Maybe<Scalars['Boolean']['output']>
  verifiedIncome: Maybe<Scalars['Boolean']['output']>
}

export enum ReportTypeEnum {
  absent_during_viewing = 'absent_during_viewing',
  bad_behavior = 'bad_behavior',
  fraud = 'fraud',
  not_responding = 'not_responding',
  other = 'other',
}

/** Autogenerated return type of ReportUser. */
export type ReportUserPayload = {
  __typename: 'ReportUserPayload'
  errors: Maybe<Array<Validation>>
  userReport: Maybe<Scalars['Boolean']['output']>
}

/** Autogenerated return type of ResendConfirmBankAccountEmail. */
export type ResendConfirmBankAccountEmailPayload = {
  __typename: 'ResendConfirmBankAccountEmailPayload'
  emailQueued: Scalars['Boolean']['output']
}

export enum ResponsibleForCleaningEnum {
  landlord = 'landlord',
  landlord_for_fee = 'landlord_for_fee',
  tenant = 'tenant',
}

/** Autogenerated return type of RevokeHomeApplication. */
export type RevokeHomeApplicationPayload = {
  __typename: 'RevokeHomeApplicationPayload'
  errors: Maybe<Array<Validation>>
  homeApplication: Maybe<HomeApplication>
}

/** Autogenerated return type of RevokeOffering. */
export type RevokeOfferingPayload = {
  __typename: 'RevokeOfferingPayload'
  offering: Offering
}

export type SavedSearch = {
  __typename: 'SavedSearch'
  id: Scalars['ID']['output']
  monitoring: Scalars['Boolean']['output']
  rentalType: HomeRentalTypeEnum
  searchParams: HomeSearchParams
  title: Scalars['String']['output']
  visibility: SavedSearchVisibilityEnum
}

export enum SavedSearchVisibilityEnum {
  private = 'private',
  public = 'public',
}

export type SavingsVerification = {
  __typename: 'SavingsVerification'
  id: Scalars['ID']['output']
  uploads: Array<Upload>
  verifiedAt: Maybe<Scalars['DateTime']['output']>
}

export enum SchibstedAccountEnvironmentEnum {
  com = 'com',
  fi = 'fi',
}

export type SchibstedLoginInput = {
  accountEnvironment?: InputMaybe<SchibstedAccountEnvironmentEnum>
  authorizationCode: Scalars['String']['input']
  companyName?: InputMaybe<Scalars['String']['input']>
  landlord?: InputMaybe<Scalars['Boolean']['input']>
  mainPlatform: PlatformEnum
  orgNumber?: InputMaybe<Scalars['String']['input']>
  professional?: InputMaybe<Scalars['Boolean']['input']>
  redirectUrl: Scalars['String']['input']
  tenant?: InputMaybe<Scalars['Boolean']['input']>
}

/** Autogenerated return type of SchibstedLogin. */
export type SchibstedLoginPayload = {
  __typename: 'SchibstedLoginPayload'
  errors: Maybe<Array<Validation>>
  payload: Maybe<LoginPayload>
}

export type ScriveEidTransaction = {
  __typename: 'ScriveEidTransaction'
  autoStartToken: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
}

export enum ScriveInvitationOptionEnum {
  api = 'api',
  email_mobile = 'email_mobile',
}

export type SearchArea = {
  __typename: 'SearchArea'
  /** Return children in alphabetical order */
  children: Maybe<SearchAreaConnection>
  city: Maybe<Scalars['String']['output']>
  countryCode: Maybe<Scalars['String']['output']>
  displayName: Maybe<Scalars['String']['output']>
  fallback: Scalars['Boolean']['output']
  geojson: Scalars['JSON']['output']
  id: Scalars['ID']['output']
  identifier: Scalars['String']['output']
  name: Scalars['String']['output']
  parent: Maybe<SearchArea>
}

export type SearchAreaChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  leastTimesShown?: InputMaybe<Scalars['Int']['input']>
}

/** The connection type for SearchArea. */
export type SearchAreaConnection = {
  __typename: 'SearchAreaConnection'
  /** A list of edges. */
  edges: Array<SearchAreaEdge>
  /** A list of nodes. */
  nodes: Array<SearchArea>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type SearchAreaEdge = {
  __typename: 'SearchAreaEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SearchArea
}

export type SearchAreaMatcher = {
  __typename: 'SearchAreaMatcher'
  city: Maybe<Scalars['String']['output']>
  countryCode: Maybe<Scalars['String']['output']>
  identifier: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type SearchPreference = {
  __typename: 'SearchPreference'
  areaIdentifier: Maybe<Array<Scalars['ID']['output']>>
  balcony: Maybe<Scalars['Boolean']['output']>
  bathtub: Maybe<Scalars['Boolean']['output']>
  bikeRoom: Maybe<Scalars['Boolean']['output']>
  corporateHome: Maybe<Scalars['Boolean']['output']>
  countryCode: Maybe<Scalars['String']['output']>
  currency: Maybe<CurrencyEnum>
  dishWasher: Maybe<Scalars['Boolean']['output']>
  elevator: Maybe<Scalars['Boolean']['output']>
  endOptimal: Maybe<Scalars['DateTime']['output']>
  endUfn: Scalars['Boolean']['output']
  firsthand: Maybe<Scalars['Boolean']['output']>
  furniture: Maybe<Scalars['Boolean']['output']>
  homeType: Maybe<Array<HomeTypeEnum>>
  householdSize: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  inhomeSauna: Maybe<Scalars['Boolean']['output']>
  internet: Maybe<Scalars['Boolean']['output']>
  matchingAreaMetadata: Maybe<Array<MatchingAreaMetadata>>
  maxMonthlyCost: Maybe<Scalars['Int']['output']>
  maxMonthlyCostCents: Maybe<Scalars['Int']['output']>
  maxRoomCount: Maybe<Scalars['Int']['output']>
  maxSquareMeters: Maybe<Scalars['Int']['output']>
  minRoomCount: Maybe<Scalars['Int']['output']>
  minSquareMeters: Maybe<Scalars['Int']['output']>
  parking: Maybe<Scalars['Boolean']['output']>
  pets: Maybe<Scalars['Boolean']['output']>
  recycling: Maybe<Scalars['Boolean']['output']>
  searchAreas: Maybe<Array<SearchArea>>
  seniorHome: Maybe<Scalars['Boolean']['output']>
  shared: Maybe<Scalars['Boolean']['output']>
  sharedWashingArea: Maybe<Scalars['Boolean']['output']>
  shower: Maybe<Scalars['Boolean']['output']>
  smoker: Maybe<Scalars['Boolean']['output']>
  startAsap: Scalars['Boolean']['output']
  startOptimal: Maybe<Scalars['DateTime']['output']>
  storage: Maybe<Scalars['Boolean']['output']>
  studentHome: Maybe<Scalars['Boolean']['output']>
  toilet: Maybe<Scalars['Boolean']['output']>
  tumbleDryer: Maybe<Scalars['Boolean']['output']>
  washingMachine: Maybe<Scalars['Boolean']['output']>
  wheelchairAccessible: Maybe<Scalars['Boolean']['output']>
}

export type SearchPreferenceInput = {
  areaIdentifier?: InputMaybe<Array<Scalars['ID']['input']>>
  balcony?: InputMaybe<Scalars['Boolean']['input']>
  bathtub?: InputMaybe<Scalars['Boolean']['input']>
  bikeRoom?: InputMaybe<Scalars['Boolean']['input']>
  corporateHome?: InputMaybe<Scalars['Boolean']['input']>
  countryCode?: InputMaybe<Scalars['String']['input']>
  currency?: InputMaybe<CurrencyEnum>
  dishWasher?: InputMaybe<Scalars['Boolean']['input']>
  elevator?: InputMaybe<Scalars['Boolean']['input']>
  endOptimal?: InputMaybe<Scalars['DateTime']['input']>
  endUfn?: InputMaybe<Scalars['Boolean']['input']>
  firsthand?: InputMaybe<Scalars['Boolean']['input']>
  furniture?: InputMaybe<Scalars['Boolean']['input']>
  homeType?: InputMaybe<Array<HomeTypeEnum>>
  householdSize?: InputMaybe<Scalars['Int']['input']>
  inhomeSauna?: InputMaybe<Scalars['Boolean']['input']>
  internet?: InputMaybe<Scalars['Boolean']['input']>
  matchingAreaMetadata?: InputMaybe<Array<MatchingAreaMetadataInput>>
  maxMonthlyCost?: InputMaybe<Scalars['Int']['input']>
  maxRoomCount?: InputMaybe<Scalars['Int']['input']>
  maxSquareMeters?: InputMaybe<Scalars['Int']['input']>
  minRoomCount?: InputMaybe<Scalars['Int']['input']>
  minSquareMeters?: InputMaybe<Scalars['Int']['input']>
  parking?: InputMaybe<Scalars['Boolean']['input']>
  pets?: InputMaybe<Scalars['Boolean']['input']>
  recycling?: InputMaybe<Scalars['Boolean']['input']>
  seniorHome?: InputMaybe<Scalars['Boolean']['input']>
  shared?: InputMaybe<Scalars['Boolean']['input']>
  sharedWashingArea?: InputMaybe<Scalars['Boolean']['input']>
  shower?: InputMaybe<Scalars['Boolean']['input']>
  smoker?: InputMaybe<Scalars['Boolean']['input']>
  startAsap?: InputMaybe<Scalars['Boolean']['input']>
  startOptimal?: InputMaybe<Scalars['DateTime']['input']>
  storage?: InputMaybe<Scalars['Boolean']['input']>
  studentHome?: InputMaybe<Scalars['Boolean']['input']>
  toilet?: InputMaybe<Scalars['Boolean']['input']>
  tumbleDryer?: InputMaybe<Scalars['Boolean']['input']>
  washingMachine?: InputMaybe<Scalars['Boolean']['input']>
  wheelchairAccessible?: InputMaybe<Scalars['Boolean']['input']>
}

/** Autogenerated return type of SendBookingEmails. */
export type SendBookingEmailsPayload = {
  __typename: 'SendBookingEmailsPayload'
  errorMessage: Maybe<Scalars['String']['output']>
  status: Maybe<SendEmailStatusEnum>
}

/** Autogenerated return type of SendEmailConfirmationToken. */
export type SendEmailConfirmationTokenPayload = {
  __typename: 'SendEmailConfirmationTokenPayload'
  success: Maybe<Scalars['Boolean']['output']>
}

export enum SendEmailStatusEnum {
  invalid = 'invalid',
  queued = 'queued',
  rejected = 'rejected',
  sent = 'sent',
}

/** Either an error type or a Success */
export type SendEvictionEmailToSocResult = AuthorizationError | NotFoundError | Success | ValidationError

/** Autogenerated return type of SendMassMessage. */
export type SendMassMessagePayload = {
  __typename: 'SendMassMessagePayload'
  errors: Maybe<Array<Validation>>
  success: Scalars['Boolean']['output']
}

/** Autogenerated return type of SendSignInCode. */
export type SendSignInCodePayload = {
  __typename: 'SendSignInCodePayload'
  success: Scalars['Boolean']['output']
}

export type Service = {
  __typename: 'Service'
  availability: ServiceAvailabilityEnum
  cost: Cost
  isActive: Scalars['Boolean']['output']
}

export enum ServiceAvailabilityEnum {
  included = 'included',
  optional = 'optional',
  unavailable = 'unavailable',
}

export type SessionResolverResult = AdminInfo | AuthorizationError | NotFoundError | ValidationError

/** Autogenerated return type of SetActiveBankAccount. */
export type SetActiveBankAccountPayload = {
  __typename: 'SetActiveBankAccountPayload'
  bankAccount: Maybe<BankAccount>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of SetDepositFree. */
export type SetDepositFreePayload = {
  __typename: 'SetDepositFreePayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

export type SetScriveFieldsInput = {
  email: Scalars['String']['input']
  familyName: Scalars['String']['input']
  firstName: Scalars['String']['input']
  mobile: Scalars['String']['input']
}

/** Autogenerated return type of SetScriveFields. */
export type SetScriveFieldsPayload = {
  __typename: 'SetScriveFieldsPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

export type Shortcut = {
  __typename: 'Shortcut'
  agent: Maybe<User>
  agentHome: Maybe<Home>
  allowedTransitions: Array<ShortcutStatesEnum>
  createdAt: Scalars['DateTime']['output']
  currentState: ShortcutStatesEnum
  homePitch: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  landlordHome: Home
  updatedAt: Scalars['DateTime']['output']
}

export enum ShortcutProposalDeclineReasonEnum {
  diy = 'diy',
  missing_features = 'missing_features',
  other = 'other',
  own_pictures = 'own_pictures',
  payment_plan = 'payment_plan',
  too_expensive = 'too_expensive',
  unclear_offering = 'unclear_offering',
  want_to_meet_tenant = 'want_to_meet_tenant',
}

export enum ShortcutRejectionReasonEnum {
  NOT_INTERESTED_WILL_PUBLISH_THEMSELVES = 'NOT_INTERESTED_WILL_PUBLISH_THEMSELVES',
  NO_RESPONSE = 'NO_RESPONSE',
  OTHER = 'OTHER',
  SHARED_HOME = 'SHARED_HOME',
  SPECIFIC_TENANT_REQUIREMENTS = 'SPECIFIC_TENANT_REQUIREMENTS',
  TOO_HIGH_RENT_EXPECTATION = 'TOO_HIGH_RENT_EXPECTATION',
  TOO_SHORT_DURATION = 'TOO_SHORT_DURATION',
  USED_ANOTHER_SERVICE = 'USED_ANOTHER_SERVICE',
}

export enum ShortcutStatesEnum {
  aborted = 'aborted',
  canceled = 'canceled',
  completed = 'completed',
  draft = 'draft',
  ongoing = 'ongoing',
  paused = 'paused',
  pending = 'pending',
}

export enum ShowingStatusEnum {
  current_tenant_has_showing = 'current_tenant_has_showing',
  no_showing = 'no_showing',
  showing = 'showing',
}

/** Autogenerated return type of SignVacationContract. */
export type SignVacationContractPayload = {
  __typename: 'SignVacationContractPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

export type Signatory = {
  __typename: 'Signatory'
  canSign: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  /** @deprecated Not used */
  invitationMethod: Maybe<Scalars['String']['output']>
  order: Maybe<Scalars['Int']['output']>
  role: SignatoryRoleEnum
  signUrl: Maybe<Scalars['String']['output']>
  signedAt: Maybe<Scalars['DateTime']['output']>
  signingDeadline: Maybe<Scalars['DateTime']['output']>
  user: User
}

export enum SignatoryRoleEnum {
  cosigning_tenant = 'cosigning_tenant',
  landlord = 'landlord',
  tenant = 'tenant',
}

export enum SigningProcessEnum {
  default = 'default',
  instant = 'instant',
}

export type SimilarHomes = {
  __typename: 'SimilarHomes'
  homeId: Scalars['ID']['output']
  similarHomes: Array<Home>
}

export enum SimplifiedHomeStatusEnum {
  archived = 'archived',
  draft = 'draft',
  published = 'published',
  rented_out = 'rented_out',
  shortcut = 'shortcut',
}

export type SmoochParams = {
  __typename: 'SmoochParams'
  smoochJwt: Maybe<Scalars['String']['output']>
}

export type SocEvictionEmailQasaInfo = {
  __typename: 'SocEvictionEmailQasaInfo'
  adress: Scalars['String']['output']
  bankgiro: Scalars['String']['output']
  firma: Scalars['String']['output']
  orgnummer: Scalars['String']['output']
  ort: Scalars['String']['output']
  postnummer: Scalars['String']['output']
}

/** Autogenerated return type of StarMarkApplication. */
export type StarMarkApplicationPayload = {
  __typename: 'StarMarkApplicationPayload'
  homeApplication: HomeApplication
}

/** Autogenerated return type of StarMarkConversation. */
export type StarMarkConversationPayload = {
  __typename: 'StarMarkConversationPayload'
  conversation: Conversation
}

/** Autogenerated return type of StartBankIdTransaction. */
export type StartBankIdTransactionPayload = {
  __typename: 'StartBankIdTransactionPayload'
  errors: Maybe<Array<Validation>>
  success: Scalars['Boolean']['output']
  transaction: Maybe<ScriveEidTransaction>
}

export type StripeCheckoutInput = {
  cancelUrl: Scalars['String']['input']
  successUrl: Scalars['String']['input']
}

export type StripePayment = {
  __typename: 'StripePayment'
  currency: CurrencyEnum
  price: Scalars['Int']['output']
  stripeSKU: Scalars['String']['output']
}

export type StripeSepaTransferMoneyAction = {
  __typename: 'StripeSepaTransferMoneyAction'
  amount: Money
  bic: Scalars['String']['output']
  currency: CurrencyEnum
  iban: Scalars['String']['output']
  id: Scalars['ID']['output']
  reference: Scalars['String']['output']
}

export type StripeSetupAccountAction = {
  __typename: 'StripeSetupAccountAction'
  id: Scalars['ID']['output']
  url: Scalars['String']['output']
}

export type StripeSetupAccountActionUrlArgs = {
  refreshUrl: Scalars['String']['input']
  returnUrl: Scalars['String']['input']
}

export type StudentVerification = {
  __typename: 'StudentVerification'
  createdAt: Scalars['DateTime']['output']
  educationType: Maybe<StudentVerificationEducationTypeEnum>
  expectedFinishDate: Scalars['DateTime']['output']
  extent: StudentVerificationExtentEnum
  id: Scalars['ID']['output']
  program: Scalars['String']['output']
  school: Scalars['String']['output']
  startDate: Scalars['DateTime']['output']
  updatedAt: Scalars['DateTime']['output']
  upload: Maybe<Upload>
}

export enum StudentVerificationEducationTypeEnum {
  municipal_adult_education = 'municipal_adult_education',
  other_type = 'other_type',
  university = 'university',
  vocational_college = 'vocational_college',
}

export enum StudentVerificationExtentEnum {
  full_time = 'full_time',
  half_time = 'half_time',
}

export type SubUser = {
  __typename: 'SubUser'
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
}

/** Autogenerated return type of SubUserCreateResetPasswordToken. */
export type SubUserCreateResetPasswordTokenPayload = {
  __typename: 'SubUserCreateResetPasswordTokenPayload'
  errors: Maybe<Array<Validation>>
  success: Scalars['Boolean']['output']
}

/** Autogenerated return type of SubUserLogin. */
export type SubUserLoginPayload = {
  __typename: 'SubUserLoginPayload'
  errors: Maybe<Array<Validation>>
  payload: Maybe<LoginPayload>
}

/** Autogenerated return type of SubUserResetPassword. */
export type SubUserResetPasswordPayload = {
  __typename: 'SubUserResetPasswordPayload'
  errors: Maybe<Array<Validation>>
  success: Scalars['Boolean']['output']
}

/** The subscription root for the GraphQL schema */
export type SubscriptionRoot = {
  __typename: 'SubscriptionRoot'
  messagesSubscription: Maybe<MessagesSubscriptionPayload>
}

/** The subscription root for the GraphQL schema */
export type SubscriptionRootMessagesSubscriptionArgs = {
  conversationId: Scalars['ID']['input']
}

/** The request was successful. The "success" field is only included to satisfy the GraphQL spec */
export type Success = {
  __typename: 'Success'
  /** Will always be true. */
  success: Scalars['Boolean']['output']
}

export type SwishPayment = {
  __typename: 'SwishPayment'
  amountCents: Maybe<Scalars['Int']['output']>
  currency: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  paymentReferenceId: Scalars['ID']['output']
  status: SwishPaymentStatusEnum
}

export enum SwishPaymentStatusEnum {
  /** The payment was canceled */
  CANCELLED = 'CANCELLED',
  /** Initial status, payment has not been processed yet */
  CREATED = 'CREATED',
  /** The payment was declined by Swish */
  DECLINED = 'DECLINED',
  /** An error occurred while processing the payment */
  ERROR = 'ERROR',
  /** The payment was successful */
  PAID = 'PAID',
  /** The payment was refunded */
  REFUNDED = 'REFUNDED',
  /**
   * We could not retrieve an updated status from Billecta before the retry limit was exceeded
   *
   */
  RETRY_LIMIT_EXCEEDED = 'RETRY_LIMIT_EXCEEDED',
  /** Billecta returned the status "unknown" */
  UNKNOWN = 'UNKNOWN',
}

export type TagInput = {
  uploadTagInput?: InputMaybe<Array<UploadTaggableInput>>
}

export type TaggableInput = {
  id: Scalars['ID']['input']
  type: TaggableTypeEnum
}

export enum TaggableTypeEnum {
  Upload = 'Upload',
}

export type TaggableUnion = Upload

export type TaxReportAddressType = {
  __typename: 'TaxReportAddressType'
  countryCode: Scalars['String']['output']
  locality: Scalars['String']['output']
  postalCode: Scalars['String']['output']
  streetAddress: Scalars['String']['output']
}

export type TaxReportMetadataType = {
  __typename: 'TaxReportMetadataType'
  address: Maybe<MetadataAddressType>
  bankAccount: Maybe<BankAccountType>
  companyName: Maybe<Scalars['String']['output']>
  familyName: Maybe<Scalars['String']['output']>
  firstName: Maybe<Scalars['String']['output']>
  freeFormatAddress: Maybe<MetadataFreeFormatAddressType>
  id: Scalars['ID']['output']
  idNumber: Maybe<Scalars['String']['output']>
  orgNo: Maybe<Scalars['String']['output']>
  professional: Scalars['Boolean']['output']
  taxYear: Scalars['Int']['output']
  user: User
}

export type TaxReportQuarterType = {
  __typename: 'TaxReportQuarterType'
  compensation: Scalars['Int']['output']
  fees: Scalars['Int']['output']
  id: Scalars['ID']['output']
  numberOfTransactions: Scalars['Int']['output']
  quarter: Scalars['Int']['output']
  taxes: Scalars['Int']['output']
}

export type TaxReportType = {
  __typename: 'TaxReportType'
  address: TaxReportAddressType
  contracts: Array<Contract>
  daysRentedOut: Scalars['Int']['output']
  home: Home
  id: Scalars['ID']['output']
  metadata: Maybe<TaxReportMetadataType>
  quarters: Array<TaxReportQuarterType>
  taxYear: Scalars['Int']['output']
  user: User
}

export type TemporaryContract = {
  __typename: 'TemporaryContract'
  acceptsCosignatures: Maybe<Scalars['Boolean']['output']>
  adminNotes: Array<AdminNote>
  annualRentIncrease: Scalars['Float']['output']
  apartmentNumber: Maybe<Scalars['String']['output']>
  availablePreviewLanguages: Array<ContractLanguageEnum>
  booking: Maybe<Booking>
  canUse: Array<Scalars['String']['output']>
  cleaningFee: Scalars['Int']['output']
  contract: Maybe<Contract>
  contractDetails: Maybe<ContractDetails>
  conversation: Maybe<Conversation>
  cosigningTenants: Maybe<Array<User>>
  createdAt: Scalars['DateTime']['output']
  currency: CurrencyEnum
  currentUserSignatory: Signatory
  depositFree: Maybe<Scalars['Boolean']['output']>
  depositFreeCost: Maybe<Scalars['Int']['output']>
  depositPayoutApprovedAt: Maybe<Scalars['DateTime']['output']>
  depositPayoutDeniedAt: Maybe<Scalars['DateTime']['output']>
  duration: Maybe<Duration>
  electricityFee: Maybe<ElectricityFeeType>
  errors: Maybe<Array<Validation>>
  evictionProcess: Maybe<EvictionProcess>
  extensionContract: Maybe<Contract>
  externalContractTakeover: Maybe<ExternalContractTakeover>
  extraCosts: Maybe<Array<ExtraCost>>
  fee: Maybe<Scalars['Int']['output']>
  /** @deprecated DEPRECATED: Use finance_invoices instead */
  financeDepositInvoices: Array<FinanceDepositInvoice>
  financeInvoices: Array<InvoiceInterface>
  financePayouts: Array<FinancePayoutType>
  /** @deprecated DEPRECATED: Use finance_invoices instead */
  financeRentInvoices: Array<FinanceMonthlyRentInvoice>
  furniture: Maybe<Scalars['Boolean']['output']>
  home: Maybe<Home>
  id: Scalars['ID']['output']
  ifInsurancePromotion: Maybe<IfInsurancePromotion>
  inspectionProtocol: Maybe<Blob>
  insurance: Maybe<HomeInsuranceEnum>
  insuranceCost: Maybe<Scalars['Int']['output']>
  insuranceRecord: Maybe<Insurance>
  insuranceResponsibility: Maybe<InsuranceResponsibilityEnum>
  inventory: Array<Scalars['String']['output']>
  inventoryLists: Maybe<Array<Blob>>
  invoicedThrough: Maybe<Scalars['DateTime']['output']>
  invoices: Array<Invoice>
  landlord: User
  landlordAffiliateCode: Maybe<AffiliateCode>
  landlordBaseFee: Maybe<Scalars['Int']['output']>
  landlordContactInfo: Maybe<LandlordContactInfo>
  landlordFees: ContractLandlordFees
  landlordHasKeys: Maybe<Scalars['Boolean']['output']>
  landlordPostSignDocuments: Maybe<Array<Blob>>
  landlordServices: ContractLandlordServices
  language: ContractLanguageEnum
  market: Market
  marketDetails: Maybe<MarketDetailsUnion>
  motherContract: Maybe<Contract>
  numberOfTenants: Maybe<Scalars['Int']['output']>
  originalFee: Maybe<Scalars['Int']['output']>
  otherLandlordDocuments: Array<Blob>
  overnightStay: Maybe<Scalars['Boolean']['output']>
  ownHome: Maybe<Scalars['Boolean']['output']>
  paymentDay: Scalars['Int']['output']
  paymentInAdvance: Scalars['Boolean']['output']
  payoutDay: Scalars['Int']['output']
  payoutInAdvance: Scalars['Boolean']['output']
  pendingTerminationEndDate: Maybe<Scalars['DateTime']['output']>
  pets: Maybe<Scalars['Boolean']['output']>
  postCleaning: Maybe<ContractPostCleaningEnum>
  preCleaning: Maybe<ContractPreCleaningEnum>
  pricingModel: PricingModel
  qasaGuarantee: Maybe<Scalars['Boolean']['output']>
  qasaGuaranteeCost: Maybe<Scalars['Int']['output']>
  qasaGuaranteeMonths: Maybe<Scalars['Int']['output']>
  renovations: Maybe<Scalars['Boolean']['output']>
  rent: Maybe<Scalars['Int']['output']>
  rentExcludes: Array<Scalars['String']['output']>
  rentIncludes: Array<Scalars['String']['output']>
  rentalInsuranceMilaRequest: Maybe<FranceInsuranceRequestType>
  rentalType: ContractRentalTypeEnum
  scriveId: Maybe<Scalars['ID']['output']>
  signUrl: Maybe<Scalars['String']['output']>
  signatories: Array<Signatory>
  signedAt: Maybe<Scalars['DateTime']['output']>
  signingProcess: SigningProcessEnum
  smallRepairs: Maybe<ContractSmallRepairsEnum>
  smoker: Maybe<Scalars['Boolean']['output']>
  status: ContractStatusEnum
  subletception: Maybe<Scalars['Boolean']['output']>
  technicalDiagnoses: Maybe<Array<Blob>>
  temporaryInstallations: Maybe<Scalars['Boolean']['output']>
  tenant: Maybe<User>
  tenantAffiliateCode: Maybe<AffiliateCode>
  tenantBaseFee: Maybe<Scalars['Int']['output']>
  tenantContactInfo: Maybe<TenantContactInfo>
  tenantFees: ContractTenantFees
  tenantInvitations: Maybe<Array<ContractTenantInvitation>>
  tenantPostSignDocuments: Maybe<Array<Blob>>
  tenantServices: ContractTenantServices
  tenureType: Maybe<TenureTypeEnum>
  terminationNotice: Maybe<Scalars['Boolean']['output']>
  termsUrl: Maybe<Scalars['String']['output']>
  type: ContractTypeEnum
  updatedAt: Maybe<Scalars['DateTime']['output']>
  upload: Maybe<Upload>
  user: User
}

export type TenantAd = {
  __typename: 'TenantAd'
  createdAt: Scalars['DateTime']['output']
  familyMembers: Array<FamilyMember>
  id: Scalars['ID']['output']
  publishedUntil: Maybe<Scalars['DateTime']['output']>
  score: Maybe<Scalars['Int']['output']>
  searchPreference: SearchPreference
  status: TenantAdStatusEnum
  updatedAt: Scalars['DateTime']['output']
  user: User
}

export type TenantAdInput = {
  familyMembers?: InputMaybe<Array<FamilyMemberInput>>
  searchPreference?: InputMaybe<SearchPreferenceInput>
}

export type TenantAdOffsetLimit = {
  __typename: 'TenantAdOffsetLimit'
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  nodes: Array<TenantAd>
  pagesCount: Scalars['Int']['output']
  totalCount: Scalars['Int']['output']
}

export enum TenantAdSortingEnum {
  best_match = 'best_match',
  newest = 'newest',
  oldest = 'oldest',
}

export enum TenantAdStatusEnum {
  expired = 'expired',
  published = 'published',
  unpublished = 'unpublished',
}

export type TenantContactInfo = {
  __typename: 'TenantContactInfo'
  familyName: Maybe<Scalars['String']['output']>
}

export type TenantInput = {
  email: Scalars['String']['input']
  familyName: Scalars['String']['input']
  firstName: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

export type TenantProfileCompleteness = {
  __typename: 'TenantProfileCompleteness'
  completenessScore: Scalars['Int']['output']
  tenantProfileFields: TenantProfileFields
}

export type TenantProfileField = {
  __typename: 'TenantProfileField'
  completed: Scalars['Boolean']['output']
  weight: Scalars['Int']['output']
}

export type TenantProfileFields = {
  __typename: 'TenantProfileFields'
  bio: TenantProfileField
  bioLived: TenantProfileField
  bioPets: TenantProfileField
  bioSpare: TenantProfileField
  /** @deprecated Use `occupations`` instead */
  bioStudy: TenantProfileField
  bioTitle: TenantProfileField
  /** @deprecated Use `occupations` instead */
  bioWork: TenantProfileField
  certificate: TenantProfileField
  currentAgreementType: TenantProfileField
  emailConfirmed: TenantProfileField
  firstName: TenantProfileField
  idNumberConfirmed: TenantProfileField
  matchingProfile: TenantProfileField
  movingReason: TenantProfileField
  occupations: TenantProfileField
  profilePicture: TenantProfileField
  referenceCheck: TenantProfileField
}

export enum TenantTypeEnum {
  cosignee = 'cosignee',
  primary = 'primary',
}

export enum TenantVerificationEnum {
  credit_check_verified = 'credit_check_verified',
  identity_verified = 'identity_verified',
  income_verified = 'income_verified',
  student = 'student',
}

export enum TenureTypeEnum {
  condominium = 'condominium',
  proprietary = 'proprietary',
  tenancy = 'tenancy',
}

/** Autogenerated return type of TerminateContract. */
export type TerminateContractPayload = {
  __typename: 'TerminateContractPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<TerminateContractValidation>>
}

export type TerminateContractValidation = {
  __typename: 'TerminateContractValidation'
  codes: Array<TerminateContractValidationCodeEnum>
  codesWithMessage: Maybe<Array<CodesWithMessage>>
  field: Scalars['String']['output']
}

export enum TerminateContractValidationCodeEnum {
  /** The end date conflicts with already processed invoices or payments, or is passed the original end date. User needs to contact support to terminate contract. */
  end_date_too_early = 'end_date_too_early',
  /** Automatic contract termination is not allowed when there is a pending extension for the contract. User will have to cancel or sign the extension before terminating. */
  pending_extension = 'pending_extension',
  /** There is already a pending termination for this contract. User needs to complete/cancel the pending termination or contact support to trigger a new termination. */
  pending_termination = 'pending_termination',
  /** Automatic contract termination is not allowed before the rental period has started. User needs to contact support to terminate contract. */
  rental_period_not_started = 'rental_period_not_started',
}

export type TestErrorsInput = {
  id: Scalars['ID']['input']
  magicNumber: Scalars['Int']['input']
}

export type TestErrorsResult =
  | AuthorizationError
  | Home
  | MagicNumberTooLowError
  | NotFoundError
  | ValidationError

export type TinkTransactionGroupType = {
  __typename: 'TinkTransactionGroupType'
  month: MonthEnum
  transactions: Array<TinkTransactionType>
}

export type TinkTransactionType = {
  __typename: 'TinkTransactionType'
  amount: Money
  id: Scalars['ID']['output']
  time: Scalars['DateTime']['output']
  type: Scalars['String']['output']
}

export type Trait = {
  __typename: 'Trait'
  id: Scalars['ID']['output']
  type: TraitTypeEnum
}

export enum TraitTypeEnum {
  archipelago = 'archipelago',
  balcony = 'balcony',
  bathtub = 'bathtub',
  bed_sheets = 'bed_sheets',
  bike_room = 'bike_room',
  bikes = 'bikes',
  boat_kayak = 'boat_kayak',
  coffee_brewer = 'coffee_brewer',
  communal_sauna = 'communal_sauna',
  cot = 'cot',
  dish_washer = 'dish_washer',
  drying_room = 'drying_room',
  electric_car_charger = 'electric_car_charger',
  electricity_included = 'electricity_included',
  elevator = 'elevator',
  extra_blankets_and_pillows = 'extra_blankets_and_pillows',
  fire_extinguisher = 'fire_extinguisher',
  first_aid_kit = 'first_aid_kit',
  freezer = 'freezer',
  french_balcony = 'french_balcony',
  fridge = 'fridge',
  furniture = 'furniture',
  garage = 'garage',
  garage_included = 'garage_included',
  garden = 'garden',
  grill = 'grill',
  grill_essentials = 'grill_essentials',
  hair_dryer = 'hair_dryer',
  heat_included = 'heat_included',
  high_chair = 'high_chair',
  indoor_fireplace = 'indoor_fireplace',
  inhome_sauna = 'inhome_sauna',
  internet = 'internet',
  internet_included = 'internet_included',
  iron = 'iron',
  jacuzzi = 'jacuzzi',
  kettle = 'kettle',
  kitchen_appliances = 'kitchen_appliances',
  kitchenette = 'kitchenette',
  microwave_oven = 'microwave_oven',
  near_beach = 'near_beach',
  near_fishing = 'near_fishing',
  near_golf = 'near_golf',
  near_hiking_routes = 'near_hiking_routes',
  near_restaurants = 'near_restaurants',
  outdoor_furniture = 'outdoor_furniture',
  outdoor_shower = 'outdoor_shower',
  outhouse = 'outhouse',
  oven = 'oven',
  overnight_stay = 'overnight_stay',
  parking = 'parking',
  parking_included = 'parking_included',
  patio = 'patio',
  pets = 'pets',
  pool = 'pool',
  porcelain_glasses_cutlery = 'porcelain_glasses_cutlery',
  recycling = 'recycling',
  remote = 'remote',
  security_door = 'security_door',
  shared_washing_area = 'shared_washing_area',
  shower = 'shower',
  shower_supplies = 'shower_supplies',
  ski_in_out = 'ski_in_out',
  smoke_alarm = 'smoke_alarm',
  smoker = 'smoker',
  sound_system = 'sound_system',
  storage = 'storage',
  stove = 'stove',
  stroller_room = 'stroller_room',
  television = 'television',
  television_included = 'television_included',
  toilet = 'toilet',
  towels = 'towels',
  tumble_dryer = 'tumble_dryer',
  washing_machine = 'washing_machine',
  water_included = 'water_included',
  wheelchair_accessible = 'wheelchair_accessible',
}

export type TransitionBookingOfferInput = {
  bookingOfferId: Scalars['ID']['input']
  toState: BookingOfferStatesEnum
}

/** Autogenerated return type of TransitionBookingOffer. */
export type TransitionBookingOfferPayload = {
  __typename: 'TransitionBookingOfferPayload'
  bookingOffer: Maybe<BookingOffer>
  errors: Maybe<Array<Validation>>
}

export type TransitionShortcutInput = {
  reason?: InputMaybe<ShortcutRejectionReasonEnum>
  shortcutId: Scalars['ID']['input']
  toState: ShortcutStatesEnum
}

/** Autogenerated return type of TransitionShortcut. */
export type TransitionShortcutPayload = {
  __typename: 'TransitionShortcutPayload'
  errors: Maybe<Array<Validation>>
  shortcut: Maybe<Shortcut>
}

export type TranslatedDescription = {
  __typename: 'TranslatedDescription'
  description: Scalars['String']['output']
  language: Scalars['String']['output']
}

export type TranslatedDescriptionInput = {
  description: Scalars['String']['input']
  language: Scalars['String']['input']
}

/** Autogenerated return type of UnarchiveConversation. */
export type UnarchiveConversationPayload = {
  __typename: 'UnarchiveConversationPayload'
  conversation: Conversation
}

/** Autogenerated return type of UnmarkFavoriteHome. */
export type UnmarkFavoriteHomePayload = {
  __typename: 'UnmarkFavoriteHomePayload'
  home: Home
}

/** Autogenerated return type of UnpublishTenantAd. */
export type UnpublishTenantAdPayload = {
  __typename: 'UnpublishTenantAdPayload'
  errors: Maybe<Array<Validation>>
  tenantAd: Maybe<TenantAd>
}

export type UnreadConversationsCount = {
  __typename: 'UnreadConversationsCount'
  count: Scalars['Int']['output']
}

export type UpcomingPayoutType = {
  __typename: 'UpcomingPayoutType'
  /** The charges that will be deducted from the tenant's rent. */
  charges: Array<FinanceChargeType>
  /** The date when the rent will be paid out to the landlord. */
  dueDate: Scalars['Date']['output']
  /** The end of the period that the payout covers. */
  periodEnd: Maybe<Scalars['DateTime']['output']>
  /** The start of the period that the payout covers. */
  periodStart: Maybe<Scalars['DateTime']['output']>
  /** The amount that will be paid out to the landlord. */
  sumAmount: Money
}

/** Autogenerated return type of UpdateApplicationBoardListOrder. */
export type UpdateApplicationBoardListOrderPayload = {
  __typename: 'UpdateApplicationBoardListOrderPayload'
  success: Scalars['Boolean']['output']
}

/** Autogenerated return type of UpdateApplicationBoardList. */
export type UpdateApplicationBoardListPayload = {
  __typename: 'UpdateApplicationBoardListPayload'
  applicationBoardList: Maybe<ApplicationBoardList>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of UpdateContractScriveId. */
export type UpdateContractScriveIdPayload = {
  __typename: 'UpdateContractScriveIdPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

/** Autogenerated return type of UpdateContractWaterFee. */
export type UpdateContractWaterFeePayload = {
  __typename: 'UpdateContractWaterFeePayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

export type UpdateEmployeeResult = AuthorizationError | Employee | NotFoundError | ValidationError

export type UpdateEvictionProcessResult =
  | AuthorizationError
  | EvictionProcess
  | NotFoundError
  | ValidationError

/** Autogenerated return type of UpdateHomeApplicationPosition. */
export type UpdateHomeApplicationPositionPayload = {
  __typename: 'UpdateHomeApplicationPositionPayload'
  homeApplication: HomeApplication
}

export type UpdateHomeInput = {
  apartmentNumber?: InputMaybe<Scalars['String']['input']>
  applicationDeadline?: InputMaybe<Scalars['DateTime']['input']>
  bathroomRenovationYear?: InputMaybe<Scalars['Int']['input']>
  bedCount?: InputMaybe<Scalars['Int']['input']>
  bedroomCount?: InputMaybe<Scalars['Int']['input']>
  buildYear?: InputMaybe<Scalars['Int']['input']>
  buildingFloors?: InputMaybe<Scalars['Int']['input']>
  cancellationPolicyName?: InputMaybe<CancellationPolicyNameEnum>
  cleaningFee?: InputMaybe<Scalars['Int']['input']>
  contractTemplateAttributes?: InputMaybe<ContractTemplateInput>
  corporateHome?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  descriptionBuilding?: InputMaybe<Scalars['String']['input']>
  descriptionContract?: InputMaybe<Scalars['String']['input']>
  descriptionTransportation?: InputMaybe<Scalars['String']['input']>
  duration?: InputMaybe<CreateDurationInput>
  electricityFee?: InputMaybe<ElectricityFeeInput>
  energyClass?: InputMaybe<Scalars['String']['input']>
  external?: InputMaybe<Scalars['Boolean']['input']>
  externalApplicationUrl?: InputMaybe<Scalars['String']['input']>
  externalEmail?: InputMaybe<Scalars['String']['input']>
  externalInfoPage?: InputMaybe<Scalars['String']['input']>
  extraCosts?: InputMaybe<Array<ExtraCostInput>>
  firsthand?: InputMaybe<Scalars['Boolean']['input']>
  floor?: InputMaybe<Scalars['Int']['input']>
  hasKitchen?: InputMaybe<Scalars['Boolean']['input']>
  houseRules?: InputMaybe<Scalars['String']['input']>
  housingAssociation?: InputMaybe<Scalars['String']['input']>
  instantSignSettingAttributes?: InputMaybe<InstantSignSettingInput>
  insurance?: InputMaybe<HomeInsuranceEnum>
  kitchenRenovationYear?: InputMaybe<Scalars['Int']['input']>
  location?: InputMaybe<CreateLocationInput>
  matterportEmbedUrl?: InputMaybe<Scalars['String']['input']>
  maxRent?: InputMaybe<Scalars['Int']['input']>
  maxRoomCount?: InputMaybe<Scalars['Float']['input']>
  maxSquareMeters?: InputMaybe<Scalars['Int']['input']>
  maxTenantCount?: InputMaybe<Scalars['Int']['input']>
  minRent?: InputMaybe<Scalars['Int']['input']>
  minRoomCount?: InputMaybe<Scalars['Float']['input']>
  minSquareMeters?: InputMaybe<Scalars['Int']['input']>
  minTenantCount?: InputMaybe<Scalars['Int']['input']>
  numberOfHomes?: InputMaybe<Scalars['Int']['input']>
  origin?: InputMaybe<Scalars['String']['input']>
  ownHome?: InputMaybe<Scalars['Boolean']['input']>
  qasaGuarantee?: InputMaybe<Scalars['Boolean']['input']>
  rent?: InputMaybe<Scalars['Int']['input']>
  rentalType?: InputMaybe<HomeRentalTypeEnum>
  responsibleForCleaning?: InputMaybe<ResponsibleForCleaningEnum>
  roomCount?: InputMaybe<Scalars['Float']['input']>
  seniorHome?: InputMaybe<Scalars['Boolean']['input']>
  shared?: InputMaybe<Scalars['Boolean']['input']>
  squareMeters?: InputMaybe<Scalars['Int']['input']>
  studentHome?: InputMaybe<Scalars['Boolean']['input']>
  tenantCount?: InputMaybe<Scalars['Int']['input']>
  tenureType?: InputMaybe<TenureTypeEnum>
  title?: InputMaybe<Scalars['String']['input']>
  toiletCount?: InputMaybe<Scalars['Int']['input']>
  traits?: InputMaybe<Array<TraitTypeEnum>>
  translatedDescriptions?: InputMaybe<Array<TranslatedDescriptionInput>>
  type?: InputMaybe<HomeTypeEnum>
  waterFeePerTenant?: InputMaybe<Scalars['Int']['input']>
}

/** Autogenerated return type of UpdateHome. */
export type UpdateHomePayload = {
  __typename: 'UpdateHomePayload'
  errors: Maybe<Array<Validation>>
  home: Maybe<Home>
}

export type UpdateInstantSignContractInput = {
  durationAttributes?: InputMaybe<InstantSignDurationInput>
}

/** Autogenerated return type of UpdateInstantSignContract. */
export type UpdateInstantSignContractPayload = {
  __typename: 'UpdateInstantSignContractPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

export type UpdateLandlordPostSignDocumentsInput = {
  landlordPostSignDocuments: Array<Scalars['ID']['input']>
}

/** Autogenerated return type of UpdateLandlordPostSignDocuments. */
export type UpdateLandlordPostSignDocumentsPayload = {
  __typename: 'UpdateLandlordPostSignDocumentsPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

export type UpdateMarketSettingsInput = {
  shortcutEnlistNewHomes?: InputMaybe<Scalars['Boolean']['input']>
  shortcutMaxPendingListings?: InputMaybe<Scalars['Int']['input']>
  shortcutStartRange?: InputMaybe<DateRangeInput>
}

/** Autogenerated return type of UpdateMarketSettings. */
export type UpdateMarketSettingsPayload = {
  __typename: 'UpdateMarketSettingsPayload'
  errors: Maybe<Array<Validation>>
  marketSettings: MarketSettings
}

/** Autogenerated return type of UpdateSavedHomeSearch. */
export type UpdateSavedHomeSearchPayload = {
  __typename: 'UpdateSavedHomeSearchPayload'
  errors: Maybe<Array<Validation>>
  user: Maybe<User>
}

export type UpdateSavedSearchInput = {
  monitoring?: InputMaybe<Scalars['Boolean']['input']>
  searchParams?: InputMaybe<HomeSearchParamsInput>
  title?: InputMaybe<Scalars['String']['input']>
  visibility?: InputMaybe<SavedSearchVisibilityEnum>
}

/** Autogenerated return type of UpdateSavedSearch. */
export type UpdateSavedSearchPayload = {
  __typename: 'UpdateSavedSearchPayload'
  errors: Maybe<Array<Validation>>
  savedSearch: Maybe<SavedSearch>
}

export type UpdateShortcutInput = {
  homePitch: Scalars['String']['input']
}

/** Autogenerated return type of UpdateShortcut. */
export type UpdateShortcutPayload = {
  __typename: 'UpdateShortcutPayload'
  errors: Maybe<Array<Validation>>
  shortcut: Shortcut
}

export type UpdateTenantPostSignDocumentsInput = {
  tenantPostSignDocuments: Array<Scalars['ID']['input']>
}

/** Autogenerated return type of UpdateTenantPostSignDocuments. */
export type UpdateTenantPostSignDocumentsPayload = {
  __typename: 'UpdateTenantPostSignDocumentsPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

export type UpdateUploadInput = {
  metadata?: InputMaybe<UploadMetadataInput>
}

/** Autogenerated return type of UpdateUpload. */
export type UpdateUploadPayload = {
  __typename: 'UpdateUploadPayload'
  errors: Maybe<Array<Validation>>
  upload: Maybe<Upload>
}

export type UpdateUserInput = {
  affiliateCode?: InputMaybe<Scalars['String']['input']>
  bio?: InputMaybe<Scalars['String']['input']>
  bioLived?: InputMaybe<Scalars['String']['input']>
  bioPets?: InputMaybe<Scalars['String']['input']>
  bioSpareTime?: InputMaybe<Scalars['String']['input']>
  bioStudy?: InputMaybe<Scalars['String']['input']>
  bioTitle?: InputMaybe<Scalars['String']['input']>
  bioWhen?: InputMaybe<Scalars['String']['input']>
  bioWhere?: InputMaybe<Scalars['String']['input']>
  bioWho?: InputMaybe<Scalars['String']['input']>
  bioWork?: InputMaybe<Scalars['String']['input']>
  biometricConsent?: InputMaybe<Scalars['Boolean']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  contactLocation?: InputMaybe<CreateLocationInput>
  doNotContact?: InputMaybe<Scalars['Boolean']['input']>
  doNotSms?: InputMaybe<Scalars['Boolean']['input']>
  doNotVacationSms?: InputMaybe<Scalars['Boolean']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  expoPushToken?: InputMaybe<Scalars['String']['input']>
  familyName?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  gender?: InputMaybe<GenderEnum>
  hasPets?: InputMaybe<Scalars['Boolean']['input']>
  householdMembersBirthYears?: InputMaybe<Array<Scalars['Int']['input']>>
  housingSituation?: InputMaybe<HousingSituationInput>
  idNumber?: InputMaybe<Scalars['String']['input']>
  landlord?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
  mainPlatform?: InputMaybe<PlatformEnum>
  movingReason?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  notificationSpan?: InputMaybe<NotificationSpanEnum>
  nrFirsthandHousingUnits?: InputMaybe<NrFirsthandHousingUnitsEnum>
  /** @deprecated Use `occupations` instead */
  occupation?: InputMaybe<OccupationEnum>
  orgNumber?: InputMaybe<Scalars['String']['input']>
  organisationNumberCountryCode?: InputMaybe<CountryCodeEnum>
  pep?: InputMaybe<Scalars['Boolean']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  professional?: InputMaybe<Scalars['Boolean']['input']>
  /** Deprecated, never used */
  prospectOnly?: InputMaybe<Scalars['Boolean']['input']>
  rca?: InputMaybe<Scalars['Boolean']['input']>
  rentingOutFirsthandHomes?: InputMaybe<Scalars['Boolean']['input']>
  safeRental?: InputMaybe<Scalars['Boolean']['input']>
  salary?: InputMaybe<Scalars['Int']['input']>
  sendMonitoringEvents?: InputMaybe<Scalars['Boolean']['input']>
  showAge?: InputMaybe<Scalars['Boolean']['input']>
  smoker?: InputMaybe<Scalars['Boolean']['input']>
  statedBirthDate?: InputMaybe<Scalars['DateTime']['input']>
  tenant?: InputMaybe<Scalars['Boolean']['input']>
  termsAccepted?: InputMaybe<Scalars['Boolean']['input']>
}

/** Autogenerated return type of UpdateUser. */
export type UpdateUserPayload = {
  __typename: 'UpdateUserPayload'
  errors: Maybe<Array<Validation>>
  user: Maybe<User>
}

export type UpdateWaterFeeInput = {
  numberOfTenants: Scalars['Int']['input']
  waterFeePerTenant: Scalars['Int']['input']
}

export type Upload = {
  __typename: 'Upload'
  contentLength: Maybe<Scalars['Int']['output']>
  contentType: Maybe<Scalars['String']['output']>
  fileType: UploadFileTypeEnum
  id: Scalars['ID']['output']
  metadata: Maybe<UploadMetadata>
  tags: Array<UploadTagType>
  title: Scalars['String']['output']
  type: UploadTypeEnum
  url: Scalars['String']['output']
}

export enum UploadFileTypeEnum {
  document = 'document',
  geometry = 'geometry',
  image = 'image',
  unknown = 'unknown',
  video = 'video',
}

export type UploadMetadata = {
  __typename: 'UploadMetadata'
  order: Maybe<Scalars['Int']['output']>
  primary: Maybe<Scalars['Boolean']['output']>
  rotation: Maybe<Scalars['Int']['output']>
}

export type UploadMetadataInput = {
  order?: InputMaybe<Scalars['Int']['input']>
  primary?: InputMaybe<Scalars['Boolean']['input']>
  rotation?: InputMaybe<Scalars['Int']['input']>
}

export type UploadTagType = {
  __typename: 'UploadTagType'
  id: Scalars['ID']['output']
  name: UploadTaggableNameEnum
}

export type UploadTaggableInput = {
  name: UploadTaggableNameEnum
}

export enum UploadTaggableNameEnum {
  layout = 'layout',
}

export enum UploadTypeEnum {
  activity_compensation_verification = 'activity_compensation_verification',
  activity_grant_verification = 'activity_grant_verification',
  area_upload_geometry = 'area_upload_geometry',
  capital_as_income_verification = 'capital_as_income_verification',
  certificate_credit_check = 'certificate_credit_check',
  child_benefit_verification = 'child_benefit_verification',
  contract_pdf = 'contract_pdf',
  csn_verification = 'csn_verification',
  data_takeout_json = 'data_takeout_json',
  employment_verification = 'employment_verification',
  home_building_picture = 'home_building_picture',
  home_layout_picture = 'home_layout_picture',
  home_picture = 'home_picture',
  housing_allowance_verification = 'housing_allowance_verification',
  housing_supplement_verification = 'housing_supplement_verification',
  income_verification = 'income_verification',
  insurance_terms = 'insurance_terms',
  interest_income_verification = 'interest_income_verification',
  introduction_benefit_verification = 'introduction_benefit_verification',
  limited_company = 'limited_company',
  limited_company_annual_account = 'limited_company_annual_account',
  limited_company_audit_certificate = 'limited_company_audit_certificate',
  limited_company_f_tax = 'limited_company_f_tax',
  maintenance_support_verification = 'maintenance_support_verification',
  message_image = 'message_image',
  parental_benefit_employed_verification = 'parental_benefit_employed_verification',
  parental_benefit_unemployed_verification = 'parental_benefit_unemployed_verification',
  passport_picture = 'passport_picture',
  pension_verification = 'pension_verification',
  private_company = 'private_company',
  private_company_audit_certificate = 'private_company_audit_certificate',
  private_company_balance_report = 'private_company_balance_report',
  private_company_declaration = 'private_company_declaration',
  private_company_f_tax = 'private_company_f_tax',
  private_company_result_report = 'private_company_result_report',
  profile_picture = 'profile_picture',
  reference_check_pdf = 'reference_check_pdf',
  savings_verification = 'savings_verification',
  sick_pay_verification = 'sick_pay_verification',
  sickness_compensation_verification = 'sickness_compensation_verification',
  social_welfare_verification = 'social_welfare_verification',
  student_verification = 'student_verification',
  unemployment_benefit_scheme_verification = 'unemployment_benefit_scheme_verification',
  verification_selfie_picture = 'verification_selfie_picture',
}

export type UpsertApplicationBoardCardInfoInput = {
  showEmployerCertificate?: InputMaybe<Scalars['Boolean']['input']>
  showIdentification?: InputMaybe<Scalars['Boolean']['input']>
  showIncome?: InputMaybe<Scalars['Boolean']['input']>
  showLastMessage?: InputMaybe<Scalars['Boolean']['input']>
  showMatchingScore?: InputMaybe<Scalars['Boolean']['input']>
  showQueueDays?: InputMaybe<Scalars['Boolean']['input']>
  showStudyCertificate?: InputMaybe<Scalars['Boolean']['input']>
  showUc?: InputMaybe<Scalars['Boolean']['input']>
}

/** Autogenerated return type of UpsertApplicationBoardCardInfo. */
export type UpsertApplicationBoardCardInfoPayload = {
  __typename: 'UpsertApplicationBoardCardInfoPayload'
  applicationBoardCardInfo: Maybe<ApplicationBoardCardInfo>
  errors: Maybe<Array<Validation>>
}

export type UpsertBankAccountInput = {
  bankLocation?: InputMaybe<CreateLocationInput>
  bankName?: InputMaybe<Scalars['String']['input']>
  bic?: InputMaybe<Scalars['String']['input']>
  clearingNumber?: InputMaybe<Scalars['String']['input']>
  countryCode?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  iban?: InputMaybe<Scalars['String']['input']>
  location?: InputMaybe<CreateLocationInput>
  name?: InputMaybe<Scalars['String']['input']>
  number?: InputMaybe<Scalars['String']['input']>
  ownerRelationType?: InputMaybe<BankAccountOwnerRelationTypeEnum>
  type?: InputMaybe<BankAccountTypeEnum>
}

/** Autogenerated return type of UpsertBankAccount. */
export type UpsertBankAccountPayload = {
  __typename: 'UpsertBankAccountPayload'
  bankAccount: Maybe<BankAccount>
  errors: Maybe<Array<Validation>>
}

export type UpsertBookingInput = {
  checkIn?: InputMaybe<Scalars['Date']['input']>
  checkOut?: InputMaybe<Scalars['Date']['input']>
  homeId?: InputMaybe<Scalars['ID']['input']>
  message?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of UpsertBooking. */
export type UpsertBookingPayload = {
  __typename: 'UpsertBookingPayload'
  booking: Maybe<Booking>
  errors: Maybe<Array<Validation>>
}

export type UpsertBuildingInput = {
  buildYear?: InputMaybe<Scalars['Int']['input']>
  buildingName?: InputMaybe<Scalars['String']['input']>
  descriptionBuilding?: InputMaybe<Scalars['String']['input']>
  descriptionTransportation?: InputMaybe<Scalars['String']['input']>
  energyClass?: InputMaybe<Scalars['String']['input']>
  location?: InputMaybe<CreateLocationInput>
  organizationUid?: InputMaybe<Scalars['ID']['input']>
}

/** Autogenerated return type of UpsertBuilding. */
export type UpsertBuildingPayload = {
  __typename: 'UpsertBuildingPayload'
  building: Maybe<Building>
  errors: Maybe<Array<Validation>>
}

export type UpsertContractDetailsInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>
  bathroomShared?: InputMaybe<Scalars['Boolean']['input']>
  bindingTime?: InputMaybe<Scalars['Int']['input']>
  cadastralReference?: InputMaybe<Scalars['String']['input']>
  canBeTerminated?: InputMaybe<Scalars['Boolean']['input']>
  codeOfConduct?: InputMaybe<Scalars['String']['input']>
  keySpecification?: InputMaybe<Scalars['String']['input']>
  kitchenShared?: InputMaybe<Scalars['Boolean']['input']>
  knownDamages?: InputMaybe<Scalars['String']['input']>
  legalType?: InputMaybe<LegalTypeEnum>
  municipality?: InputMaybe<Scalars['String']['input']>
  rentedAsIs?: InputMaybe<Scalars['Boolean']['input']>
  sectionNumber?: InputMaybe<Scalars['String']['input']>
  shorterTerminationExplanation?: InputMaybe<Scalars['String']['input']>
  tenantEmail?: InputMaybe<Scalars['String']['input']>
  tenantFamilyName?: InputMaybe<Scalars['String']['input']>
  tenantFirstName?: InputMaybe<Scalars['String']['input']>
  tenantPhoneNumber?: InputMaybe<Scalars['String']['input']>
  terminationNoticeMonths?: InputMaybe<Scalars['Int']['input']>
  titleNumber?: InputMaybe<Scalars['String']['input']>
}

export type UpsertContractInput = {
  acceptsCosignatures?: InputMaybe<Scalars['Boolean']['input']>
  annualRentIncrease?: InputMaybe<Scalars['Float']['input']>
  apartmentNumber?: InputMaybe<Scalars['String']['input']>
  canUse?: InputMaybe<Array<Scalars['String']['input']>>
  contractDetails?: InputMaybe<UpsertContractDetailsInput>
  duration?: InputMaybe<CreateDurationInput>
  electricityFee?: InputMaybe<ElectricityFeeInput>
  extraCosts?: InputMaybe<Array<ExtraCostInput>>
  furniture?: InputMaybe<Scalars['Boolean']['input']>
  homeId?: InputMaybe<Scalars['ID']['input']>
  insurance?: InputMaybe<HomeInsuranceEnum>
  insuranceResponsibility?: InputMaybe<InsuranceResponsibilityEnum>
  inventory?: InputMaybe<Array<Scalars['String']['input']>>
  landlordHasKeys?: InputMaybe<Scalars['Boolean']['input']>
  numberOfTenants?: InputMaybe<Scalars['Int']['input']>
  overnightStay?: InputMaybe<Scalars['Boolean']['input']>
  ownHome?: InputMaybe<Scalars['Boolean']['input']>
  paymentDay?: InputMaybe<Scalars['Int']['input']>
  pets?: InputMaybe<Scalars['Boolean']['input']>
  postCleaning?: InputMaybe<ContractPostCleaningEnum>
  preCleaning?: InputMaybe<ContractPreCleaningEnum>
  qasaGuarantee?: InputMaybe<Scalars['Boolean']['input']>
  renovations?: InputMaybe<Scalars['Boolean']['input']>
  rent?: InputMaybe<Scalars['Int']['input']>
  rentExcludes?: InputMaybe<Array<Scalars['String']['input']>>
  rentIncludes?: InputMaybe<Array<Scalars['String']['input']>>
  rentalType?: InputMaybe<ContractRentalTypeEnum>
  smallRepairs?: InputMaybe<ContractSmallRepairsEnum>
  smoker?: InputMaybe<Scalars['Boolean']['input']>
  subletception?: InputMaybe<Scalars['Boolean']['input']>
  temporaryInstallations?: InputMaybe<Scalars['Boolean']['input']>
  tenantUid?: InputMaybe<Scalars['ID']['input']>
  tenureType?: InputMaybe<TenureTypeEnum>
  terminationNotice?: InputMaybe<Scalars['Boolean']['input']>
  waterFeePerTenant?: InputMaybe<Scalars['Int']['input']>
}

/** Autogenerated return type of UpsertContract. */
export type UpsertContractPayload = {
  __typename: 'UpsertContractPayload'
  contract: Maybe<Contract>
  errors: Maybe<Array<Validation>>
}

export type UpsertEmployeeGroupResult = AuthorizationError | EmployeeGroup | NotFoundError | ValidationError

export type UpsertEmploymentVerificationInput = {
  documents?: InputMaybe<Array<Scalars['ID']['input']>>
  employerName?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of UpsertEmploymentVerification. */
export type UpsertEmploymentVerificationPayload = {
  __typename: 'UpsertEmploymentVerificationPayload'
  employmentVerification: Maybe<EmploymentVerification>
  errors: Maybe<Array<Validation>>
}

export type UpsertHomeAvailabilityInput = {
  date: Scalars['Date']['input']
  price: Scalars['Int']['input']
}

/** Autogenerated return type of UpsertHomeAvailability. */
export type UpsertHomeAvailabilityPayload = {
  __typename: 'UpsertHomeAvailabilityPayload'
  errors: Maybe<Array<Validation>>
  upserted: Maybe<Array<HomeAvailability>>
}

export type UpsertHomeAvailabilitySettingsInput = {
  defaultPricePerNight?: InputMaybe<Scalars['Int']['input']>
  homeId: Scalars['ID']['input']
  minNightsStay?: InputMaybe<Scalars['Int']['input']>
  noCheckinDays?: InputMaybe<Array<DayOfTheWeekTypeEnum>>
  rentOnlyWeekly?: InputMaybe<Scalars['Boolean']['input']>
}

/** Autogenerated return type of UpsertHomeAvailabilitySettings. */
export type UpsertHomeAvailabilitySettingsPayload = {
  __typename: 'UpsertHomeAvailabilitySettingsPayload'
  errors: Maybe<Array<Validation>>
  homeAvailabilitySettings: Maybe<HomeAvailabilitySettings>
}

export type UpsertHomeTemplateInput = {
  apartmentNumber?: InputMaybe<Scalars['String']['input']>
  bathroomRenovationYear?: InputMaybe<Scalars['Int']['input']>
  buildingId?: InputMaybe<Scalars['ID']['input']>
  corporateHome?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  electricityFee?: InputMaybe<ElectricityFeeInput>
  firsthand?: InputMaybe<Scalars['Boolean']['input']>
  floor?: InputMaybe<Scalars['Int']['input']>
  kitchenRenovationYear?: InputMaybe<Scalars['Int']['input']>
  location?: InputMaybe<CreateLocationInput>
  organizationUid?: InputMaybe<Scalars['ID']['input']>
  rent?: InputMaybe<Scalars['Int']['input']>
  roomCount?: InputMaybe<Scalars['Float']['input']>
  seniorHome?: InputMaybe<Scalars['Boolean']['input']>
  shared?: InputMaybe<Scalars['Boolean']['input']>
  squareMeters?: InputMaybe<Scalars['Int']['input']>
  studentHome?: InputMaybe<Scalars['Boolean']['input']>
  tenantCount?: InputMaybe<Scalars['Int']['input']>
  tenureType?: InputMaybe<TenureTypeEnum>
  traits?: InputMaybe<Array<TraitTypeEnum>>
  type?: InputMaybe<HomeTypeEnum>
  waterFeePerTenant?: InputMaybe<Scalars['Int']['input']>
}

/** Autogenerated return type of UpsertHomeTemplate. */
export type UpsertHomeTemplatePayload = {
  __typename: 'UpsertHomeTemplatePayload'
  errors: Maybe<Array<Validation>>
  homeTemplate: Maybe<HomeTemplate>
}

/** Autogenerated return type of UpsertIncomeVerification. */
export type UpsertIncomeVerificationPayload = {
  __typename: 'UpsertIncomeVerificationPayload'
  errors: Maybe<Array<Validation>>
  incomeVerification: Maybe<IncomeVerification>
}

/** Autogenerated return type of UpsertInspection. */
export type UpsertInspectionPayload = {
  __typename: 'UpsertInspectionPayload'
  errors: Maybe<Array<Validation>>
  inspection: Maybe<Inspection>
}

export type UpsertInstantSignInput = {
  durationAttributes?: InputMaybe<InstantSignDurationInput>
  homeId?: InputMaybe<Scalars['ID']['input']>
}

export type UpsertMessageMacroInput = {
  description?: InputMaybe<Scalars['String']['input']>
  text?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of UpsertMessageMacro. */
export type UpsertMessageMacroPayload = {
  __typename: 'UpsertMessageMacroPayload'
  errors: Maybe<Array<Validation>>
  messageMacro: Maybe<MessageMacro>
}

export type UpsertOccupationInput = {
  /** Leave blank if this is an current occupation */
  endedAt?: InputMaybe<Scalars['DateTime']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  occupationScheduleType?: InputMaybe<OccupationScheduleTypeEnum>
  occupationType?: InputMaybe<OccupationTypeEnum>
  startedAt?: InputMaybe<Scalars['DateTime']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of UpsertOccupation. */
export type UpsertOccupationPayload = {
  __typename: 'UpsertOccupationPayload'
  errors: Maybe<Array<Validation>>
  occupation: Maybe<Occupation>
}

export type UpsertOccupationVerificationInput = {
  document?: InputMaybe<Scalars['ID']['input']>
  occupationId: Scalars['ID']['input']
  verificationType?: InputMaybe<VerificationTypeEnum>
}

/** Autogenerated return type of UpsertOccupationVerification. */
export type UpsertOccupationVerificationPayload = {
  __typename: 'UpsertOccupationVerificationPayload'
  errors: Maybe<Array<Validation>>
  occupationVerification: Maybe<OccupationVerification>
}

export type UpsertRentalRequirementInput = {
  approvedCreditCheck?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  rentMultiplier?: InputMaybe<Scalars['Float']['input']>
  studentVerification?: InputMaybe<Scalars['Boolean']['input']>
  verifiedIdNumber?: InputMaybe<Scalars['Boolean']['input']>
  verifiedIncome?: InputMaybe<Scalars['Boolean']['input']>
}

/** Autogenerated return type of UpsertRentalRequirement. */
export type UpsertRentalRequirementPayload = {
  __typename: 'UpsertRentalRequirementPayload'
  errors: Maybe<Array<Validation>>
  rentalRequirement: Maybe<RentalRequirement>
}

export type UpsertStudentVerificationInput = {
  educationType?: InputMaybe<StudentVerificationEducationTypeEnum>
  expectedFinishDate?: InputMaybe<Scalars['DateTime']['input']>
  extent?: InputMaybe<StudentVerificationExtentEnum>
  program?: InputMaybe<Scalars['String']['input']>
  school?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
}

/** Autogenerated return type of UpsertStudentVerification. */
export type UpsertStudentVerificationPayload = {
  __typename: 'UpsertStudentVerificationPayload'
  errors: Maybe<Array<Validation>>
  studentVerification: Maybe<StudentVerification>
}

/** Autogenerated return type of UpsertTenantAd. */
export type UpsertTenantAdPayload = {
  __typename: 'UpsertTenantAdPayload'
  errors: Maybe<Array<Validation>>
  tenantAd: Maybe<TenantAd>
}

/** Autogenerated return type of UpsertViewingCalendar. */
export type UpsertViewingCalendarPayload = {
  __typename: 'UpsertViewingCalendarPayload'
  errors: Maybe<Array<Validation>>
  information: Maybe<Scalars['String']['output']>
  slots: Maybe<Array<ViewingSlot>>
}

export type User = {
  __typename: 'User'
  /** latest approved identifications of all types */
  activeIdentifications: Maybe<Array<Identification>>
  adminFields: Maybe<AdminFields>
  affiliateCode: Maybe<AffiliateCode>
  age: Maybe<Scalars['Int']['output']>
  applicationStats: UserApplicationStats
  bio: UserBio
  bookings: Array<Booking>
  certificate: Maybe<Certificate>
  companyName: Maybe<Scalars['String']['output']>
  contactLocation: Maybe<Location>
  /** conversation object for a specific home, if any */
  conversationForHome: Maybe<Conversation>
  createdAt: Scalars['DateTime']['output']
  creditReport: Maybe<CreditReport>
  /** latest approved or latest created */
  currentIdentification: Maybe<Identification>
  doNotContact: Maybe<Scalars['Boolean']['output']>
  doNotSms: Maybe<Scalars['Boolean']['output']>
  doNotVacationSms: Maybe<Scalars['Boolean']['output']>
  emailConfirmed: Scalars['Boolean']['output']
  employmentVerification: Maybe<EmploymentVerification>
  firstName: Maybe<Scalars['String']['output']>
  /** Home applications done by this user for homes where the current user is landlord */
  homeApplicationsToMyHomes: Array<HomeApplication>
  homes: Array<Home>
  householdMembersBirthYears: Maybe<Array<Scalars['Int']['output']>>
  housingSituation: Maybe<HousingSituation>
  /** @deprecated Use `currentIdentification` instead */
  idNumberConfirmed: Scalars['Boolean']['output']
  /** Use `currentIdentification` instead */
  identityConfirmed: Scalars['Boolean']['output']
  /** Use `currentIdentification` instead */
  identityConfirmedAt: Maybe<Scalars['DateTime']['output']>
  incomeVerifiedAt: Maybe<Scalars['DateTime']['output']>
  landlord: Scalars['Boolean']['output']
  landlordApplicationResponseRate: Maybe<Scalars['Float']['output']>
  landlordApplicationResponseTimeHours: Maybe<Scalars['Int']['output']>
  locale: Maybe<Scalars['String']['output']>
  mainPlatform: PlatformEnum
  matchingProfile: UserMatchingProfile
  movingReason: Maybe<Scalars['String']['output']>
  notificationSpan: Maybe<NotificationSpanEnum>
  nrFirsthandHousingUnits: Maybe<NrFirsthandHousingUnitsEnum>
  occupations: Maybe<Array<Occupation>>
  pep: Maybe<Scalars['Boolean']['output']>
  premium: Scalars['Boolean']['output']
  private: UserPrivate
  proAgent: Scalars['Boolean']['output']
  proPilot: Scalars['Boolean']['output']
  proUser: Scalars['Boolean']['output']
  professional: Scalars['Boolean']['output']
  profilePicture: Maybe<Upload>
  /** Deprecated, never used */
  prospectOnly: Scalars['Boolean']['output']
  publishedHomes: Array<Home>
  rca: Maybe<Scalars['Boolean']['output']>
  /** @deprecated Feature is deprecated */
  referenceCheck: Maybe<Scalars['String']['output']>
  removed: Scalars['Boolean']['output']
  rentingOutFirsthandHomes: Maybe<Scalars['Boolean']['output']>
  safeRental: Maybe<Scalars['Boolean']['output']>
  seenAt: Maybe<Scalars['DateTime']['output']>
  sendMonitoringEvents: Scalars['Boolean']['output']
  showAge: Maybe<Scalars['Boolean']['output']>
  smoker: Scalars['Boolean']['output']
  studentVerification: Maybe<StudentVerification>
  suspended: Scalars['Boolean']['output']
  tenant: Scalars['Boolean']['output']
  tenantAd: Maybe<TenantAd>
  tenantProfileCompleteness: TenantProfileCompleteness
  uid: Scalars['ID']['output']
  updatedAt: Scalars['DateTime']['output']
  /** Home application notes between user and user who sent the request */
  userNotes: Array<UserNote>
}

export type UserBookingsArgs = {
  homeId?: InputMaybe<Scalars['ID']['input']>
}

export type UserConversationForHomeArgs = {
  homeId: Scalars['ID']['input']
}

export type UserHomeApplicationsToMyHomesArgs = {
  homeIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UserAction = {
  __typename: 'UserAction'
  actionable: UserActionablesUnion
  completedAt: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  dismissedAt: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type UserActionablesUnion = StripeSepaTransferMoneyAction | StripeSetupAccountAction

export type UserApplicationStats = {
  __typename: 'UserApplicationStats'
  count: Maybe<Scalars['Int']['output']>
  inContactCount: Maybe<Scalars['Int']['output']>
  locales: Maybe<Array<Scalars['String']['output']>>
  priceMax: Maybe<Scalars['Int']['output']>
  priceMin: Maybe<Scalars['Int']['output']>
  roomMax: Maybe<Scalars['Int']['output']>
  roomMin: Maybe<Scalars['Int']['output']>
  uid: Scalars['ID']['output']
}

export type UserBio = {
  __typename: 'UserBio'
  intro: Maybe<Scalars['String']['output']>
  lived: Maybe<Scalars['String']['output']>
  pets: Maybe<Scalars['String']['output']>
  spareTime: Maybe<Scalars['String']['output']>
  study: Maybe<Scalars['String']['output']>
  title: Maybe<Scalars['String']['output']>
  uid: Scalars['ID']['output']
  where: Maybe<Scalars['String']['output']>
  who: Maybe<Scalars['String']['output']>
  work: Maybe<Scalars['String']['output']>
}

/** The connection type for User. */
export type UserConnection = {
  __typename: 'UserConnection'
  /** A list of edges. */
  edges: Array<UserEdge>
  /** A list of nodes. */
  nodes: Array<User>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

export type UserContracts = {
  __typename: 'UserContracts'
  activeContracts: ContractConnection
  draftContracts: ContractConnection
  pastContracts: ContractConnection
}

export type UserContractsActiveContractsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type UserContractsDraftContractsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type UserContractsPastContractsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** An edge in a connection. */
export type UserEdge = {
  __typename: 'UserEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: User
}

export type UserMatchingProfile = {
  __typename: 'UserMatchingProfile'
  commuteLocation: Maybe<Location>
  corporateHome: Maybe<Scalars['Boolean']['output']>
  firsthand: Maybe<Scalars['Boolean']['output']>
  furniture: Maybe<Scalars['Boolean']['output']>
  homeType: Maybe<Array<HomeTypeEnum>>
  householdSize: Maybe<Scalars['Int']['output']>
  matchingArea: Maybe<MatchingArea>
  matchingAreaMetadata: Maybe<Array<MatchingAreaMetadata>>
  maxMonthlyCost: Maybe<Scalars['Int']['output']>
  /** @deprecated Use `maxMonthlyCost` instead */
  maxRent: Maybe<Scalars['Int']['output']>
  maxRentalLength: Maybe<Scalars['Int']['output']>
  minRentalLength: Maybe<Scalars['Int']['output']>
  minRoomCount: Maybe<Scalars['Int']['output']>
  minSquareMeters: Maybe<Scalars['Int']['output']>
  moveInEarliest: Maybe<Scalars['DateTime']['output']>
  pets: Maybe<Scalars['Boolean']['output']>
  safeRental: Maybe<Scalars['Boolean']['output']>
  seniorHome: Maybe<Scalars['Boolean']['output']>
  sharedHomeOk: Maybe<Scalars['Boolean']['output']>
  studentHome: Maybe<Scalars['Boolean']['output']>
  uid: Scalars['ID']['output']
  wheelchairAccessible: Maybe<Scalars['Boolean']['output']>
}

export type UserNote = {
  __typename: 'UserNote'
  author: User
  createdAt: Scalars['DateTime']['output']
  homeApplication: Maybe<HomeApplication>
  id: Scalars['ID']['output']
  note: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export enum UserNoteRecordTypeEnum {
  home_application = 'home_application',
}

export type UserPrivate = {
  __typename: 'UserPrivate'
  activeBankAccount: Maybe<BankAccount>
  amplitudeId: Scalars['String']['output']
  bankAccounts: Array<BankAccount>
  biometricConsent: Scalars['Boolean']['output']
  biometricConsentAt: Maybe<Scalars['DateTime']['output']>
  birthDate: Maybe<Scalars['Date']['output']>
  contactLocation: Maybe<Location>
  email: Scalars['String']['output']
  familyName: Maybe<Scalars['String']['output']>
  /** @deprecated DEPRECATED: Use finance_invoices instead */
  financeDepositInvoices: Array<FinanceDepositInvoice>
  financeInvoices: Array<InvoiceInterface>
  financePayouts: Array<FinancePayoutType>
  /** @deprecated DEPRECATED: Use finance_invoices instead */
  financeRentInvoices: Array<FinanceMonthlyRentInvoice>
  gender: Maybe<GenderEnum>
  homeqUid: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** Masked id number */
  idNumber: Maybe<Scalars['String']['output']>
  /** Use `currentIdentification` instead */
  identificationStatus: Maybe<IdentificationStatusEnum>
  incomeVerification: Maybe<IncomeVerification>
  invoiceAdsFrom: Maybe<Scalars['DateTime']['output']>
  isOnfidoReverificationAvailable: Scalars['Boolean']['output']
  orgNumber: Maybe<Scalars['String']['output']>
  organisationNumberCountryCode: Maybe<CountryCodeEnum>
  organizations: Array<Organization>
  passportUpload: Maybe<Upload>
  phoneNumber: Maybe<Scalars['String']['output']>
  requiredUserActions: Array<UserAction>
  savingsVerification: Maybe<SavingsVerification>
  schibstedAccountId: Maybe<Scalars['String']['output']>
  selfieUpload: Maybe<Upload>
  smoochParams: Maybe<SmoochParams>
  statedBirthDate: Maybe<Scalars['DateTime']['output']>
  stripeAccount: Maybe<AccountResource>
  subUser: Maybe<SubUser>
  taxReportMetadata: Maybe<TaxReportMetadataType>
  taxReports: Array<TaxReportType>
  termsAccepted: Scalars['Boolean']['output']
  termsAcceptedAt: Maybe<Scalars['DateTime']['output']>
  unreadConversationsCount: Scalars['Int']['output']
  /** Current user session has been verified with identification method (BankID, Onfido, etc.) */
  verifiedAccess: Scalars['Boolean']['output']
}

export type UserReport = {
  __typename: 'UserReport'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  message: Scalars['String']['output']
  reportType: UserReportTypeEnum
  reportedUser: User
  reportingUser: User
  updatedAt: Scalars['DateTime']['output']
}

/** The connection type for UserReport. */
export type UserReportConnection = {
  __typename: 'UserReportConnection'
  /** A list of edges. */
  edges: Array<UserReportEdge>
  /** A list of nodes. */
  nodes: Array<UserReport>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type UserReportEdge = {
  __typename: 'UserReportEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: UserReport
}

export enum UserReportTypeEnum {
  absent_during_viewing = 'absent_during_viewing',
  bad_behavior = 'bad_behavior',
  fraud = 'fraud',
  not_responding = 'not_responding',
  other = 'other',
}

export type UsersSearchInput = {
  email?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  proAgent?: InputMaybe<Scalars['Boolean']['input']>
  schibstedAccountId?: InputMaybe<Scalars['String']['input']>
  uid?: InputMaybe<Scalars['String']['input']>
}

export type Validation = {
  __typename: 'Validation'
  codes: Array<Scalars['String']['output']>
  codesWithMessage: Maybe<Array<CodesWithMessage>>
  field: Scalars['String']['output']
}

export type ValidationError = Error & {
  __typename: 'ValidationError'
  attributeErrors: Array<AttributeError>
  message: Scalars['String']['output']
}

export enum VerificationTypeEnum {
  employment = 'employment',
  student = 'student',
}

/** Autogenerated return type of VerifyBankAccount. */
export type VerifyBankAccountPayload = {
  __typename: 'VerifyBankAccountPayload'
  bankAccount: BankAccount
}

export type Viewing = {
  __typename: 'Viewing'
  bookingStatus: ViewingStatusEnum
  cronofyBookingId: Scalars['String']['output']
  endsAt: Scalars['DateTime']['output']
  homeApplication: HomeApplication
  id: Scalars['ID']['output']
  startsAt: Scalars['DateTime']['output']
}

export type ViewingBooking = {
  __typename: 'ViewingBooking'
  bookingId: Maybe<Scalars['ID']['output']>
  endTime: Scalars['DateTime']['output']
  startTime: Scalars['DateTime']['output']
}

export type ViewingCalendar = {
  __typename: 'ViewingCalendar'
  bookings: Maybe<Array<ViewingBooking>>
  cronofyId: Scalars['String']['output']
  cronofySub: Scalars['String']['output']
  home: Home
  id: Scalars['ID']['output']
  information: Maybe<Scalars['String']['output']>
  schedule: Maybe<Array<ViewingScheduleUnion>>
  slots: Maybe<Array<ViewingSlot>>
  viewings: Maybe<Array<Viewing>>
}

/** Union type for viewing Slots and Bookings */
export type ViewingScheduleUnion = ViewingBooking | ViewingSlot

export type ViewingSlot = {
  __typename: 'ViewingSlot'
  endTime: Scalars['DateTime']['output']
  slotId: Scalars['ID']['output']
  startTime: Scalars['DateTime']['output']
}

/** The connection type for ViewingSlot. */
export type ViewingSlotConnection = {
  __typename: 'ViewingSlotConnection'
  /** A list of edges. */
  edges: Array<ViewingSlotEdge>
  /** A list of nodes. */
  nodes: Array<ViewingSlot>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type ViewingSlotEdge = {
  __typename: 'ViewingSlotEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ViewingSlot
}

export type ViewingSlotInput = {
  endTime: Scalars['DateTime']['input']
  slotId: Scalars['ID']['input']
  startTime: Scalars['DateTime']['input']
}

export enum ViewingStatusEnum {
  cancelled = 'cancelled',
  confirmed = 'confirmed',
  pending = 'pending',
}

export type WithinDistanceFromInput = {
  dist: Scalars['Float']['input']
  lat: Scalars['Float']['input']
  lng: Scalars['Float']['input']
}

export type YearlyIncomeStatementsType = {
  __typename: 'YearlyIncomeStatementsType'
  amount: Scalars['Float']['output']
  year: Scalars['String']['output']
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename: 'QueryRoot'
  me: {
    __typename: 'User'
    mainPlatform: PlatformEnum
    emailConfirmed: boolean
    landlord: boolean
    tenant: boolean
    age: number | null
    firstName: string | null
    uid: string
    professional: boolean
    companyName: string | null
    premium: boolean
    currentIdentification: {
      __typename: 'Identification'
      identityConfirmed: boolean
      identityConfirmedAt: string | null
      id: string
      identificationType: IdentificationTypeEnum
    } | null
    profilePicture: { __typename: 'Upload'; url: string } | null
    activeIdentifications: Array<{
      __typename: 'Identification'
      id: string
      identificationType: IdentificationTypeEnum
      identityConfirmedAt: string | null
      identityConfirmed: boolean
    }> | null
    certificate: { __typename: 'Certificate'; id: string; status: CertificateStatusEnum } | null
    private: {
      __typename: 'UserPrivate'
      id: string
      familyName: string | null
      phoneNumber: string | null
      invoiceAdsFrom: string | null
      verifiedAccess: boolean
      homeqUid: string | null
      activeBankAccount: { __typename: 'BankAccount'; id: string } | null
    }
    tenantProfileCompleteness: {
      __typename: 'TenantProfileCompleteness'
      completenessScore: number
      tenantProfileFields: {
        __typename: 'TenantProfileFields'
        bioLived: { __typename: 'TenantProfileField'; completed: boolean }
        bio: { __typename: 'TenantProfileField'; completed: boolean }
        firstName: { __typename: 'TenantProfileField'; completed: boolean }
        idNumberConfirmed: { __typename: 'TenantProfileField'; completed: boolean }
        matchingProfile: { __typename: 'TenantProfileField'; completed: boolean }
        movingReason: { __typename: 'TenantProfileField'; completed: boolean }
        profilePicture: { __typename: 'TenantProfileField'; completed: boolean }
        bioPets: { __typename: 'TenantProfileField'; completed: boolean }
        bioTitle: { __typename: 'TenantProfileField'; completed: boolean }
        currentAgreementType: { __typename: 'TenantProfileField'; completed: boolean }
      }
    }
  } | null
}

export type UpdateExpoPushTokenMutationVariables = Exact<{
  uid: Scalars['ID']['input']
  token: Scalars['String']['input']
}>

export type UpdateExpoPushTokenMutation = {
  __typename: 'MutationRoot'
  updateUser: { __typename: 'UpdateUserPayload'; user: { __typename: 'User'; uid: string } | null } | null
}

export type HomeQueryQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type HomeQueryQuery = {
  __typename: 'QueryRoot'
  home: {
    __typename: 'Home'
    id: string
    origin: string | null
    canBePublished: boolean
    status: HomeStatusEnum
    matterportEmbedUrl: string | null
    description: string | null
    descriptionBuilding: string | null
    descriptionContract: string | null
    descriptionFeatures: string | null
    descriptionLayout: string | null
    descriptionTransportation: string | null
    housingAssociation: string | null
    energyClass: string | null
    firsthand: boolean
    seniorHome: boolean
    corporateHome: boolean
    studentHome: boolean
    applicationDeadline: string | null
    buildYear: number | null
    bathroomRenovationYear: number | null
    kitchenRenovationYear: number | null
    tenantBaseFee: number | null
    rent: number | null
    currency: CurrencyEnum
    minRent: number | null
    maxRent: number | null
    external: boolean
    externalInfoPage: string | null
    externalApplicationUrl: string | null
    externalEmail: string | null
    publishedAt: string | null
    adPaidAt: string | null
    numberOfHomes: number | null
    rentalType: HomeRentalTypeEnum
    tenantCount: number | null
    minTenantCount: number | null
    maxTenantCount: number | null
    openForSigning: boolean
    title: string | null
    landlordUnitId: string | null
    applicationSortingType: ApplicationSortingTypeEnum | null
    applicationSelectionType: ApplicationSelectionTypeEnum | null
    displayStreetNumber: boolean
    shared: boolean | null
    type: HomeTypeEnum
    roomCount: number | null
    squareMeters: number | null
    bedroomCount: number | null
    bedCount: number | null
    toiletCount: number | null
    hasKitchen: boolean
    favoriteMarkedByUser: boolean
    minRoomCount: number | null
    maxRoomCount: number | null
    minSquareMeters: number | null
    maxSquareMeters: number | null
    floor: number | null
    buildingFloors: number | null
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      proAgent: boolean
      professional: boolean
      premium: boolean
      companyName: string | null
      proPilot: boolean
      currentIdentification: {
        __typename: 'Identification'
        identityConfirmed: boolean
        identityConfirmedAt: string | null
      } | null
      bio: { __typename: 'UserBio'; intro: string | null }
      profilePicture: { __typename: 'Upload'; url: string } | null
    }
    location: {
      __typename: 'Location'
      streetNumber: string | null
      id: string
      route: string | null
      locality: string | null
      latitude: number | null
      longitude: number | null
    }
    duration: {
      __typename: 'Duration'
      id: string
      startAsap: boolean
      endOptimal: string | null
      endUfn: boolean
      startOptimal: string | null
      possibilityOfExtension: boolean
      durationPreference: { __typename: 'DurationPreference'; startLatest: string | null } | null
    }
    uploads: Array<{
      __typename: 'Upload'
      id: string
      url: string
      type: UploadTypeEnum
      metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
    }>
    tenantFees: {
      __typename: 'HomeTenantFees'
      base: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; factor: number | null; total: number }
      }
      waterFeePerTenant: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; isFree: boolean; fixed: number | null }
      }
      monthlyElectricityFee: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
      } | null
    }
    electricityFee: {
      __typename: 'ElectricityFeeType'
      paymentPlan: ElectricityFeePaymentOptionEnum
      monthlyFee: number | null
    } | null
    market: { __typename: 'Market'; id: string; name: MarketNameEnum }
    traits: Array<{ __typename: 'Trait'; id: string; type: TraitTypeEnum }>
    shortcut: { __typename: 'Shortcut'; id: string; homePitch: string | null } | null
    instantSignSetting: {
      __typename: 'InstantSignSetting'
      id: string
      enabled: boolean
      landlordHoursToSign: number
    } | null
    landlord: {
      __typename: 'User'
      uid: string
      firstName: string | null
      profilePicture: { __typename: 'Upload'; url: string } | null
    }
    landlordFees: {
      __typename: 'HomeLandlordFees'
      base: { __typename: 'Fee'; cost: { __typename: 'Cost'; total: number } }
      listingPublicationFee: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
      }
    }
    pricingModel: { __typename: 'PricingModel'; tenantBaseFeeFactor: number }
    homeTemplates: Array<{
      __typename: 'HomeTemplate'
      id: string
      apartmentNumber: string | null
      rent: number | null
      squareMeters: number | null
      roomCount: number | null
      description: string | null
      floor: number | null
      type: HomeTypeEnum
      traits: Array<{ __typename: 'Trait'; id: string; type: TraitTypeEnum }>
      homeLayoutPictures: Array<{ __typename: 'Upload'; id: string; url: string }>
    }>
    rentalRequirement: {
      __typename: 'RentalRequirement'
      approvedCreditCheck: boolean | null
      rentMultiplier: number | null
      studentVerification: boolean | null
      verifiedIdNumber: boolean | null
      verifiedIncome: boolean | null
    } | null
  } | null
}

export type ActiveListingsIdsQueryQueryVariables = Exact<{
  market?: InputMaybe<MarketNameTypeEnum>
}>

export type ActiveListingsIdsQueryQuery = { __typename: 'QueryRoot'; homeIds: Array<string> }

export type SearchAreaHierarchyQueryVariables = Exact<{
  countryCodes: Array<CountryCodeEnum> | CountryCodeEnum
  id?: InputMaybe<Scalars['ID']['input']>
  childrenMinimumViewCount?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchAreaHierarchyQuery = {
  __typename: 'QueryRoot'
  searchAreaHierarchy: {
    __typename: 'SearchArea'
    id: string
    name: string
    identifier: string
    city: string | null
    countryCode: string | null
    children: {
      __typename: 'SearchAreaConnection'
      nodes: Array<{ __typename: 'SearchArea'; id: string; name: string; identifier: string }>
    } | null
    parent: { __typename: 'SearchArea'; id: string; identifier: string; name: string } | null
  } | null
}

export type BankAccountFieldsFragment = {
  __typename: 'BankAccount'
  name: string | null
  active: boolean
  bankName: string | null
  bic: string | null
  clearingNumber: string | null
  countryCode: string | null
  email: string | null
  iban: string | null
  id: string
  number: string | null
  type: BankAccountTypeEnum
  verified: boolean
  bankLocation: {
    __typename: 'Location'
    id: string
    formattedAddress: string | null
    locality: string | null
    postalCode: string | null
    route: string | null
    streetNumber: string | null
  } | null
  location: {
    __typename: 'Location'
    id: string
    formattedAddress: string | null
    locality: string | null
    postalCode: string | null
    route: string | null
    streetNumber: string | null
  } | null
}

export type FinancePayoutsFragment = {
  __typename: 'Contract'
  financePayouts: Array<{
    __typename: 'FinancePayoutType'
    id: string
    paidAt: string | null
    reference: string
    currentState: PayoutStateEnum
    periodStart: string | null
    periodEnd: string | null
    receipt: { __typename: 'Blob'; id: string; url: string | null } | null
    amount: { __typename: 'Money'; humanized: string }
    charges: Array<{
      __typename: 'FinanceChargeType'
      id: string
      vatPercent: string
      productName: ProductEnum
      vat: { __typename: 'Money'; humanized: string; fractional: number }
      amount: { __typename: 'Money'; humanized: string }
    }>
  }>
}

export type FinanceRentInvoicesFragment = {
  __typename: 'Contract'
  financeRentInvoices: Array<{
    __typename: 'FinanceMonthlyRentInvoice'
    id: string
    reference: string
    dueDate: string
    periodStart: string
    periodEnd: string
    currentState: InvoiceStateEnum
    overdue: boolean
    receipt: { __typename: 'Blob'; id: string; url: string | null } | null
    upcomingPayout: {
      __typename: 'UpcomingPayoutType'
      dueDate: string
      periodEnd: string | null
      periodStart: string | null
      sumAmount: { __typename: 'Money'; humanized: string }
      charges: Array<{
        __typename: 'FinanceChargeType'
        id: string
        productName: ProductEnum
        vatPercent: string
        amount: { __typename: 'Money'; humanized: string }
        vat: { __typename: 'Money'; fractional: number; humanized: string }
      }>
    } | null
    items: Array<{
      __typename: 'FinanceItemType'
      id: string
      productName: ProductEnum
      vatPercent: string
      vat: { __typename: 'Money'; humanized: string; fractional: number }
      amount: { __typename: 'Money'; humanized: string }
    }>
    sumAmount: { __typename: 'Money'; currency: CurrencyEnum; humanized: string }
    paymentAction: {
      __typename: 'StripeSepaTransferMoneyAction'
      id: string
      reference: string
      iban: string
      bic: string
      amount: { __typename: 'Money'; humanized: string }
    } | null
  }>
}

export type FinanceGenericInvoiceFragment = {
  __typename: 'Contract'
  financeInvoices: Array<
    | { __typename: 'FinanceDepositInvoice' }
    | {
        __typename: 'FinanceGenericInvoice'
        id: string
        reference: string
        dueDate: string
        periodStart: string | null
        periodEnd: string | null
        currentState: InvoiceStateEnum
        overdue: boolean
        receipt: { __typename: 'Blob'; id: string; url: string | null } | null
        items: Array<{
          __typename: 'FinanceItemType'
          id: string
          productName: ProductEnum
          vatPercent: string
          vat: { __typename: 'Money'; humanized: string; fractional: number }
          amount: { __typename: 'Money'; humanized: string }
        }>
        sumAmount: { __typename: 'Money'; currency: CurrencyEnum; humanized: string }
        paymentAction: {
          __typename: 'StripeSepaTransferMoneyAction'
          id: string
          reference: string
          iban: string
          bic: string
          amount: { __typename: 'Money'; humanized: string }
        } | null
      }
    | { __typename: 'FinanceMonthlyRentInvoice' }
  >
}

export type FinanceDepositInvoicesFragment = {
  __typename: 'Contract'
  financeDepositInvoices: Array<{
    __typename: 'FinanceDepositInvoice'
    id: string
    dueDate: string
    reference: string
    currentState: InvoiceStateEnum
    overdue: boolean
    receipt: { __typename: 'Blob'; id: string; url: string | null } | null
    items: Array<{
      __typename: 'FinanceItemType'
      id: string
      productName: ProductEnum
      vatPercent: string
      vat: { __typename: 'Money'; humanized: string; fractional: number }
      amount: { __typename: 'Money'; humanized: string }
    }>
    sumAmount: { __typename: 'Money'; currency: CurrencyEnum; humanized: string }
    paymentAction: {
      __typename: 'StripeSepaTransferMoneyAction'
      id: string
      reference: string
      iban: string
      bic: string
      amount: { __typename: 'Money'; humanized: string }
    } | null
  }>
}

export type BaseContractFragment = {
  __typename: 'Contract'
  id: string
  type: ContractTypeEnum
  terminationNotice: boolean | null
  tenureType: TenureTypeEnum | null
  fee: number | null
  qasaGuarantee: boolean | null
  qasaGuaranteeCost: number | null
  insuranceCost: number | null
  acceptsCosignatures: boolean | null
  signedAt: string | null
  signUrl: string | null
  status: ContractStatusEnum
  landlordHasKeys: boolean | null
  insuranceResponsibility: InsuranceResponsibilityEnum | null
  rent: number | null
  inventory: Array<string>
  payoutDay: number
  paymentDay: number
  canUse: Array<string>
  rentIncludes: Array<string>
  rentExcludes: Array<string>
  subletception: boolean | null
  renovations: boolean | null
  preCleaning: ContractPreCleaningEnum | null
  currency: CurrencyEnum
  postCleaning: ContractPostCleaningEnum | null
  temporaryInstallations: boolean | null
  smallRepairs: ContractSmallRepairsEnum | null
  pets: boolean | null
  smoker: boolean | null
  ownHome: boolean | null
  furniture: boolean | null
  overnightStay: boolean | null
  signingProcess: SigningProcessEnum
  market: { __typename: 'Market'; id: string; name: MarketNameEnum }
  externalContractTakeover: {
    __typename: 'ExternalContractTakeover'
    currentState: ExternalContractTakeoverStateEnum
  } | null
  currentUserSignatory: { __typename: 'Signatory'; id: string; signedAt: string | null; canSign: boolean }
  user: { __typename: 'User'; uid: string; proAgent: boolean }
  signatories: Array<{
    __typename: 'Signatory'
    id: string
    role: SignatoryRoleEnum
    signedAt: string | null
    order: number | null
    signingDeadline: string | null
    canSign: boolean
    user: { __typename: 'User'; uid: string }
  }>
  tenant: {
    __typename: 'User'
    uid: string
    firstName: string | null
    companyName: string | null
    professional: boolean
    profilePicture: { __typename: 'Upload'; url: string } | null
    currentIdentification: {
      __typename: 'Identification'
      identityConfirmed: boolean
      identityConfirmedAt: string | null
    } | null
  } | null
  landlord: {
    __typename: 'User'
    uid: string
    firstName: string | null
    safeRental: boolean | null
    companyName: string | null
    professional: boolean
    profilePicture: { __typename: 'Upload'; url: string } | null
  }
  rentalInsuranceMilaRequest: {
    __typename: 'FranceInsuranceRequestType'
    id: string
    currentState: FranceInsuranceRequestStateEnum | null
    documentRequests: Array<{ __typename: 'FranceDocumentRequestType'; id: string }> | null
  } | null
  cosigningTenants: Array<{
    __typename: 'User'
    uid: string
    firstName: string | null
    companyName: string | null
    professional: boolean
    profilePicture: { __typename: 'Upload'; url: string } | null
  }> | null
  tenantInvitations: Array<{
    __typename: 'ContractTenantInvitation'
    claimKey: string | null
    claimable: boolean
    invitedBy: {
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
    } | null
  }> | null
  duration: {
    __typename: 'Duration'
    id: string
    endOptimal: string | null
    endUfn: boolean
    startAsap: boolean
    startOptimal: string | null
    updatedAt: string | null
  } | null
  home: {
    __typename: 'Home'
    ownHome: boolean | null
    tenureType: TenureTypeEnum | null
    id: string
    currency: CurrencyEnum
    qasaGuaranteeCost: number | null
    insuranceCost: number | null
    qasaGuarantee: boolean | null
    insurance: HomeInsuranceEnum | null
    rent: number | null
    rentalType: HomeRentalTypeEnum
    roomCount: number | null
    squareMeters: number | null
    bedCount: number | null
    bedroomCount: number | null
    tenantCount: number | null
    status: HomeStatusEnum
    title: string | null
    type: HomeTypeEnum
    safeRental: boolean | null
    apartmentNumber: string | null
    tenantBaseFee: number | null
    inspection: {
      __typename: 'Inspection'
      id: string
      observations: Array<{
        __typename: 'InspectionObservation'
        description: string | null
        section: InspectionRoomsEnum
      }> | null
    } | null
    market: { __typename: 'Market'; id: string; name: MarketNameEnum; currency: MarketCurrencyEnum }
    tenantFees: {
      __typename: 'HomeTenantFees'
      waterFeePerTenant: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; fixed: number | null }
      }
      monthlyElectricityFee: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
      } | null
    }
    instantSignSetting: { __typename: 'InstantSignSetting'; landlordHoursToSign: number } | null
    duration: {
      __typename: 'Duration'
      id: string
      startOptimal: string | null
      startAsap: boolean
      endUfn: boolean
      endOptimal: string | null
      updatedAt: string | null
    }
    landlord: {
      __typename: 'User'
      uid: string
      professional: boolean
      companyName: string | null
      firstName: string | null
    }
    location: {
      __typename: 'Location'
      latitude: number | null
      locality: string | null
      longitude: number | null
      postalCode: string | null
      route: string | null
      streetNumber: string | null
      googleMapsPlaceId: string
      countryCode: string
      id: string
    }
    uploads: Array<{
      __typename: 'Upload'
      id: string
      url: string
      metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
    }>
    pricingModel: { __typename: 'PricingModel'; insuranceCostFactor: number; qasaGuaranteeCostFactor: number }
    electricityFee: {
      __typename: 'ElectricityFeeType'
      paymentPlan: ElectricityFeePaymentOptionEnum
      monthlyFee: number | null
    } | null
    landlordServices: {
      __typename: 'HomeLandlordServices'
      insurance: {
        __typename: 'Service'
        availability: ServiceAvailabilityEnum
        isActive: boolean
        cost: { __typename: 'Cost'; factor: number | null; isFree: boolean; total: number }
      }
      qasaGuarantee: {
        __typename: 'Service'
        availability: ServiceAvailabilityEnum
        isActive: boolean
        cost: { __typename: 'Cost'; isFree: boolean; factor: number | null; total: number }
      }
    }
  } | null
  upload: { __typename: 'Upload'; url: string } | null
  contractDetails: {
    __typename: 'ContractDetails'
    tenantFirstName: string | null
    tenantFamilyName: string | null
    tenantEmail: string | null
    tenantPhoneNumber: string | null
    legalType: LegalTypeEnum | null
    bindingTime: number | null
    canBeTerminated: boolean | null
    terminationNoticeMonths: number | null
    shorterTerminationExplanation: string | null
    knownDamages: string | null
    keySpecification: string | null
    codeOfConduct: string | null
    additionalInformation: string | null
    cadastralReference: string | null
    sectionNumber: string | null
    municipality: string | null
    titleNumber: string | null
    bathroomShared: boolean | null
    kitchenShared: boolean | null
    rentedAsIs: boolean | null
  } | null
  extraCosts: Array<{ __typename: 'ExtraCost'; costFor: ExtraCostForEnum; plan: ExtraCostPlanEnum }> | null
  tenantServices: {
    __typename: 'ContractTenantServices'
    depositFree: {
      __typename: 'Service'
      availability: ServiceAvailabilityEnum
      isActive: boolean
      cost: { __typename: 'Cost'; isFree: boolean; factor: number | null; total: number }
    }
  }
  landlordServices: {
    __typename: 'ContractLandlordServices'
    insurance: {
      __typename: 'Service'
      availability: ServiceAvailabilityEnum
      isActive: boolean
      cost: { __typename: 'Cost'; factor: number | null; isFree: boolean; total: number }
    }
    qasaGuarantee: {
      __typename: 'Service'
      availability: ServiceAvailabilityEnum
      isActive: boolean
      cost: { __typename: 'Cost'; isFree: boolean; factor: number | null; total: number }
    }
  }
  ifInsurancePromotion: { __typename: 'IfInsurancePromotion'; signedUp: boolean | null } | null
}

export type BaseHomeApplicationFragment = {
  __typename: 'HomeApplication'
  id: string
  message: string | null
  createdAt: string
  endOptimal: string | null
  endUfn: boolean
  matchInterest: string | null
  startOptimal: string | null
  status: HomeApplicationStatusEnum
  origin: HomeApplicationOriginEnum
  home: {
    __typename: 'Home'
    id: string
    currency: CurrencyEnum
    qasaGuaranteeCost: number | null
    insuranceCost: number | null
    qasaGuarantee: boolean | null
    insurance: HomeInsuranceEnum | null
    rent: number | null
    rentalType: HomeRentalTypeEnum
    roomCount: number | null
    squareMeters: number | null
    bedCount: number | null
    bedroomCount: number | null
    tenantCount: number | null
    status: HomeStatusEnum
    title: string | null
    type: HomeTypeEnum
    safeRental: boolean | null
    apartmentNumber: string | null
    tenantBaseFee: number | null
    duration: {
      __typename: 'Duration'
      id: string
      startOptimal: string | null
      startAsap: boolean
      endUfn: boolean
      endOptimal: string | null
      updatedAt: string | null
    }
    landlord: {
      __typename: 'User'
      uid: string
      professional: boolean
      companyName: string | null
      firstName: string | null
    }
    location: {
      __typename: 'Location'
      latitude: number | null
      locality: string | null
      longitude: number | null
      postalCode: string | null
      route: string | null
      streetNumber: string | null
      googleMapsPlaceId: string
      countryCode: string
      id: string
    }
    uploads: Array<{
      __typename: 'Upload'
      id: string
      url: string
      metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
    }>
    pricingModel: { __typename: 'PricingModel'; insuranceCostFactor: number; qasaGuaranteeCostFactor: number }
    electricityFee: {
      __typename: 'ElectricityFeeType'
      paymentPlan: ElectricityFeePaymentOptionEnum
      monthlyFee: number | null
    } | null
    landlordServices: {
      __typename: 'HomeLandlordServices'
      insurance: {
        __typename: 'Service'
        availability: ServiceAvailabilityEnum
        isActive: boolean
        cost: { __typename: 'Cost'; factor: number | null; isFree: boolean; total: number }
      }
      qasaGuarantee: {
        __typename: 'Service'
        availability: ServiceAvailabilityEnum
        isActive: boolean
        cost: { __typename: 'Cost'; isFree: boolean; factor: number | null; total: number }
      }
    }
  }
  tenant: {
    __typename: 'User'
    createdAt: string
    firstName: string | null
    age: number | null
    incomeVerifiedAt: string | null
    emailConfirmed: boolean
    landlord: boolean
    professional: boolean
    companyName: string | null
    premium: boolean
    safeRental: boolean | null
    seenAt: string | null
    tenant: boolean
    notificationSpan: NotificationSpanEnum | null
    sendMonitoringEvents: boolean
    doNotContact: boolean | null
    doNotSms: boolean | null
    doNotVacationSms: boolean | null
    showAge: boolean | null
    movingReason: string | null
    landlordApplicationResponseRate: number | null
    landlordApplicationResponseTimeHours: number | null
    nrFirsthandHousingUnits: NrFirsthandHousingUnitsEnum | null
    rentingOutFirsthandHomes: boolean | null
    uid: string
    certificate: {
      __typename: 'Certificate'
      id: string
      status: CertificateStatusEnum
      expiresAt: string | null
    } | null
    currentIdentification: {
      __typename: 'Identification'
      identityConfirmed: boolean
      identityConfirmedAt: string | null
    } | null
    activeIdentifications: Array<{
      __typename: 'Identification'
      id: string
      identityConfirmed: boolean
      identityConfirmedAt: string | null
    }> | null
    employmentVerification: { __typename: 'EmploymentVerification'; id: string } | null
    studentVerification: { __typename: 'StudentVerification'; id: string } | null
    profilePicture: { __typename: 'Upload'; id: string; url: string } | null
    housingSituation: {
      __typename: 'HousingSituation'
      id: string
      agreementType: HousingSituationAgreementTypeEnum | null
      landlordCity: string | null
      hasReference: boolean
    } | null
  }
  conversation: { __typename: 'Conversation'; id: string } | null
}

export type BaseHomeFragment = {
  __typename: 'Home'
  id: string
  currency: CurrencyEnum
  qasaGuaranteeCost: number | null
  insuranceCost: number | null
  qasaGuarantee: boolean | null
  insurance: HomeInsuranceEnum | null
  rent: number | null
  rentalType: HomeRentalTypeEnum
  roomCount: number | null
  squareMeters: number | null
  bedCount: number | null
  bedroomCount: number | null
  tenantCount: number | null
  status: HomeStatusEnum
  title: string | null
  type: HomeTypeEnum
  safeRental: boolean | null
  apartmentNumber: string | null
  tenantBaseFee: number | null
  duration: {
    __typename: 'Duration'
    id: string
    startOptimal: string | null
    startAsap: boolean
    endUfn: boolean
    endOptimal: string | null
    updatedAt: string | null
  }
  landlord: {
    __typename: 'User'
    uid: string
    professional: boolean
    companyName: string | null
    firstName: string | null
  }
  location: {
    __typename: 'Location'
    latitude: number | null
    locality: string | null
    longitude: number | null
    postalCode: string | null
    route: string | null
    streetNumber: string | null
    googleMapsPlaceId: string
    countryCode: string
    id: string
  }
  uploads: Array<{
    __typename: 'Upload'
    id: string
    url: string
    metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
  }>
  pricingModel: { __typename: 'PricingModel'; insuranceCostFactor: number; qasaGuaranteeCostFactor: number }
  electricityFee: {
    __typename: 'ElectricityFeeType'
    paymentPlan: ElectricityFeePaymentOptionEnum
    monthlyFee: number | null
  } | null
  landlordServices: {
    __typename: 'HomeLandlordServices'
    insurance: {
      __typename: 'Service'
      availability: ServiceAvailabilityEnum
      isActive: boolean
      cost: { __typename: 'Cost'; factor: number | null; isFree: boolean; total: number }
    }
    qasaGuarantee: {
      __typename: 'Service'
      availability: ServiceAvailabilityEnum
      isActive: boolean
      cost: { __typename: 'Cost'; isFree: boolean; factor: number | null; total: number }
    }
  }
}

export type UserBioFragment = {
  __typename: 'User'
  bio: {
    __typename: 'UserBio'
    title: string | null
    intro: string | null
    lived: string | null
    pets: string | null
  }
}

export type UserPrivateFieldsFragment = {
  __typename: 'User'
  currentIdentification: {
    __typename: 'Identification'
    id: string
    identityConfirmed: boolean
    identityConfirmedAt: string | null
    identificationType: IdentificationTypeEnum
  } | null
  activeIdentifications: Array<{
    __typename: 'Identification'
    id: string
    identificationType: IdentificationTypeEnum
  }> | null
  housingSituation: {
    __typename: 'HousingSituation'
    id: string
    landlordPhoneNumber: string | null
    landlordName: string | null
  } | null
  private: {
    __typename: 'UserPrivate'
    id: string
    isOnfidoReverificationAvailable: boolean
    homeqUid: string | null
    schibstedAccountId: string | null
    phoneNumber: string | null
    verifiedAccess: boolean
    email: string
    birthDate: string | null
    familyName: string | null
    orgNumber: string | null
    organisationNumberCountryCode: CountryCodeEnum | null
    amplitudeId: string
    invoiceAdsFrom: string | null
    activeBankAccount: {
      __typename: 'BankAccount'
      name: string | null
      active: boolean
      bankName: string | null
      bic: string | null
      clearingNumber: string | null
      countryCode: string | null
      email: string | null
      iban: string | null
      id: string
      number: string | null
      type: BankAccountTypeEnum
      verified: boolean
      bankLocation: {
        __typename: 'Location'
        id: string
        formattedAddress: string | null
        locality: string | null
        postalCode: string | null
        route: string | null
        streetNumber: string | null
      } | null
      location: {
        __typename: 'Location'
        id: string
        formattedAddress: string | null
        locality: string | null
        postalCode: string | null
        route: string | null
        streetNumber: string | null
      } | null
    } | null
    bankAccounts: Array<{
      __typename: 'BankAccount'
      name: string | null
      active: boolean
      bankName: string | null
      bic: string | null
      clearingNumber: string | null
      countryCode: string | null
      email: string | null
      iban: string | null
      id: string
      number: string | null
      type: BankAccountTypeEnum
      verified: boolean
      bankLocation: {
        __typename: 'Location'
        id: string
        formattedAddress: string | null
        locality: string | null
        postalCode: string | null
        route: string | null
        streetNumber: string | null
      } | null
      location: {
        __typename: 'Location'
        id: string
        formattedAddress: string | null
        locality: string | null
        postalCode: string | null
        route: string | null
        streetNumber: string | null
      } | null
    }>
    stripeAccount: {
      __typename: 'AccountResource'
      id: string
      currentState: AccountResourceStateEnum
    } | null
    contactLocation: {
      __typename: 'Location'
      id: string
      route: string | null
      streetNumber: string | null
      formattedAddress: string | null
      postalCode: string | null
      locality: string | null
      country: string | null
      countryCode: string
    } | null
    smoochParams: { __typename: 'SmoochParams'; smoochJwt: string | null } | null
  }
}

export type UserProfileCompletenessFragment = {
  __typename: 'User'
  tenantProfileCompleteness: {
    __typename: 'TenantProfileCompleteness'
    completenessScore: number
    tenantProfileFields: {
      __typename: 'TenantProfileFields'
      bioLived: { __typename: 'TenantProfileField'; completed: boolean }
      bio: { __typename: 'TenantProfileField'; completed: boolean }
      firstName: { __typename: 'TenantProfileField'; completed: boolean }
      idNumberConfirmed: { __typename: 'TenantProfileField'; completed: boolean }
      matchingProfile: { __typename: 'TenantProfileField'; completed: boolean }
      movingReason: { __typename: 'TenantProfileField'; completed: boolean }
      profilePicture: { __typename: 'TenantProfileField'; completed: boolean }
      bioPets: { __typename: 'TenantProfileField'; completed: boolean }
      bioTitle: { __typename: 'TenantProfileField'; completed: boolean }
      currentAgreementType: { __typename: 'TenantProfileField'; completed: boolean }
    }
  }
}

export type BaseUserFragment = {
  __typename: 'User'
  createdAt: string
  firstName: string | null
  age: number | null
  incomeVerifiedAt: string | null
  emailConfirmed: boolean
  landlord: boolean
  professional: boolean
  companyName: string | null
  premium: boolean
  safeRental: boolean | null
  seenAt: string | null
  tenant: boolean
  notificationSpan: NotificationSpanEnum | null
  sendMonitoringEvents: boolean
  doNotContact: boolean | null
  doNotSms: boolean | null
  doNotVacationSms: boolean | null
  showAge: boolean | null
  movingReason: string | null
  landlordApplicationResponseRate: number | null
  landlordApplicationResponseTimeHours: number | null
  nrFirsthandHousingUnits: NrFirsthandHousingUnitsEnum | null
  rentingOutFirsthandHomes: boolean | null
  uid: string
  certificate: {
    __typename: 'Certificate'
    id: string
    status: CertificateStatusEnum
    expiresAt: string | null
  } | null
  currentIdentification: {
    __typename: 'Identification'
    identityConfirmed: boolean
    identityConfirmedAt: string | null
  } | null
  activeIdentifications: Array<{
    __typename: 'Identification'
    id: string
    identityConfirmed: boolean
    identityConfirmedAt: string | null
  }> | null
  employmentVerification: { __typename: 'EmploymentVerification'; id: string } | null
  studentVerification: { __typename: 'StudentVerification'; id: string } | null
  profilePicture: { __typename: 'Upload'; id: string; url: string } | null
  housingSituation: {
    __typename: 'HousingSituation'
    id: string
    agreementType: HousingSituationAgreementTypeEnum | null
    landlordCity: string | null
    hasReference: boolean
  } | null
}

export type CancelContractSigningMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CancelContractSigningMutation = {
  __typename: 'MutationRoot'
  cancelContractSigning: {
    __typename: 'CancelContractSigningPayload'
    contract: { __typename: 'Contract'; id: string; status: ContractStatusEnum } | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type CreateHomeMutationMutationVariables = Exact<{
  input: CreateHomeInput
}>

export type CreateHomeMutationMutation = {
  __typename: 'MutationRoot'
  createHome: {
    __typename: 'CreateHomePayload'
    home: {
      __typename: 'Home'
      id: string
      status: HomeStatusEnum
      rentalType: HomeRentalTypeEnum
      pricingModel: {
        __typename: 'PricingModel'
        insuranceCostFactor: number
        qasaGuaranteeCostFactor: number
      }
    } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type CreateUploadMutationMutationVariables = Exact<{
  input: CreateUploadInput
}>

export type CreateUploadMutationMutation = {
  __typename: 'MutationRoot'
  createUpload: {
    __typename: 'CreateUploadPayload'
    upload: { __typename: 'CreateUpload'; action: string; fields: Record<string, unknown>; id: string } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type DestroyUploadMutationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DestroyUploadMutationMutation = {
  __typename: 'MutationRoot'
  destroyUpload: { __typename: 'DestroyUploadPayload'; id: string } | null
}

export type DestroyUserMutationVariables = Exact<{
  uid: Scalars['ID']['input']
}>

export type DestroyUserMutation = {
  __typename: 'MutationRoot'
  destroyUser: {
    __typename: 'DestroyUserPayload'
    success: boolean
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type ExtendContractMutationMutationVariables = Exact<{
  id: Scalars['ID']['input']
  endOptimal?: InputMaybe<Scalars['DateTime']['input']>
  rent?: InputMaybe<Scalars['Int']['input']>
}>

export type ExtendContractMutationMutation = {
  __typename: 'MutationRoot'
  extendContract: {
    __typename: 'ExtendContractPayload'
    contract: {
      __typename: 'Contract'
      status: ContractStatusEnum
      rent: number | null
      id: string
      duration: { __typename: 'Duration'; endOptimal: string | null; endUfn: boolean; id: string } | null
      extensionContract: { __typename: 'Contract'; id: string } | null
    } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type FinalizeUploadMutationMutationVariables = Exact<{
  etag: Scalars['String']['input']
  id: Scalars['ID']['input']
}>

export type FinalizeUploadMutationMutation = {
  __typename: 'MutationRoot'
  finalizeUpload: {
    __typename: 'FinalizeUploadPayload'
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
    upload: {
      __typename: 'Upload'
      fileType: UploadFileTypeEnum
      id: string
      title: string
      type: UploadTypeEnum
      url: string
      metadata: {
        __typename: 'UploadMetadata'
        order: number | null
        primary: boolean | null
        rotation: number | null
      } | null
    } | null
  } | null
}

export type IdentifyMutationVariables = Exact<{
  input: IdentifyInput
}>

export type IdentifyMutation = {
  __typename: 'MutationRoot'
  identify: {
    __typename: 'IdentifyPayload'
    accessUrl: string | null
    success: boolean
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type InitiateContractSignMutationMutationVariables = Exact<{
  id: Scalars['ID']['input']
  landlordInvitationMethod?: InputMaybe<ScriveInvitationOptionEnum>
  tenantInvitationMethod?: InputMaybe<ScriveInvitationOptionEnum>
  sendInitialInvitation?: InputMaybe<Scalars['Boolean']['input']>
}>

export type InitiateContractSignMutationMutation = {
  __typename: 'MutationRoot'
  initiateContractSigning: {
    __typename: 'InitiateContractSigningPayload'
    contract: { __typename: 'Contract'; id: string; signUrl: string | null } | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type ReportUserMutationVariables = Exact<{
  uid: Scalars['ID']['input']
  reason: Scalars['String']['input']
  reportType?: InputMaybe<ReportTypeEnum>
}>

export type ReportUserMutation = {
  __typename: 'MutationRoot'
  reportUser: {
    __typename: 'ReportUserPayload'
    userReport: boolean | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type SchibstedLoginMutationVariables = Exact<{
  input: SchibstedLoginInput
}>

export type SchibstedLoginMutation = {
  __typename: 'MutationRoot'
  schibstedLogin: {
    __typename: 'SchibstedLoginPayload'
    payload: {
      __typename: 'LoginPayload'
      accessToken: string
      user: {
        __typename: 'User'
        smoker: boolean
        createdAt: string
        firstName: string | null
        age: number | null
        incomeVerifiedAt: string | null
        emailConfirmed: boolean
        landlord: boolean
        professional: boolean
        companyName: string | null
        premium: boolean
        safeRental: boolean | null
        seenAt: string | null
        tenant: boolean
        notificationSpan: NotificationSpanEnum | null
        sendMonitoringEvents: boolean
        doNotContact: boolean | null
        doNotSms: boolean | null
        doNotVacationSms: boolean | null
        showAge: boolean | null
        movingReason: string | null
        landlordApplicationResponseRate: number | null
        landlordApplicationResponseTimeHours: number | null
        nrFirsthandHousingUnits: NrFirsthandHousingUnitsEnum | null
        rentingOutFirsthandHomes: boolean | null
        uid: string
        affiliateCode: {
          __typename: 'AffiliateCode'
          code: string
          discountFor: AffiliateCodeDiscountForEnum
          discountType: AffiliateCodeDiscountTypeEnum
          discountValue: number
          expirationDate: string
          affiliateCodeType: AffiliateCodeTypeEnum
        } | null
        creditReport: {
          __typename: 'CreditReport'
          id: string
          fetchStatus: CreditReportFetchStatusEnum
        } | null
        applicationStats: {
          __typename: 'UserApplicationStats'
          uid: string
          count: number | null
          inContactCount: number | null
          locales: Array<string> | null
          priceMax: number | null
          priceMin: number | null
          roomMax: number | null
          roomMin: number | null
        }
        certificate: {
          __typename: 'Certificate'
          id: string
          status: CertificateStatusEnum
          expiresAt: string | null
        } | null
        currentIdentification: {
          __typename: 'Identification'
          identityConfirmed: boolean
          identityConfirmedAt: string | null
          id: string
          identificationType: IdentificationTypeEnum
        } | null
        activeIdentifications: Array<{
          __typename: 'Identification'
          identityConfirmed: boolean
          identityConfirmedAt: string | null
          id: string
          identificationType: IdentificationTypeEnum
        }> | null
        employmentVerification: { __typename: 'EmploymentVerification'; id: string } | null
        studentVerification: { __typename: 'StudentVerification'; id: string } | null
        profilePicture: { __typename: 'Upload'; id: string; url: string } | null
        housingSituation: {
          __typename: 'HousingSituation'
          id: string
          agreementType: HousingSituationAgreementTypeEnum | null
          landlordCity: string | null
          hasReference: boolean
          landlordPhoneNumber: string | null
          landlordName: string | null
        } | null
        private: {
          __typename: 'UserPrivate'
          id: string
          isOnfidoReverificationAvailable: boolean
          homeqUid: string | null
          schibstedAccountId: string | null
          phoneNumber: string | null
          verifiedAccess: boolean
          email: string
          birthDate: string | null
          familyName: string | null
          orgNumber: string | null
          organisationNumberCountryCode: CountryCodeEnum | null
          amplitudeId: string
          invoiceAdsFrom: string | null
          activeBankAccount: {
            __typename: 'BankAccount'
            name: string | null
            active: boolean
            bankName: string | null
            bic: string | null
            clearingNumber: string | null
            countryCode: string | null
            email: string | null
            iban: string | null
            id: string
            number: string | null
            type: BankAccountTypeEnum
            verified: boolean
            bankLocation: {
              __typename: 'Location'
              id: string
              formattedAddress: string | null
              locality: string | null
              postalCode: string | null
              route: string | null
              streetNumber: string | null
            } | null
            location: {
              __typename: 'Location'
              id: string
              formattedAddress: string | null
              locality: string | null
              postalCode: string | null
              route: string | null
              streetNumber: string | null
            } | null
          } | null
          bankAccounts: Array<{
            __typename: 'BankAccount'
            name: string | null
            active: boolean
            bankName: string | null
            bic: string | null
            clearingNumber: string | null
            countryCode: string | null
            email: string | null
            iban: string | null
            id: string
            number: string | null
            type: BankAccountTypeEnum
            verified: boolean
            bankLocation: {
              __typename: 'Location'
              id: string
              formattedAddress: string | null
              locality: string | null
              postalCode: string | null
              route: string | null
              streetNumber: string | null
            } | null
            location: {
              __typename: 'Location'
              id: string
              formattedAddress: string | null
              locality: string | null
              postalCode: string | null
              route: string | null
              streetNumber: string | null
            } | null
          }>
          stripeAccount: {
            __typename: 'AccountResource'
            id: string
            currentState: AccountResourceStateEnum
          } | null
          contactLocation: {
            __typename: 'Location'
            id: string
            route: string | null
            streetNumber: string | null
            formattedAddress: string | null
            postalCode: string | null
            locality: string | null
            country: string | null
            countryCode: string
          } | null
          smoochParams: { __typename: 'SmoochParams'; smoochJwt: string | null } | null
        }
        bio: {
          __typename: 'UserBio'
          title: string | null
          intro: string | null
          lived: string | null
          pets: string | null
        }
        tenantProfileCompleteness: {
          __typename: 'TenantProfileCompleteness'
          completenessScore: number
          tenantProfileFields: {
            __typename: 'TenantProfileFields'
            bioLived: { __typename: 'TenantProfileField'; completed: boolean }
            bio: { __typename: 'TenantProfileField'; completed: boolean }
            firstName: { __typename: 'TenantProfileField'; completed: boolean }
            idNumberConfirmed: { __typename: 'TenantProfileField'; completed: boolean }
            matchingProfile: { __typename: 'TenantProfileField'; completed: boolean }
            movingReason: { __typename: 'TenantProfileField'; completed: boolean }
            profilePicture: { __typename: 'TenantProfileField'; completed: boolean }
            bioPets: { __typename: 'TenantProfileField'; completed: boolean }
            bioTitle: { __typename: 'TenantProfileField'; completed: boolean }
            currentAgreementType: { __typename: 'TenantProfileField'; completed: boolean }
          }
        }
      }
    } | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type UpdateUploadMutationMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateUploadInput
}>

export type UpdateUploadMutationMutation = {
  __typename: 'MutationRoot'
  updateUpload: {
    __typename: 'UpdateUploadPayload'
    upload: {
      __typename: 'Upload'
      id: string
      metadata: {
        __typename: 'UploadMetadata'
        order: number | null
        primary: boolean | null
        rotation: number | null
      } | null
    } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type UpdateUserMutationMutationVariables = Exact<{
  uid: Scalars['ID']['input']
  input: UpdateUserInput
}>

export type UpdateUserMutationMutation = {
  __typename: 'MutationRoot'
  updateUser: {
    __typename: 'UpdateUserPayload'
    user: {
      __typename: 'User'
      smoker: boolean
      createdAt: string
      firstName: string | null
      age: number | null
      incomeVerifiedAt: string | null
      emailConfirmed: boolean
      landlord: boolean
      professional: boolean
      companyName: string | null
      premium: boolean
      safeRental: boolean | null
      seenAt: string | null
      tenant: boolean
      notificationSpan: NotificationSpanEnum | null
      sendMonitoringEvents: boolean
      doNotContact: boolean | null
      doNotSms: boolean | null
      doNotVacationSms: boolean | null
      showAge: boolean | null
      movingReason: string | null
      landlordApplicationResponseRate: number | null
      landlordApplicationResponseTimeHours: number | null
      nrFirsthandHousingUnits: NrFirsthandHousingUnitsEnum | null
      rentingOutFirsthandHomes: boolean | null
      uid: string
      affiliateCode: {
        __typename: 'AffiliateCode'
        code: string
        discountFor: AffiliateCodeDiscountForEnum
        discountType: AffiliateCodeDiscountTypeEnum
        discountValue: number
        expirationDate: string
        affiliateCodeType: AffiliateCodeTypeEnum
      } | null
      certificate: {
        __typename: 'Certificate'
        id: string
        status: CertificateStatusEnum
        expiresAt: string | null
      } | null
      currentIdentification: {
        __typename: 'Identification'
        identityConfirmed: boolean
        identityConfirmedAt: string | null
        id: string
        identificationType: IdentificationTypeEnum
      } | null
      activeIdentifications: Array<{
        __typename: 'Identification'
        identityConfirmed: boolean
        identityConfirmedAt: string | null
        id: string
        identificationType: IdentificationTypeEnum
      }> | null
      employmentVerification: { __typename: 'EmploymentVerification'; id: string } | null
      studentVerification: { __typename: 'StudentVerification'; id: string } | null
      profilePicture: { __typename: 'Upload'; id: string; url: string } | null
      housingSituation: {
        __typename: 'HousingSituation'
        id: string
        agreementType: HousingSituationAgreementTypeEnum | null
        landlordCity: string | null
        hasReference: boolean
        landlordPhoneNumber: string | null
        landlordName: string | null
      } | null
      bio: {
        __typename: 'UserBio'
        title: string | null
        intro: string | null
        lived: string | null
        pets: string | null
      }
      private: {
        __typename: 'UserPrivate'
        id: string
        isOnfidoReverificationAvailable: boolean
        homeqUid: string | null
        schibstedAccountId: string | null
        phoneNumber: string | null
        verifiedAccess: boolean
        email: string
        birthDate: string | null
        familyName: string | null
        orgNumber: string | null
        organisationNumberCountryCode: CountryCodeEnum | null
        amplitudeId: string
        invoiceAdsFrom: string | null
        activeBankAccount: {
          __typename: 'BankAccount'
          name: string | null
          active: boolean
          bankName: string | null
          bic: string | null
          clearingNumber: string | null
          countryCode: string | null
          email: string | null
          iban: string | null
          id: string
          number: string | null
          type: BankAccountTypeEnum
          verified: boolean
          bankLocation: {
            __typename: 'Location'
            id: string
            formattedAddress: string | null
            locality: string | null
            postalCode: string | null
            route: string | null
            streetNumber: string | null
          } | null
          location: {
            __typename: 'Location'
            id: string
            formattedAddress: string | null
            locality: string | null
            postalCode: string | null
            route: string | null
            streetNumber: string | null
          } | null
        } | null
        bankAccounts: Array<{
          __typename: 'BankAccount'
          name: string | null
          active: boolean
          bankName: string | null
          bic: string | null
          clearingNumber: string | null
          countryCode: string | null
          email: string | null
          iban: string | null
          id: string
          number: string | null
          type: BankAccountTypeEnum
          verified: boolean
          bankLocation: {
            __typename: 'Location'
            id: string
            formattedAddress: string | null
            locality: string | null
            postalCode: string | null
            route: string | null
            streetNumber: string | null
          } | null
          location: {
            __typename: 'Location'
            id: string
            formattedAddress: string | null
            locality: string | null
            postalCode: string | null
            route: string | null
            streetNumber: string | null
          } | null
        }>
        stripeAccount: {
          __typename: 'AccountResource'
          id: string
          currentState: AccountResourceStateEnum
        } | null
        contactLocation: {
          __typename: 'Location'
          id: string
          route: string | null
          streetNumber: string | null
          formattedAddress: string | null
          postalCode: string | null
          locality: string | null
          country: string | null
          countryCode: string
        } | null
        smoochParams: { __typename: 'SmoochParams'; smoochJwt: string | null } | null
      }
    } | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type UpsertBankAccountMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>
  input: UpsertBankAccountInput
}>

export type UpsertBankAccountMutation = {
  __typename: 'MutationRoot'
  upsertBankAccount: {
    __typename: 'UpsertBankAccountPayload'
    bankAccount: {
      __typename: 'BankAccount'
      name: string | null
      active: boolean
      bankName: string | null
      bic: string | null
      clearingNumber: string | null
      countryCode: string | null
      email: string | null
      iban: string | null
      id: string
      number: string | null
      type: BankAccountTypeEnum
      verified: boolean
      bankLocation: {
        __typename: 'Location'
        id: string
        formattedAddress: string | null
        locality: string | null
        postalCode: string | null
        route: string | null
        streetNumber: string | null
      } | null
      location: {
        __typename: 'Location'
        id: string
        formattedAddress: string | null
        locality: string | null
        postalCode: string | null
        route: string | null
        streetNumber: string | null
      } | null
    } | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type ContractFragmentFragment = {
  __typename: 'Contract'
  qasaGuaranteeCost: number | null
  insuranceCost: number | null
  qasaGuarantee: boolean | null
  insurance: HomeInsuranceEnum | null
  rentIncludes: Array<string>
  rentExcludes: Array<string>
  furniture: boolean | null
  inventory: Array<string>
  canUse: Array<string>
  landlordHasKeys: boolean | null
  subletception: boolean | null
  preCleaning: ContractPreCleaningEnum | null
  postCleaning: ContractPostCleaningEnum | null
  renovations: boolean | null
  temporaryInstallations: boolean | null
  smallRepairs: ContractSmallRepairsEnum | null
  insuranceResponsibility: InsuranceResponsibilityEnum | null
  overnightStay: boolean | null
  pets: boolean | null
  smoker: boolean | null
  numberOfTenants: number | null
  acceptsCosignatures: boolean | null
  apartmentNumber: string | null
  id: string
  rent: number | null
  status: ContractStatusEnum
  paymentDay: number
  marketDetails:
    | {
        __typename: 'FinlandMarketDetails'
        id: string
        minimumStayMonths: number | null
        rentIndexation: {
          __typename: 'FinlandRentIndexationType'
          fixedPercentage: number | null
          indexationOption: FinlandRentIndexationOptionEnum
        } | null
      }
    | { __typename: 'FranceMarketDetails' }
    | null
  duration: {
    __typename: 'Duration'
    endOptimal: string | null
    endUfn: boolean
    startAsap: boolean
    startOptimal: string | null
    updatedAt: string | null
    id: string
  } | null
  tenantFees: {
    __typename: 'ContractTenantFees'
    waterFeePerTenant: {
      __typename: 'Fee'
      availability: FeeAvailabilityEnum
      cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
    }
    monthlyElectricityFee: {
      __typename: 'Fee'
      availability: FeeAvailabilityEnum
      cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
    } | null
  }
  electricityFee: {
    __typename: 'ElectricityFeeType'
    paymentPlan: ElectricityFeePaymentOptionEnum
    monthlyFee: number | null
  } | null
  home: {
    __typename: 'Home'
    id: string
    currency: CurrencyEnum
    qasaGuaranteeCost: number | null
    insuranceCost: number | null
    qasaGuarantee: boolean | null
    insurance: HomeInsuranceEnum | null
    rent: number | null
    rentalType: HomeRentalTypeEnum
    roomCount: number | null
    squareMeters: number | null
    bedCount: number | null
    bedroomCount: number | null
    tenantCount: number | null
    status: HomeStatusEnum
    title: string | null
    type: HomeTypeEnum
    safeRental: boolean | null
    apartmentNumber: string | null
    tenantBaseFee: number | null
    duration: {
      __typename: 'Duration'
      id: string
      startOptimal: string | null
      startAsap: boolean
      endUfn: boolean
      endOptimal: string | null
      updatedAt: string | null
    }
    landlord: {
      __typename: 'User'
      uid: string
      professional: boolean
      companyName: string | null
      firstName: string | null
    }
    location: {
      __typename: 'Location'
      latitude: number | null
      locality: string | null
      longitude: number | null
      postalCode: string | null
      route: string | null
      streetNumber: string | null
      googleMapsPlaceId: string
      countryCode: string
      id: string
    }
    uploads: Array<{
      __typename: 'Upload'
      id: string
      url: string
      metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
    }>
    pricingModel: { __typename: 'PricingModel'; insuranceCostFactor: number; qasaGuaranteeCostFactor: number }
    electricityFee: {
      __typename: 'ElectricityFeeType'
      paymentPlan: ElectricityFeePaymentOptionEnum
      monthlyFee: number | null
    } | null
    landlordServices: {
      __typename: 'HomeLandlordServices'
      insurance: {
        __typename: 'Service'
        availability: ServiceAvailabilityEnum
        isActive: boolean
        cost: { __typename: 'Cost'; factor: number | null; isFree: boolean; total: number }
      }
      qasaGuarantee: {
        __typename: 'Service'
        availability: ServiceAvailabilityEnum
        isActive: boolean
        cost: { __typename: 'Cost'; isFree: boolean; factor: number | null; total: number }
      }
    }
  } | null
  tenant: { __typename: 'User'; uid: string; firstName: string | null } | null
  contractDetails: {
    __typename: 'ContractDetails'
    tenantFirstName: string | null
    tenantFamilyName: string | null
    tenantEmail: string | null
    tenantPhoneNumber: string | null
    legalType: LegalTypeEnum | null
    knownDamages: string | null
    keySpecification: string | null
    codeOfConduct: string | null
    additionalInformation: string | null
    cadastralReference: string | null
    sectionNumber: string | null
    municipality: string | null
    titleNumber: string | null
    bathroomShared: boolean | null
    kitchenShared: boolean | null
  } | null
  extraCosts: Array<{ __typename: 'ExtraCost'; costFor: ExtraCostForEnum; plan: ExtraCostPlanEnum }> | null
}

export type UpsertContractMutationMutationVariables = Exact<{
  input: UpsertContractInput
  id?: InputMaybe<Scalars['ID']['input']>
}>

export type UpsertContractMutationMutation = {
  __typename: 'MutationRoot'
  upsertContract: {
    __typename: 'UpsertContractPayload'
    contract: {
      __typename: 'Contract'
      qasaGuaranteeCost: number | null
      insuranceCost: number | null
      qasaGuarantee: boolean | null
      insurance: HomeInsuranceEnum | null
      rentIncludes: Array<string>
      rentExcludes: Array<string>
      furniture: boolean | null
      inventory: Array<string>
      canUse: Array<string>
      landlordHasKeys: boolean | null
      subletception: boolean | null
      preCleaning: ContractPreCleaningEnum | null
      postCleaning: ContractPostCleaningEnum | null
      renovations: boolean | null
      temporaryInstallations: boolean | null
      smallRepairs: ContractSmallRepairsEnum | null
      insuranceResponsibility: InsuranceResponsibilityEnum | null
      overnightStay: boolean | null
      pets: boolean | null
      smoker: boolean | null
      numberOfTenants: number | null
      acceptsCosignatures: boolean | null
      apartmentNumber: string | null
      id: string
      rent: number | null
      status: ContractStatusEnum
      paymentDay: number
      marketDetails:
        | {
            __typename: 'FinlandMarketDetails'
            id: string
            minimumStayMonths: number | null
            rentIndexation: {
              __typename: 'FinlandRentIndexationType'
              fixedPercentage: number | null
              indexationOption: FinlandRentIndexationOptionEnum
            } | null
          }
        | { __typename: 'FranceMarketDetails' }
        | null
      duration: {
        __typename: 'Duration'
        endOptimal: string | null
        endUfn: boolean
        startAsap: boolean
        startOptimal: string | null
        updatedAt: string | null
        id: string
      } | null
      tenantFees: {
        __typename: 'ContractTenantFees'
        waterFeePerTenant: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
        }
        monthlyElectricityFee: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
        } | null
      }
      electricityFee: {
        __typename: 'ElectricityFeeType'
        paymentPlan: ElectricityFeePaymentOptionEnum
        monthlyFee: number | null
      } | null
      home: {
        __typename: 'Home'
        id: string
        currency: CurrencyEnum
        qasaGuaranteeCost: number | null
        insuranceCost: number | null
        qasaGuarantee: boolean | null
        insurance: HomeInsuranceEnum | null
        rent: number | null
        rentalType: HomeRentalTypeEnum
        roomCount: number | null
        squareMeters: number | null
        bedCount: number | null
        bedroomCount: number | null
        tenantCount: number | null
        status: HomeStatusEnum
        title: string | null
        type: HomeTypeEnum
        safeRental: boolean | null
        apartmentNumber: string | null
        tenantBaseFee: number | null
        duration: {
          __typename: 'Duration'
          id: string
          startOptimal: string | null
          startAsap: boolean
          endUfn: boolean
          endOptimal: string | null
          updatedAt: string | null
        }
        landlord: {
          __typename: 'User'
          uid: string
          professional: boolean
          companyName: string | null
          firstName: string | null
        }
        location: {
          __typename: 'Location'
          latitude: number | null
          locality: string | null
          longitude: number | null
          postalCode: string | null
          route: string | null
          streetNumber: string | null
          googleMapsPlaceId: string
          countryCode: string
          id: string
        }
        uploads: Array<{
          __typename: 'Upload'
          id: string
          url: string
          metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
        }>
        pricingModel: {
          __typename: 'PricingModel'
          insuranceCostFactor: number
          qasaGuaranteeCostFactor: number
        }
        electricityFee: {
          __typename: 'ElectricityFeeType'
          paymentPlan: ElectricityFeePaymentOptionEnum
          monthlyFee: number | null
        } | null
        landlordServices: {
          __typename: 'HomeLandlordServices'
          insurance: {
            __typename: 'Service'
            availability: ServiceAvailabilityEnum
            isActive: boolean
            cost: { __typename: 'Cost'; factor: number | null; isFree: boolean; total: number }
          }
          qasaGuarantee: {
            __typename: 'Service'
            availability: ServiceAvailabilityEnum
            isActive: boolean
            cost: { __typename: 'Cost'; isFree: boolean; factor: number | null; total: number }
          }
        }
      } | null
      tenant: { __typename: 'User'; uid: string; firstName: string | null } | null
      contractDetails: {
        __typename: 'ContractDetails'
        tenantFirstName: string | null
        tenantFamilyName: string | null
        tenantEmail: string | null
        tenantPhoneNumber: string | null
        legalType: LegalTypeEnum | null
        knownDamages: string | null
        keySpecification: string | null
        codeOfConduct: string | null
        additionalInformation: string | null
        cadastralReference: string | null
        sectionNumber: string | null
        municipality: string | null
        titleNumber: string | null
        bathroomShared: boolean | null
        kitchenShared: boolean | null
      } | null
      extraCosts: Array<{
        __typename: 'ExtraCost'
        costFor: ExtraCostForEnum
        plan: ExtraCostPlanEnum
      }> | null
    } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type UpsertFinnishContractMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>
  input: FinlandUpsertContractInput
}>

export type UpsertFinnishContractMutation = {
  __typename: 'MutationRoot'
  finlandUpsertContract: {
    __typename: 'FinlandUpsertContractPayload'
    contract: {
      __typename: 'Contract'
      qasaGuaranteeCost: number | null
      insuranceCost: number | null
      qasaGuarantee: boolean | null
      insurance: HomeInsuranceEnum | null
      rentIncludes: Array<string>
      rentExcludes: Array<string>
      furniture: boolean | null
      inventory: Array<string>
      canUse: Array<string>
      landlordHasKeys: boolean | null
      subletception: boolean | null
      preCleaning: ContractPreCleaningEnum | null
      postCleaning: ContractPostCleaningEnum | null
      renovations: boolean | null
      temporaryInstallations: boolean | null
      smallRepairs: ContractSmallRepairsEnum | null
      insuranceResponsibility: InsuranceResponsibilityEnum | null
      overnightStay: boolean | null
      pets: boolean | null
      smoker: boolean | null
      numberOfTenants: number | null
      acceptsCosignatures: boolean | null
      apartmentNumber: string | null
      id: string
      rent: number | null
      status: ContractStatusEnum
      paymentDay: number
      marketDetails:
        | {
            __typename: 'FinlandMarketDetails'
            id: string
            minimumStayMonths: number | null
            rentIndexation: {
              __typename: 'FinlandRentIndexationType'
              fixedPercentage: number | null
              indexationOption: FinlandRentIndexationOptionEnum
            } | null
          }
        | { __typename: 'FranceMarketDetails' }
        | null
      duration: {
        __typename: 'Duration'
        endOptimal: string | null
        endUfn: boolean
        startAsap: boolean
        startOptimal: string | null
        updatedAt: string | null
        id: string
      } | null
      tenantFees: {
        __typename: 'ContractTenantFees'
        waterFeePerTenant: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
        }
        monthlyElectricityFee: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
        } | null
      }
      electricityFee: {
        __typename: 'ElectricityFeeType'
        paymentPlan: ElectricityFeePaymentOptionEnum
        monthlyFee: number | null
      } | null
      home: {
        __typename: 'Home'
        id: string
        currency: CurrencyEnum
        qasaGuaranteeCost: number | null
        insuranceCost: number | null
        qasaGuarantee: boolean | null
        insurance: HomeInsuranceEnum | null
        rent: number | null
        rentalType: HomeRentalTypeEnum
        roomCount: number | null
        squareMeters: number | null
        bedCount: number | null
        bedroomCount: number | null
        tenantCount: number | null
        status: HomeStatusEnum
        title: string | null
        type: HomeTypeEnum
        safeRental: boolean | null
        apartmentNumber: string | null
        tenantBaseFee: number | null
        duration: {
          __typename: 'Duration'
          id: string
          startOptimal: string | null
          startAsap: boolean
          endUfn: boolean
          endOptimal: string | null
          updatedAt: string | null
        }
        landlord: {
          __typename: 'User'
          uid: string
          professional: boolean
          companyName: string | null
          firstName: string | null
        }
        location: {
          __typename: 'Location'
          latitude: number | null
          locality: string | null
          longitude: number | null
          postalCode: string | null
          route: string | null
          streetNumber: string | null
          googleMapsPlaceId: string
          countryCode: string
          id: string
        }
        uploads: Array<{
          __typename: 'Upload'
          id: string
          url: string
          metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
        }>
        pricingModel: {
          __typename: 'PricingModel'
          insuranceCostFactor: number
          qasaGuaranteeCostFactor: number
        }
        electricityFee: {
          __typename: 'ElectricityFeeType'
          paymentPlan: ElectricityFeePaymentOptionEnum
          monthlyFee: number | null
        } | null
        landlordServices: {
          __typename: 'HomeLandlordServices'
          insurance: {
            __typename: 'Service'
            availability: ServiceAvailabilityEnum
            isActive: boolean
            cost: { __typename: 'Cost'; factor: number | null; isFree: boolean; total: number }
          }
          qasaGuarantee: {
            __typename: 'Service'
            availability: ServiceAvailabilityEnum
            isActive: boolean
            cost: { __typename: 'Cost'; isFree: boolean; factor: number | null; total: number }
          }
        }
      } | null
      tenant: { __typename: 'User'; uid: string; firstName: string | null } | null
      contractDetails: {
        __typename: 'ContractDetails'
        tenantFirstName: string | null
        tenantFamilyName: string | null
        tenantEmail: string | null
        tenantPhoneNumber: string | null
        legalType: LegalTypeEnum | null
        knownDamages: string | null
        keySpecification: string | null
        codeOfConduct: string | null
        additionalInformation: string | null
        cadastralReference: string | null
        sectionNumber: string | null
        municipality: string | null
        titleNumber: string | null
        bathroomShared: boolean | null
        kitchenShared: boolean | null
      } | null
      extraCosts: Array<{
        __typename: 'ExtraCost'
        costFor: ExtraCostForEnum
        plan: ExtraCostPlanEnum
      }> | null
    } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type ContractQueryQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ContractQueryQuery = {
  __typename: 'QueryRoot'
  contract: {
    __typename: 'Contract'
    currency: CurrencyEnum
    qasaGuarantee: boolean | null
    insurance: HomeInsuranceEnum | null
    termsUrl: string | null
    depositFree: boolean | null
    depositFreeCost: number | null
    depositPayoutApprovedAt: string | null
    depositPayoutDeniedAt: string | null
    signUrl: string | null
    language: ContractLanguageEnum
    availablePreviewLanguages: Array<ContractLanguageEnum>
    numberOfTenants: number | null
    pendingTerminationEndDate: string | null
    apartmentNumber: string | null
    id: string
    type: ContractTypeEnum
    terminationNotice: boolean | null
    tenureType: TenureTypeEnum | null
    fee: number | null
    qasaGuaranteeCost: number | null
    insuranceCost: number | null
    acceptsCosignatures: boolean | null
    signedAt: string | null
    status: ContractStatusEnum
    landlordHasKeys: boolean | null
    insuranceResponsibility: InsuranceResponsibilityEnum | null
    rent: number | null
    inventory: Array<string>
    payoutDay: number
    paymentDay: number
    canUse: Array<string>
    rentIncludes: Array<string>
    rentExcludes: Array<string>
    subletception: boolean | null
    renovations: boolean | null
    preCleaning: ContractPreCleaningEnum | null
    postCleaning: ContractPostCleaningEnum | null
    temporaryInstallations: boolean | null
    smallRepairs: ContractSmallRepairsEnum | null
    pets: boolean | null
    smoker: boolean | null
    ownHome: boolean | null
    furniture: boolean | null
    overnightStay: boolean | null
    signingProcess: SigningProcessEnum
    extensionContract: {
      __typename: 'Contract'
      id: string
      status: ContractStatusEnum
      signUrl: string | null
      rent: number | null
      currency: CurrencyEnum
      duration: {
        __typename: 'Duration'
        startOptimal: string | null
        endOptimal: string | null
        endUfn: boolean
      } | null
      signatories: Array<{
        __typename: 'Signatory'
        id: string
        role: SignatoryRoleEnum
        signedAt: string | null
        order: number | null
      }>
      motherContract: { __typename: 'Contract'; id: string } | null
    } | null
    conversation: { __typename: 'Conversation'; id: string } | null
    marketDetails:
      | {
          __typename: 'FinlandMarketDetails'
          id: string
          minimumStayMonths: number | null
          rentIndexation: {
            __typename: 'FinlandRentIndexationType'
            fixedPercentage: number | null
            indexationOption: FinlandRentIndexationOptionEnum
          } | null
        }
      | { __typename: 'FranceMarketDetails' }
      | null
    inspectionProtocol: { __typename: 'Blob'; url: string | null; id: string } | null
    tenantFees: {
      __typename: 'ContractTenantFees'
      waterFeePerTenant: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
      }
      monthlyElectricityFee: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
      } | null
    }
    electricityFee: {
      __typename: 'ElectricityFeeType'
      monthlyFee: number | null
      paymentPlan: ElectricityFeePaymentOptionEnum
    } | null
    landlordFees: {
      __typename: 'ContractLandlordFees'
      base: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; factor: number | null; total: number }
      }
    }
    tenantPostSignDocuments: Array<{
      __typename: 'Blob'
      url: string | null
      id: string
      filename: string
      contentType: string
    }> | null
    landlordPostSignDocuments: Array<{
      __typename: 'Blob'
      url: string | null
      id: string
      filename: string
      contentType: string
    }> | null
    invoices: Array<{
      __typename: 'Invoice'
      amount: number
      currency: CurrencyEnum
      guaranteed: boolean | null
      periodEndsAt: string
      periodStartsAt: string
      type: InvoiceTypeEnum
      dueAt: string
      paidAt: string | null
      id: string
      sentAt: string | null
      sendAt: string
      creditedAmount: number | null
      batch: number
      url: string | null
      invoiceEntries: Array<{
        __typename: 'InvoiceEntry'
        amount: number
        currency: CurrencyEnum
        costType: InvoiceEntryCostTypeEnum
        entryType: InvoiceEntryTypeEnum
        id: string
      }> | null
    }>
    home: {
      __typename: 'Home'
      id: string
      ownHome: boolean | null
      tenureType: TenureTypeEnum | null
      currency: CurrencyEnum
      qasaGuaranteeCost: number | null
      insuranceCost: number | null
      qasaGuarantee: boolean | null
      insurance: HomeInsuranceEnum | null
      rent: number | null
      rentalType: HomeRentalTypeEnum
      roomCount: number | null
      squareMeters: number | null
      bedCount: number | null
      bedroomCount: number | null
      tenantCount: number | null
      status: HomeStatusEnum
      title: string | null
      type: HomeTypeEnum
      safeRental: boolean | null
      apartmentNumber: string | null
      tenantBaseFee: number | null
      market: { __typename: 'Market'; id: string; name: MarketNameEnum; currency: MarketCurrencyEnum }
      inspection: {
        __typename: 'Inspection'
        id: string
        observations: Array<{
          __typename: 'InspectionObservation'
          description: string | null
          section: InspectionRoomsEnum
        }> | null
      } | null
      tenantFees: {
        __typename: 'HomeTenantFees'
        waterFeePerTenant: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: { __typename: 'Cost'; fixed: number | null }
        }
        monthlyElectricityFee: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
        } | null
      }
      instantSignSetting: { __typename: 'InstantSignSetting'; landlordHoursToSign: number } | null
      duration: {
        __typename: 'Duration'
        id: string
        startOptimal: string | null
        startAsap: boolean
        endUfn: boolean
        endOptimal: string | null
        updatedAt: string | null
      }
      landlord: {
        __typename: 'User'
        uid: string
        professional: boolean
        companyName: string | null
        firstName: string | null
      }
      location: {
        __typename: 'Location'
        latitude: number | null
        locality: string | null
        longitude: number | null
        postalCode: string | null
        route: string | null
        streetNumber: string | null
        googleMapsPlaceId: string
        countryCode: string
        id: string
      }
      uploads: Array<{
        __typename: 'Upload'
        id: string
        url: string
        metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
      }>
      pricingModel: {
        __typename: 'PricingModel'
        insuranceCostFactor: number
        qasaGuaranteeCostFactor: number
      }
      electricityFee: {
        __typename: 'ElectricityFeeType'
        paymentPlan: ElectricityFeePaymentOptionEnum
        monthlyFee: number | null
      } | null
      landlordServices: {
        __typename: 'HomeLandlordServices'
        insurance: {
          __typename: 'Service'
          availability: ServiceAvailabilityEnum
          isActive: boolean
          cost: { __typename: 'Cost'; factor: number | null; isFree: boolean; total: number }
        }
        qasaGuarantee: {
          __typename: 'Service'
          availability: ServiceAvailabilityEnum
          isActive: boolean
          cost: { __typename: 'Cost'; isFree: boolean; factor: number | null; total: number }
        }
      }
    } | null
    market: { __typename: 'Market'; id: string; name: MarketNameEnum }
    externalContractTakeover: {
      __typename: 'ExternalContractTakeover'
      currentState: ExternalContractTakeoverStateEnum
    } | null
    currentUserSignatory: { __typename: 'Signatory'; id: string; signedAt: string | null; canSign: boolean }
    user: { __typename: 'User'; uid: string; proAgent: boolean }
    signatories: Array<{
      __typename: 'Signatory'
      id: string
      role: SignatoryRoleEnum
      signedAt: string | null
      order: number | null
      signingDeadline: string | null
      canSign: boolean
      user: { __typename: 'User'; uid: string }
    }>
    tenant: {
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
      profilePicture: { __typename: 'Upload'; url: string } | null
      currentIdentification: {
        __typename: 'Identification'
        identityConfirmed: boolean
        identityConfirmedAt: string | null
      } | null
    } | null
    landlord: {
      __typename: 'User'
      uid: string
      firstName: string | null
      safeRental: boolean | null
      companyName: string | null
      professional: boolean
      profilePicture: { __typename: 'Upload'; url: string } | null
    }
    rentalInsuranceMilaRequest: {
      __typename: 'FranceInsuranceRequestType'
      id: string
      currentState: FranceInsuranceRequestStateEnum | null
      documentRequests: Array<{ __typename: 'FranceDocumentRequestType'; id: string }> | null
    } | null
    cosigningTenants: Array<{
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
      profilePicture: { __typename: 'Upload'; url: string } | null
    }> | null
    tenantInvitations: Array<{
      __typename: 'ContractTenantInvitation'
      claimKey: string | null
      claimable: boolean
      invitedBy: {
        __typename: 'User'
        uid: string
        firstName: string | null
        companyName: string | null
        professional: boolean
      } | null
    }> | null
    duration: {
      __typename: 'Duration'
      id: string
      endOptimal: string | null
      endUfn: boolean
      startAsap: boolean
      startOptimal: string | null
      updatedAt: string | null
    } | null
    upload: { __typename: 'Upload'; url: string } | null
    contractDetails: {
      __typename: 'ContractDetails'
      tenantFirstName: string | null
      tenantFamilyName: string | null
      tenantEmail: string | null
      tenantPhoneNumber: string | null
      legalType: LegalTypeEnum | null
      bindingTime: number | null
      canBeTerminated: boolean | null
      terminationNoticeMonths: number | null
      shorterTerminationExplanation: string | null
      knownDamages: string | null
      keySpecification: string | null
      codeOfConduct: string | null
      additionalInformation: string | null
      cadastralReference: string | null
      sectionNumber: string | null
      municipality: string | null
      titleNumber: string | null
      bathroomShared: boolean | null
      kitchenShared: boolean | null
      rentedAsIs: boolean | null
    } | null
    extraCosts: Array<{ __typename: 'ExtraCost'; costFor: ExtraCostForEnum; plan: ExtraCostPlanEnum }> | null
    tenantServices: {
      __typename: 'ContractTenantServices'
      depositFree: {
        __typename: 'Service'
        availability: ServiceAvailabilityEnum
        isActive: boolean
        cost: { __typename: 'Cost'; isFree: boolean; factor: number | null; total: number }
      }
    }
    landlordServices: {
      __typename: 'ContractLandlordServices'
      insurance: {
        __typename: 'Service'
        availability: ServiceAvailabilityEnum
        isActive: boolean
        cost: { __typename: 'Cost'; factor: number | null; isFree: boolean; total: number }
      }
      qasaGuarantee: {
        __typename: 'Service'
        availability: ServiceAvailabilityEnum
        isActive: boolean
        cost: { __typename: 'Cost'; isFree: boolean; factor: number | null; total: number }
      }
    }
    ifInsurancePromotion: { __typename: 'IfInsurancePromotion'; signedUp: boolean | null } | null
    financePayouts: Array<{
      __typename: 'FinancePayoutType'
      id: string
      paidAt: string | null
      reference: string
      currentState: PayoutStateEnum
      periodStart: string | null
      periodEnd: string | null
      receipt: { __typename: 'Blob'; id: string; url: string | null } | null
      amount: { __typename: 'Money'; humanized: string }
      charges: Array<{
        __typename: 'FinanceChargeType'
        id: string
        vatPercent: string
        productName: ProductEnum
        vat: { __typename: 'Money'; humanized: string; fractional: number }
        amount: { __typename: 'Money'; humanized: string }
      }>
    }>
    financeDepositInvoices: Array<{
      __typename: 'FinanceDepositInvoice'
      id: string
      dueDate: string
      reference: string
      currentState: InvoiceStateEnum
      overdue: boolean
      receipt: { __typename: 'Blob'; id: string; url: string | null } | null
      items: Array<{
        __typename: 'FinanceItemType'
        id: string
        productName: ProductEnum
        vatPercent: string
        vat: { __typename: 'Money'; humanized: string; fractional: number }
        amount: { __typename: 'Money'; humanized: string }
      }>
      sumAmount: { __typename: 'Money'; currency: CurrencyEnum; humanized: string }
      paymentAction: {
        __typename: 'StripeSepaTransferMoneyAction'
        id: string
        reference: string
        iban: string
        bic: string
        amount: { __typename: 'Money'; humanized: string }
      } | null
    }>
    financeRentInvoices: Array<{
      __typename: 'FinanceMonthlyRentInvoice'
      id: string
      reference: string
      dueDate: string
      periodStart: string
      periodEnd: string
      currentState: InvoiceStateEnum
      overdue: boolean
      receipt: { __typename: 'Blob'; id: string; url: string | null } | null
      upcomingPayout: {
        __typename: 'UpcomingPayoutType'
        dueDate: string
        periodEnd: string | null
        periodStart: string | null
        sumAmount: { __typename: 'Money'; humanized: string }
        charges: Array<{
          __typename: 'FinanceChargeType'
          id: string
          productName: ProductEnum
          vatPercent: string
          amount: { __typename: 'Money'; humanized: string }
          vat: { __typename: 'Money'; fractional: number; humanized: string }
        }>
      } | null
      items: Array<{
        __typename: 'FinanceItemType'
        id: string
        productName: ProductEnum
        vatPercent: string
        vat: { __typename: 'Money'; humanized: string; fractional: number }
        amount: { __typename: 'Money'; humanized: string }
      }>
      sumAmount: { __typename: 'Money'; currency: CurrencyEnum; humanized: string }
      paymentAction: {
        __typename: 'StripeSepaTransferMoneyAction'
        id: string
        reference: string
        iban: string
        bic: string
        amount: { __typename: 'Money'; humanized: string }
      } | null
    }>
    financeInvoices: Array<
      | { __typename: 'FinanceDepositInvoice' }
      | {
          __typename: 'FinanceGenericInvoice'
          id: string
          reference: string
          dueDate: string
          periodStart: string | null
          periodEnd: string | null
          currentState: InvoiceStateEnum
          overdue: boolean
          receipt: { __typename: 'Blob'; id: string; url: string | null } | null
          items: Array<{
            __typename: 'FinanceItemType'
            id: string
            productName: ProductEnum
            vatPercent: string
            vat: { __typename: 'Money'; humanized: string; fractional: number }
            amount: { __typename: 'Money'; humanized: string }
          }>
          sumAmount: { __typename: 'Money'; currency: CurrencyEnum; humanized: string }
          paymentAction: {
            __typename: 'StripeSepaTransferMoneyAction'
            id: string
            reference: string
            iban: string
            bic: string
            amount: { __typename: 'Money'; humanized: string }
          } | null
        }
      | { __typename: 'FinanceMonthlyRentInvoice' }
    >
  }
}

export type ContractsCountsQueryQueryVariables = Exact<{ [key: string]: never }>

export type ContractsCountsQueryQuery = {
  __typename: 'QueryRoot'
  contractsCounts: {
    __typename: 'ContractsCounts'
    vacation: {
      __typename: 'ContractsByStatusCounts'
      canceled: number
      draft: number
      extended: number
      ended: number
      finalDraft: number
      pending: number
      pendingAprilaApplication: number
      pendingAprilaRentalAgreement: number
      pendingTenantCreditCheck: number
      signed: number
      rejected: number
      timedout: number
    }
    longTerm: {
      __typename: 'ContractsByStatusCounts'
      canceled: number
      draft: number
      awaitingInsuranceApproval: number
      extended: number
      ended: number
      finalDraft: number
      pending: number
      pendingAprilaApplication: number
      pendingAprilaRentalAgreement: number
      pendingTenantCreditCheck: number
      signed: number
      rejected: number
      timedout: number
    }
  }
}

export type ContractsQueryQueryVariables = Exact<{
  status?: InputMaybe<Array<ContractStatusEnum> | ContractStatusEnum>
  signingProcess?: InputMaybe<Array<SigningProcessEnum> | SigningProcessEnum>
}>

export type ContractsQueryQuery = {
  __typename: 'QueryRoot'
  contracts: {
    __typename: 'ContractConnection'
    nodes: Array<{
      __typename: 'Contract'
      id: string
      type: ContractTypeEnum
      terminationNotice: boolean | null
      tenureType: TenureTypeEnum | null
      fee: number | null
      qasaGuarantee: boolean | null
      qasaGuaranteeCost: number | null
      insuranceCost: number | null
      acceptsCosignatures: boolean | null
      signedAt: string | null
      signUrl: string | null
      status: ContractStatusEnum
      landlordHasKeys: boolean | null
      insuranceResponsibility: InsuranceResponsibilityEnum | null
      rent: number | null
      inventory: Array<string>
      payoutDay: number
      paymentDay: number
      canUse: Array<string>
      rentIncludes: Array<string>
      rentExcludes: Array<string>
      subletception: boolean | null
      renovations: boolean | null
      preCleaning: ContractPreCleaningEnum | null
      currency: CurrencyEnum
      postCleaning: ContractPostCleaningEnum | null
      temporaryInstallations: boolean | null
      smallRepairs: ContractSmallRepairsEnum | null
      pets: boolean | null
      smoker: boolean | null
      ownHome: boolean | null
      furniture: boolean | null
      overnightStay: boolean | null
      signingProcess: SigningProcessEnum
      market: { __typename: 'Market'; id: string; name: MarketNameEnum }
      externalContractTakeover: {
        __typename: 'ExternalContractTakeover'
        currentState: ExternalContractTakeoverStateEnum
      } | null
      currentUserSignatory: { __typename: 'Signatory'; id: string; signedAt: string | null; canSign: boolean }
      user: { __typename: 'User'; uid: string; proAgent: boolean }
      signatories: Array<{
        __typename: 'Signatory'
        id: string
        role: SignatoryRoleEnum
        signedAt: string | null
        order: number | null
        signingDeadline: string | null
        canSign: boolean
        user: { __typename: 'User'; uid: string }
      }>
      tenant: {
        __typename: 'User'
        uid: string
        firstName: string | null
        companyName: string | null
        professional: boolean
        profilePicture: { __typename: 'Upload'; url: string } | null
        currentIdentification: {
          __typename: 'Identification'
          identityConfirmed: boolean
          identityConfirmedAt: string | null
        } | null
      } | null
      landlord: {
        __typename: 'User'
        uid: string
        firstName: string | null
        safeRental: boolean | null
        companyName: string | null
        professional: boolean
        profilePicture: { __typename: 'Upload'; url: string } | null
      }
      rentalInsuranceMilaRequest: {
        __typename: 'FranceInsuranceRequestType'
        id: string
        currentState: FranceInsuranceRequestStateEnum | null
        documentRequests: Array<{ __typename: 'FranceDocumentRequestType'; id: string }> | null
      } | null
      cosigningTenants: Array<{
        __typename: 'User'
        uid: string
        firstName: string | null
        companyName: string | null
        professional: boolean
        profilePicture: { __typename: 'Upload'; url: string } | null
      }> | null
      tenantInvitations: Array<{
        __typename: 'ContractTenantInvitation'
        claimKey: string | null
        claimable: boolean
        invitedBy: {
          __typename: 'User'
          uid: string
          firstName: string | null
          companyName: string | null
          professional: boolean
        } | null
      }> | null
      duration: {
        __typename: 'Duration'
        id: string
        endOptimal: string | null
        endUfn: boolean
        startAsap: boolean
        startOptimal: string | null
        updatedAt: string | null
      } | null
      home: {
        __typename: 'Home'
        ownHome: boolean | null
        tenureType: TenureTypeEnum | null
        id: string
        currency: CurrencyEnum
        qasaGuaranteeCost: number | null
        insuranceCost: number | null
        qasaGuarantee: boolean | null
        insurance: HomeInsuranceEnum | null
        rent: number | null
        rentalType: HomeRentalTypeEnum
        roomCount: number | null
        squareMeters: number | null
        bedCount: number | null
        bedroomCount: number | null
        tenantCount: number | null
        status: HomeStatusEnum
        title: string | null
        type: HomeTypeEnum
        safeRental: boolean | null
        apartmentNumber: string | null
        tenantBaseFee: number | null
        inspection: {
          __typename: 'Inspection'
          id: string
          observations: Array<{
            __typename: 'InspectionObservation'
            description: string | null
            section: InspectionRoomsEnum
          }> | null
        } | null
        market: { __typename: 'Market'; id: string; name: MarketNameEnum; currency: MarketCurrencyEnum }
        tenantFees: {
          __typename: 'HomeTenantFees'
          waterFeePerTenant: {
            __typename: 'Fee'
            availability: FeeAvailabilityEnum
            cost: { __typename: 'Cost'; fixed: number | null }
          }
          monthlyElectricityFee: {
            __typename: 'Fee'
            availability: FeeAvailabilityEnum
            cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
          } | null
        }
        instantSignSetting: { __typename: 'InstantSignSetting'; landlordHoursToSign: number } | null
        duration: {
          __typename: 'Duration'
          id: string
          startOptimal: string | null
          startAsap: boolean
          endUfn: boolean
          endOptimal: string | null
          updatedAt: string | null
        }
        landlord: {
          __typename: 'User'
          uid: string
          professional: boolean
          companyName: string | null
          firstName: string | null
        }
        location: {
          __typename: 'Location'
          latitude: number | null
          locality: string | null
          longitude: number | null
          postalCode: string | null
          route: string | null
          streetNumber: string | null
          googleMapsPlaceId: string
          countryCode: string
          id: string
        }
        uploads: Array<{
          __typename: 'Upload'
          id: string
          url: string
          metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
        }>
        pricingModel: {
          __typename: 'PricingModel'
          insuranceCostFactor: number
          qasaGuaranteeCostFactor: number
        }
        electricityFee: {
          __typename: 'ElectricityFeeType'
          paymentPlan: ElectricityFeePaymentOptionEnum
          monthlyFee: number | null
        } | null
        landlordServices: {
          __typename: 'HomeLandlordServices'
          insurance: {
            __typename: 'Service'
            availability: ServiceAvailabilityEnum
            isActive: boolean
            cost: { __typename: 'Cost'; factor: number | null; isFree: boolean; total: number }
          }
          qasaGuarantee: {
            __typename: 'Service'
            availability: ServiceAvailabilityEnum
            isActive: boolean
            cost: { __typename: 'Cost'; isFree: boolean; factor: number | null; total: number }
          }
        }
      } | null
      upload: { __typename: 'Upload'; url: string } | null
      contractDetails: {
        __typename: 'ContractDetails'
        tenantFirstName: string | null
        tenantFamilyName: string | null
        tenantEmail: string | null
        tenantPhoneNumber: string | null
        legalType: LegalTypeEnum | null
        bindingTime: number | null
        canBeTerminated: boolean | null
        terminationNoticeMonths: number | null
        shorterTerminationExplanation: string | null
        knownDamages: string | null
        keySpecification: string | null
        codeOfConduct: string | null
        additionalInformation: string | null
        cadastralReference: string | null
        sectionNumber: string | null
        municipality: string | null
        titleNumber: string | null
        bathroomShared: boolean | null
        kitchenShared: boolean | null
        rentedAsIs: boolean | null
      } | null
      extraCosts: Array<{
        __typename: 'ExtraCost'
        costFor: ExtraCostForEnum
        plan: ExtraCostPlanEnum
      }> | null
      tenantServices: {
        __typename: 'ContractTenantServices'
        depositFree: {
          __typename: 'Service'
          availability: ServiceAvailabilityEnum
          isActive: boolean
          cost: { __typename: 'Cost'; isFree: boolean; factor: number | null; total: number }
        }
      }
      landlordServices: {
        __typename: 'ContractLandlordServices'
        insurance: {
          __typename: 'Service'
          availability: ServiceAvailabilityEnum
          isActive: boolean
          cost: { __typename: 'Cost'; factor: number | null; isFree: boolean; total: number }
        }
        qasaGuarantee: {
          __typename: 'Service'
          availability: ServiceAvailabilityEnum
          isActive: boolean
          cost: { __typename: 'Cost'; isFree: boolean; factor: number | null; total: number }
        }
      }
      ifInsurancePromotion: { __typename: 'IfInsurancePromotion'; signedUp: boolean | null } | null
    }>
  }
}

export type HomeMatchingProfileQueryQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type HomeMatchingProfileQueryQuery = {
  __typename: 'QueryRoot'
  home: {
    __typename: 'Home'
    id: string
    rent: number | null
    tenantBaseFee: number | null
    monthlyCostCents: number | null
    roomCount: number | null
    squareMeters: number | null
    tenantCount: number | null
    type: HomeTypeEnum
    shared: boolean | null
    traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
    duration: {
      __typename: 'Duration'
      startAsap: boolean
      startOptimal: string | null
      endUfn: boolean
      endOptimal: string | null
    }
    location: { __typename: 'Location'; locality: string | null }
  } | null
}

export type HomesQueryVariables = Exact<{ [key: string]: never }>

export type HomesQuery = {
  __typename: 'QueryRoot'
  me: {
    __typename: 'User'
    uid: string
    homes: Array<{
      __typename: 'Home'
      id: string
      squareMeters: number | null
      roomCount: number | null
      type: HomeTypeEnum
      status: HomeStatusEnum
      uploads: Array<{ __typename: 'Upload'; id: string; url: string }>
      location: {
        __typename: 'Location'
        id: string
        route: string | null
        streetNumber: string | null
        postalCode: string | null
        locality: string | null
      }
    }>
  } | null
}

export type IdentificationStatusQueryQueryVariables = Exact<{
  externalId: Scalars['ID']['input']
}>

export type IdentificationStatusQueryQuery = {
  __typename: 'QueryRoot'
  identificationStatus: IdentificationStatusEnum | null
}

export type MeQueryQueryVariables = Exact<{ [key: string]: never }>

export type MeQueryQuery = {
  __typename: 'QueryRoot'
  me: {
    __typename: 'User'
    smoker: boolean
    createdAt: string
    firstName: string | null
    age: number | null
    incomeVerifiedAt: string | null
    emailConfirmed: boolean
    landlord: boolean
    professional: boolean
    companyName: string | null
    premium: boolean
    safeRental: boolean | null
    seenAt: string | null
    tenant: boolean
    notificationSpan: NotificationSpanEnum | null
    sendMonitoringEvents: boolean
    doNotContact: boolean | null
    doNotSms: boolean | null
    doNotVacationSms: boolean | null
    showAge: boolean | null
    movingReason: string | null
    landlordApplicationResponseRate: number | null
    landlordApplicationResponseTimeHours: number | null
    nrFirsthandHousingUnits: NrFirsthandHousingUnitsEnum | null
    rentingOutFirsthandHomes: boolean | null
    uid: string
    creditReport: { __typename: 'CreditReport'; id: string; fetchStatus: CreditReportFetchStatusEnum } | null
    applicationStats: {
      __typename: 'UserApplicationStats'
      uid: string
      count: number | null
      inContactCount: number | null
      locales: Array<string> | null
      priceMax: number | null
      priceMin: number | null
      roomMax: number | null
      roomMin: number | null
    }
    affiliateCode: {
      __typename: 'AffiliateCode'
      code: string
      discountFor: AffiliateCodeDiscountForEnum
      discountType: AffiliateCodeDiscountTypeEnum
      discountValue: number
      expirationDate: string
      affiliateCodeType: AffiliateCodeTypeEnum
    } | null
    certificate: {
      __typename: 'Certificate'
      id: string
      status: CertificateStatusEnum
      expiresAt: string | null
    } | null
    currentIdentification: {
      __typename: 'Identification'
      identityConfirmed: boolean
      identityConfirmedAt: string | null
      id: string
      identificationType: IdentificationTypeEnum
    } | null
    activeIdentifications: Array<{
      __typename: 'Identification'
      identityConfirmed: boolean
      identityConfirmedAt: string | null
      id: string
      identificationType: IdentificationTypeEnum
    }> | null
    employmentVerification: { __typename: 'EmploymentVerification'; id: string } | null
    studentVerification: { __typename: 'StudentVerification'; id: string } | null
    profilePicture: { __typename: 'Upload'; id: string; url: string } | null
    housingSituation: {
      __typename: 'HousingSituation'
      id: string
      agreementType: HousingSituationAgreementTypeEnum | null
      landlordCity: string | null
      hasReference: boolean
      landlordPhoneNumber: string | null
      landlordName: string | null
    } | null
    private: {
      __typename: 'UserPrivate'
      id: string
      isOnfidoReverificationAvailable: boolean
      homeqUid: string | null
      schibstedAccountId: string | null
      phoneNumber: string | null
      verifiedAccess: boolean
      email: string
      birthDate: string | null
      familyName: string | null
      orgNumber: string | null
      organisationNumberCountryCode: CountryCodeEnum | null
      amplitudeId: string
      invoiceAdsFrom: string | null
      activeBankAccount: {
        __typename: 'BankAccount'
        name: string | null
        active: boolean
        bankName: string | null
        bic: string | null
        clearingNumber: string | null
        countryCode: string | null
        email: string | null
        iban: string | null
        id: string
        number: string | null
        type: BankAccountTypeEnum
        verified: boolean
        bankLocation: {
          __typename: 'Location'
          id: string
          formattedAddress: string | null
          locality: string | null
          postalCode: string | null
          route: string | null
          streetNumber: string | null
        } | null
        location: {
          __typename: 'Location'
          id: string
          formattedAddress: string | null
          locality: string | null
          postalCode: string | null
          route: string | null
          streetNumber: string | null
        } | null
      } | null
      bankAccounts: Array<{
        __typename: 'BankAccount'
        name: string | null
        active: boolean
        bankName: string | null
        bic: string | null
        clearingNumber: string | null
        countryCode: string | null
        email: string | null
        iban: string | null
        id: string
        number: string | null
        type: BankAccountTypeEnum
        verified: boolean
        bankLocation: {
          __typename: 'Location'
          id: string
          formattedAddress: string | null
          locality: string | null
          postalCode: string | null
          route: string | null
          streetNumber: string | null
        } | null
        location: {
          __typename: 'Location'
          id: string
          formattedAddress: string | null
          locality: string | null
          postalCode: string | null
          route: string | null
          streetNumber: string | null
        } | null
      }>
      stripeAccount: {
        __typename: 'AccountResource'
        id: string
        currentState: AccountResourceStateEnum
      } | null
      contactLocation: {
        __typename: 'Location'
        id: string
        route: string | null
        streetNumber: string | null
        formattedAddress: string | null
        postalCode: string | null
        locality: string | null
        country: string | null
        countryCode: string
      } | null
      smoochParams: { __typename: 'SmoochParams'; smoochJwt: string | null } | null
    }
    bio: {
      __typename: 'UserBio'
      title: string | null
      intro: string | null
      lived: string | null
      pets: string | null
    }
    tenantProfileCompleteness: {
      __typename: 'TenantProfileCompleteness'
      completenessScore: number
      tenantProfileFields: {
        __typename: 'TenantProfileFields'
        bioLived: { __typename: 'TenantProfileField'; completed: boolean }
        bio: { __typename: 'TenantProfileField'; completed: boolean }
        firstName: { __typename: 'TenantProfileField'; completed: boolean }
        idNumberConfirmed: { __typename: 'TenantProfileField'; completed: boolean }
        matchingProfile: { __typename: 'TenantProfileField'; completed: boolean }
        movingReason: { __typename: 'TenantProfileField'; completed: boolean }
        profilePicture: { __typename: 'TenantProfileField'; completed: boolean }
        bioPets: { __typename: 'TenantProfileField'; completed: boolean }
        bioTitle: { __typename: 'TenantProfileField'; completed: boolean }
        currentAgreementType: { __typename: 'TenantProfileField'; completed: boolean }
      }
    }
  } | null
}

export type SearchAreaQueryQueryVariables = Exact<{
  areaParams?: InputMaybe<Array<AreaParamsInput> | AreaParamsInput>
}>

export type SearchAreaQueryQuery = {
  __typename: 'QueryRoot'
  searchArea: Array<{
    __typename: 'SearchArea'
    name: string
    city: string | null
    identifier: string
    geojson: Record<string, unknown>
  }>
}

export type RemoveCosigningTenantMutationVariables = Exact<{
  uid: Scalars['ID']['input']
  contractId: Scalars['ID']['input']
}>

export type RemoveCosigningTenantMutation = {
  __typename: 'MutationRoot'
  removeCosigningTenant: { __typename: 'RemoveCosigningTenantPayload'; uid: string } | null
}

export type UpdateHomeAddonsMutationVariables = Exact<{
  input: UpdateHomeInput
  id: Scalars['ID']['input']
}>

export type UpdateHomeAddonsMutation = {
  __typename: 'MutationRoot'
  updateHome: {
    __typename: 'UpdateHomePayload'
    home: {
      __typename: 'Home'
      id: string
      qasaGuaranteeCost: number | null
      insuranceCost: number | null
      qasaGuarantee: boolean | null
      insurance: HomeInsuranceEnum | null
    } | null
  } | null
}

export type UpsertContractAddonsMutationVariables = Exact<{
  input: UpsertContractInput
  id?: InputMaybe<Scalars['ID']['input']>
}>

export type UpsertContractAddonsMutation = {
  __typename: 'MutationRoot'
  upsertContract: {
    __typename: 'UpsertContractPayload'
    contract: {
      __typename: 'Contract'
      id: string
      qasaGuaranteeCost: number | null
      insuranceCost: number | null
      qasaGuarantee: boolean | null
      insurance: HomeInsuranceEnum | null
    } | null
  } | null
}

export type FetchContractAddonsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type FetchContractAddonsQuery = {
  __typename: 'QueryRoot'
  contract: {
    __typename: 'Contract'
    qasaGuaranteeCost: number | null
    insuranceCost: number | null
    qasaGuarantee: boolean | null
    insurance: HomeInsuranceEnum | null
  }
}

export type HomeqUserClaimsQueryVariables = Exact<{ [key: string]: never }>

export type HomeqUserClaimsQuery = {
  __typename: 'QueryRoot'
  homeqUserClaims: {
    __typename: 'ConnectHubHomeQUserClaimsType'
    allowedClaimTypes: Array<ConnectHubHomeQClaimTypeEnum>
    currentClaims: Array<{
      __typename: 'ConnectHubHomeQCurrentClaimType'
      id: string
      renewed: boolean
      dateCreated: string
      expiryDate: string | null
      status: ConnectHubHomeQClaimStatusEnum
      type: ConnectHubHomeQClaimTypeEnum
      originalClaimId: string | null
      incomeSource: ConnectHubHomeQClaimIncomeSourceEnum
      income: number
      reason: string | null
      files: Array<{
        __typename: 'ConnectHubHomeQClaimFileType'
        id: string | null
        file: string | null
        classification: ConnectHubHomeQClaimFileClassificationEnum
        reference: string | null
      }> | null
      attributes: {
        __typename: 'ConnectHubHomeQClaimAttributesType'
        orgType: ConnectHubHomeQClaimOrgTypeEnum | null
        description: string | null
      }
    }>
  } | null
}

export type CreateHomeqClaimMutationVariables = Exact<{
  type: ConnectHubHomeQClaimTypeEnum
  orgType?: InputMaybe<ConnectHubHomeQClaimOrgTypeEnum>
}>

export type CreateHomeqClaimMutation = {
  __typename: 'MutationRoot'
  createHomeqClaim: {
    __typename: 'CreateClaimPayload'
    claim: { __typename: 'ConnectHubHomeQCurrentClaimType'; id: string } | null
  } | null
}

export type UpdateHomeqClaimMutationVariables = Exact<{
  claimId: Scalars['ID']['input']
  updateHomeqClaimInput: ConnectHubInputObjectsUpdateClaimInput
  renewed: Scalars['Boolean']['input']
}>

export type UpdateHomeqClaimMutation = {
  __typename: 'MutationRoot'
  updateHomeqClaim: { __typename: 'ConnectHubHomeQUpdateClaimPayload'; success: boolean | null } | null
}

export type CreateHomeqFileReferenceMutationVariables = Exact<{
  input: ConnectHubInputObjectsCreateFileReferenceInput
}>

export type CreateHomeqFileReferenceMutation = {
  __typename: 'MutationRoot'
  createHomeqFileReference: {
    __typename: 'ConnectHubHomeQCreateFileReferencePayload'
    fileReference: {
      __typename: 'ConnectHubHomeQFileReferenceType'
      fileReference: string | null
      signedUrl: string | null
      resourceUri: string | null
      headers: {
        __typename: 'ConnectHubHomeQFileReferenceHeadersType'
        contentMd5: string
        contentType: string
        xAmzAcl: string
      } | null
    } | null
  } | null
}

export type HomeqLandlordsQueryVariables = Exact<{ [key: string]: never }>

export type HomeqLandlordsQuery = {
  __typename: 'QueryRoot'
  homeqLandlordCompanies: Array<{
    __typename: 'ConnectHubHomeQLandlordCompanyType'
    id: string
    name: string
  }> | null
}

export type OnfidoSdkTokenQueryVariables = Exact<{ [key: string]: never }>

export type OnfidoSdkTokenQuery = { __typename: 'QueryRoot'; onfidoSdkToken: string }

export type InitiateOnfidoCheckMutationVariables = Exact<{
  reverify?: InputMaybe<Scalars['Boolean']['input']>
}>

export type InitiateOnfidoCheckMutation = {
  __typename: 'MutationRoot'
  initiateOnfidoCheck: { __typename: 'InitiateOnfidoCheckPayload'; success: boolean } | null
}

export type ActiveIdentificationsQueryVariables = Exact<{ [key: string]: never }>

export type ActiveIdentificationsQuery = {
  __typename: 'QueryRoot'
  me: {
    __typename: 'User'
    uid: string
    activeIdentifications: Array<{
      __typename: 'Identification'
      id: string
      identificationStatus: IdentificationStatusEnum
      identificationType: IdentificationTypeEnum
      identityConfirmed: boolean
      identityConfirmedAt: string | null
    }> | null
  } | null
}

export type OccupationFragmentFragment = {
  __typename: 'Occupation'
  current: boolean | null
  endedAt: string | null
  id: string
  occupationScheduleType: OccupationScheduleTypeEnum | null
  occupationType: OccupationTypeEnum
  startedAt: string
  subtitle: string | null
  title: string | null
}

export type UpsertOccupationMutationVariables = Exact<{
  input: UpsertOccupationInput
}>

export type UpsertOccupationMutation = {
  __typename: 'MutationRoot'
  upsertOccupation: {
    __typename: 'UpsertOccupationPayload'
    occupation: {
      __typename: 'Occupation'
      current: boolean | null
      endedAt: string | null
      id: string
      occupationScheduleType: OccupationScheduleTypeEnum | null
      occupationType: OccupationTypeEnum
      startedAt: string
      subtitle: string | null
      title: string | null
    } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type DestroyOccupationMutationMutationVariables = Exact<{
  destroyOccupationId: Scalars['ID']['input']
}>

export type DestroyOccupationMutationMutation = {
  __typename: 'MutationRoot'
  destroyOccupation: { __typename: 'DestroyOccupationPayload'; success: boolean } | null
}

export type CreateBlobMutationVariables = Exact<{
  input: CreateBlobInput
}>

export type CreateBlobMutation = {
  __typename: 'MutationRoot'
  createBlob: {
    __typename: 'CreateBlobPayload'
    id: string
    headers: Record<string, unknown>
    signedUrl: string
  } | null
}

export type AdminAccessTokenQueryVariables = Exact<{
  uid: Scalars['ID']['input']
}>

export type AdminAccessTokenQuery = {
  __typename: 'QueryRoot'
  adminAccessToken:
    | {
        __typename: 'AccessToken'
        expiresAt: string | null
        impersonatedByAdmin: boolean
        token: string
        uid: string
      }
    | { __typename: 'AuthorizationError'; message: string }
    | { __typename: 'NotFoundError'; message: string }
    | {
        __typename: 'ValidationError'
        message: string
        attributeErrors: Array<{
          __typename: 'AttributeError'
          attribute: string
          message: string
          type: string
        }>
      }
}

export type ConfirmEmailMutationVariables = Exact<{
  token: Scalars['String']['input']
}>

export type ConfirmEmailMutation = {
  __typename: 'MutationRoot'
  confirmEmail: {
    __typename: 'ConfirmEmailPayload'
    user: { __typename: 'User'; uid: string; emailConfirmed: boolean } | null
    errors: Array<{
      __typename: 'Validation'
      codesWithMessage: Array<{ __typename: 'CodesWithMessage'; message: string | null }> | null
    }> | null
  } | null
}

export type ContractPreviewQueryQueryVariables = Exact<{
  id: Scalars['ID']['input']
  language?: InputMaybe<ContractLanguageEnum>
}>

export type ContractPreviewQueryQuery = {
  __typename: 'QueryRoot'
  generateContractPreviewPdf: {
    __typename: 'ContractPreview'
    previewSource: string | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  }
}

export type SignatoriesQueryVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type SignatoriesQuery = {
  __typename: 'QueryRoot'
  contract: {
    __typename: 'Contract'
    signatories: Array<{ __typename: 'Signatory'; role: SignatoryRoleEnum; order: number | null }>
  }
}

export type EmploymentVerificationQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type EmploymentVerificationQuery = {
  __typename: 'QueryRoot'
  user: {
    __typename: 'User'
    uid: string
    employmentVerification: {
      __typename: 'EmploymentVerification'
      createdAt: string
      employerName: string
      id: string
      role: string | null
      updatedAt: string
      upload: {
        __typename: 'Upload'
        id: string
        title: string
        type: UploadTypeEnum
        url: string
        fileType: UploadFileTypeEnum
      } | null
    } | null
  } | null
}

export type UpsertEmploymentVerificationMutationVariables = Exact<{
  input: UpsertEmploymentVerificationInput
}>

export type UpsertEmploymentVerificationMutation = {
  __typename: 'MutationRoot'
  upsertEmploymentVerification: {
    __typename: 'UpsertEmploymentVerificationPayload'
    employmentVerification: {
      __typename: 'EmploymentVerification'
      employerName: string
      id: string
      role: string | null
      updatedAt: string
      createdAt: string
    } | null
  } | null
}

export type FavoritesQueryQueryVariables = Exact<{
  order?: InputMaybe<HomeSearchOrderEnum>
  orderBy?: InputMaybe<HomeSearchOrderByEnum>
  rentalTypes?: InputMaybe<Array<HomeRentalTypeEnum> | HomeRentalTypeEnum>
  offset?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type FavoritesQueryQuery = {
  __typename: 'QueryRoot'
  favoriteHomes: {
    __typename: 'HomeSearch'
    filterHomesOffset: {
      __typename: 'HomeOffsetLimit'
      pagesCount: number
      totalCount: number
      hasNextPage: boolean
      hasPreviousPage: boolean
      nodes: Array<{
        __typename: 'Home'
        id: string
        firsthand: boolean
        rent: number | null
        tenantBaseFee: number | null
        currency: CurrencyEnum
        title: string | null
        averagePricePerNight: number | null
        minimumPricePerNight: number | null
        maximumPricePerNight: number | null
        favoriteMarkedByUser: boolean
        displayStreetNumber: boolean
        roomCount: number | null
        seniorHome: boolean
        shared: boolean | null
        squareMeters: number | null
        studentHome: boolean
        type: HomeTypeEnum
        corporateHome: boolean
        numberOfHomes: number | null
        minRent: number | null
        maxRent: number | null
        minRoomCount: number | null
        maxRoomCount: number | null
        minSquareMeters: number | null
        maxSquareMeters: number | null
        rentalType: HomeRentalTypeEnum
        tenantCount: number | null
        bedCount: number | null
        bedroomCount: number | null
        floor: number | null
        buildingFloors: number | null
        landlord: {
          __typename: 'User'
          uid: string
          companyName: string | null
          premium: boolean
          professional: boolean
          proPilot: boolean
          profilePicture: { __typename: 'Upload'; url: string } | null
        }
        location: {
          __typename: 'Location'
          id: string
          latitude: number | null
          longitude: number | null
          route: string | null
          locality: string | null
          sublocality: string | null
          countryCode: string
          streetNumber: string | null
        }
        links: Array<{ __typename: 'Link'; locale: string; url: string }>
        duration: {
          __typename: 'Duration'
          createdAt: string | null
          endOptimal: string | null
          endUfn: boolean
          id: string
          startAsap: boolean
          startOptimal: string | null
          updatedAt: string | null
        }
        uploads: Array<{
          __typename: 'Upload'
          id: string
          url: string
          type: UploadTypeEnum
          title: string
          metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
        }>
        user: {
          __typename: 'User'
          uid: string
          proAgent: boolean
          firstName: string | null
          profilePicture: { __typename: 'Upload'; url: string } | null
        }
        instantSignSetting: { __typename: 'InstantSignSetting'; id: string; enabled: boolean } | null
        traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
      }>
    }
  }
}

export type HomePublishSuccessQueryQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type HomePublishSuccessQueryQuery = {
  __typename: 'QueryRoot'
  home: {
    __typename: 'Home'
    id: string
    status: HomeStatusEnum
    rentalType: HomeRentalTypeEnum
    landlord: { __typename: 'User'; uid: string }
    landlordServices: {
      __typename: 'HomeLandlordServices'
      insurance: { __typename: 'Service'; availability: ServiceAvailabilityEnum }
      qasaGuarantee: { __typename: 'Service'; availability: ServiceAvailabilityEnum }
    }
    market: { __typename: 'Market'; id: string; name: MarketNameEnum }
  } | null
}

export type HomeViewQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type HomeViewQuery = {
  __typename: 'QueryRoot'
  home: {
    __typename: 'Home'
    id: string
    landlordUnitId: string | null
    origin: string | null
    openForSigning: boolean
    matterportEmbedUrl: string | null
    external: boolean
    publishedAt: string | null
    type: HomeTypeEnum
    favoriteMarkedByUser: boolean
    displayStreetNumber: boolean
    title: string | null
    externalInfoPage: string | null
    externalApplicationUrl: string | null
    externalEmail: string | null
    insurance: HomeInsuranceEnum | null
    insuranceCost: number | null
    qasaGuarantee: boolean | null
    qasaGuaranteeCost: number | null
    shared: boolean | null
    description: string | null
    descriptionBuilding: string | null
    descriptionContract: string | null
    descriptionFeatures: string | null
    descriptionLayout: string | null
    descriptionTransportation: string | null
    housingAssociation: string | null
    energyClass: string | null
    kitchenRenovationYear: number | null
    bathroomRenovationYear: number | null
    buildYear: number | null
    rent: number | null
    tenantBaseFee: number | null
    roomCount: number | null
    squareMeters: number | null
    status: HomeStatusEnum
    firsthand: boolean
    seniorHome: boolean
    studentHome: boolean
    corporateHome: boolean
    tenantCount: number | null
    adPaidAt: string | null
    minTenantCount: number | null
    maxTenantCount: number | null
    minSquareMeters: number | null
    maxSquareMeters: number | null
    minRoomCount: number | null
    maxRoomCount: number | null
    minRent: number | null
    maxRent: number | null
    numberOfHomes: number | null
    rentalType: HomeRentalTypeEnum
    houseRules: string | null
    bedCount: number | null
    bedroomCount: number | null
    hasKitchen: boolean
    toiletCount: number | null
    responsibleForCleaning: ResponsibleForCleaningEnum | null
    cleaningFee: number
    floor: number | null
    buildingFloors: number | null
    applicationDeadline: string | null
    currency: CurrencyEnum
    applicationSortingType: ApplicationSortingTypeEnum | null
    applicationSelectionType: ApplicationSelectionTypeEnum | null
    canBePublished: boolean
    market: { __typename: 'Market'; id: string; name: MarketNameEnum }
    shortcut: { __typename: 'Shortcut'; id: string; homePitch: string | null } | null
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
      proPilot: boolean
      proAgent: boolean
      premium: boolean
      landlordApplicationResponseRate: number | null
      landlordApplicationResponseTimeHours: number | null
      currentIdentification: {
        __typename: 'Identification'
        identityConfirmed: boolean
        identityConfirmedAt: string | null
      } | null
      profilePicture: { __typename: 'Upload'; url: string } | null
      bio: { __typename: 'UserBio'; intro: string | null }
    }
    instantSignSetting: {
      __typename: 'InstantSignSetting'
      id: string
      enabled: boolean
      landlordHoursToSign: number
    } | null
    location: {
      __typename: 'Location'
      id: string
      latitude: number | null
      locality: string | null
      longitude: number | null
      route: string | null
      streetNumber: string | null
    }
    landlord: {
      __typename: 'User'
      uid: string
      landlordApplicationResponseRate: number | null
      landlordApplicationResponseTimeHours: number | null
      firstName: string | null
      professional: boolean
      premium: boolean
      companyName: string | null
      proPilot: boolean
      proAgent: boolean
      seenAt: string | null
      createdAt: string
      currentIdentification: {
        __typename: 'Identification'
        identityConfirmed: boolean
        identityConfirmedAt: string | null
      } | null
      profilePicture: { __typename: 'Upload'; url: string } | null
      contactLocation: {
        __typename: 'Location'
        postalCode: string | null
        route: string | null
        streetNumber: string | null
        locality: string | null
      } | null
      bio: { __typename: 'UserBio'; intro: string | null }
    }
    uploads: Array<{
      __typename: 'Upload'
      id: string
      url: string
      type: UploadTypeEnum
      metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
    }>
    traits: Array<{ __typename: 'Trait'; id: string; type: TraitTypeEnum }>
    duration: {
      __typename: 'Duration'
      id: string
      startOptimal: string | null
      endOptimal: string | null
      startAsap: boolean
      endUfn: boolean
      possibilityOfExtension: boolean
      durationPreference: { __typename: 'DurationPreference'; startLatest: string | null } | null
    }
    homeTemplates: Array<{
      __typename: 'HomeTemplate'
      id: string
      apartmentNumber: string | null
      squareMeters: number | null
      roomCount: number | null
      floor: number | null
      rent: number | null
      type: HomeTypeEnum
      description: string | null
      homeLayoutPictures: Array<{ __typename: 'Upload'; id: string; url: string }>
      traits: Array<{ __typename: 'Trait'; id: string; type: TraitTypeEnum }>
    }>
    tenantFees: {
      __typename: 'HomeTenantFees'
      base: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; factor: number | null; total: number }
      }
      waterFeePerTenant: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; isFree: boolean; fixed: number | null }
      }
      monthlyElectricityFee: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; isFree: boolean; fixed: number | null }
      } | null
    }
    electricityFee: {
      __typename: 'ElectricityFeeType'
      paymentPlan: ElectricityFeePaymentOptionEnum
      monthlyFee: number | null
    } | null
    property: {
      __typename: 'Property'
      minSquareMeters: number | null
      maxSquareMeters: number | null
      minRoomCount: number | null
      maxRoomCount: number | null
      minRent: number | null
      maxRent: number | null
      numberOfHomes: number | null
    } | null
    rentalRequirement: {
      __typename: 'RentalRequirement'
      approvedCreditCheck: boolean | null
      rentMultiplier: number | null
      studentVerification: boolean | null
      verifiedIdNumber: boolean | null
      verifiedIncome: boolean | null
    } | null
    landlordServices: {
      __typename: 'HomeLandlordServices'
      insurance: {
        __typename: 'Service'
        availability: ServiceAvailabilityEnum
        isActive: boolean
        cost: { __typename: 'Cost'; factor: number | null; isFree: boolean; total: number }
      }
      qasaGuarantee: {
        __typename: 'Service'
        availability: ServiceAvailabilityEnum
        isActive: boolean
        cost: { __typename: 'Cost'; factor: number | null; isFree: boolean; total: number }
      }
    }
    landlordFees: {
      __typename: 'HomeLandlordFees'
      base: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; factor: number | null; total: number }
      }
      listingPublicationFee: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
      }
    }
    pricingModel: {
      __typename: 'PricingModel'
      insuranceCostFactor: number
      qasaGuaranteeCostFactor: number
      tenantBaseFeeFactor: number
    }
    currentCancellationPolicy: {
      __typename: 'CancellationPolicy'
      policyName: CancellationPolicyNameEnum | null
      partialRefundFactor: number | null
      partialRefundDays: number | null
      fullRefundDays: number | null
    } | null
  } | null
}

export type ApplicationForHomeQueryVariables = Exact<{
  home_id?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  status?: InputMaybe<Array<HomeApplicationStatusEnum> | HomeApplicationStatusEnum>
}>

export type ApplicationForHomeQuery = {
  __typename: 'QueryRoot'
  homeApplications: {
    __typename: 'HomeApplicationConnection'
    nodes: Array<{
      __typename: 'HomeApplication'
      status: HomeApplicationStatusEnum
      id: string
      homeTemplates: Array<{ __typename: 'HomeTemplate'; id: string }>
      home: {
        __typename: 'Home'
        id: string
        homeTemplates: Array<{
          __typename: 'HomeTemplate'
          id: string
          apartmentNumber: string | null
          rent: number | null
        }>
      }
      conversation: { __typename: 'Conversation'; id: string } | null
      contract: { __typename: 'Contract'; id: string } | null
    }>
  }
}

export type DuplicateHomeMutationVariables = Exact<{
  id: Scalars['ID']['input']
  endOptimal?: InputMaybe<Scalars['DateTime']['input']>
  startOptimal?: InputMaybe<Scalars['DateTime']['input']>
}>

export type DuplicateHomeMutation = {
  __typename: 'MutationRoot'
  duplicateHome: {
    __typename: 'DuplicateHomePayload'
    home: { __typename: 'Home'; id: string } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type PublishHomeMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type PublishHomeMutation = {
  __typename: 'MutationRoot'
  publishHome: {
    __typename: 'PublishHomePayload'
    home: { __typename: 'Home'; id: string } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type ValidateInviteCodeQueryVariables = Exact<{
  claimKey: Scalars['String']['input']
}>

export type ValidateInviteCodeQuery = {
  __typename: 'QueryRoot'
  contractTenantInvitation: { __typename: 'ContractTenantInvitation'; claimable: boolean }
}

export type LandlordPreSignContractQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type LandlordPreSignContractQuery = {
  __typename: 'QueryRoot'
  contract: {
    __typename: 'Contract'
    termsUrl: string | null
    signUrl: string | null
    id: string
    type: ContractTypeEnum
    terminationNotice: boolean | null
    tenureType: TenureTypeEnum | null
    fee: number | null
    qasaGuarantee: boolean | null
    qasaGuaranteeCost: number | null
    insuranceCost: number | null
    acceptsCosignatures: boolean | null
    signedAt: string | null
    status: ContractStatusEnum
    landlordHasKeys: boolean | null
    insuranceResponsibility: InsuranceResponsibilityEnum | null
    rent: number | null
    inventory: Array<string>
    payoutDay: number
    paymentDay: number
    canUse: Array<string>
    rentIncludes: Array<string>
    rentExcludes: Array<string>
    subletception: boolean | null
    renovations: boolean | null
    preCleaning: ContractPreCleaningEnum | null
    currency: CurrencyEnum
    postCleaning: ContractPostCleaningEnum | null
    temporaryInstallations: boolean | null
    smallRepairs: ContractSmallRepairsEnum | null
    pets: boolean | null
    smoker: boolean | null
    ownHome: boolean | null
    furniture: boolean | null
    overnightStay: boolean | null
    signingProcess: SigningProcessEnum
    conversation: { __typename: 'Conversation'; id: string } | null
    tenantContactInfo: { __typename: 'TenantContactInfo'; familyName: string | null } | null
    landlordFees: {
      __typename: 'ContractLandlordFees'
      base: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; factor: number | null; total: number }
      }
    }
    home: {
      __typename: 'Home'
      id: string
      ownHome: boolean | null
      tenureType: TenureTypeEnum | null
      currency: CurrencyEnum
      qasaGuaranteeCost: number | null
      insuranceCost: number | null
      qasaGuarantee: boolean | null
      insurance: HomeInsuranceEnum | null
      rent: number | null
      rentalType: HomeRentalTypeEnum
      roomCount: number | null
      squareMeters: number | null
      bedCount: number | null
      bedroomCount: number | null
      tenantCount: number | null
      status: HomeStatusEnum
      title: string | null
      type: HomeTypeEnum
      safeRental: boolean | null
      apartmentNumber: string | null
      tenantBaseFee: number | null
      instantSignSetting: {
        __typename: 'InstantSignSetting'
        landlordHoursToSign: number
        enabled: boolean
      } | null
      inspection: {
        __typename: 'Inspection'
        id: string
        observations: Array<{
          __typename: 'InspectionObservation'
          description: string | null
          section: InspectionRoomsEnum
        }> | null
      } | null
      market: { __typename: 'Market'; id: string; name: MarketNameEnum; currency: MarketCurrencyEnum }
      tenantFees: {
        __typename: 'HomeTenantFees'
        waterFeePerTenant: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: { __typename: 'Cost'; fixed: number | null }
        }
        monthlyElectricityFee: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
        } | null
      }
      duration: {
        __typename: 'Duration'
        id: string
        startOptimal: string | null
        startAsap: boolean
        endUfn: boolean
        endOptimal: string | null
        updatedAt: string | null
      }
      landlord: {
        __typename: 'User'
        uid: string
        professional: boolean
        companyName: string | null
        firstName: string | null
      }
      location: {
        __typename: 'Location'
        latitude: number | null
        locality: string | null
        longitude: number | null
        postalCode: string | null
        route: string | null
        streetNumber: string | null
        googleMapsPlaceId: string
        countryCode: string
        id: string
      }
      uploads: Array<{
        __typename: 'Upload'
        id: string
        url: string
        metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
      }>
      pricingModel: {
        __typename: 'PricingModel'
        insuranceCostFactor: number
        qasaGuaranteeCostFactor: number
      }
      electricityFee: {
        __typename: 'ElectricityFeeType'
        paymentPlan: ElectricityFeePaymentOptionEnum
        monthlyFee: number | null
      } | null
      landlordServices: {
        __typename: 'HomeLandlordServices'
        insurance: {
          __typename: 'Service'
          availability: ServiceAvailabilityEnum
          isActive: boolean
          cost: { __typename: 'Cost'; factor: number | null; isFree: boolean; total: number }
        }
        qasaGuarantee: {
          __typename: 'Service'
          availability: ServiceAvailabilityEnum
          isActive: boolean
          cost: { __typename: 'Cost'; isFree: boolean; factor: number | null; total: number }
        }
      }
    } | null
    marketDetails:
      | { __typename: 'FinlandMarketDetails' }
      | {
          __typename: 'FranceMarketDetails'
          recoverableCharges: {
            __typename: 'FranceRecoverableChargesType'
            monthlyCost: number | null
            paymentPlan: FranceRecoverableChargesPaymentPlanEnum
          } | null
        }
      | null
    market: { __typename: 'Market'; id: string; name: MarketNameEnum }
    externalContractTakeover: {
      __typename: 'ExternalContractTakeover'
      currentState: ExternalContractTakeoverStateEnum
    } | null
    currentUserSignatory: { __typename: 'Signatory'; id: string; signedAt: string | null; canSign: boolean }
    user: { __typename: 'User'; uid: string; proAgent: boolean }
    signatories: Array<{
      __typename: 'Signatory'
      id: string
      role: SignatoryRoleEnum
      signedAt: string | null
      order: number | null
      signingDeadline: string | null
      canSign: boolean
      user: { __typename: 'User'; uid: string }
    }>
    tenant: {
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
      profilePicture: { __typename: 'Upload'; url: string } | null
      currentIdentification: {
        __typename: 'Identification'
        identityConfirmed: boolean
        identityConfirmedAt: string | null
      } | null
    } | null
    landlord: {
      __typename: 'User'
      uid: string
      firstName: string | null
      safeRental: boolean | null
      companyName: string | null
      professional: boolean
      profilePicture: { __typename: 'Upload'; url: string } | null
    }
    rentalInsuranceMilaRequest: {
      __typename: 'FranceInsuranceRequestType'
      id: string
      currentState: FranceInsuranceRequestStateEnum | null
      documentRequests: Array<{ __typename: 'FranceDocumentRequestType'; id: string }> | null
    } | null
    cosigningTenants: Array<{
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
      profilePicture: { __typename: 'Upload'; url: string } | null
    }> | null
    tenantInvitations: Array<{
      __typename: 'ContractTenantInvitation'
      claimKey: string | null
      claimable: boolean
      invitedBy: {
        __typename: 'User'
        uid: string
        firstName: string | null
        companyName: string | null
        professional: boolean
      } | null
    }> | null
    duration: {
      __typename: 'Duration'
      id: string
      endOptimal: string | null
      endUfn: boolean
      startAsap: boolean
      startOptimal: string | null
      updatedAt: string | null
    } | null
    upload: { __typename: 'Upload'; url: string } | null
    contractDetails: {
      __typename: 'ContractDetails'
      tenantFirstName: string | null
      tenantFamilyName: string | null
      tenantEmail: string | null
      tenantPhoneNumber: string | null
      legalType: LegalTypeEnum | null
      bindingTime: number | null
      canBeTerminated: boolean | null
      terminationNoticeMonths: number | null
      shorterTerminationExplanation: string | null
      knownDamages: string | null
      keySpecification: string | null
      codeOfConduct: string | null
      additionalInformation: string | null
      cadastralReference: string | null
      sectionNumber: string | null
      municipality: string | null
      titleNumber: string | null
      bathroomShared: boolean | null
      kitchenShared: boolean | null
      rentedAsIs: boolean | null
    } | null
    extraCosts: Array<{ __typename: 'ExtraCost'; costFor: ExtraCostForEnum; plan: ExtraCostPlanEnum }> | null
    tenantServices: {
      __typename: 'ContractTenantServices'
      depositFree: {
        __typename: 'Service'
        availability: ServiceAvailabilityEnum
        isActive: boolean
        cost: { __typename: 'Cost'; isFree: boolean; factor: number | null; total: number }
      }
    }
    landlordServices: {
      __typename: 'ContractLandlordServices'
      insurance: {
        __typename: 'Service'
        availability: ServiceAvailabilityEnum
        isActive: boolean
        cost: { __typename: 'Cost'; factor: number | null; isFree: boolean; total: number }
      }
      qasaGuarantee: {
        __typename: 'Service'
        availability: ServiceAvailabilityEnum
        isActive: boolean
        cost: { __typename: 'Cost'; isFree: boolean; factor: number | null; total: number }
      }
    }
    ifInsurancePromotion: { __typename: 'IfInsurancePromotion'; signedUp: boolean | null } | null
  }
}

export type DashboardAttachmentsSectionFragment = {
  __typename: 'Home'
  id: string
  dashboardStatus: HomeDashboardStatusEnum
  simplifiedStatus: SimplifiedHomeStatusEnum
  market: { __typename: 'Market'; id: string; name: MarketNameEnum }
  inspection: {
    __typename: 'Inspection'
    id: string
    observations: Array<{
      __typename: 'InspectionObservation'
      description: string | null
      section: InspectionRoomsEnum
    }> | null
  } | null
}

export type ApplicationsCardFragment = {
  __typename: 'Home'
  id: string
  simplifiedStatus: SimplifiedHomeStatusEnum
  applicationForecast: number | null
  applicationStats: { __typename: 'HomeApplicationStats'; tenantApplicationCount: number }
}

export type LikesCardFragment = {
  __typename: 'Home'
  id: string
  simplifiedStatus: SimplifiedHomeStatusEnum
  favoriteMarkedCount: number
}

export type MatchingTenantsCardFragment = {
  __typename: 'Home'
  id: string
  simplifiedStatus: SimplifiedHomeStatusEnum
  matchingTenantsCount: number
}

export type StatusCardFragment = {
  __typename: 'Home'
  id: string
  dashboardStatus: HomeDashboardStatusEnum
  publishedAt: string | null
  expiresAt: string | null
  lastBumpedAt: string | null
  activeContract: {
    __typename: 'Contract'
    id: string
    duration: {
      __typename: 'Duration'
      startOptimal: string | null
      endUfn: boolean
      endOptimal: string | null
    } | null
  } | null
}

export type UnreadMessagesCardFragment = {
  __typename: 'Home'
  id: string
  simplifiedStatus: SimplifiedHomeStatusEnum
  messagesCount: number
  unreadMessagesCount: number
}

export type ViewsCardFragment = {
  __typename: 'Home'
  id: string
  simplifiedStatus: SimplifiedHomeStatusEnum
  pageViews: number
  market: { __typename: 'Market'; name: MarketNameEnum }
}

export type BankAccountCardFragment = { __typename: 'Home'; simplifiedStatus: SimplifiedHomeStatusEnum }

export type DepositCardFragment = {
  __typename: 'Home'
  simplifiedStatus: SimplifiedHomeStatusEnum
  currency: CurrencyEnum
  rent: number | null
  market: { __typename: 'Market'; name: MarketNameEnum }
}

export type RentCardFragment = {
  __typename: 'Home'
  id: string
  simplifiedStatus: SimplifiedHomeStatusEnum
  rent: number | null
  currency: CurrencyEnum
  market: { __typename: 'Market'; name: MarketNameEnum }
  activeContract: { __typename: 'Contract'; rent: number | null } | null
}

export type EvictionCardFragment = {
  __typename: 'Home'
  simplifiedStatus: SimplifiedHomeStatusEnum
  market: { __typename: 'Market'; id: string; name: MarketNameEnum }
}

export type InsuranceCardFragment = {
  __typename: 'Home'
  simplifiedStatus: SimplifiedHomeStatusEnum
  market: { __typename: 'Market'; id: string; name: MarketNameEnum }
  landlordServices: {
    __typename: 'HomeLandlordServices'
    insurance: { __typename: 'Service'; availability: ServiceAvailabilityEnum }
  }
}

export type RentGuaranteeCardFragment = {
  __typename: 'Home'
  simplifiedStatus: SimplifiedHomeStatusEnum
  market: { __typename: 'Market'; id: string; name: MarketNameEnum }
  landlordServices: {
    __typename: 'HomeLandlordServices'
    qasaGuarantee: { __typename: 'Service'; availability: ServiceAvailabilityEnum }
  }
}

export type SupportCardFragment = {
  __typename: 'Home'
  simplifiedStatus: SimplifiedHomeStatusEnum
  market: { __typename: 'Market'; id: string; name: MarketNameEnum }
}

export type DashboardHeaderFragment = {
  __typename: 'Home'
  id: string
  simplifiedStatus: SimplifiedHomeStatusEnum
  dashboardStatus: HomeDashboardStatusEnum
  canBeDeleted: boolean
  expiresAt: string | null
  homeTemplates: Array<{ __typename: 'HomeTemplate'; id: string }>
  location: { __typename: 'Location'; route: string | null }
  duration: {
    __typename: 'Duration'
    startOptimal: string | null
    endOptimal: string | null
    startAsap: boolean
    endUfn: boolean
  }
  uploads: Array<{ __typename: 'Upload'; url: string }>
  activeContract: {
    __typename: 'Contract'
    id: string
    tenant: {
      __typename: 'User'
      uid: string
      firstName: string | null
      profilePicture: { __typename: 'Upload'; url: string } | null
      currentIdentification: { __typename: 'Identification'; identityConfirmed: boolean } | null
    } | null
  } | null
}

export type TenantCardFragment = {
  __typename: 'Contract'
  tenant: {
    __typename: 'User'
    uid: string
    firstName: string | null
    profilePicture: { __typename: 'Upload'; url: string } | null
    currentIdentification: { __typename: 'Identification'; identityConfirmed: boolean } | null
  } | null
}

export type UpsertInspectionMutationVariables = Exact<{
  homeId: Scalars['ID']['input']
  observations?: InputMaybe<Array<InspectionObservationInput> | InspectionObservationInput>
}>

export type UpsertInspectionMutation = {
  __typename: 'MutationRoot'
  upsertInspection: {
    __typename: 'UpsertInspectionPayload'
    inspection: {
      __typename: 'Inspection'
      id: string
      observations: Array<{
        __typename: 'InspectionObservation'
        description: string | null
        section: InspectionRoomsEnum
      }> | null
    } | null
  } | null
}

export type DashboardQueryQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DashboardQueryQuery = {
  __typename: 'QueryRoot'
  home: {
    __typename: 'Home'
    id: string
    dashboardStatus: HomeDashboardStatusEnum
    rentalType: HomeRentalTypeEnum
    simplifiedStatus: SimplifiedHomeStatusEnum
    canBeDeleted: boolean
    expiresAt: string | null
    publishedAt: string | null
    lastBumpedAt: string | null
    matchingTenantsCount: number
    messagesCount: number
    unreadMessagesCount: number
    pageViews: number
    favoriteMarkedCount: number
    applicationForecast: number | null
    rent: number | null
    currency: CurrencyEnum
    activeContract: {
      __typename: 'Contract'
      id: string
      rent: number | null
      duration: {
        __typename: 'Duration'
        startOptimal: string | null
        endUfn: boolean
        endOptimal: string | null
      } | null
      tenant: {
        __typename: 'User'
        uid: string
        firstName: string | null
        profilePicture: { __typename: 'Upload'; url: string } | null
        currentIdentification: { __typename: 'Identification'; identityConfirmed: boolean } | null
      } | null
    } | null
    user: { __typename: 'User'; uid: string }
    location: { __typename: 'Location'; route: string | null; streetNumber: string | null }
    listingBumpPayment: { __typename: 'StripePayment'; currency: CurrencyEnum; price: number }
    market: { __typename: 'Market'; id: string; name: MarketNameEnum }
    inspection: {
      __typename: 'Inspection'
      id: string
      observations: Array<{
        __typename: 'InspectionObservation'
        description: string | null
        section: InspectionRoomsEnum
      }> | null
    } | null
    homeTemplates: Array<{ __typename: 'HomeTemplate'; id: string }>
    duration: {
      __typename: 'Duration'
      startOptimal: string | null
      endOptimal: string | null
      startAsap: boolean
      endUfn: boolean
    }
    uploads: Array<{ __typename: 'Upload'; url: string }>
    applicationStats: { __typename: 'HomeApplicationStats'; tenantApplicationCount: number }
    landlordFees: {
      __typename: 'HomeLandlordFees'
      base: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; factor: number | null; total: number }
      }
    }
    landlordServices: {
      __typename: 'HomeLandlordServices'
      insurance: { __typename: 'Service'; availability: ServiceAvailabilityEnum }
      qasaGuarantee: { __typename: 'Service'; availability: ServiceAvailabilityEnum }
    }
  } | null
}

export type DashboardOverviewSectionFragment = {
  __typename: 'Home'
  id: string
  simplifiedStatus: SimplifiedHomeStatusEnum
  dashboardStatus: HomeDashboardStatusEnum
  canBeDeleted: boolean
  expiresAt: string | null
  publishedAt: string | null
  lastBumpedAt: string | null
  matchingTenantsCount: number
  messagesCount: number
  unreadMessagesCount: number
  pageViews: number
  favoriteMarkedCount: number
  applicationForecast: number | null
  rent: number | null
  currency: CurrencyEnum
  homeTemplates: Array<{ __typename: 'HomeTemplate'; id: string }>
  location: { __typename: 'Location'; route: string | null }
  duration: {
    __typename: 'Duration'
    startOptimal: string | null
    endOptimal: string | null
    startAsap: boolean
    endUfn: boolean
  }
  uploads: Array<{ __typename: 'Upload'; url: string }>
  activeContract: {
    __typename: 'Contract'
    id: string
    duration: {
      __typename: 'Duration'
      startOptimal: string | null
      endUfn: boolean
      endOptimal: string | null
    } | null
    tenant: {
      __typename: 'User'
      uid: string
      firstName: string | null
      profilePicture: { __typename: 'Upload'; url: string } | null
      currentIdentification: { __typename: 'Identification'; identityConfirmed: boolean } | null
    } | null
  } | null
  market: { __typename: 'Market'; name: MarketNameEnum }
  applicationStats: { __typename: 'HomeApplicationStats'; tenantApplicationCount: number }
  landlordFees: {
    __typename: 'HomeLandlordFees'
    base: {
      __typename: 'Fee'
      availability: FeeAvailabilityEnum
      cost: { __typename: 'Cost'; factor: number | null; total: number }
    }
  }
}

export type DashboardRentSectionFragment = {
  __typename: 'Home'
  dashboardStatus: HomeDashboardStatusEnum
  id: string
  simplifiedStatus: SimplifiedHomeStatusEnum
  rent: number | null
  currency: CurrencyEnum
  market: { __typename: 'Market'; name: MarketNameEnum }
  activeContract: { __typename: 'Contract'; rent: number | null } | null
}

export type DashboardRentalTimelineFragment = {
  __typename: 'Home'
  simplifiedStatus: SimplifiedHomeStatusEnum
  rent: number | null
  currency: CurrencyEnum
  duration: {
    __typename: 'Duration'
    startOptimal: string | null
    startAsap: boolean
    endOptimal: string | null
    endUfn: boolean
  }
  landlordFees: {
    __typename: 'HomeLandlordFees'
    base: {
      __typename: 'Fee'
      availability: FeeAvailabilityEnum
      cost: { __typename: 'Cost'; factor: number | null; total: number }
    }
  }
}

export type DashboardSafetySectionFragment = {
  __typename: 'Home'
  dashboardStatus: HomeDashboardStatusEnum
  simplifiedStatus: SimplifiedHomeStatusEnum
  market: { __typename: 'Market'; id: string; name: MarketNameEnum }
  landlordServices: {
    __typename: 'HomeLandlordServices'
    insurance: { __typename: 'Service'; availability: ServiceAvailabilityEnum }
    qasaGuarantee: { __typename: 'Service'; availability: ServiceAvailabilityEnum }
  }
}

export type ListingDashboardVacationQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ListingDashboardVacationQuery = {
  __typename: 'QueryRoot'
  home: {
    __typename: 'Home'
    id: string
    currency: CurrencyEnum
    expiresAt: string | null
    canBeDeleted: boolean
    rentalType: HomeRentalTypeEnum
    title: string | null
    dashboardStatus: HomeDashboardStatusEnum
    lastBumpedAt: string | null
    type: HomeTypeEnum
    rent: number | null
    roomCount: number | null
    squareMeters: number | null
    bedCount: number | null
    tenantCount: number | null
    bedroomCount: number | null
    status: HomeStatusEnum
    archiveReason: HomeArchiveReasonEnum | null
    publishedAt: string | null
    location: {
      __typename: 'Location'
      route: string | null
      streetNumber: string | null
      id: string
      locality: string | null
    }
    user: { __typename: 'User'; uid: string }
    listingBumpPayment: { __typename: 'StripePayment'; currency: CurrencyEnum; price: number }
    uploads: Array<{
      __typename: 'Upload'
      id: string
      url: string
      metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
    }>
    duration: {
      __typename: 'Duration'
      id: string
      startOptimal: string | null
      endOptimal: string | null
      startAsap: boolean
      endUfn: boolean
    }
    market: { __typename: 'Market'; id: string; name: MarketNameEnum }
  } | null
}

export type DeleteListingMutationMutationVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type DeleteListingMutationMutation = {
  __typename: 'MutationRoot'
  deleteHome: { __typename: 'DeleteHomePayload'; homeId: string | null } | null
}

export type ReservationSummaryQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ReservationSummaryQuery = {
  __typename: 'QueryRoot'
  home: {
    __typename: 'Home'
    id: string
    type: HomeTypeEnum
    currency: CurrencyEnum
    title: string | null
    shared: boolean | null
    description: string | null
    roomCount: number | null
    squareMeters: number | null
    status: HomeStatusEnum
    rentalType: HomeRentalTypeEnum
    responsibleForCleaning: ResponsibleForCleaningEnum | null
    bedCount: number | null
    tenantCount: number | null
    bedroomCount: number | null
    cleaningFee: number
    location: {
      __typename: 'Location'
      id: string
      locality: string | null
      route: string | null
      streetNumber: string | null
    }
    landlord: {
      __typename: 'User'
      uid: string
      landlordApplicationResponseRate: number | null
      landlordApplicationResponseTimeHours: number | null
      firstName: string | null
      companyName: string | null
      proPilot: boolean
      professional: boolean
      currentIdentification: {
        __typename: 'Identification'
        identityConfirmed: boolean
        identityConfirmedAt: string | null
      } | null
      profilePicture: { __typename: 'Upload'; url: string } | null
    }
    uploads: Array<{
      __typename: 'Upload'
      id: string
      url: string
      metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
    }>
    currentCancellationPolicy: {
      __typename: 'CancellationPolicy'
      policyName: CancellationPolicyNameEnum | null
      partialRefundFactor: number | null
      partialRefundDays: number | null
      fullRefundDays: number | null
    } | null
    market: { __typename: 'Market'; id: string; name: MarketNameEnum }
  } | null
}

export type StudentCredentialsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type StudentCredentialsQuery = {
  __typename: 'QueryRoot'
  user: {
    __typename: 'User'
    uid: string
    studentVerification: {
      __typename: 'StudentVerification'
      id: string
      educationType: StudentVerificationEducationTypeEnum | null
      extent: StudentVerificationExtentEnum
      expectedFinishDate: string
      program: string
      school: string
      startDate: string
      upload: {
        __typename: 'Upload'
        id: string
        title: string
        type: UploadTypeEnum
        url: string
        fileType: UploadFileTypeEnum
      } | null
    } | null
  } | null
}

export type UpsertStudentCredentialsMutationVariables = Exact<{
  input: UpsertStudentVerificationInput
}>

export type UpsertStudentCredentialsMutation = {
  __typename: 'MutationRoot'
  upsertStudentVerification: {
    __typename: 'UpsertStudentVerificationPayload'
    studentVerification: {
      __typename: 'StudentVerification'
      id: string
      educationType: StudentVerificationEducationTypeEnum | null
      extent: StudentVerificationExtentEnum
      school: string
      program: string
      startDate: string
      expectedFinishDate: string
      createdAt: string
      updatedAt: string
    } | null
  } | null
}

export type ContractSignLandingQueryQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ContractSignLandingQueryQuery = {
  __typename: 'QueryRoot'
  contract: {
    __typename: 'Contract'
    termsUrl: string | null
    signUrl: string | null
    rent: number | null
    market: { __typename: 'Market'; name: MarketNameEnum }
    landlord: { __typename: 'User'; uid: string; firstName: string | null }
    tenant: { __typename: 'User'; uid: string; firstName: string | null } | null
    home: {
      __typename: 'Home'
      location: {
        __typename: 'Location'
        id: string
        locality: string | null
        route: string | null
        streetNumber: string | null
      }
      landlordServices: {
        __typename: 'HomeLandlordServices'
        qasaGuarantee: { __typename: 'Service'; isActive: boolean; availability: ServiceAvailabilityEnum }
        insurance: { __typename: 'Service'; isActive: boolean; availability: ServiceAvailabilityEnum }
      }
    } | null
    duration: {
      __typename: 'Duration'
      startOptimal: string | null
      endOptimal: string | null
      endUfn: boolean
    } | null
  }
}

export type VerifyBankAccountMutationVariables = Exact<{
  verificationTokenId: Scalars['ID']['input']
}>

export type VerifyBankAccountMutation = {
  __typename: 'MutationRoot'
  verifyBankAccount: {
    __typename: 'VerifyBankAccountPayload'
    bankAccount: { __typename: 'BankAccount'; id: string; verified: boolean }
  } | null
}

export type HomeqHomeApplicationRequirementsQueryVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type HomeqHomeApplicationRequirementsQuery = {
  __typename: 'QueryRoot'
  homeqHomeApplicationPreconditions: {
    __typename: 'ConnectHubHomeQHomeApplicationPreconditions'
    activeAd: boolean
    allowsWarrantor: boolean
    applicantGroupAlreadyApplied: boolean
    applicantGroupContainsLocked: boolean
    applicantGroupExceedLimit: boolean
    applicantIncompleteProfile: boolean
    applicantMissingOccupation: boolean
    canApply: boolean
    claimedWarrantor: boolean
    coApplicantMissingInfo: boolean
    isMainApplicant: boolean
    restrictedDueToSeniorStatus: boolean
    restrictedDueToStudentStatus: boolean
    restrictedDueToYouthStatus: boolean
    warrantorMissingInfo: boolean
  } | null
}

export type HomeqApplicationUserDataQueryVariables = Exact<{ [key: string]: never }>

export type HomeqApplicationUserDataQuery = {
  __typename: 'QueryRoot'
  me: {
    __typename: 'User'
    uid: string
    firstName: string | null
    emailConfirmed: boolean
    householdMembersBirthYears: Array<number> | null
    private: {
      __typename: 'UserPrivate'
      familyName: string | null
      phoneNumber: string | null
      email: string
      birthDate: string | null
      homeqUid: string | null
    }
    bio: { __typename: 'UserBio'; intro: string | null }
    creditReport: { __typename: 'CreditReport'; id: string; fetchStatus: CreditReportFetchStatusEnum } | null
    currentIdentification: {
      __typename: 'Identification'
      id: string
      identificationType: IdentificationTypeEnum
      identificationStatus: IdentificationStatusEnum
      identityConfirmed: boolean
      idNumber: string | null
      firstName: string | null
      familyName: string | null
    } | null
    housingSituation: {
      __typename: 'HousingSituation'
      id: string
      agreementType: HousingSituationAgreementTypeEnum | null
      landlordName: string | null
      landlordPhoneNumber: string | null
      landlordCity: string | null
      externalLandlordReference: string | null
    } | null
  } | null
}

export type HomeqApplicationUserClaimsQueryVariables = Exact<{ [key: string]: never }>

export type HomeqApplicationUserClaimsQuery = {
  __typename: 'QueryRoot'
  homeqUserClaims: {
    __typename: 'ConnectHubHomeQUserClaimsType'
    allowedClaimTypes: Array<ConnectHubHomeQClaimTypeEnum>
    currentClaims: Array<{
      __typename: 'ConnectHubHomeQCurrentClaimType'
      id: string
      status: ConnectHubHomeQClaimStatusEnum
      type: ConnectHubHomeQClaimTypeEnum
      income: number
      expiryDate: string | null
      files: Array<{
        __typename: 'ConnectHubHomeQClaimFileType'
        id: string | null
        file: string | null
        classification: ConnectHubHomeQClaimFileClassificationEnum
        reference: string | null
      }> | null
      attributes: { __typename: 'ConnectHubHomeQClaimAttributesType'; description: string | null }
    }>
  } | null
}

export type FindHomeQUserQueryVariables = Exact<{ [key: string]: never }>

export type FindHomeQUserQuery = {
  __typename: 'QueryRoot'
  homeqUser: {
    __typename: 'ConnectHubHomeQUserType'
    id: string
    email: string
    firstName: string
    lastName: string
  } | null
}

export type SendEmailConfirmationTokenPayloadMutationVariables = Exact<{
  redirectPath: Scalars['String']['input']
}>

export type SendEmailConfirmationTokenPayloadMutation = {
  __typename: 'MutationRoot'
  sendEmailConfirmationToken: {
    __typename: 'SendEmailConfirmationTokenPayload'
    success: boolean | null
  } | null
}

export type ConnectHomeQUserMutationVariables = Exact<{ [key: string]: never }>

export type ConnectHomeQUserMutation = {
  __typename: 'MutationRoot'
  connectHomeqUser: {
    __typename: 'ConnectHubHomeQConnectUserPayload'
    success: boolean | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string> }> | null
  } | null
}

export type CreateApplicationMutationVariables = Exact<{
  home_id: Scalars['ID']['input']
  counter_offer: Scalars['Int']['input']
  homeTemplateIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  start_optimal?: InputMaybe<Scalars['DateTime']['input']>
  message?: InputMaybe<Scalars['String']['input']>
}>

export type CreateApplicationMutation = {
  __typename: 'MutationRoot'
  createTenantHomeApplication: {
    __typename: 'CreateTenantHomeApplicationPayload'
    homeApplication: { __typename: 'HomeApplication'; id: string; startOptimal: string | null } | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type CreateHomeqApplicationMutationVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type CreateHomeqApplicationMutation = {
  __typename: 'MutationRoot'
  createHomeqApplication: {
    __typename: 'ConnectHubHomeQCreateApplicationPayload'
    homeApplication: { __typename: 'ConnectHubHomeQHomeApplicationType'; id: string } | null
  } | null
}

export type CreateCreditReportMutationVariables = Exact<{
  authToken: Scalars['String']['input']
}>

export type CreateCreditReportMutation = {
  __typename: 'MutationRoot'
  createCreditReport: {
    __typename: 'CreateCreditReportPayload'
    creditReport: { __typename: 'CreditReport'; id: string; fetchStatus: CreditReportFetchStatusEnum } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type GetCreditReportQueryVariables = Exact<{ [key: string]: never }>

export type GetCreditReportQuery = {
  __typename: 'QueryRoot'
  me: {
    __typename: 'User'
    uid: string
    creditReport: {
      __typename: 'CreditReport'
      createdAt: string
      fetchStatus: CreditReportFetchStatusEnum
      id: string
      numberPaymentRemarks: number | null
      totalDebt: number | null
      guardianship: boolean | null
      seizureAttempt: boolean | null
      debtSanitization: boolean | null
      expiresOn: string
      currentAddress: {
        __typename: 'CurrentAddressType'
        city: string
        postalCode: string
        street: string
      } | null
      moveInfo: { __typename: 'MoveInfoType'; count: number; years: number } | null
      yearlyIncomeStatements: Array<{
        __typename: 'YearlyIncomeStatementsType'
        amount: number
        year: string
      }> | null
    } | null
  } | null
}

export type DestroyCreditReportMutationVariables = Exact<{
  creditReportId: Scalars['ID']['input']
}>

export type DestroyCreditReportMutation = {
  __typename: 'MutationRoot'
  destroyCreditReport: {
    __typename: 'DestroyCreditReportPayload'
    creditReport: { __typename: 'CreditReport'; id: string }
  } | null
}

export type HouseholdMembersQueryVariables = Exact<{ [key: string]: never }>

export type HouseholdMembersQuery = {
  __typename: 'QueryRoot'
  me: {
    __typename: 'User'
    uid: string
    firstName: string | null
    householdMembersBirthYears: Array<number> | null
    private: { __typename: 'UserPrivate'; familyName: string | null; birthDate: string | null }
  } | null
}

export type SendSignInCodeMutationVariables = Exact<{
  email: Scalars['String']['input']
  platform: PlatformEnum
}>

export type SendSignInCodeMutation = {
  __typename: 'MutationRoot'
  sendSignInCode: { __typename: 'SendSignInCodePayload'; success: boolean } | null
}

export type ExchangeSignInCodeMutationVariables = Exact<{
  code: Scalars['String']['input']
}>

export type ExchangeSignInCodeMutation = {
  __typename: 'MutationRoot'
  exchangeSignInCode: {
    __typename: 'ExchangeSignInCodePayload'
    accessToken: string | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string> }> | null
  } | null
}

export type ImportTinkBankAccountMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>
}>

export type ImportTinkBankAccountMutation = {
  __typename: 'MutationRoot'
  importTinkBankAccount: {
    __typename: 'ImportTinkBankAccountPayload'
    isIdNumberMismatch: boolean
    bankAccount: {
      __typename: 'BankAccount'
      name: string | null
      active: boolean
      bankName: string | null
      bic: string | null
      clearingNumber: string | null
      countryCode: string | null
      email: string | null
      iban: string | null
      id: string
      number: string | null
      type: BankAccountTypeEnum
      verified: boolean
      bankLocation: {
        __typename: 'Location'
        id: string
        formattedAddress: string | null
        locality: string | null
        postalCode: string | null
        route: string | null
        streetNumber: string | null
      } | null
      location: {
        __typename: 'Location'
        id: string
        formattedAddress: string | null
        locality: string | null
        postalCode: string | null
        route: string | null
        streetNumber: string | null
      } | null
    } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type ContractInvitationQueryVariables = Exact<{
  claimKey: Scalars['String']['input']
}>

export type ContractInvitationQuery = {
  __typename: 'QueryRoot'
  contractTenantInvitation: {
    __typename: 'ContractTenantInvitation'
    claimKey: string | null
    claimable: boolean
    recipientEmail: string | null
    tenantType: TenantTypeEnum
    invitedBy: {
      __typename: 'User'
      uid: string
      firstName: string | null
      professional: boolean
      companyName: string | null
    } | null
    home: {
      __typename: 'Home'
      location: {
        __typename: 'Location'
        route: string | null
        streetNumber: string | null
        locality: string | null
      }
      uploads: Array<{
        __typename: 'Upload'
        id: string
        url: string
        metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
      }>
    } | null
  }
}

export type ClaimContractInvitationMutationVariables = Exact<{
  claimKey: Scalars['String']['input']
}>

export type ClaimContractInvitationMutation = {
  __typename: 'MutationRoot'
  claimContractInvitation: {
    __typename: 'ClaimContractInvitationPayload'
    contract: {
      __typename: 'Contract'
      id: string
      tenant: {
        __typename: 'User'
        uid: string
        firstName: string | null
        professional: boolean
        companyName: string | null
      } | null
      home: {
        __typename: 'Home'
        location: { __typename: 'Location'; route: string | null; streetNumber: string | null }
      } | null
    } | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type UpdateTenantPostSignDocumentsMutationVariables = Exact<{
  updateTenantPostSignDocumentsId: Scalars['ID']['input']
  input: UpdateTenantPostSignDocumentsInput
}>

export type UpdateTenantPostSignDocumentsMutation = {
  __typename: 'MutationRoot'
  updateTenantPostSignDocuments: {
    __typename: 'UpdateTenantPostSignDocumentsPayload'
    contract: {
      __typename: 'Contract'
      id: string
      tenantPostSignDocuments: Array<{ __typename: 'Blob'; id: string }> | null
    } | null
  } | null
}

export type UpdateLandlordPostSignDocumentsMutationVariables = Exact<{
  updateLandlordPostSignDocumentsId: Scalars['ID']['input']
  input: UpdateLandlordPostSignDocumentsInput
}>

export type UpdateLandlordPostSignDocumentsMutation = {
  __typename: 'MutationRoot'
  updateLandlordPostSignDocuments: {
    __typename: 'UpdateLandlordPostSignDocumentsPayload'
    contract: {
      __typename: 'Contract'
      id: string
      landlordPostSignDocuments: Array<{ __typename: 'Blob'; id: string }> | null
    } | null
  } | null
}

export type TerminateContractMutationVariables = Exact<{
  endAt: Scalars['DateTime']['input']
  terminateContractId: Scalars['ID']['input']
}>

export type TerminateContractMutation = {
  __typename: 'MutationRoot'
  terminateContract: {
    __typename: 'TerminateContractPayload'
    contract: { __typename: 'Contract'; id: string; pendingTerminationEndDate: string | null } | null
    errors: Array<{
      __typename: 'TerminateContractValidation'
      codes: Array<TerminateContractValidationCodeEnum>
      field: string
    }> | null
  } | null
}

export type IfInsurancePromotionSignUpMutationVariables = Exact<{
  contractId: Scalars['ID']['input']
  signedUp: Scalars['Boolean']['input']
}>

export type IfInsurancePromotionSignUpMutation = {
  __typename: 'MutationRoot'
  ifInsurancePromotionSignUp: {
    __typename: 'IfInsurancePromotionSignUpPayload'
    ifInsurancePromotion: { __typename: 'IfInsurancePromotion'; signedUp: boolean | null } | null
  } | null
}

export type SetDepositFreeMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SetDepositFreeMutation = {
  __typename: 'MutationRoot'
  setDepositFree: {
    __typename: 'SetDepositFreePayload'
    contract: {
      __typename: 'Contract'
      id: string
      depositFree: boolean | null
      depositFreeCost: number | null
      invoices: Array<{
        __typename: 'Invoice'
        invoiceEntries: Array<{
          __typename: 'InvoiceEntry'
          amount: number
          costType: InvoiceEntryCostTypeEnum
          entryType: InvoiceEntryTypeEnum
          id: string
        }> | null
      }>
    } | null
  } | null
}

export type ApproveDepositPayoutMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ApproveDepositPayoutMutation = {
  __typename: 'MutationRoot'
  approveDepositPayout: {
    __typename: 'ApproveDepositPayoutPayload'
    contract: { __typename: 'Contract'; id: string; depositPayoutApprovedAt: string | null } | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type DenyDepositPayoutMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DenyDepositPayoutMutation = {
  __typename: 'MutationRoot'
  denyDepositPayout: {
    __typename: 'DenyDepositPayoutPayload'
    contract: { __typename: 'Contract'; id: string; depositPayoutDeniedAt: string | null } | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type CheckoutTokenQueryVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type CheckoutTokenQuery = { __typename: 'QueryRoot'; checkoutToken: string }

export type ClaimCheckoutContractInvitationMutationVariables = Exact<{
  claimKey: Scalars['String']['input']
}>

export type ClaimCheckoutContractInvitationMutation = {
  __typename: 'MutationRoot'
  claimContractInvitation: {
    __typename: 'ClaimContractInvitationPayload'
    contract: { __typename: 'Contract'; id: string; status: ContractStatusEnum } | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type ProfileForCreateTenantListingQueryVariables = Exact<{
  uid: Scalars['ID']['input']
}>

export type ProfileForCreateTenantListingQuery = {
  __typename: 'QueryRoot'
  user: {
    __typename: 'User'
    uid: string
    professional: boolean
    movingReason: string | null
    firstName: string | null
    age: number | null
    showAge: boolean | null
    housingSituation: {
      __typename: 'HousingSituation'
      id: string
      agreementType: HousingSituationAgreementTypeEnum | null
      landlordCity: string | null
      landlordName: string | null
      landlordPhoneNumber: string | null
    } | null
    occupations: Array<{
      __typename: 'Occupation'
      current: boolean | null
      endedAt: string | null
      id: string
      occupationScheduleType: OccupationScheduleTypeEnum | null
      occupationType: OccupationTypeEnum
      startedAt: string
      subtitle: string | null
      title: string | null
    }> | null
    bio: {
      __typename: 'UserBio'
      title: string | null
      intro: string | null
      lived: string | null
      pets: string | null
    }
    private: { __typename: 'UserPrivate'; id: string; familyName: string | null; phoneNumber: string | null }
    tenantAd: {
      __typename: 'TenantAd'
      id: string
      searchPreference: {
        __typename: 'SearchPreference'
        id: string
        homeType: Array<HomeTypeEnum> | null
        balcony: boolean | null
        bathtub: boolean | null
        bikeRoom: boolean | null
        corporateHome: boolean | null
        countryCode: string | null
        currency: CurrencyEnum | null
        dishWasher: boolean | null
        furniture: boolean | null
        householdSize: number | null
        inhomeSauna: boolean | null
        storage: boolean | null
        studentHome: boolean | null
        pets: boolean | null
        recycling: boolean | null
        seniorHome: boolean | null
        shared: boolean | null
        smoker: boolean | null
        washingMachine: boolean | null
        tumbleDryer: boolean | null
        wheelchairAccessible: boolean | null
        parking: boolean | null
        minRoomCount: number | null
        minSquareMeters: number | null
        maxMonthlyCost: number | null
        startAsap: boolean
        startOptimal: string | null
        endUfn: boolean
        endOptimal: string | null
        searchAreas: Array<{
          __typename: 'SearchArea'
          name: string
          city: string | null
          geojson: Record<string, unknown>
          identifier: string
        }> | null
      }
    } | null
  } | null
}

export type CreateCertificateMutationVariables = Exact<{ [key: string]: never }>

export type CreateCertificateMutation = {
  __typename: 'MutationRoot'
  createCertificate: {
    __typename: 'CreateCertificatePayload'
    certificate: { __typename: 'Certificate'; id: string; status: CertificateStatusEnum } | null
  } | null
}

export type HomeSearchCoordsQueryQueryVariables = Exact<{
  market?: InputMaybe<MarketNameTypeEnum>
  searchParams?: InputMaybe<HomeSearchParamsInput>
  filterOnArea?: InputMaybe<Scalars['Boolean']['input']>
}>

export type HomeSearchCoordsQueryQuery = {
  __typename: 'QueryRoot'
  homeSearchCoords: { __typename: 'HomeSearchCoords'; filterHomesRaw: Record<string, unknown> }
}

export type MapHomeCardFragment = {
  __typename: 'Home'
  id: string
  roomCount: number | null
  squareMeters: number | null
  displayStreetNumber: boolean
  minimumPricePerNight: number | null
  maximumPricePerNight: number | null
  averagePricePerNight: number | null
  rent: number | null
  tenantBaseFee: number | null
  minRent: number | null
  maxRent: number | null
  numberOfHomes: number | null
  currency: CurrencyEnum
  type: HomeTypeEnum
  title: string | null
  rentalType: HomeRentalTypeEnum
  tenantCount: number | null
  bedroomCount: number | null
  bedCount: number | null
  favoriteMarkedByUser: boolean
  uploads: Array<{
    __typename: 'Upload'
    id: string
    url: string
    metadata: { __typename: 'UploadMetadata'; order: number | null; primary: boolean | null } | null
  }>
  duration: {
    __typename: 'Duration'
    id: string
    startOptimal: string | null
    endOptimal: string | null
    startAsap: boolean
    endUfn: boolean
  }
  location: {
    __typename: 'Location'
    id: string
    route: string | null
    locality: string | null
    streetNumber: string | null
    countryCode: string
  }
}

export type MapPopupContentHomeQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type MapPopupContentHomeQuery = {
  __typename: 'QueryRoot'
  home: {
    __typename: 'Home'
    id: string
    roomCount: number | null
    squareMeters: number | null
    displayStreetNumber: boolean
    minimumPricePerNight: number | null
    maximumPricePerNight: number | null
    averagePricePerNight: number | null
    rent: number | null
    tenantBaseFee: number | null
    minRent: number | null
    maxRent: number | null
    numberOfHomes: number | null
    currency: CurrencyEnum
    type: HomeTypeEnum
    title: string | null
    rentalType: HomeRentalTypeEnum
    tenantCount: number | null
    bedroomCount: number | null
    bedCount: number | null
    favoriteMarkedByUser: boolean
    uploads: Array<{
      __typename: 'Upload'
      id: string
      url: string
      metadata: { __typename: 'UploadMetadata'; order: number | null; primary: boolean | null } | null
    }>
    duration: {
      __typename: 'Duration'
      id: string
      startOptimal: string | null
      endOptimal: string | null
      startAsap: boolean
      endUfn: boolean
    }
    location: {
      __typename: 'Location'
      id: string
      route: string | null
      locality: string | null
      streetNumber: string | null
      countryCode: string
    }
  } | null
}

export type BlockListingDataForHomeQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type BlockListingDataForHomeQuery = {
  __typename: 'QueryRoot'
  home: {
    __typename: 'Home'
    id: string
    numberOfHomes: number | null
    minSquareMeters: number | null
    maxSquareMeters: number | null
    minRoomCount: number | null
    maxRoomCount: number | null
    minRent: number | null
    maxRent: number | null
  } | null
}

export type FindTenantAdsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  homeId?: InputMaybe<Scalars['ID']['input']>
  platform?: InputMaybe<Array<PlatformEnum> | PlatformEnum>
  sorting?: InputMaybe<TenantAdSortingEnum>
}>

export type FindTenantAdsQuery = {
  __typename: 'QueryRoot'
  tenantAds: {
    __typename: 'TenantAdOffsetLimit'
    totalCount: number
    pagesCount: number
    nodes: Array<{
      __typename: 'TenantAd'
      id: string
      score: number | null
      user: {
        __typename: 'User'
        uid: string
        firstName: string | null
        showAge: boolean | null
        age: number | null
        professional: boolean
        profilePicture: { __typename: 'Upload'; id: string; url: string } | null
        bio: { __typename: 'UserBio'; title: string | null }
        currentIdentification: {
          __typename: 'Identification'
          identityConfirmed: boolean
          identityConfirmedAt: string | null
        } | null
        housingSituation: { __typename: 'HousingSituation'; id: string; hasReference: boolean } | null
      }
      searchPreference: {
        __typename: 'SearchPreference'
        id: string
        maxMonthlyCost: number | null
        maxMonthlyCostCents: number | null
        currency: CurrencyEnum | null
        minRoomCount: number | null
        minSquareMeters: number | null
        householdSize: number | null
        furniture: boolean | null
        endOptimal: string | null
        endUfn: boolean
        startOptimal: string | null
        startAsap: boolean
        matchingAreaMetadata: Array<{
          __typename: 'MatchingAreaMetadata'
          name: string
          city: string | null
          osmId: string | null
        }> | null
      }
    }>
  }
}

export type HomeSelectQueryVariables = Exact<{
  orderBy?: InputMaybe<HomeSearchOrderByEnum>
  order?: InputMaybe<HomeSearchOrderEnum>
  homeStatuses?: InputMaybe<Array<HomeStatusEnum> | HomeStatusEnum>
}>

export type HomeSelectQuery = {
  __typename: 'QueryRoot'
  homes: {
    __typename: 'HomeConnection'
    totalCount: number
    edges: Array<{
      __typename: 'HomeEdge'
      node: {
        __typename: 'Home'
        id: string
        displayStreetNumber: boolean
        status: HomeStatusEnum
        archiveReason: HomeArchiveReasonEnum | null
        rent: number | null
        currency: CurrencyEnum
        type: HomeTypeEnum
        numberOfHomes: number | null
        roomCount: number | null
        minRoomCount: number | null
        maxRoomCount: number | null
        squareMeters: number | null
        minSquareMeters: number | null
        maxSquareMeters: number | null
        studentHome: boolean
        seniorHome: boolean
        corporateHome: boolean
        shared: boolean | null
        floor: number | null
        buildingFloors: number | null
        location: {
          __typename: 'Location'
          id: string
          streetNumber: string | null
          route: string | null
          locality: string | null
        }
        landlord: { __typename: 'User'; uid: string }
        traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
        uploads: Array<{
          __typename: 'Upload'
          id: string
          url: string
          metadata: { __typename: 'UploadMetadata'; order: number | null; primary: boolean | null } | null
        }>
      }
    }>
  }
}

export type HomeStatusQueryVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type HomeStatusQuery = {
  __typename: 'QueryRoot'
  home: { __typename: 'Home'; id: string; status: HomeStatusEnum } | null
}

export type CreateHomeFromTemplateInHomeViewMutationMutationVariables = Exact<{
  homeTemplateId: Scalars['ID']['input']
}>

export type CreateHomeFromTemplateInHomeViewMutationMutation = {
  __typename: 'MutationRoot'
  createHomeFromTemplate: {
    __typename: 'CreateHomeFromTemplatePayload'
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
    home: { __typename: 'Home'; id: string; rentalType: HomeRentalTypeEnum } | null
  } | null
}

export type InitiateListingPublicationFeePaymentMutationVariables = Exact<{
  input: InitiateListingPublicationFeePaymentInput
}>

export type InitiateListingPublicationFeePaymentMutation = {
  __typename: 'MutationRoot'
  initiateListingPublicationFeePayment: {
    __typename: 'InitiateListingPublicationFeePaymentPayload'
    sessionId: string | null
    success: boolean
  } | null
}

export type ExistingBookingsQueryVariables = Exact<{
  homeId: Scalars['ID']['input']
  status?: InputMaybe<Array<BookingStatusEnum> | BookingStatusEnum>
}>

export type ExistingBookingsQuery = {
  __typename: 'QueryRoot'
  bookings: Array<{
    __typename: 'Booking'
    id: string
    status: BookingStatusEnum
    checkIn: string
    checkOut: string
    conversation: { __typename: 'Conversation'; id: string } | null
    pricingInfo: {
      __typename: 'PricePreview'
      rentPrice: number
      tenantFee: number
      landlordFee: number
      totalTenantPrice: number
      cleaningFee: number
    }
  }>
}

export type HomeAvailabilitiesQueryVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type HomeAvailabilitiesQuery = {
  __typename: 'QueryRoot'
  homeAvailabilities: Array<{
    __typename: 'HomeAvailability'
    date: string | null
    price: number
    status: HomeAvailabilityStatusEnum
    booking: {
      __typename: 'Booking'
      id: string
      status: BookingStatusEnum
      tenant: {
        __typename: 'User'
        uid: string
        profilePicture: { __typename: 'Upload'; id: string; url: string } | null
      }
    } | null
  }>
  home: {
    __typename: 'Home'
    id: string
    currency: CurrencyEnum
    landlord: { __typename: 'User'; uid: string }
    pricingModel: { __typename: 'PricingModel'; landlordBaseFeeFactor: number }
  } | null
}

export type VacationPricingPreviewQueryVariables = Exact<{
  checkIn: Scalars['DateTime']['input']
  checkOut: Scalars['DateTime']['input']
  homeId: Scalars['ID']['input']
}>

export type VacationPricingPreviewQuery = {
  __typename: 'QueryRoot'
  pricePreview: {
    __typename: 'PricePreview'
    averagePricePerNight: number
    tenantFee: number
    landlordFee: number
    totalTenantPrice: number
    rentPrice: number
  }
}

export type InitiateTinkIncomeCheckMutationMutationVariables = Exact<{
  tinkId: Scalars['ID']['input']
}>

export type InitiateTinkIncomeCheckMutationMutation = {
  __typename: 'MutationRoot'
  initiateTinkIncomeCheck: {
    __typename: 'InitiateTinkIncomeCheckPayload'
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type IncomeVerificationQueryQueryVariables = Exact<{
  uid: Scalars['ID']['input']
}>

export type IncomeVerificationQueryQuery = {
  __typename: 'QueryRoot'
  incomeVerification: {
    __typename: 'IncomeVerification'
    id: string
    status: IncomeVerificationStatusEnum
    verifiedAt: string | null
    meanTotalIncome: { __typename: 'Money'; humanized: string }
    transactionGroups: Array<{
      __typename: 'TinkTransactionGroupType'
      month: MonthEnum
      transactions: Array<{
        __typename: 'TinkTransactionType'
        id: string
        time: string
        amount: { __typename: 'Money'; humanized: string }
      }>
    }>
  } | null
}

export type TenantAdsInstantMatchQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  scoreThreshold?: InputMaybe<Scalars['Int']['input']>
  homeId?: InputMaybe<Scalars['ID']['input']>
  platform?: InputMaybe<Array<PlatformEnum> | PlatformEnum>
}>

export type TenantAdsInstantMatchQuery = {
  __typename: 'QueryRoot'
  tenantAds: {
    __typename: 'TenantAdOffsetLimit'
    nodes: Array<{
      __typename: 'TenantAd'
      id: string
      score: number | null
      user: {
        __typename: 'User'
        uid: string
        firstName: string | null
        showAge: boolean | null
        age: number | null
        professional: boolean
        profilePicture: { __typename: 'Upload'; id: string; url: string } | null
        bio: { __typename: 'UserBio'; title: string | null }
        currentIdentification: {
          __typename: 'Identification'
          identityConfirmed: boolean
          identityConfirmedAt: string | null
        } | null
        housingSituation: { __typename: 'HousingSituation'; id: string; hasReference: boolean } | null
      }
      searchPreference: {
        __typename: 'SearchPreference'
        id: string
        maxMonthlyCost: number | null
        maxMonthlyCostCents: number | null
        currency: CurrencyEnum | null
        minRoomCount: number | null
        minSquareMeters: number | null
        householdSize: number | null
        furniture: boolean | null
        endOptimal: string | null
        endUfn: boolean
        startOptimal: string | null
        startAsap: boolean
        matchingAreaMetadata: Array<{
          __typename: 'MatchingAreaMetadata'
          name: string
          city: string | null
          osmId: string | null
        }> | null
      }
    }>
  }
}

export type InstantSignContractAlreadySignedQueryVariables = Exact<{
  status?: InputMaybe<Array<ContractStatusEnum> | ContractStatusEnum>
  homeId: Scalars['ID']['input']
  signingProcess?: InputMaybe<Array<SigningProcessEnum> | SigningProcessEnum>
}>

export type InstantSignContractAlreadySignedQuery = {
  __typename: 'QueryRoot'
  contracts: {
    __typename: 'ContractConnection'
    nodes: Array<{ __typename: 'Contract'; id: string; status: ContractStatusEnum }>
  }
  home: { __typename: 'Home'; id: string; openForSigning: boolean } | null
}

export type InstantSignContractPreviewQueryQueryVariables = Exact<{
  id: Scalars['ID']['input']
  language?: InputMaybe<ContractLanguageEnum>
}>

export type InstantSignContractPreviewQueryQuery = {
  __typename: 'QueryRoot'
  generateContractPreviewPdf: {
    __typename: 'ContractPreview'
    previewSource: string | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  }
  contract: {
    __typename: 'Contract'
    id: string
    language: ContractLanguageEnum
    availablePreviewLanguages: Array<ContractLanguageEnum>
    status: ContractStatusEnum
    signUrl: string | null
  }
}

export type InstantSignExistingContractsQueryVariables = Exact<{
  status?: InputMaybe<Array<ContractStatusEnum> | ContractStatusEnum>
  homeId?: InputMaybe<Scalars['ID']['input']>
}>

export type InstantSignExistingContractsQuery = {
  __typename: 'QueryRoot'
  contracts: {
    __typename: 'ContractConnection'
    nodes: Array<{
      __typename: 'Contract'
      signingProcess: SigningProcessEnum
      id: string
      status: ContractStatusEnum
      signUrl: string | null
      termsUrl: string | null
      signatories: Array<{
        __typename: 'Signatory'
        id: string
        role: SignatoryRoleEnum
        signedAt: string | null
        user: { __typename: 'User'; uid: string }
      }>
      duration: { __typename: 'Duration'; startOptimal: string | null } | null
    }>
  }
}

export type CreateInstantSignContractMutationVariables = Exact<{
  contractInput: CreateInstantSignContractInput
}>

export type CreateInstantSignContractMutation = {
  __typename: 'MutationRoot'
  createInstantSignContract: {
    __typename: 'CreateInstantSignContractPayload'
    contract: { __typename: 'Contract'; id: string; termsUrl: string | null; signUrl: string | null } | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type UpdateInstantSignContractMutationVariables = Exact<{
  updateInstantSignContractId: Scalars['ID']['input']
  contractInput: UpdateInstantSignContractInput
}>

export type UpdateInstantSignContractMutation = {
  __typename: 'MutationRoot'
  updateInstantSignContract: {
    __typename: 'UpdateInstantSignContractPayload'
    contract: { __typename: 'Contract'; id: string; termsUrl: string | null; signUrl: string | null } | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type CityHomeCountQueryVariables = Exact<{
  searchParams: HomeSearchParamsInput
}>

export type CityHomeCountQuery = {
  __typename: 'QueryRoot'
  homeSearch: {
    __typename: 'HomeIndexSearchQuery'
    documents: { __typename: 'HomeDocumentOffsetLimit'; totalCount: number }
  }
}

export type MeetYourTenantQueryQueryVariables = Exact<{
  uid: Scalars['ID']['input']
}>

export type MeetYourTenantQueryQuery = {
  __typename: 'QueryRoot'
  user: {
    __typename: 'User'
    uid: string
    firstName: string | null
    age: number | null
    movingReason: string | null
    currentIdentification: {
      __typename: 'Identification'
      identityConfirmed: boolean
      identityConfirmedAt: string | null
    } | null
    tenantAd: {
      __typename: 'TenantAd'
      searchPreference: { __typename: 'SearchPreference'; householdSize: number | null }
    } | null
    profilePicture: { __typename: 'Upload'; id: string; url: string } | null
    bio: {
      __typename: 'UserBio'
      title: string | null
      intro: string | null
      lived: string | null
      pets: string | null
    }
    occupations: Array<{
      __typename: 'Occupation'
      id: string
      title: string | null
      subtitle: string | null
      current: boolean | null
      occupationScheduleType: OccupationScheduleTypeEnum | null
      startedAt: string
    }> | null
    certificate: { __typename: 'Certificate'; id: string; certifiedAt: string | null } | null
    housingSituation: {
      __typename: 'HousingSituation'
      id: string
      agreementType: HousingSituationAgreementTypeEnum | null
      landlordCity: string | null
      hasReference: boolean
    } | null
  } | null
}

export type ArchiveListingMutationMutationVariables = Exact<{
  homeId: Scalars['ID']['input']
  reason?: InputMaybe<HomeArchiveReasonEnum>
}>

export type ArchiveListingMutationMutation = {
  __typename: 'MutationRoot'
  archiveHome: {
    __typename: 'ArchiveHomePayload'
    home: {
      __typename: 'Home'
      id: string
      status: HomeStatusEnum
      archiveReason: HomeArchiveReasonEnum | null
    } | null
  } | null
}

export type BumpListingVacationMutationVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type BumpListingVacationMutation = {
  __typename: 'MutationRoot'
  bumpVacationAd: {
    __typename: 'BumpVacationAdPayload'
    home: { __typename: 'Home'; id: string } | null
  } | null
}

export type BumpListingForPremiumUserMutationMutationVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type BumpListingForPremiumUserMutationMutation = {
  __typename: 'MutationRoot'
  bumpListingForPremiumUser: {
    __typename: 'BumpListingPayload'
    home: { __typename: 'Home'; id: string }
  } | null
}

export type BumpListingPaymentMutationMutationVariables = Exact<{
  input: InitiateListingBumpPaymentInput
}>

export type BumpListingPaymentMutationMutation = {
  __typename: 'MutationRoot'
  initiateListingBumpPayment: {
    __typename: 'InitiateListingBumpPaymentPayload'
    sessionId: string | null
    success: boolean
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type ListingImageFragment = {
  __typename: 'Home'
  uploads: Array<{
    __typename: 'Upload'
    id: string
    url: string
    metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
  }>
}

export type ListingInfoFragment = {
  __typename: 'Home'
  type: HomeTypeEnum
  rent: number | null
  roomCount: number | null
  squareMeters: number | null
  bedCount: number | null
  tenantCount: number | null
  bedroomCount: number | null
  location: {
    __typename: 'Location'
    id: string
    locality: string | null
    route: string | null
    streetNumber: string | null
  }
  duration: {
    __typename: 'Duration'
    id: string
    startOptimal: string | null
    endOptimal: string | null
    startAsap: boolean
    endUfn: boolean
  }
  market: { __typename: 'Market'; id: string; name: MarketNameEnum }
}

export type ListingStatusDetailFragment = { __typename: 'Home'; publishedAt: string | null }

export type ListingStatusDetailQueryQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ListingStatusDetailQueryQuery = {
  __typename: 'QueryRoot'
  home: { __typename: 'Home'; id: string; publishedAt: string | null } | null
}

export type PublishListingMutationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type PublishListingMutationMutation = {
  __typename: 'MutationRoot'
  publishHome: {
    __typename: 'PublishHomePayload'
    home: {
      __typename: 'Home'
      id: string
      status: HomeStatusEnum
      archiveReason: HomeArchiveReasonEnum | null
      publishedAt: string | null
    } | null
  } | null
}

export type ListingExtendedStatusFragment = {
  __typename: 'Home'
  status: HomeStatusEnum
  archiveReason: HomeArchiveReasonEnum | null
}

export type ListingExtendedStatusQueryQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ListingExtendedStatusQueryQuery = {
  __typename: 'QueryRoot'
  home: {
    __typename: 'Home'
    id: string
    status: HomeStatusEnum
    archiveReason: HomeArchiveReasonEnum | null
  } | null
}

export type VacationListingStatisticsQueryVariables = Exact<{
  id: Scalars['ID']['input']
  status?: InputMaybe<Array<BookingStatusEnum> | BookingStatusEnum>
}>

export type VacationListingStatisticsQuery = {
  __typename: 'QueryRoot'
  homeStatsQuery: { __typename: 'HomeStats'; cumulativePageViews: Array<number> } | null
  home: { __typename: 'Home'; favoriteMarkedCount: number } | null
  bookings: Array<{ __typename: 'Booking'; id: string }>
}

export type ListingAvailabilitySettingsQueryVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type ListingAvailabilitySettingsQuery = {
  __typename: 'QueryRoot'
  homeAvailabilitySettingsQuery: {
    __typename: 'HomeAvailabilitySettings'
    minNightsStay: number
    noCheckinDays: Array<DayOfTheWeekTypeEnum>
    rentOnlyWeekly: boolean
  } | null
}

export type ListingAvailabilityQueryVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type ListingAvailabilityQuery = {
  __typename: 'QueryRoot'
  homeAvailabilities: Array<{
    __typename: 'HomeAvailability'
    date: string | null
    price: number
    status: HomeAvailabilityStatusEnum
  }>
}

export type UpsertListingAvailabilitySettingsMutationVariables = Exact<{
  input: UpsertHomeAvailabilitySettingsInput
}>

export type UpsertListingAvailabilitySettingsMutation = {
  __typename: 'MutationRoot'
  upsertHomeAvailabilitySettings: {
    __typename: 'UpsertHomeAvailabilitySettingsPayload'
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
    homeAvailabilitySettings: {
      __typename: 'HomeAvailabilitySettings'
      minNightsStay: number
      noCheckinDays: Array<DayOfTheWeekTypeEnum>
      rentOnlyWeekly: boolean
    } | null
  } | null
}

export type EditListingMutationMutationVariables = Exact<{
  homeId: Scalars['ID']['input']
  input: UpdateHomeInput
}>

export type EditListingMutationMutation = {
  __typename: 'MutationRoot'
  updateHome: {
    __typename: 'UpdateHomePayload'
    home: {
      __typename: 'Home'
      id: string
      qasaGuaranteeCost: number | null
      insuranceCost: number | null
      recommendedRentNew: number | null
    } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type EvaluateHomeForShortcutMutationVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type EvaluateHomeForShortcutMutation = {
  __typename: 'MutationRoot'
  evaluateHomeForShortcut: {
    __typename: 'EvaluateHomeForShortcutPayload'
    home: { __typename: 'Home'; status: HomeStatusEnum; id: string }
  } | null
}

export type HomeDraftsAndPreviouslySignedHomesQueryQueryVariables = Exact<{
  first: Scalars['Int']['input']
  rentalTypes?: InputMaybe<Array<HomeRentalTypeEnum> | HomeRentalTypeEnum>
}>

export type HomeDraftsAndPreviouslySignedHomesQueryQuery = {
  __typename: 'QueryRoot'
  homeDrafts: {
    __typename: 'HomeConnection'
    totalCount: number
    edges: Array<{
      __typename: 'HomeEdge'
      node: {
        __typename: 'Home'
        createdAt: string
        id: string
        roomCount: number | null
        type: HomeTypeEnum
        shared: boolean | null
        squareMeters: number | null
        location: { __typename: 'Location'; id: string; route: string | null; streetNumber: string | null }
        uploads: Array<{
          __typename: 'Upload'
          id: string
          url: string
          metadata: { __typename: 'UploadMetadata'; order: number | null; primary: boolean | null } | null
        }>
      }
    }>
  }
  previouslySignedHomes: {
    __typename: 'HomeConnection'
    totalCount: number
    edges: Array<{
      __typename: 'HomeEdge'
      node: {
        __typename: 'Home'
        publishedAt: string | null
        id: string
        type: HomeTypeEnum
        shared: boolean | null
        roomCount: number | null
        squareMeters: number | null
        location: { __typename: 'Location'; id: string; route: string | null; streetNumber: string | null }
        uploads: Array<{
          __typename: 'Upload'
          id: string
          url: string
          metadata: { __typename: 'UploadMetadata'; order: number | null; primary: boolean | null } | null
        }>
        homeTemplates: Array<{ __typename: 'HomeTemplate'; id: string }>
      }
    }>
  }
}

export type CreateHomeFromTemplateInCreateListingMutationMutationVariables = Exact<{
  homeTemplateId: Scalars['ID']['input']
}>

export type CreateHomeFromTemplateInCreateListingMutationMutation = {
  __typename: 'MutationRoot'
  createHomeFromTemplate: {
    __typename: 'CreateHomeFromTemplatePayload'
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
    home: { __typename: 'Home'; id: string } | null
  } | null
}

export type ListingQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ListingQuery = {
  __typename: 'QueryRoot'
  home: {
    __typename: 'Home'
    id: string
    type: HomeTypeEnum
    tenureType: TenureTypeEnum | null
    apartmentNumber: string | null
    floor: number | null
    buildingFloors: number | null
    ownHome: boolean | null
    insurance: HomeInsuranceEnum | null
    insuranceCost: number | null
    qasaGuarantee: boolean | null
    qasaGuaranteeCost: number | null
    shared: boolean | null
    description: string | null
    rent: number | null
    recommendedRentNew: number | null
    roomCount: number | null
    squareMeters: number | null
    status: HomeStatusEnum
    firsthand: boolean
    seniorHome: boolean
    studentHome: boolean
    corporateHome: boolean
    tenantCount: number | null
    energyClass: string | null
    housingAssociation: string | null
    kitchenRenovationYear: number | null
    bathroomRenovationYear: number | null
    buildYear: number | null
    publishedAt: string | null
    external: boolean
    externalApplicationUrl: string | null
    externalEmail: string | null
    externalInfoPage: string | null
    rentalType: HomeRentalTypeEnum
    houseRules: string | null
    responsibleForCleaning: ResponsibleForCleaningEnum | null
    cleaningFee: number
    title: string | null
    bedCount: number | null
    bedroomCount: number | null
    hasKitchen: boolean
    toiletCount: number | null
    origin: string | null
    currency: CurrencyEnum
    market: { __typename: 'Market'; id: string; name: MarketNameEnum }
    location: {
      __typename: 'Location'
      formattedAddress: string | null
      latitude: number | null
      locality: string | null
      longitude: number | null
      postalCode: string | null
      route: string | null
      streetNumber: string | null
      countryCode: string
    }
    landlord: { __typename: 'User'; uid: string }
    uploads: Array<{
      __typename: 'Upload'
      id: string
      url: string
      metadata: {
        __typename: 'UploadMetadata'
        primary: boolean | null
        order: number | null
        rotation: number | null
      } | null
    }>
    traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
    duration: {
      __typename: 'Duration'
      startOptimal: string | null
      endOptimal: string | null
      startAsap: boolean
      endUfn: boolean
      possibilityOfExtension: boolean
    }
    landlordServices: {
      __typename: 'HomeLandlordServices'
      qasaGuarantee: { __typename: 'Service'; isActive: boolean; availability: ServiceAvailabilityEnum }
      insurance: { __typename: 'Service'; isActive: boolean; availability: ServiceAvailabilityEnum }
    }
    landlordFees: {
      __typename: 'HomeLandlordFees'
      base: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; factor: number | null; total: number; isFree: boolean }
      }
    }
    tenantFees: {
      __typename: 'HomeTenantFees'
      base: { __typename: 'Fee'; cost: { __typename: 'Cost'; factor: number | null } }
      waterFeePerTenant: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; fixed: number | null }
      }
      monthlyElectricityFee: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: { __typename: 'Cost'; isFree: boolean; fixed: number | null }
      } | null
    }
    electricityFee: {
      __typename: 'ElectricityFeeType'
      paymentPlan: ElectricityFeePaymentOptionEnum
      monthlyFee: number | null
    } | null
    property: {
      __typename: 'Property'
      maxSquareMeters: number | null
      maxRoomCount: number | null
      minRoomCount: number | null
      minSquareMeters: number | null
      numberOfHomes: number | null
    } | null
    pricingModel: { __typename: 'PricingModel'; insuranceCostFactor: number; qasaGuaranteeCostFactor: number }
    currentCancellationPolicy: {
      __typename: 'CancellationPolicy'
      policyName: CancellationPolicyNameEnum | null
      partialRefundFactor: number | null
      partialRefundDays: number | null
      fullRefundDays: number | null
    } | null
    cancellationOptions: Array<{
      __typename: 'CancellationPolicy'
      policyName: CancellationPolicyNameEnum | null
      partialRefundFactor: number | null
      partialRefundDays: number | null
      fullRefundDays: number | null
    }> | null
  } | null
}

export type CancellationPolicyOptionsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CancellationPolicyOptionsQuery = {
  __typename: 'QueryRoot'
  home: {
    __typename: 'Home'
    cancellationOptions: Array<{
      __typename: 'CancellationPolicy'
      policyName: CancellationPolicyNameEnum | null
      partialRefundFactor: number | null
      partialRefundDays: number | null
      fullRefundDays: number | null
    }> | null
  } | null
}

export type LandlordHomeAvailabilitiesQueryVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type LandlordHomeAvailabilitiesQuery = {
  __typename: 'QueryRoot'
  homeAvailabilities: Array<{
    __typename: 'HomeAvailability'
    date: string | null
    price: number
    status: HomeAvailabilityStatusEnum
    booking: {
      __typename: 'Booking'
      id: string
      status: BookingStatusEnum
      conversation: { __typename: 'Conversation'; id: string } | null
      tenant: {
        __typename: 'User'
        uid: string
        profilePicture: { __typename: 'Upload'; id: string; url: string } | null
      }
    } | null
  }>
  home: {
    __typename: 'Home'
    id: string
    currency: CurrencyEnum
    landlord: { __typename: 'User'; uid: string }
    pricingModel: { __typename: 'PricingModel'; landlordBaseFeeFactor: number }
  } | null
}

export type UpsertHomeAvailabilityMutationVariables = Exact<{
  homeId: Scalars['ID']['input']
  availability: Array<UpsertHomeAvailabilityInput> | UpsertHomeAvailabilityInput
}>

export type UpsertHomeAvailabilityMutation = {
  __typename: 'MutationRoot'
  upsertHomeAvailability: {
    __typename: 'UpsertHomeAvailabilityPayload'
    upserted: Array<{
      __typename: 'HomeAvailability'
      price: number
      date: string | null
      home: { __typename: 'Home'; id: string }
    }> | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type DestroyHomeAvailabilityMutationVariables = Exact<{
  homeId: Scalars['ID']['input']
  dates: Array<Scalars['Date']['input']> | Scalars['Date']['input']
}>

export type DestroyHomeAvailabilityMutation = {
  __typename: 'MutationRoot'
  destroyHomeAvailability: { __typename: 'DestroyHomeAvailabilityPayload'; dates: Array<string> } | null
}

export type HomeAvailabilitySettingsQueryVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type HomeAvailabilitySettingsQuery = {
  __typename: 'QueryRoot'
  homeAvailabilitySettingsQuery: {
    __typename: 'HomeAvailabilitySettings'
    minNightsStay: number
    minPrice: number
    rentOnlyWeekly: boolean
  } | null
}

export type NewUploadRefFragment = {
  __typename: 'Upload'
  id: string
  metadata: {
    __typename: 'UploadMetadata'
    order: number | null
    primary: boolean | null
    rotation: number | null
  } | null
}

export type HomesListQueryQueryVariables = Exact<{
  homeStatuses?: InputMaybe<Array<HomeStatusEnum> | HomeStatusEnum>
  order?: InputMaybe<HomeSearchOrderEnum>
  orderBy?: InputMaybe<HomeSearchOrderByEnum>
  searchString?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  cursor?: InputMaybe<Scalars['String']['input']>
  rentalTypes?: InputMaybe<Array<HomeRentalTypeEnum> | HomeRentalTypeEnum>
}>

export type HomesListQueryQuery = {
  __typename: 'QueryRoot'
  homes: {
    __typename: 'HomeConnection'
    totalCount: number
    pageInfo: {
      __typename: 'PageInfo'
      startCursor: string | null
      hasPreviousPage: boolean
      hasNextPage: boolean
      endCursor: string | null
    }
    edges: Array<{
      __typename: 'HomeEdge'
      node: {
        __typename: 'Home'
        currency: CurrencyEnum
        archiveReason: HomeArchiveReasonEnum | null
        studentHome: boolean
        status: HomeStatusEnum
        squareMeters: number | null
        shared: boolean | null
        roomCount: number | null
        rent: number | null
        rentalType: HomeRentalTypeEnum
        title: string | null
        publishedAt: string | null
        bedCount: number | null
        tenantCount: number | null
        bedroomCount: number | null
        type: HomeTypeEnum
        id: string
        uploads: Array<{
          __typename: 'Upload'
          id: string
          fileType: UploadFileTypeEnum
          title: string
          type: UploadTypeEnum
          url: string
          metadata: { __typename: 'UploadMetadata'; order: number | null; primary: boolean | null } | null
        }>
        location: {
          __typename: 'Location'
          id: string
          locality: string | null
          streetNumber: string | null
          route: string | null
        }
        duration: {
          __typename: 'Duration'
          id: string
          endOptimal: string | null
          endUfn: boolean
          startAsap: boolean
          startOptimal: string | null
        }
      }
    }>
  }
}

export type ProContactFormMutationVariables = Exact<{
  input: ProContactFormInput
}>

export type ProContactFormMutation = {
  __typename: 'MutationRoot'
  proContactForm: { __typename: 'ProContactFormPayload'; success: boolean } | null
}

export type ProPilotUsersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  cursor?: InputMaybe<Scalars['String']['input']>
}>

export type ProPilotUsersQuery = {
  __typename: 'QueryRoot'
  proPilotUsers: {
    __typename: 'UserConnection'
    totalCount: number
    pageInfo: { __typename: 'PageInfo'; hasNextPage: boolean; endCursor: string | null }
    edges: Array<{
      __typename: 'UserEdge'
      cursor: string
      node: {
        __typename: 'User'
        uid: string
        companyName: string | null
        publishedHomes: Array<{ __typename: 'Home'; id: string }>
        bio: { __typename: 'UserBio'; intro: string | null }
        profilePicture: { __typename: 'Upload'; url: string } | null
      }
    }>
  }
}

export type CreateFindTenantLandlordApplicationMutationVariables = Exact<{
  input: CreateLandlordHomeApplicationInput
}>

export type CreateFindTenantLandlordApplicationMutation = {
  __typename: 'MutationRoot'
  createLandlordHomeApplication: {
    __typename: 'CreateLandlordHomeApplicationPayload'
    homeApplication: {
      __typename: 'HomeApplication'
      id: string
      conversation: { __typename: 'Conversation'; id: string } | null
    } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type HousingSituationExtraDataQueryVariables = Exact<{ [key: string]: never }>

export type HousingSituationExtraDataQuery = {
  __typename: 'QueryRoot'
  me: {
    __typename: 'User'
    uid: string
    housingSituation: {
      __typename: 'HousingSituation'
      id: string
      landlordPhoneNumber: string | null
      landlordName: string | null
    } | null
  } | null
}

export type UpsertTenantAdMutationMutationVariables = Exact<{
  input: TenantAdInput
}>

export type UpsertTenantAdMutationMutation = {
  __typename: 'MutationRoot'
  upsertTenantAd: {
    __typename: 'UpsertTenantAdPayload'
    tenantAd: {
      __typename: 'TenantAd'
      id: string
      status: TenantAdStatusEnum
      publishedUntil: string | null
      searchPreference: {
        __typename: 'SearchPreference'
        id: string
        balcony: boolean | null
        bathtub: boolean | null
        bikeRoom: boolean | null
        corporateHome: boolean | null
        countryCode: string | null
        currency: CurrencyEnum | null
        dishWasher: boolean | null
        furniture: boolean | null
        householdSize: number | null
        inhomeSauna: boolean | null
        storage: boolean | null
        studentHome: boolean | null
        pets: boolean | null
        recycling: boolean | null
        seniorHome: boolean | null
        shared: boolean | null
        smoker: boolean | null
        washingMachine: boolean | null
        tumbleDryer: boolean | null
        wheelchairAccessible: boolean | null
        parking: boolean | null
        minRoomCount: number | null
        minSquareMeters: number | null
        homeType: Array<HomeTypeEnum> | null
        maxMonthlyCost: number | null
        startAsap: boolean
        startOptimal: string | null
        endUfn: boolean
        endOptimal: string | null
        searchAreas: Array<{
          __typename: 'SearchArea'
          city: string | null
          name: string
          geojson: Record<string, unknown>
          identifier: string
        }> | null
      }
    } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type PublishTenantAdMutationMutationVariables = Exact<{ [key: string]: never }>

export type PublishTenantAdMutationMutation = {
  __typename: 'MutationRoot'
  publishTenantAd: {
    __typename: 'PublishTenantAdPayload'
    tenantAd: { __typename: 'TenantAd'; id: string; status: TenantAdStatusEnum } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type UnpublishTenantAdMutationMutationVariables = Exact<{ [key: string]: never }>

export type UnpublishTenantAdMutationMutation = {
  __typename: 'MutationRoot'
  unpublishTenantAd: {
    __typename: 'UnpublishTenantAdPayload'
    tenantAd: {
      __typename: 'TenantAd'
      id: string
      status: TenantAdStatusEnum
      publishedUntil: string | null
    } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type ConversationForHomeQueryVariables = Exact<{
  homeId: Scalars['ID']['input']
  uid: Scalars['ID']['input']
}>

export type ConversationForHomeQuery = {
  __typename: 'QueryRoot'
  user: {
    __typename: 'User'
    uid: string
    conversationForHome: {
      __typename: 'Conversation'
      homeApplication: {
        __typename: 'HomeApplication'
        id: string
        conversation: { __typename: 'Conversation'; id: string } | null
      } | null
    } | null
  } | null
}

export type ProPilotConnectedUidsQueryVariables = Exact<{
  uid: Scalars['ID']['input']
}>

export type ProPilotConnectedUidsQuery = { __typename: 'QueryRoot'; proPilotConnectedUids: Array<string> }

export type ProfileQueryQueryVariables = Exact<{
  uid: Scalars['ID']['input']
}>

export type ProfileQueryQuery = {
  __typename: 'QueryRoot'
  user: {
    __typename: 'User'
    uid: string
    professional: boolean
    proPilot: boolean
    proAgent: boolean
    landlord: boolean
    tenant: boolean
    createdAt: string
    seenAt: string | null
    landlordApplicationResponseRate: number | null
    movingReason: string | null
    landlordApplicationResponseTimeHours: number | null
    firstName: string | null
    companyName: string | null
    age: number | null
    showAge: boolean | null
    occupations: Array<{
      __typename: 'Occupation'
      current: boolean | null
      endedAt: string | null
      id: string
      occupationScheduleType: OccupationScheduleTypeEnum | null
      occupationType: OccupationTypeEnum
      startedAt: string
      subtitle: string | null
      title: string | null
    }> | null
    housingSituation: {
      __typename: 'HousingSituation'
      id: string
      agreementType: HousingSituationAgreementTypeEnum | null
      landlordCity: string | null
      hasReference: boolean
    } | null
    tenantAd: {
      __typename: 'TenantAd'
      id: string
      status: TenantAdStatusEnum
      publishedUntil: string | null
      searchPreference: {
        __typename: 'SearchPreference'
        id: string
        balcony: boolean | null
        bathtub: boolean | null
        bikeRoom: boolean | null
        corporateHome: boolean | null
        countryCode: string | null
        currency: CurrencyEnum | null
        dishWasher: boolean | null
        furniture: boolean | null
        householdSize: number | null
        inhomeSauna: boolean | null
        storage: boolean | null
        studentHome: boolean | null
        pets: boolean | null
        recycling: boolean | null
        seniorHome: boolean | null
        shared: boolean | null
        smoker: boolean | null
        washingMachine: boolean | null
        tumbleDryer: boolean | null
        wheelchairAccessible: boolean | null
        parking: boolean | null
        minRoomCount: number | null
        minSquareMeters: number | null
        homeType: Array<HomeTypeEnum> | null
        maxMonthlyCost: number | null
        maxMonthlyCostCents: number | null
        startAsap: boolean
        startOptimal: string | null
        endUfn: boolean
        endOptimal: string | null
        searchAreas: Array<{
          __typename: 'SearchArea'
          city: string | null
          name: string
          geojson: Record<string, unknown>
          identifier: string
        }> | null
      }
    } | null
    tenantProfileCompleteness: {
      __typename: 'TenantProfileCompleteness'
      completenessScore: number
      tenantProfileFields: {
        __typename: 'TenantProfileFields'
        bioLived: { __typename: 'TenantProfileField'; completed: boolean }
        bio: { __typename: 'TenantProfileField'; completed: boolean }
        firstName: { __typename: 'TenantProfileField'; completed: boolean }
        idNumberConfirmed: { __typename: 'TenantProfileField'; completed: boolean }
        occupations: { __typename: 'TenantProfileField'; completed: boolean }
        matchingProfile: { __typename: 'TenantProfileField'; completed: boolean }
        movingReason: { __typename: 'TenantProfileField'; completed: boolean }
        profilePicture: { __typename: 'TenantProfileField'; completed: boolean }
        bioPets: { __typename: 'TenantProfileField'; completed: boolean }
        bioTitle: { __typename: 'TenantProfileField'; completed: boolean }
        currentAgreementType: { __typename: 'TenantProfileField'; completed: boolean }
      }
    }
    certificate: {
      __typename: 'Certificate'
      id: string
      status: CertificateStatusEnum
      expiresAt: string | null
    } | null
    profilePicture: { __typename: 'Upload'; id: string; url: string } | null
    bio: {
      __typename: 'UserBio'
      title: string | null
      intro: string | null
      lived: string | null
      pets: string | null
    }
    currentIdentification: {
      __typename: 'Identification'
      identityConfirmed: boolean
      identityConfirmedAt: string | null
    } | null
    publishedHomes: Array<{
      __typename: 'Home'
      id: string
      firsthand: boolean
      rent: number | null
      tenantBaseFee: number | null
      title: string | null
      currency: CurrencyEnum
      favoriteMarkedByUser: boolean
      displayStreetNumber: boolean
      floor: number | null
      buildingFloors: number | null
      roomCount: number | null
      shared: boolean | null
      squareMeters: number | null
      type: HomeTypeEnum
      numberOfHomes: number | null
      minRent: number | null
      maxRent: number | null
      minRoomCount: number | null
      maxRoomCount: number | null
      minSquareMeters: number | null
      maxSquareMeters: number | null
      averagePricePerNight: number | null
      minimumPricePerNight: number | null
      maximumPricePerNight: number | null
      studentHome: boolean
      seniorHome: boolean
      rentalType: HomeRentalTypeEnum
      tenantCount: number | null
      bedCount: number | null
      bedroomCount: number | null
      corporateHome: boolean
      landlord: {
        __typename: 'User'
        uid: string
        companyName: string | null
        premium: boolean
        professional: boolean
        proPilot: boolean
        profilePicture: { __typename: 'Upload'; url: string } | null
      }
      location: {
        __typename: 'Location'
        id: string
        route: string | null
        locality: string | null
        latitude: number | null
        longitude: number | null
        sublocality: string | null
        countryCode: string
        streetNumber: string | null
      }
      traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
      duration: {
        __typename: 'Duration'
        createdAt: string | null
        endOptimal: string | null
        endUfn: boolean
        id: string
        startAsap: boolean
        startOptimal: string | null
        updatedAt: string | null
      }
      user: {
        __typename: 'User'
        uid: string
        proAgent: boolean
        firstName: string | null
        profilePicture: { __typename: 'Upload'; url: string } | null
      }
      instantSignSetting: { __typename: 'InstantSignSetting'; id: string; enabled: boolean } | null
      uploads: Array<{
        __typename: 'Upload'
        id: string
        url: string
        type: UploadTypeEnum
        title: string
        metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
      }>
      links: Array<{ __typename: 'Link'; locale: string; url: string }>
    }>
    homeApplicationsToMyHomes: Array<{
      __typename: 'HomeApplication'
      id: string
      status: HomeApplicationStatusEnum
      origin: HomeApplicationOriginEnum
      message: string | null
      matchInterest: string | null
      createdAt: string
      startOptimal: string | null
      endOptimal: string | null
      endUfn: boolean
      tenant: {
        __typename: 'User'
        uid: string
        firstName: string | null
        showAge: boolean | null
        age: number | null
        companyName: string | null
        professional: boolean
        landlord: boolean
        seenAt: string | null
        createdAt: string
        profilePicture: { __typename: 'Upload'; id: string; url: string } | null
        certificate: {
          __typename: 'Certificate'
          id: string
          status: CertificateStatusEnum
          expiresAt: string | null
        } | null
        currentIdentification: {
          __typename: 'Identification'
          identityConfirmed: boolean
          identityConfirmedAt: string | null
        } | null
      }
      home: {
        __typename: 'Home'
        id: string
        rent: number | null
        roomCount: number | null
        squareMeters: number | null
        status: HomeStatusEnum
        title: string | null
        location: {
          __typename: 'Location'
          route: string | null
          locality: string | null
          latitude: number | null
          longitude: number | null
          postalCode: string | null
          streetNumber: string | null
          googleMapsPlaceId: string
          countryCode: string
          id: string
        }
        uploads: Array<{
          __typename: 'Upload'
          url: string
          id: string
          metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
        }>
      }
      conversation: { __typename: 'Conversation'; id: string } | null
    }>
    applicationStats: {
      __typename: 'UserApplicationStats'
      uid: string
      locales: Array<string> | null
      priceMin: number | null
      priceMax: number | null
      roomMin: number | null
      roomMax: number | null
    }
  } | null
}

export type CreateBookingMutationVariables = Exact<{
  input: UpsertBookingInput
}>

export type CreateBookingMutation = {
  __typename: 'MutationRoot'
  upsertBooking: {
    __typename: 'UpsertBookingPayload'
    booking: {
      __typename: 'Booking'
      id: string
      conversation: { __typename: 'Conversation'; id: string } | null
      home: {
        __typename: 'Home'
        id: string
        landlord: { __typename: 'User'; firstName: string | null; uid: string }
      }
    } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type StripeAccountQueryVariables = Exact<{
  refreshUrl: Scalars['String']['input']
  returnUrl: Scalars['String']['input']
}>

export type StripeAccountQuery = {
  __typename: 'QueryRoot'
  me: {
    __typename: 'User'
    uid: string
    private: {
      __typename: 'UserPrivate'
      id: string
      stripeAccount: { __typename: 'AccountResource'; url: string; id: string } | null
    }
  } | null
}

export type ResendConfirmBankAccountEmailMutationVariables = Exact<{
  activeBankAccountId: Scalars['ID']['input']
}>

export type ResendConfirmBankAccountEmailMutation = {
  __typename: 'MutationRoot'
  resendConfirmBankAccountEmail: {
    __typename: 'ResendConfirmBankAccountEmailPayload'
    emailQueued: boolean
  } | null
}

export type HomeqUserPointsQueryVariables = Exact<{ [key: string]: never }>

export type HomeqUserPointsQuery = {
  __typename: 'QueryRoot'
  homeqUser: {
    __typename: 'ConnectHubHomeQUserType'
    id: string
    points: {
      __typename: 'ConnectHubHomeQPointsType'
      queuePoints: number
      bonusPoints: Array<{
        __typename: 'ConnectHubHomeQBonusPointsType'
        companyId: number
        companyName: string
        points: number
      }>
    }
  } | null
}

export type UserTaxReportsQueryVariables = Exact<{ [key: string]: never }>

export type UserTaxReportsQuery = {
  __typename: 'QueryRoot'
  me: {
    __typename: 'User'
    uid: string
    private: {
      __typename: 'UserPrivate'
      id: string
      taxReports: Array<{
        __typename: 'TaxReportType'
        id: string
        taxYear: number
        daysRentedOut: number
        address: {
          __typename: 'TaxReportAddressType'
          countryCode: string
          locality: string
          postalCode: string
          streetAddress: string
        }
        home: { __typename: 'Home'; id: string }
        metadata: {
          __typename: 'TaxReportMetadataType'
          id: string
          familyName: string | null
          firstName: string | null
          idNumber: string | null
          professional: boolean
          taxYear: number
          orgNo: string | null
          companyName: string | null
          address: {
            __typename: 'MetadataAddressType'
            countryCode: string
            locality: string
            postalCode: string
            streetAddress: string
          } | null
          freeFormatAddress: {
            __typename: 'MetadataFreeFormatAddressType'
            address: string
            countryCode: string
            locality: string
          } | null
          bankAccount: { __typename: 'BankAccountType'; number: string; type: BankAccountTypeTypeEnum } | null
        } | null
        quarters: Array<{
          __typename: 'TaxReportQuarterType'
          id: string
          compensation: number
          fees: number
          numberOfTransactions: number
          quarter: number
          taxes: number
        }>
      }>
    }
  } | null
}

export type DeleteHomeqClaimMutationVariables = Exact<{
  claimId: Scalars['ID']['input']
  renewed?: InputMaybe<Scalars['Boolean']['input']>
}>

export type DeleteHomeqClaimMutation = {
  __typename: 'MutationRoot'
  deleteHomeqClaim: { __typename: 'ConnectHubHomeQDeleteClaimPayload'; success: boolean | null } | null
}

export type RenewHomeqClaimMutationVariables = Exact<{
  claimId: Scalars['ID']['input']
}>

export type RenewHomeqClaimMutation = {
  __typename: 'MutationRoot'
  renewHomeqClaim: {
    __typename: 'ConnectHubHomeQRenewClaimPayload'
    claim: { __typename: 'ConnectHubHomeQCurrentClaimType'; id: string } | null
  } | null
}

export type DiscardIdentificationMutationVariables = Exact<{
  identificationId?: InputMaybe<Scalars['ID']['input']>
}>

export type DiscardIdentificationMutation = {
  __typename: 'MutationRoot'
  discardIdentification: { __typename: 'DiscardIdentificationPayload'; success: boolean } | null
}

export type VerificationsQueryQueryVariables = Exact<{ [key: string]: never }>

export type VerificationsQueryQuery = {
  __typename: 'QueryRoot'
  me: {
    __typename: 'User'
    uid: string
    private: { __typename: 'UserPrivate'; homeqUid: string | null }
    certificate: {
      __typename: 'Certificate'
      status: CertificateStatusEnum
      expiresAt: string | null
      createdAt: string
    } | null
    currentIdentification: {
      __typename: 'Identification'
      id: string
      identificationType: IdentificationTypeEnum
      identificationStatus: IdentificationStatusEnum
      identityConfirmed: boolean
      idNumber: string | null
      firstName: string | null
      familyName: string | null
    } | null
    employmentVerification: {
      __typename: 'EmploymentVerification'
      employerName: string
      id: string
      role: string | null
      upload: { __typename: 'Upload'; id: string; title: string; url: string } | null
    } | null
    creditReport: {
      __typename: 'CreditReport'
      fetchStatus: CreditReportFetchStatusEnum
      id: string
      expiresOn: string
    } | null
    studentVerification: {
      __typename: 'StudentVerification'
      id: string
      school: string
      program: string
      upload: { __typename: 'Upload'; id: string; title: string; url: string } | null
    } | null
    housingSituation: {
      __typename: 'HousingSituation'
      id: string
      agreementType: HousingSituationAgreementTypeEnum | null
      landlordName: string | null
      landlordPhoneNumber: string | null
      landlordCity: string | null
      externalLandlordReference: string | null
    } | null
  } | null
}

export type IncomeVerificationQueryVariables = Exact<{
  uid: Scalars['ID']['input']
}>

export type IncomeVerificationQuery = {
  __typename: 'QueryRoot'
  incomeVerification: {
    __typename: 'IncomeVerification'
    id: string
    verifiedAt: string | null
    status: IncomeVerificationStatusEnum
    meanTotalIncome: { __typename: 'Money'; humanized: string }
  } | null
}

export type DestroyIncomeVerificationMutationVariables = Exact<{ [key: string]: never }>

export type DestroyIncomeVerificationMutation = {
  __typename: 'MutationRoot'
  destroyIncomeVerification: { __typename: 'DestroyIncomeVerificationPayload'; success: boolean } | null
}

export type CurrentHomeqClaimsQueryVariables = Exact<{ [key: string]: never }>

export type CurrentHomeqClaimsQuery = {
  __typename: 'QueryRoot'
  homeqUserClaims: {
    __typename: 'ConnectHubHomeQUserClaimsType'
    currentClaims: Array<{
      __typename: 'ConnectHubHomeQCurrentClaimType'
      id: string
      renewed: boolean
      dateCreated: string
      expiryDate: string | null
      status: ConnectHubHomeQClaimStatusEnum
      type: ConnectHubHomeQClaimTypeEnum
      originalClaimId: string | null
      incomeSource: ConnectHubHomeQClaimIncomeSourceEnum
      income: number
      reason: string | null
      files: Array<{
        __typename: 'ConnectHubHomeQClaimFileType'
        id: string | null
        file: string | null
        classification: ConnectHubHomeQClaimFileClassificationEnum
        reference: string | null
      }> | null
      attributes: {
        __typename: 'ConnectHubHomeQClaimAttributesType'
        orgType: ConnectHubHomeQClaimOrgTypeEnum | null
        description: string | null
      }
    }>
  } | null
}

export type OptInShortcutMutationVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type OptInShortcutMutation = {
  __typename: 'MutationRoot'
  acceptShortcutProposal: { __typename: 'AcceptShortcutProposalPayload'; homeId: string } | null
}

export type OptOutShortcutMutationVariables = Exact<{
  homeId: Scalars['ID']['input']
  declineReason?: InputMaybe<ShortcutProposalDeclineReasonEnum>
}>

export type OptOutShortcutMutation = {
  __typename: 'MutationRoot'
  declineShortcutProposal: { __typename: 'DeclineShortcutProposalPayload'; homeId: string } | null
}

export type GetHomeStatusQueryVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type GetHomeStatusQuery = {
  __typename: 'QueryRoot'
  home: {
    __typename: 'Home'
    id: string
    status: HomeStatusEnum
    market: { __typename: 'Market'; id: string; name: MarketNameEnum }
  } | null
}

export type UpdatePhoneNumberMutationVariables = Exact<{
  uid: Scalars['ID']['input']
  input: UpdateUserInput
}>

export type UpdatePhoneNumberMutation = {
  __typename: 'MutationRoot'
  updateUser: {
    __typename: 'UpdateUserPayload'
    user: {
      __typename: 'User'
      uid: string
      private: { __typename: 'UserPrivate'; id: string; phoneNumber: string | null }
    } | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type FavoriteMarkHomeMutationVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type FavoriteMarkHomeMutation = {
  __typename: 'MutationRoot'
  favoriteMarkHome: { __typename: 'FavoriteMarkHomePayload'; home: { __typename: 'Home'; id: string } } | null
}

export type UnmarkFavoriteHomeMutationVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type UnmarkFavoriteHomeMutation = {
  __typename: 'MutationRoot'
  unmarkFavoriteHome: {
    __typename: 'UnmarkFavoriteHomePayload'
    home: { __typename: 'Home'; id: string }
  } | null
}

export type DurationComponentFragment = {
  __typename: 'Duration'
  id: string
  endOptimal: string | null
  endUfn: boolean
  startAsap: boolean
  startOptimal: string | null
}

export type CurrentUserFragment = {
  __typename: 'User'
  emailConfirmed: boolean
  landlord: boolean
  tenant: boolean
  age: number | null
  firstName: string | null
  professional: boolean
  companyName: string | null
  uid: string
  premium: boolean
  currentIdentification: {
    __typename: 'Identification'
    id: string
    identityConfirmed: boolean
    identityConfirmedAt: string | null
    identificationType: IdentificationTypeEnum
  } | null
  activeIdentifications: Array<{
    __typename: 'Identification'
    id: string
    identificationType: IdentificationTypeEnum
    identityConfirmedAt: string | null
    identityConfirmed: boolean
  }> | null
  certificate: { __typename: 'Certificate'; id: string; status: CertificateStatusEnum } | null
  profilePicture: { __typename: 'Upload'; url: string } | null
  private: {
    __typename: 'UserPrivate'
    id: string
    familyName: string | null
    phoneNumber: string | null
    invoiceAdsFrom: string | null
    verifiedAccess: boolean
    homeqUid: string | null
    activeBankAccount: { __typename: 'BankAccount'; id: string } | null
  }
  tenantProfileCompleteness: {
    __typename: 'TenantProfileCompleteness'
    completenessScore: number
    tenantProfileFields: {
      __typename: 'TenantProfileFields'
      bioLived: { __typename: 'TenantProfileField'; completed: boolean }
      bio: { __typename: 'TenantProfileField'; completed: boolean }
      firstName: { __typename: 'TenantProfileField'; completed: boolean }
      idNumberConfirmed: { __typename: 'TenantProfileField'; completed: boolean }
      matchingProfile: { __typename: 'TenantProfileField'; completed: boolean }
      movingReason: { __typename: 'TenantProfileField'; completed: boolean }
      profilePicture: { __typename: 'TenantProfileField'; completed: boolean }
      bioPets: { __typename: 'TenantProfileField'; completed: boolean }
      bioTitle: { __typename: 'TenantProfileField'; completed: boolean }
      currentAgreementType: { __typename: 'TenantProfileField'; completed: boolean }
    }
  }
}

export type DeclineRevokeBookingMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeclineRevokeBookingMutation = {
  __typename: 'MutationRoot'
  declineOrRevokeBooking: {
    __typename: 'DeclineOrRevokeBookingPayload'
    booking: { __typename: 'Booking'; id: string; status: BookingStatusEnum } | null
  } | null
}

export type DeclineHomeApplicationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeclineHomeApplicationMutation = {
  __typename: 'MutationRoot'
  declineHomeApplication: {
    __typename: 'DeclineHomeApplicationPayload'
    homeApplication: { __typename: 'HomeApplication'; id: string; status: HomeApplicationStatusEnum } | null
  } | null
}

export type ChatEditContractActionFragment = {
  __typename: 'Conversation'
  homeApplication: {
    __typename: 'HomeApplication'
    id: string
    endOptimal: string | null
    startOptimal: string | null
    home: {
      __typename: 'Home'
      id: string
      market: { __typename: 'Market'; id: string; name: MarketNameEnum }
      landlord: { __typename: 'User'; uid: string }
    }
    tenant: { __typename: 'User'; uid: string }
    contract: { __typename: 'Contract'; id: string } | null
  } | null
}

export type CreateTenantInvitationMutationVariables = Exact<{
  input: CreateTenantInvitationInput
}>

export type CreateTenantInvitationMutation = {
  __typename: 'MutationRoot'
  createTenantInvitation: {
    __typename: 'CreateTenantInvitationPayload'
    tenantInvitation: { __typename: 'ContractTenantInvitation'; claimKey: string | null } | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type AcceptOfferingMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type AcceptOfferingMutation = {
  __typename: 'MutationRoot'
  acceptOffering: {
    __typename: 'AcceptOfferingPayload'
    offering: { __typename: 'Offering'; offeringStatus: OfferingStatusEnum; id: string }
  } | null
}

export type DeclineOfferingMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeclineOfferingMutation = {
  __typename: 'MutationRoot'
  declineOffering: {
    __typename: 'DeclineOfferingPayload'
    offering: { __typename: 'Offering'; offeringStatus: OfferingStatusEnum; id: string }
  } | null
}

export type OfferingActionsFragment = {
  __typename: 'Offering'
  id: string
  offeringStatus: OfferingStatusEnum
}

export type ChatActionsMessageFragment = {
  __typename: 'ConversationMessage'
  id: string
  messageType: ConversationMessageTypeEnum
  reference:
    | { __typename: 'BookingOffer' }
    | { __typename: 'ContractTenantInvitation' }
    | { __typename: 'HomeApplication'; conversation: { __typename: 'Conversation'; id: string } | null }
    | { __typename: 'Shortcut' }
    | null
}

export type ChatActionsConversationFragment = {
  __typename: 'Conversation'
  id: string
  archivedAt: string | null
  homeApplication: {
    __typename: 'HomeApplication'
    id: string
    endOptimal: string | null
    startOptimal: string | null
    offering: { __typename: 'Offering'; id: string; offeringStatus: OfferingStatusEnum } | null
    contract: {
      __typename: 'Contract'
      id: string
      status: ContractStatusEnum
      signUrl: string | null
      extensionContract: {
        __typename: 'Contract'
        id: string
        status: ContractStatusEnum
        signUrl: string | null
        signatories: Array<{
          __typename: 'Signatory'
          id: string
          role: SignatoryRoleEnum
          signedAt: string | null
        }>
      } | null
      market: { __typename: 'Market'; id: string; name: MarketNameEnum }
    } | null
    home: {
      __typename: 'Home'
      id: string
      market: { __typename: 'Market'; id: string; name: MarketNameEnum }
      landlord: { __typename: 'User'; uid: string }
    }
    tenant: { __typename: 'User'; uid: string }
  } | null
  home: {
    __typename: 'Home'
    id: string
    status: HomeStatusEnum
    location: { __typename: 'Location'; id: string; route: string | null; streetNumber: string | null }
  }
}

export type FloatingActionButtonsConversationFragment = {
  __typename: 'Conversation'
  id: string
  homeApplication: {
    __typename: 'HomeApplication'
    contract: { __typename: 'Contract'; acceptsCosignatures: boolean | null } | null
  } | null
}

export type SwishPaymentInvoicesQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SwishPaymentInvoicesQuery = {
  __typename: 'QueryRoot'
  contract: {
    __typename: 'Contract'
    invoices: Array<{
      __typename: 'Invoice'
      id: string
      swishPayments: Array<{
        __typename: 'SwishPayment'
        id: string
        status: SwishPaymentStatusEnum
        paymentReferenceId: string
      } | null>
    }>
  }
}

export type TransitionBookingOfferMutationVariables = Exact<{
  input: TransitionBookingOfferInput
}>

export type TransitionBookingOfferMutation = {
  __typename: 'MutationRoot'
  transitionBookingOffer: {
    __typename: 'TransitionBookingOfferPayload'
    bookingOffer: { __typename: 'BookingOffer'; id: string; currentState: BookingOfferStatesEnum } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type GetBookingInvoiceQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetBookingInvoiceQuery = {
  __typename: 'QueryRoot'
  contract: {
    __typename: 'Contract'
    id: string
    invoices: Array<{
      __typename: 'Invoice'
      amount: number
      paidAt: string | null
      id: string
      url: string | null
      type: InvoiceTypeEnum
    }>
  }
}

export type InitiateBookingCardPaymentMutationVariables = Exact<{
  input: InitiateBookingCardPaymentInput
}>

export type InitiateBookingCardPaymentMutation = {
  __typename: 'MutationRoot'
  initiateBookingCardPayment: {
    __typename: 'InitiateBookingCardPaymentPayload'
    sessionId: string | null
    success: boolean
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type InitiateBookingSwishPaymentMutationVariables = Exact<{
  input: InitiateSwishPaymentInput
}>

export type InitiateBookingSwishPaymentMutation = {
  __typename: 'MutationRoot'
  initiateSwishPayment: {
    __typename: 'InitiateSwishPaymentPayload'
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
    swishPayment: {
      __typename: 'SwishPayment'
      id: string
      status: SwishPaymentStatusEnum
      paymentReferenceId: string
    } | null
  } | null
}

export type HeaderActionsFragment = {
  __typename: 'Conversation'
  id: string
  home: {
    __typename: 'Home'
    id: string
    tenantCount: number | null
    rent: number | null
    qasaGuarantee: boolean | null
    qasaGuaranteeCost: number | null
    insurance: HomeInsuranceEnum | null
    insuranceCost: number | null
    instantSignSetting: { __typename: 'InstantSignSetting'; enabled: boolean } | null
    market: { __typename: 'Market'; id: string; name: MarketNameEnum }
    landlordFees: {
      __typename: 'HomeLandlordFees'
      base: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: {
          __typename: 'Cost'
          total: number
          factor: number | null
          fixed: number | null
          isFree: boolean
        }
      }
    }
    tenantFees: {
      __typename: 'HomeTenantFees'
      waterFeePerTenant: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: {
          __typename: 'Cost'
          total: number
          factor: number | null
          fixed: number | null
          isFree: boolean
        }
      }
    }
    landlordServices: {
      __typename: 'HomeLandlordServices'
      qasaGuarantee: {
        __typename: 'Service'
        isActive: boolean
        availability: ServiceAvailabilityEnum
        cost: { __typename: 'Cost'; total: number }
      }
      insurance: {
        __typename: 'Service'
        isActive: boolean
        availability: ServiceAvailabilityEnum
        cost: { __typename: 'Cost'; total: number }
      }
    }
  }
  homeApplication: {
    __typename: 'HomeApplication'
    id: string
    contract: {
      __typename: 'Contract'
      id: string
      rent: number | null
      qasaGuarantee: boolean | null
      qasaGuaranteeCost: number | null
      insurance: HomeInsuranceEnum | null
      insuranceCost: number | null
      numberOfTenants: number | null
      signatories: Array<{
        __typename: 'Signatory'
        id: string
        role: SignatoryRoleEnum
        signedAt: string | null
      }>
      landlordFees: {
        __typename: 'ContractLandlordFees'
        base: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: {
            __typename: 'Cost'
            total: number
            factor: number | null
            fixed: number | null
            isFree: boolean
          }
        }
      }
      tenantFees: {
        __typename: 'ContractTenantFees'
        waterFeePerTenant: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: {
            __typename: 'Cost'
            total: number
            factor: number | null
            fixed: number | null
            isFree: boolean
          }
        }
      }
    } | null
  } | null
}

export type ArchiveConversationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ArchiveConversationMutation = {
  __typename: 'MutationRoot'
  archiveConversation: {
    __typename: 'ArchiveConversationPayload'
    conversation: { __typename: 'Conversation'; id: string; archivedAt: string | null }
  } | null
}

export type OpenConversationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type OpenConversationMutation = {
  __typename: 'MutationRoot'
  openConversation: {
    __typename: 'OpenConversationPayload'
    conversation: { __typename: 'Conversation'; id: string; status: ConversationStatusEnum }
    errors: Array<{ __typename: 'Validation'; codes: Array<string> }> | null
  } | null
}

export type UpsertContractMutationVariables = Exact<{
  input: UpsertContractInput
  id?: InputMaybe<Scalars['ID']['input']>
}>

export type UpsertContractMutation = {
  __typename: 'MutationRoot'
  upsertContract: {
    __typename: 'UpsertContractPayload'
    contract: { __typename: 'Contract'; id: string } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type CreateBookingOfferMutationVariables = Exact<{
  input: CreateBookingOfferInput
}>

export type CreateBookingOfferMutation = {
  __typename: 'MutationRoot'
  createBookingOffer: {
    __typename: 'CreateBookingOfferPayload'
    bookingOffer: { __typename: 'BookingOffer'; id: string } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type CreateBookingOfferFromBookingMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CreateBookingOfferFromBookingMutation = {
  __typename: 'MutationRoot'
  createBookingOfferFromBooking: {
    __typename: 'CreateBookingOfferFromBookingPayload'
    bookingOffer: { __typename: 'BookingOffer'; id: string } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type EditBookingDialogFragment = {
  __typename: 'Conversation'
  id: string
  booking: {
    __typename: 'Booking'
    id: string
    status: BookingStatusEnum
    checkIn: string
    checkOut: string
    bookingOffers: Array<{ __typename: 'BookingOffer'; id: string; currentState: BookingOfferStatesEnum }>
  } | null
  home: {
    __typename: 'Home'
    id: string
    pricingModel: { __typename: 'PricingModel'; tenantBaseFeeFactor: number }
  }
}

export type HomeAvailabilitiesAppQueryVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type HomeAvailabilitiesAppQuery = {
  __typename: 'QueryRoot'
  homeAvailabilities: Array<{
    __typename: 'HomeAvailability'
    id: string
    date: string | null
    status: HomeAvailabilityStatusEnum
    booking: {
      __typename: 'Booking'
      id: string
      status: BookingStatusEnum
      conversation: { __typename: 'Conversation'; id: string } | null
      tenant: {
        __typename: 'User'
        uid: string
        profilePicture: { __typename: 'Upload'; id: string; url: string } | null
      }
    } | null
  }>
}

export type EditBookingFormConversationFragment = {
  __typename: 'Conversation'
  id: string
  home: {
    __typename: 'Home'
    id: string
    pricingModel: { __typename: 'PricingModel'; tenantBaseFeeFactor: number }
  }
}

export type EditBookingFormHomeAvailabilityFragment = {
  __typename: 'HomeAvailability'
  id: string
  date: string | null
  status: HomeAvailabilityStatusEnum
  booking: {
    __typename: 'Booking'
    id: string
    status: BookingStatusEnum
    conversation: { __typename: 'Conversation'; id: string } | null
    tenant: {
      __typename: 'User'
      uid: string
      profilePicture: { __typename: 'Upload'; id: string; url: string } | null
    }
  } | null
}

export type DeclineHomeApplicationForConversationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeclineHomeApplicationForConversationMutation = {
  __typename: 'MutationRoot'
  declineHomeApplication: {
    __typename: 'DeclineHomeApplicationPayload'
    homeApplication: { __typename: 'HomeApplication'; id: string; status: HomeApplicationStatusEnum } | null
  } | null
}

export type RevokeSentApplicationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type RevokeSentApplicationMutation = {
  __typename: 'MutationRoot'
  revokeHomeApplication: {
    __typename: 'RevokeHomeApplicationPayload'
    homeApplication: { __typename: 'HomeApplication'; id: string; status: HomeApplicationStatusEnum } | null
  } | null
}

export type UnarchiveConversationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UnarchiveConversationMutation = {
  __typename: 'MutationRoot'
  unarchiveConversation: {
    __typename: 'UnarchiveConversationPayload'
    conversation: { __typename: 'Conversation'; id: string; archivedAt: string | null }
  } | null
}

export type AdditionalOptionsMenuFragment = {
  __typename: 'Conversation'
  id: string
  status: ConversationStatusEnum
  role: ConversationRoleTypeEnum
  initiator: boolean
  home: { __typename: 'Home'; id: string; status: HomeStatusEnum }
  statusChanger: { __typename: 'User'; uid: string } | null
  homeApplication: { __typename: 'HomeApplication'; id: string; status: HomeApplicationStatusEnum } | null
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    user: { __typename: 'User'; uid: string; firstName: string | null }
  }>
}

export type ChatHeaderProfileFragment = {
  __typename: 'Conversation'
  id: string
  role: ConversationRoleTypeEnum
  home: {
    __typename: 'Home'
    location: { __typename: 'Location'; route: string | null; streetNumber: string | null }
  }
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    role: ConversationRoleTypeEnum
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
      profilePicture: { __typename: 'Upload'; id: string; url: string } | null
    }
  }>
}

export type ChatHeaderFragment = {
  __typename: 'Conversation'
  id: string
  role: ConversationRoleTypeEnum
  status: ConversationStatusEnum
  initiator: boolean
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    role: ConversationRoleTypeEnum
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
      profilePicture: { __typename: 'Upload'; id: string; url: string } | null
    }
  }>
  home: {
    __typename: 'Home'
    id: string
    status: HomeStatusEnum
    tenantCount: number | null
    rent: number | null
    qasaGuarantee: boolean | null
    qasaGuaranteeCost: number | null
    insurance: HomeInsuranceEnum | null
    insuranceCost: number | null
    location: { __typename: 'Location'; route: string | null; streetNumber: string | null }
    instantSignSetting: { __typename: 'InstantSignSetting'; enabled: boolean } | null
    market: { __typename: 'Market'; id: string; name: MarketNameEnum }
    landlordFees: {
      __typename: 'HomeLandlordFees'
      base: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: {
          __typename: 'Cost'
          total: number
          factor: number | null
          fixed: number | null
          isFree: boolean
        }
      }
    }
    tenantFees: {
      __typename: 'HomeTenantFees'
      waterFeePerTenant: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: {
          __typename: 'Cost'
          total: number
          factor: number | null
          fixed: number | null
          isFree: boolean
        }
      }
    }
    landlordServices: {
      __typename: 'HomeLandlordServices'
      qasaGuarantee: {
        __typename: 'Service'
        isActive: boolean
        availability: ServiceAvailabilityEnum
        cost: { __typename: 'Cost'; total: number }
      }
      insurance: {
        __typename: 'Service'
        isActive: boolean
        availability: ServiceAvailabilityEnum
        cost: { __typename: 'Cost'; total: number }
      }
    }
  }
  statusChanger: { __typename: 'User'; uid: string } | null
  homeApplication: {
    __typename: 'HomeApplication'
    id: string
    status: HomeApplicationStatusEnum
    contract: {
      __typename: 'Contract'
      id: string
      rent: number | null
      qasaGuarantee: boolean | null
      qasaGuaranteeCost: number | null
      insurance: HomeInsuranceEnum | null
      insuranceCost: number | null
      numberOfTenants: number | null
      signatories: Array<{
        __typename: 'Signatory'
        id: string
        role: SignatoryRoleEnum
        signedAt: string | null
      }>
      landlordFees: {
        __typename: 'ContractLandlordFees'
        base: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: {
            __typename: 'Cost'
            total: number
            factor: number | null
            fixed: number | null
            isFree: boolean
          }
        }
      }
      tenantFees: {
        __typename: 'ContractTenantFees'
        waterFeePerTenant: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: {
            __typename: 'Cost'
            total: number
            factor: number | null
            fixed: number | null
            isFree: boolean
          }
        }
      }
    } | null
  } | null
}

export type ChatHomeDetailsFragment = {
  __typename: 'Conversation'
  home: {
    __typename: 'Home'
    id: string
    title: string | null
    type: HomeTypeEnum
    rent: number | null
    tenantBaseFee: number | null
    bedroomCount: number | null
    bedCount: number | null
    tenantCount: number | null
    numberOfHomes: number | null
    rentalType: HomeRentalTypeEnum
    minRent: number | null
    maxRent: number | null
    displayStreetNumber: boolean
    roomCount: number | null
    minRoomCount: number | null
    maxRoomCount: number | null
    squareMeters: number | null
    minSquareMeters: number | null
    maxSquareMeters: number | null
    studentHome: boolean
    seniorHome: boolean
    corporateHome: boolean
    shared: boolean | null
    floor: number | null
    buildingFloors: number | null
    location: {
      __typename: 'Location'
      route: string | null
      streetNumber: string | null
      locality: string | null
    }
    uploads: Array<{ __typename: 'Upload'; id: string; url: string }>
    traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
  }
  homeApplication: {
    __typename: 'HomeApplication'
    homeTemplates: Array<{ __typename: 'HomeTemplate'; id: string }>
  } | null
}

export type AcceptHomeApplicationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type AcceptHomeApplicationMutation = {
  __typename: 'MutationRoot'
  acceptHomeApplication: {
    __typename: 'AcceptHomeApplicationPayload'
    homeApplication: {
      __typename: 'HomeApplication'
      id: string
      status: HomeApplicationStatusEnum
      conversation: { __typename: 'Conversation'; id: string } | null
    } | null
  } | null
}

export type CreateMessageForChatMutationVariables = Exact<{
  input: CreateConversationMessageInput
}>

export type CreateMessageForChatMutation = {
  __typename: 'MutationRoot'
  createConversationMessage: {
    __typename: 'CreateConversationMessagePayload'
    message: {
      __typename: 'ConversationMessage'
      id: string
      message: string | null
      createdAt: string
      updatedAt: string
      messageType: ConversationMessageTypeEnum
      upload: { __typename: 'Upload'; id: string; title: string; url: string } | null
      user: {
        __typename: 'User'
        uid: string
        firstName: string | null
        professional: boolean
        companyName: string | null
      }
    } | null
  } | null
}

export type ChatHomeIntroMessageFragment = {
  __typename: 'Conversation'
  id: string
  role: ConversationRoleTypeEnum
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
      proAgent: boolean
    }
  }>
  home: {
    __typename: 'Home'
    id: string
    displayStreetNumber: boolean
    rent: number | null
    currency: CurrencyEnum
    type: HomeTypeEnum
    numberOfHomes: number | null
    roomCount: number | null
    minRoomCount: number | null
    maxRoomCount: number | null
    squareMeters: number | null
    minSquareMeters: number | null
    maxSquareMeters: number | null
    studentHome: boolean
    seniorHome: boolean
    corporateHome: boolean
    shared: boolean | null
    floor: number | null
    buildingFloors: number | null
    location: {
      __typename: 'Location'
      route: string | null
      streetNumber: string | null
      id: string
      locality: string | null
    }
    uploads: Array<{
      __typename: 'Upload'
      id: string
      url: string
      metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
    }>
    landlord: { __typename: 'User'; uid: string }
    traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
  }
}

export type ChatIntroMessageFragment = {
  __typename: 'Conversation'
  id: string
  role: ConversationRoleTypeEnum
  homeApplication: { __typename: 'HomeApplication'; origin: HomeApplicationOriginEnum } | null
  booking: { __typename: 'Booking'; id: string } | null
  home: {
    __typename: 'Home'
    id: string
    displayStreetNumber: boolean
    rent: number | null
    currency: CurrencyEnum
    type: HomeTypeEnum
    numberOfHomes: number | null
    roomCount: number | null
    minRoomCount: number | null
    maxRoomCount: number | null
    squareMeters: number | null
    minSquareMeters: number | null
    maxSquareMeters: number | null
    studentHome: boolean
    seniorHome: boolean
    corporateHome: boolean
    shared: boolean | null
    floor: number | null
    buildingFloors: number | null
    location: {
      __typename: 'Location'
      route: string | null
      streetNumber: string | null
      id: string
      locality: string | null
    }
    uploads: Array<{
      __typename: 'Upload'
      id: string
      url: string
      metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
    }>
    landlord: { __typename: 'User'; uid: string }
    traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
  }
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    role: ConversationRoleTypeEnum
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
      proAgent: boolean
    }
  }>
}

export type ChatProfileQueryVariables = Exact<{
  uid: Scalars['ID']['input']
  homeId: Scalars['ID']['input']
}>

export type ChatProfileQuery = {
  __typename: 'QueryRoot'
  user: {
    __typename: 'User'
    uid: string
    firstName: string | null
    companyName: string | null
    professional: boolean
    tenant: boolean
    showAge: boolean | null
    age: number | null
    profilePicture: { __typename: 'Upload'; id: string; url: string } | null
    tenantProfileCompleteness: {
      __typename: 'TenantProfileCompleteness'
      completenessScore: number
      tenantProfileFields: {
        __typename: 'TenantProfileFields'
        bioLived: { __typename: 'TenantProfileField'; completed: boolean }
        bio: { __typename: 'TenantProfileField'; completed: boolean }
        firstName: { __typename: 'TenantProfileField'; completed: boolean }
        idNumberConfirmed: { __typename: 'TenantProfileField'; completed: boolean }
        matchingProfile: { __typename: 'TenantProfileField'; completed: boolean }
        movingReason: { __typename: 'TenantProfileField'; completed: boolean }
        profilePicture: { __typename: 'TenantProfileField'; completed: boolean }
        bioPets: { __typename: 'TenantProfileField'; completed: boolean }
        bioTitle: { __typename: 'TenantProfileField'; completed: boolean }
        currentAgreementType: { __typename: 'TenantProfileField'; completed: boolean }
      }
    }
    tenantAd: {
      __typename: 'TenantAd'
      searchPreference: {
        __typename: 'SearchPreference'
        id: string
        maxMonthlyCost: number | null
        maxMonthlyCostCents: number | null
        currency: CurrencyEnum | null
        minRoomCount: number | null
        minSquareMeters: number | null
        householdSize: number | null
        furniture: boolean | null
        endOptimal: string | null
        endUfn: boolean
        startOptimal: string | null
        startAsap: boolean
      }
    } | null
    bio: { __typename: 'UserBio'; title: string | null }
    currentIdentification: {
      __typename: 'Identification'
      identityConfirmed: boolean
      identityConfirmedAt: string | null
    } | null
    housingSituation: { __typename: 'HousingSituation'; id: string; hasReference: boolean } | null
  } | null
  home: {
    __typename: 'Home'
    id: string
    rent: number | null
    tenantBaseFee: number | null
    monthlyCostCents: number | null
    roomCount: number | null
    squareMeters: number | null
    tenantCount: number | null
    type: HomeTypeEnum
    shared: boolean | null
    traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
    duration: {
      __typename: 'Duration'
      startAsap: boolean
      startOptimal: string | null
      endUfn: boolean
      endOptimal: string | null
    }
  } | null
}

export type MatchingScoreQueryVariables = Exact<{
  tenantUid: Scalars['ID']['input']
  homeId: Scalars['ID']['input']
}>

export type MatchingScoreQuery = {
  __typename: 'QueryRoot'
  matchingScoreAws: { __typename: 'MatchingScore'; score: number | null }
}

export type ChatProfileFragment = {
  __typename: 'Conversation'
  role: ConversationRoleTypeEnum
  booking: { __typename: 'Booking'; id: string } | null
  home: { __typename: 'Home'; id: string }
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    role: ConversationRoleTypeEnum
    user: { __typename: 'User'; uid: string }
  }>
}

export type ChatMessageListFragment = {
  __typename: 'Conversation'
  id: string
  role: ConversationRoleTypeEnum
  initiator: boolean
  archivedAt: string | null
  homeApplication: {
    __typename: 'HomeApplication'
    origin: HomeApplicationOriginEnum
    id: string
    endOptimal: string | null
    startOptimal: string | null
    homeTemplates: Array<{ __typename: 'HomeTemplate'; id: string }>
    contract: {
      __typename: 'Contract'
      acceptsCosignatures: boolean | null
      id: string
      status: ContractStatusEnum
      signUrl: string | null
      extensionContract: {
        __typename: 'Contract'
        id: string
        status: ContractStatusEnum
        signUrl: string | null
        signatories: Array<{
          __typename: 'Signatory'
          id: string
          role: SignatoryRoleEnum
          signedAt: string | null
        }>
      } | null
      market: { __typename: 'Market'; id: string; name: MarketNameEnum }
    } | null
    offering: { __typename: 'Offering'; id: string; offeringStatus: OfferingStatusEnum } | null
    home: {
      __typename: 'Home'
      id: string
      market: { __typename: 'Market'; id: string; name: MarketNameEnum }
      landlord: { __typename: 'User'; uid: string }
    }
    tenant: { __typename: 'User'; uid: string }
  } | null
  messages: {
    __typename: 'ConversationMessageConnection'
    nodes: Array<{
      __typename: 'ConversationMessage'
      id: string
      createdAt: string
      message: string | null
      messageType: ConversationMessageTypeEnum
      user: {
        __typename: 'User'
        uid: string
        firstName: string | null
        companyName: string | null
        professional: boolean
      }
      upload: { __typename: 'Upload'; id: string; title: string; url: string } | null
      reference:
        | { __typename: 'BookingOffer'; id: string; createdFromBooking: boolean }
        | { __typename: 'ContractTenantInvitation'; recipientEmail: string | null }
        | { __typename: 'HomeApplication'; conversation: { __typename: 'Conversation'; id: string } | null }
        | { __typename: 'Shortcut' }
        | null
    }>
  }
  home: {
    __typename: 'Home'
    id: string
    title: string | null
    type: HomeTypeEnum
    rent: number | null
    tenantBaseFee: number | null
    bedroomCount: number | null
    bedCount: number | null
    tenantCount: number | null
    numberOfHomes: number | null
    rentalType: HomeRentalTypeEnum
    minRent: number | null
    maxRent: number | null
    displayStreetNumber: boolean
    responsibleForCleaning: ResponsibleForCleaningEnum | null
    status: HomeStatusEnum
    roomCount: number | null
    minRoomCount: number | null
    maxRoomCount: number | null
    squareMeters: number | null
    minSquareMeters: number | null
    maxSquareMeters: number | null
    studentHome: boolean
    seniorHome: boolean
    corporateHome: boolean
    shared: boolean | null
    floor: number | null
    buildingFloors: number | null
    currency: CurrencyEnum
    location: {
      __typename: 'Location'
      route: string | null
      streetNumber: string | null
      id: string
      locality: string | null
    }
    uploads: Array<{
      __typename: 'Upload'
      id: string
      url: string
      metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
    }>
    currentCancellationPolicy: {
      __typename: 'CancellationPolicy'
      policyName: CancellationPolicyNameEnum | null
      partialRefundFactor: number | null
      partialRefundDays: number | null
      fullRefundDays: number | null
      landlordFullRefundDays: number | null
    } | null
    traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
    landlord: { __typename: 'User'; uid: string }
  }
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    archivedAt: string | null
    role: ConversationRoleTypeEnum
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
      proAgent: boolean
      proPilot: boolean
      premium: boolean
    }
  }>
  booking: {
    __typename: 'Booking'
    id: string
    status: BookingStatusEnum
    checkIn: string
    checkOut: string
    canceledAt: string | null
    refundAction: RefundActionEnum | null
    actionDeadline: string | null
    createdAt: string
    tenant: { __typename: 'User'; uid: string }
    landlord: { __typename: 'User'; uid: string }
    bookingOffers: Array<{
      __typename: 'BookingOffer'
      id: string
      currentState: BookingOfferStatesEnum
      createdFromBooking: boolean
      checkIn: string
      checkOut: string
      pricingInfo: {
        __typename: 'PricePreview'
        tenantFee: number
        landlordFee: number
        totalTenantPrice: number
        rentPrice: number
      }
    }>
    pricingInfo: {
      __typename: 'PricePreview'
      landlordFee: number
      rentPrice: number
      tenantFee: number
      totalTenantPrice: number
      averagePricePerNight: number
      cleaningFee: number
    }
    contract: {
      __typename: 'Contract'
      id: string
      status: ContractStatusEnum
      createdAt: string
      tenant: { __typename: 'User'; uid: string } | null
      invoices: Array<{
        __typename: 'Invoice'
        amount: number
        url: string | null
        id: string
        paidAt: string | null
        type: InvoiceTypeEnum
      }>
    } | null
  } | null
  lastSeenMessageIds: Array<{ __typename: 'LastSeenMessage'; messageId: string | null; uid: string }>
}

export type BookingSystemMessageFragment = {
  __typename: 'ConversationMessage'
  id: string
  messageType: ConversationMessageTypeEnum
  reference:
    | { __typename: 'BookingOffer'; id: string; createdFromBooking: boolean }
    | { __typename: 'ContractTenantInvitation' }
    | { __typename: 'HomeApplication' }
    | { __typename: 'Shortcut' }
    | null
}

export type BookingSystemMessageConversationFragment = {
  __typename: 'Conversation'
  id: string
  home: {
    __typename: 'Home'
    id: string
    responsibleForCleaning: ResponsibleForCleaningEnum | null
    currentCancellationPolicy: {
      __typename: 'CancellationPolicy'
      policyName: CancellationPolicyNameEnum | null
      partialRefundFactor: number | null
      partialRefundDays: number | null
      fullRefundDays: number | null
      landlordFullRefundDays: number | null
    } | null
  }
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    archivedAt: string | null
    role: ConversationRoleTypeEnum
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
    }
  }>
  booking: {
    __typename: 'Booking'
    id: string
    status: BookingStatusEnum
    checkIn: string
    checkOut: string
    canceledAt: string | null
    refundAction: RefundActionEnum | null
    actionDeadline: string | null
    createdAt: string
    tenant: { __typename: 'User'; uid: string }
    landlord: { __typename: 'User'; uid: string }
    bookingOffers: Array<{
      __typename: 'BookingOffer'
      id: string
      currentState: BookingOfferStatesEnum
      createdFromBooking: boolean
      checkIn: string
      checkOut: string
      pricingInfo: {
        __typename: 'PricePreview'
        tenantFee: number
        landlordFee: number
        totalTenantPrice: number
        rentPrice: number
      }
    }>
    pricingInfo: {
      __typename: 'PricePreview'
      landlordFee: number
      rentPrice: number
      tenantFee: number
      totalTenantPrice: number
      averagePricePerNight: number
      cleaningFee: number
    }
    contract: {
      __typename: 'Contract'
      id: string
      status: ContractStatusEnum
      createdAt: string
      tenant: { __typename: 'User'; uid: string } | null
      invoices: Array<{
        __typename: 'Invoice'
        amount: number
        url: string | null
        id: string
        paidAt: string | null
        type: InvoiceTypeEnum
      }>
    } | null
  } | null
}

export type ChatImageMessageFragment = {
  __typename: 'ConversationMessage'
  upload: { __typename: 'Upload'; id: string; title: string; url: string } | null
}

export type ChatImageMessageConversationFragment = {
  __typename: 'Conversation'
  id: string
  role: ConversationRoleTypeEnum
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    role: ConversationRoleTypeEnum
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
    }
  }>
}

export type ChatMessageReadNoticeFragment = {
  __typename: 'Conversation'
  id: string
  lastSeenMessageIds: Array<{ __typename: 'LastSeenMessage'; messageId: string | null; uid: string }>
}

export type ChatMessageFragment = {
  __typename: 'ConversationMessage'
  id: string
  message: string | null
  messageType: ConversationMessageTypeEnum
  user: {
    __typename: 'User'
    uid: string
    firstName: string | null
    companyName: string | null
    professional: boolean
  }
  upload: { __typename: 'Upload'; id: string; title: string; url: string } | null
  reference:
    | { __typename: 'BookingOffer'; id: string; createdFromBooking: boolean }
    | { __typename: 'ContractTenantInvitation'; recipientEmail: string | null }
    | { __typename: 'HomeApplication'; conversation: { __typename: 'Conversation'; id: string } | null }
    | { __typename: 'Shortcut' }
    | null
}

export type ChatMessageConversationFragment = {
  __typename: 'Conversation'
  id: string
  role: ConversationRoleTypeEnum
  initiator: boolean
  archivedAt: string | null
  home: {
    __typename: 'Home'
    id: string
    responsibleForCleaning: ResponsibleForCleaningEnum | null
    status: HomeStatusEnum
    currentCancellationPolicy: {
      __typename: 'CancellationPolicy'
      policyName: CancellationPolicyNameEnum | null
      partialRefundFactor: number | null
      partialRefundDays: number | null
      fullRefundDays: number | null
      landlordFullRefundDays: number | null
    } | null
    location: { __typename: 'Location'; id: string; route: string | null; streetNumber: string | null }
  }
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    archivedAt: string | null
    role: ConversationRoleTypeEnum
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
      proAgent: boolean
      proPilot: boolean
      premium: boolean
    }
  }>
  booking: {
    __typename: 'Booking'
    id: string
    status: BookingStatusEnum
    checkIn: string
    checkOut: string
    canceledAt: string | null
    refundAction: RefundActionEnum | null
    actionDeadline: string | null
    createdAt: string
    tenant: { __typename: 'User'; uid: string }
    landlord: { __typename: 'User'; uid: string }
    bookingOffers: Array<{
      __typename: 'BookingOffer'
      id: string
      currentState: BookingOfferStatesEnum
      createdFromBooking: boolean
      checkIn: string
      checkOut: string
      pricingInfo: {
        __typename: 'PricePreview'
        tenantFee: number
        landlordFee: number
        totalTenantPrice: number
        rentPrice: number
      }
    }>
    pricingInfo: {
      __typename: 'PricePreview'
      landlordFee: number
      rentPrice: number
      tenantFee: number
      totalTenantPrice: number
      averagePricePerNight: number
      cleaningFee: number
    }
    contract: {
      __typename: 'Contract'
      id: string
      status: ContractStatusEnum
      createdAt: string
      tenant: { __typename: 'User'; uid: string } | null
      invoices: Array<{
        __typename: 'Invoice'
        amount: number
        url: string | null
        id: string
        paidAt: string | null
        type: InvoiceTypeEnum
      }>
    } | null
  } | null
  homeApplication: {
    __typename: 'HomeApplication'
    id: string
    endOptimal: string | null
    startOptimal: string | null
    offering: { __typename: 'Offering'; id: string; offeringStatus: OfferingStatusEnum } | null
    contract: {
      __typename: 'Contract'
      id: string
      status: ContractStatusEnum
      signUrl: string | null
      extensionContract: {
        __typename: 'Contract'
        id: string
        status: ContractStatusEnum
        signUrl: string | null
        signatories: Array<{
          __typename: 'Signatory'
          id: string
          role: SignatoryRoleEnum
          signedAt: string | null
        }>
      } | null
      market: { __typename: 'Market'; id: string; name: MarketNameEnum }
    } | null
    home: {
      __typename: 'Home'
      id: string
      market: { __typename: 'Market'; id: string; name: MarketNameEnum }
      landlord: { __typename: 'User'; uid: string }
    }
    tenant: { __typename: 'User'; uid: string }
  } | null
  lastSeenMessageIds: Array<{ __typename: 'LastSeenMessage'; messageId: string | null; uid: string }>
}

export type ChatSystemMessageFragment = {
  __typename: 'ConversationMessage'
  id: string
  messageType: ConversationMessageTypeEnum
  user: {
    __typename: 'User'
    uid: string
    firstName: string | null
    companyName: string | null
    professional: boolean
  }
  reference:
    | { __typename: 'BookingOffer' }
    | { __typename: 'ContractTenantInvitation'; recipientEmail: string | null }
    | { __typename: 'HomeApplication'; conversation: { __typename: 'Conversation'; id: string } | null }
    | { __typename: 'Shortcut' }
    | null
}

export type ChatSystemMessageConversationFragment = {
  __typename: 'Conversation'
  id: string
  role: ConversationRoleTypeEnum
  initiator: boolean
  archivedAt: string | null
  homeApplication: {
    __typename: 'HomeApplication'
    id: string
    endOptimal: string | null
    startOptimal: string | null
    offering: { __typename: 'Offering'; id: string; offeringStatus: OfferingStatusEnum } | null
    contract: {
      __typename: 'Contract'
      id: string
      status: ContractStatusEnum
      signUrl: string | null
      extensionContract: {
        __typename: 'Contract'
        id: string
        status: ContractStatusEnum
        signUrl: string | null
        signatories: Array<{
          __typename: 'Signatory'
          id: string
          role: SignatoryRoleEnum
          signedAt: string | null
        }>
      } | null
      market: { __typename: 'Market'; id: string; name: MarketNameEnum }
    } | null
    home: {
      __typename: 'Home'
      id: string
      market: { __typename: 'Market'; id: string; name: MarketNameEnum }
      landlord: { __typename: 'User'; uid: string }
    }
    tenant: { __typename: 'User'; uid: string }
  } | null
  home: {
    __typename: 'Home'
    id: string
    status: HomeStatusEnum
    location: { __typename: 'Location'; id: string; route: string | null; streetNumber: string | null }
  }
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    archivedAt: string | null
    role: ConversationRoleTypeEnum
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
      proAgent: boolean
    }
  }>
}

export type ChatWarningMessageFragment = {
  __typename: 'Conversation'
  id: string
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    role: ConversationRoleTypeEnum
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      professional: boolean
      proAgent: boolean
      proPilot: boolean
      premium: boolean
    }
  }>
}

export type LandlordAddonsHomeFragment = {
  __typename: 'Home'
  id: string
  landlordServices: {
    __typename: 'HomeLandlordServices'
    qasaGuarantee: {
      __typename: 'Service'
      isActive: boolean
      availability: ServiceAvailabilityEnum
      cost: { __typename: 'Cost'; total: number }
    }
    insurance: {
      __typename: 'Service'
      isActive: boolean
      availability: ServiceAvailabilityEnum
      cost: { __typename: 'Cost'; total: number }
    }
  }
}

export type LandlordProfileFragment = {
  __typename: 'User'
  uid: string
  firstName: string | null
  companyName: string | null
  professional: boolean
  proPilot: boolean
  proAgent: boolean
  landlordApplicationResponseRate: number | null
  landlordApplicationResponseTimeHours: number | null
  profilePicture: { __typename: 'Upload'; id: string; url: string } | null
  currentIdentification: { __typename: 'Identification'; identityConfirmed: boolean } | null
}

export type LandlordViewApplicationFragment = {
  __typename: 'Conversation'
  id: string
  home: {
    __typename: 'Home'
    id: string
    tenantCount: number | null
    rent: number | null
    qasaGuarantee: boolean | null
    qasaGuaranteeCost: number | null
    insurance: HomeInsuranceEnum | null
    insuranceCost: number | null
    market: { __typename: 'Market'; id: string; name: MarketNameEnum }
    landlordFees: {
      __typename: 'HomeLandlordFees'
      base: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: {
          __typename: 'Cost'
          total: number
          factor: number | null
          fixed: number | null
          isFree: boolean
        }
      }
    }
    tenantFees: {
      __typename: 'HomeTenantFees'
      waterFeePerTenant: {
        __typename: 'Fee'
        availability: FeeAvailabilityEnum
        cost: {
          __typename: 'Cost'
          total: number
          factor: number | null
          fixed: number | null
          isFree: boolean
        }
      }
    }
    landlordServices: {
      __typename: 'HomeLandlordServices'
      qasaGuarantee: {
        __typename: 'Service'
        isActive: boolean
        availability: ServiceAvailabilityEnum
        cost: { __typename: 'Cost'; total: number }
      }
      insurance: {
        __typename: 'Service'
        isActive: boolean
        availability: ServiceAvailabilityEnum
        cost: { __typename: 'Cost'; total: number }
      }
    }
  }
  homeApplication: {
    __typename: 'HomeApplication'
    id: string
    contract: {
      __typename: 'Contract'
      id: string
      rent: number | null
      qasaGuarantee: boolean | null
      qasaGuaranteeCost: number | null
      insurance: HomeInsuranceEnum | null
      insuranceCost: number | null
      numberOfTenants: number | null
      landlordFees: {
        __typename: 'ContractLandlordFees'
        base: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: {
            __typename: 'Cost'
            total: number
            factor: number | null
            fixed: number | null
            isFree: boolean
          }
        }
      }
      tenantFees: {
        __typename: 'ContractTenantFees'
        waterFeePerTenant: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: {
            __typename: 'Cost'
            total: number
            factor: number | null
            fixed: number | null
            isFree: boolean
          }
        }
      }
    } | null
  } | null
}

export type TenantCostBreakdownTableFragment = {
  __typename: 'Home'
  id: string
  rent: number | null
  minRent: number | null
  maxRent: number | null
  firsthand: boolean
  seniorHome: boolean
  corporateHome: boolean
  currency: CurrencyEnum
  studentHome: boolean
  numberOfHomes: number | null
  user: { __typename: 'User'; uid: string; professional: boolean }
  tenantFees: {
    __typename: 'HomeTenantFees'
    base: { __typename: 'Fee'; cost: { __typename: 'Cost'; total: number } }
  }
  market: { __typename: 'Market'; id: string; name: MarketNameEnum }
}

export type TenantViewApplicationFragment = {
  __typename: 'Conversation'
  id: string
  home: {
    __typename: 'Home'
    id: string
    rent: number | null
    minRent: number | null
    maxRent: number | null
    firsthand: boolean
    seniorHome: boolean
    corporateHome: boolean
    currency: CurrencyEnum
    studentHome: boolean
    numberOfHomes: number | null
    landlord: { __typename: 'User'; uid: string; proPilot: boolean }
    user: { __typename: 'User'; uid: string; professional: boolean }
    tenantFees: {
      __typename: 'HomeTenantFees'
      base: { __typename: 'Fee'; cost: { __typename: 'Cost'; total: number } }
    }
    market: { __typename: 'Market'; id: string; name: MarketNameEnum }
  }
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    role: ConversationRoleTypeEnum
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
      proPilot: boolean
      proAgent: boolean
      landlordApplicationResponseRate: number | null
      landlordApplicationResponseTimeHours: number | null
      profilePicture: { __typename: 'Upload'; id: string; url: string } | null
      currentIdentification: { __typename: 'Identification'; identityConfirmed: boolean } | null
    }
  }>
}

export type CancelBookingMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CancelBookingMutation = {
  __typename: 'MutationRoot'
  cancelBooking: {
    __typename: 'CancelBookingPayload'
    booking: {
      __typename: 'Booking'
      id: string
      status: BookingStatusEnum
      canceledAt: string | null
      refundAction: RefundActionEnum | null
    } | null
  } | null
}

export type LandlordViewBookingFragment = {
  __typename: 'Conversation'
  id: string
  booking: {
    __typename: 'Booking'
    id: string
    status: BookingStatusEnum
    checkIn: string
    checkOut: string
    bookingOffers: Array<{ __typename: 'BookingOffer'; id: string; currentState: BookingOfferStatesEnum }>
  } | null
  home: {
    __typename: 'Home'
    id: string
    pricingModel: { __typename: 'PricingModel'; tenantBaseFeeFactor: number }
  }
}

export type ChatQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ChatQuery = {
  __typename: 'QueryRoot'
  conversation: {
    __typename: 'Conversation'
    id: string
    read: boolean
    role: ConversationRoleTypeEnum
    initiator: boolean
    status: ConversationStatusEnum
    archivedAt: string | null
    homeApplication: {
      __typename: 'HomeApplication'
      origin: HomeApplicationOriginEnum
      id: string
      status: HomeApplicationStatusEnum
      endOptimal: string | null
      startOptimal: string | null
      contract: {
        __typename: 'Contract'
        id: string
        rent: number | null
        qasaGuarantee: boolean | null
        qasaGuaranteeCost: number | null
        insurance: HomeInsuranceEnum | null
        insuranceCost: number | null
        numberOfTenants: number | null
        acceptsCosignatures: boolean | null
        status: ContractStatusEnum
        signUrl: string | null
        landlordFees: {
          __typename: 'ContractLandlordFees'
          base: {
            __typename: 'Fee'
            availability: FeeAvailabilityEnum
            cost: {
              __typename: 'Cost'
              total: number
              factor: number | null
              fixed: number | null
              isFree: boolean
            }
          }
        }
        tenantFees: {
          __typename: 'ContractTenantFees'
          waterFeePerTenant: {
            __typename: 'Fee'
            availability: FeeAvailabilityEnum
            cost: {
              __typename: 'Cost'
              total: number
              factor: number | null
              fixed: number | null
              isFree: boolean
            }
          }
        }
        signatories: Array<{
          __typename: 'Signatory'
          id: string
          role: SignatoryRoleEnum
          signedAt: string | null
        }>
        extensionContract: {
          __typename: 'Contract'
          id: string
          status: ContractStatusEnum
          signUrl: string | null
          signatories: Array<{
            __typename: 'Signatory'
            id: string
            role: SignatoryRoleEnum
            signedAt: string | null
          }>
        } | null
        market: { __typename: 'Market'; id: string; name: MarketNameEnum }
      } | null
      homeTemplates: Array<{ __typename: 'HomeTemplate'; id: string }>
      offering: { __typename: 'Offering'; id: string; offeringStatus: OfferingStatusEnum } | null
      home: {
        __typename: 'Home'
        id: string
        market: { __typename: 'Market'; id: string; name: MarketNameEnum }
        landlord: { __typename: 'User'; uid: string }
      }
      tenant: { __typename: 'User'; uid: string }
    } | null
    messages: {
      __typename: 'ConversationMessageConnection'
      nodes: Array<{
        __typename: 'ConversationMessage'
        id: string
        createdAt: string
        message: string | null
        messageType: ConversationMessageTypeEnum
        user: {
          __typename: 'User'
          uid: string
          firstName: string | null
          companyName: string | null
          professional: boolean
        }
        upload: { __typename: 'Upload'; id: string; title: string; url: string } | null
        reference:
          | { __typename: 'BookingOffer'; id: string; createdFromBooking: boolean }
          | { __typename: 'ContractTenantInvitation'; recipientEmail: string | null }
          | { __typename: 'HomeApplication'; conversation: { __typename: 'Conversation'; id: string } | null }
          | { __typename: 'Shortcut' }
          | null
      }>
    }
    conversationUserRelations: Array<{
      __typename: 'ConversationUserRelation'
      id: string
      role: ConversationRoleTypeEnum
      archivedAt: string | null
      user: {
        __typename: 'User'
        uid: string
        firstName: string | null
        companyName: string | null
        professional: boolean
        proAgent: boolean
        proPilot: boolean
        premium: boolean
        landlordApplicationResponseRate: number | null
        landlordApplicationResponseTimeHours: number | null
        profilePicture: { __typename: 'Upload'; id: string; url: string } | null
        currentIdentification: { __typename: 'Identification'; identityConfirmed: boolean } | null
      }
    }>
    booking: {
      __typename: 'Booking'
      id: string
      status: BookingStatusEnum
      checkIn: string
      checkOut: string
      canceledAt: string | null
      refundAction: RefundActionEnum | null
      actionDeadline: string | null
      createdAt: string
      tenant: { __typename: 'User'; uid: string }
      landlord: { __typename: 'User'; uid: string }
      bookingOffers: Array<{
        __typename: 'BookingOffer'
        id: string
        currentState: BookingOfferStatesEnum
        createdFromBooking: boolean
        checkIn: string
        checkOut: string
        pricingInfo: {
          __typename: 'PricePreview'
          tenantFee: number
          landlordFee: number
          totalTenantPrice: number
          rentPrice: number
        }
      }>
      pricingInfo: {
        __typename: 'PricePreview'
        landlordFee: number
        rentPrice: number
        tenantFee: number
        totalTenantPrice: number
        averagePricePerNight: number
        cleaningFee: number
      }
      contract: {
        __typename: 'Contract'
        id: string
        status: ContractStatusEnum
        createdAt: string
        tenant: { __typename: 'User'; uid: string } | null
        invoices: Array<{
          __typename: 'Invoice'
          amount: number
          url: string | null
          id: string
          paidAt: string | null
          type: InvoiceTypeEnum
        }>
      } | null
    } | null
    home: {
      __typename: 'Home'
      id: string
      tenantCount: number | null
      rent: number | null
      qasaGuarantee: boolean | null
      qasaGuaranteeCost: number | null
      insurance: HomeInsuranceEnum | null
      insuranceCost: number | null
      title: string | null
      type: HomeTypeEnum
      tenantBaseFee: number | null
      bedroomCount: number | null
      bedCount: number | null
      numberOfHomes: number | null
      rentalType: HomeRentalTypeEnum
      minRent: number | null
      maxRent: number | null
      displayStreetNumber: boolean
      status: HomeStatusEnum
      responsibleForCleaning: ResponsibleForCleaningEnum | null
      firsthand: boolean
      seniorHome: boolean
      corporateHome: boolean
      currency: CurrencyEnum
      studentHome: boolean
      roomCount: number | null
      minRoomCount: number | null
      maxRoomCount: number | null
      squareMeters: number | null
      minSquareMeters: number | null
      maxSquareMeters: number | null
      shared: boolean | null
      floor: number | null
      buildingFloors: number | null
      market: { __typename: 'Market'; id: string; name: MarketNameEnum }
      landlordFees: {
        __typename: 'HomeLandlordFees'
        base: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: {
            __typename: 'Cost'
            total: number
            factor: number | null
            fixed: number | null
            isFree: boolean
          }
        }
      }
      tenantFees: {
        __typename: 'HomeTenantFees'
        waterFeePerTenant: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: {
            __typename: 'Cost'
            total: number
            factor: number | null
            fixed: number | null
            isFree: boolean
          }
        }
        base: { __typename: 'Fee'; cost: { __typename: 'Cost'; total: number } }
      }
      landlordServices: {
        __typename: 'HomeLandlordServices'
        qasaGuarantee: {
          __typename: 'Service'
          isActive: boolean
          availability: ServiceAvailabilityEnum
          cost: { __typename: 'Cost'; total: number }
        }
        insurance: {
          __typename: 'Service'
          isActive: boolean
          availability: ServiceAvailabilityEnum
          cost: { __typename: 'Cost'; total: number }
        }
      }
      landlord: { __typename: 'User'; uid: string; proPilot: boolean }
      location: {
        __typename: 'Location'
        route: string | null
        streetNumber: string | null
        id: string
        locality: string | null
      }
      uploads: Array<{
        __typename: 'Upload'
        id: string
        url: string
        metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
      }>
      instantSignSetting: { __typename: 'InstantSignSetting'; enabled: boolean } | null
      currentCancellationPolicy: {
        __typename: 'CancellationPolicy'
        policyName: CancellationPolicyNameEnum | null
        partialRefundFactor: number | null
        partialRefundDays: number | null
        fullRefundDays: number | null
        landlordFullRefundDays: number | null
      } | null
      pricingModel: { __typename: 'PricingModel'; tenantBaseFeeFactor: number }
      user: { __typename: 'User'; uid: string; professional: boolean }
      traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
    }
    lastMessage: { __typename: 'ConversationMessage'; id: string } | null
    statusChanger: { __typename: 'User'; uid: string } | null
    lastSeenMessageIds: Array<{ __typename: 'LastSeenMessage'; messageId: string | null; uid: string }>
  } | null
}

export type UnreadMessagesSubscriptionVariables = Exact<{
  conversationId: Scalars['ID']['input']
}>

export type UnreadMessagesSubscription = {
  __typename: 'SubscriptionRoot'
  messagesSubscription: {
    __typename: 'MessagesSubscriptionPayload'
    unreadMessages: Array<{
      __typename: 'ConversationMessage'
      createdAt: string
      id: string
      message: string | null
      messageType: ConversationMessageTypeEnum
      user: {
        __typename: 'User'
        uid: string
        firstName: string | null
        companyName: string | null
        professional: boolean
      }
      upload: { __typename: 'Upload'; id: string; title: string; url: string } | null
      reference:
        | { __typename: 'BookingOffer'; id: string; createdFromBooking: boolean }
        | { __typename: 'ContractTenantInvitation'; recipientEmail: string | null }
        | { __typename: 'HomeApplication'; conversation: { __typename: 'Conversation'; id: string } | null }
        | { __typename: 'Shortcut' }
        | null
    }> | null
  } | null
}

export type MarkLastChatMessageAsReadMutationVariables = Exact<{
  input: MarkConversationInput
}>

export type MarkLastChatMessageAsReadMutation = {
  __typename: 'MutationRoot'
  markConversation: {
    __typename: 'MarkConversationPayload'
    conversation: {
      __typename: 'Conversation'
      id: string
      read: boolean
      unreadMessagesCount: number
      lastSeenMessageIds: Array<{ __typename: 'LastSeenMessage'; messageId: string | null }>
    }
  } | null
}

export type MarkLastChatMessageAsReadFragment = {
  __typename: 'Conversation'
  id: string
  read: boolean
  initiator: boolean
  lastMessage: { __typename: 'ConversationMessage'; id: string } | null
  messages: {
    __typename: 'ConversationMessageConnection'
    nodes: Array<{ __typename: 'ConversationMessage'; id: string; user: { __typename: 'User'; uid: string } }>
  }
}

export type HomeSearchQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  market?: InputMaybe<MarketNameTypeEnum>
  order?: InputMaybe<HomeSearchOrderEnum>
  orderBy?: InputMaybe<HomeSearchOrderByEnum>
  searchParams: HomeSearchParamsInput
}>

export type HomeSearchQuery = {
  __typename: 'QueryRoot'
  homeSearch: {
    __typename: 'HomeSearch'
    filterHomesOffset: {
      __typename: 'HomeOffsetLimit'
      pagesCount: number
      totalCount: number
      hasNextPage: boolean
      hasPreviousPage: boolean
      nodes: Array<{
        __typename: 'Home'
        id: string
        firsthand: boolean
        rent: number | null
        tenantBaseFee: number | null
        title: string | null
        currency: CurrencyEnum
        minimumPricePerNight: number | null
        maximumPricePerNight: number | null
        averagePricePerNight: number | null
        favoriteMarkedByUser: boolean
        floor: number | null
        buildingFloors: number | null
        displayStreetNumber: boolean
        roomCount: number | null
        seniorHome: boolean
        shared: boolean | null
        squareMeters: number | null
        studentHome: boolean
        type: HomeTypeEnum
        corporateHome: boolean
        numberOfHomes: number | null
        minRent: number | null
        maxRent: number | null
        minRoomCount: number | null
        maxRoomCount: number | null
        minSquareMeters: number | null
        maxSquareMeters: number | null
        rentalType: HomeRentalTypeEnum
        tenantCount: number | null
        bedCount: number | null
        bedroomCount: number | null
        landlord: {
          __typename: 'User'
          uid: string
          companyName: string | null
          premium: boolean
          professional: boolean
          proPilot: boolean
          profilePicture: { __typename: 'Upload'; url: string } | null
        }
        user: {
          __typename: 'User'
          uid: string
          firstName: string | null
          proAgent: boolean
          profilePicture: { __typename: 'Upload'; url: string } | null
        }
        instantSignSetting: { __typename: 'InstantSignSetting'; id: string; enabled: boolean } | null
        location: {
          __typename: 'Location'
          id: string
          latitude: number | null
          longitude: number | null
          route: string | null
          locality: string | null
          streetNumber: string | null
          sublocality: string | null
          countryCode: string
        }
        links: Array<{ __typename: 'Link'; locale: string; url: string }>
        traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
        duration: {
          __typename: 'Duration'
          createdAt: string | null
          endOptimal: string | null
          endUfn: boolean
          id: string
          startAsap: boolean
          startOptimal: string | null
          updatedAt: string | null
        }
        uploads: Array<{
          __typename: 'Upload'
          id: string
          url: string
          type: UploadTypeEnum
          title: string
          metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
        }>
      }>
    }
  }
}

export type HomeIndexSearchQueryQueryVariables = Exact<{
  order?: InputMaybe<HomeIndexSearchOrderInput>
  offset?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  params?: InputMaybe<HomeSearchParamsInput>
}>

export type HomeIndexSearchQueryQuery = {
  __typename: 'QueryRoot'
  homeIndexSearch: {
    __typename: 'HomeIndexSearchQuery'
    documents: {
      __typename: 'HomeDocumentOffsetLimit'
      hasNextPage: boolean
      hasPreviousPage: boolean
      pagesCount: number
      totalCount: number
      nodes: Array<{
        __typename: 'HomeDocument'
        bedroomCount: number | null
        blockListing: boolean | null
        rentalLengthSeconds: number | null
        householdSize: number | null
        corporateHome: boolean | null
        description: string | null
        endDate: string | null
        firstHand: boolean | null
        furnished: boolean | null
        homeType: string | null
        id: string
        instantSign: boolean | null
        market: string | null
        lastBumpedAt: string | null
        monthlyCost: number | null
        petsAllowed: boolean | null
        platform: string | null
        publishedAt: string | null
        publishedOrBumpedAt: string | null
        rent: number | null
        currency: string | null
        roomCount: number | null
        seniorHome: boolean | null
        shared: boolean | null
        shortcutHome: boolean | null
        smokingAllowed: boolean | null
        sortingScore: number | null
        squareMeters: number | null
        startDate: string | null
        studentHome: boolean | null
        tenantBaseFee: number | null
        title: string | null
        wheelchairAccessible: boolean | null
        displayStreetNumber: boolean | null
        location: {
          __typename: 'HomeDocumentLocationType'
          id: number | null
          locality: string | null
          countryCode: string | null
          streetNumber: string | null
          route: string | null
          point: { __typename: 'GeoPoint'; lat: number; lon: number } | null
        } | null
        uploads: Array<{
          __typename: 'HomeDocumentUploadType'
          id: number | null
          order: number | null
          type: string | null
          url: string | null
        }> | null
      }>
    }
  }
}

export type GetUserFavoriteHomesQueryVariables = Exact<{ [key: string]: never }>

export type GetUserFavoriteHomesQuery = {
  __typename: 'QueryRoot'
  favoriteHomes: {
    __typename: 'HomeSearch'
    filterHomesOffset: { __typename: 'HomeOffsetLimit'; nodes: Array<{ __typename: 'Home'; id: string }> }
  }
}

export type SearchAreaQueryVariables = Exact<{
  areaParams?: InputMaybe<Array<AreaParamsInput> | AreaParamsInput>
}>

export type SearchAreaQuery = {
  __typename: 'QueryRoot'
  searchArea: Array<{
    __typename: 'SearchArea'
    name: string
    city: string | null
    identifier: string
    geojson: Record<string, unknown>
  }>
}

export type SearchAreaFieldsFragment = {
  __typename: 'SearchArea'
  name: string
  city: string | null
  identifier: string
  geojson: Record<string, unknown>
}

export type SavedSearchFragmentFragment = {
  __typename: 'SavedSearch'
  id: string
  title: string
  monitoring: boolean
  visibility: SavedSearchVisibilityEnum
  searchParams: {
    __typename: 'HomeSearchParams'
    corporateHome: boolean | null
    firsthand: boolean | null
    furniture: boolean | null
    homeType: Array<HomeTypeEnum> | null
    minMonthlyCost: number | null
    maxMonthlyCost: number | null
    minRentalLength: number | null
    maxRoomCount: number | null
    minRoomCount: number | null
    minSquareMeters: number | null
    maxSquareMeters: number | null
    moveInEarliest: string | null
    pets: boolean | null
    smoker: boolean | null
    traits: Array<HomeTraitEnum> | null
    checkIn: string | null
    checkOut: string | null
    minBedCount: number | null
    minBedroomCount: number | null
    minToiletCount: number | null
    householdSize: number | null
    minPricePerNight: number | null
    maxPricePerNight: number | null
    seniorHome: boolean | null
    shared: boolean | null
    studentHome: boolean | null
    wheelchairAccessible: boolean | null
    hasKitchen: boolean | null
    searchAreas: Array<{
      __typename: 'SearchArea'
      name: string
      city: string | null
      countryCode: string | null
    }> | null
  }
}

export type SavedSearchesQueryVariables = Exact<{
  rentalType?: InputMaybe<HomeRentalTypeEnum>
}>

export type SavedSearchesQuery = {
  __typename: 'QueryRoot'
  savedSearch: Array<{
    __typename: 'SavedSearch'
    id: string
    title: string
    monitoring: boolean
    visibility: SavedSearchVisibilityEnum
    searchParams: {
      __typename: 'HomeSearchParams'
      corporateHome: boolean | null
      firsthand: boolean | null
      furniture: boolean | null
      homeType: Array<HomeTypeEnum> | null
      minMonthlyCost: number | null
      maxMonthlyCost: number | null
      minRentalLength: number | null
      maxRoomCount: number | null
      minRoomCount: number | null
      minSquareMeters: number | null
      maxSquareMeters: number | null
      moveInEarliest: string | null
      pets: boolean | null
      smoker: boolean | null
      traits: Array<HomeTraitEnum> | null
      checkIn: string | null
      checkOut: string | null
      minBedCount: number | null
      minBedroomCount: number | null
      minToiletCount: number | null
      householdSize: number | null
      minPricePerNight: number | null
      maxPricePerNight: number | null
      seniorHome: boolean | null
      shared: boolean | null
      studentHome: boolean | null
      wheelchairAccessible: boolean | null
      hasKitchen: boolean | null
      searchAreas: Array<{
        __typename: 'SearchArea'
        name: string
        city: string | null
        countryCode: string | null
      }> | null
    }
  }>
}

export type CreateSavedSearchMutationVariables = Exact<{
  input: CreateSavedSearchInput
}>

export type CreateSavedSearchMutation = {
  __typename: 'MutationRoot'
  createSavedSearch: {
    __typename: 'CreateSavedSearchPayload'
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
    savedSearch: {
      __typename: 'SavedSearch'
      id: string
      title: string
      monitoring: boolean
      visibility: SavedSearchVisibilityEnum
      searchParams: {
        __typename: 'HomeSearchParams'
        corporateHome: boolean | null
        firsthand: boolean | null
        furniture: boolean | null
        homeType: Array<HomeTypeEnum> | null
        minMonthlyCost: number | null
        maxMonthlyCost: number | null
        minRentalLength: number | null
        maxRoomCount: number | null
        minRoomCount: number | null
        minSquareMeters: number | null
        maxSquareMeters: number | null
        moveInEarliest: string | null
        pets: boolean | null
        smoker: boolean | null
        traits: Array<HomeTraitEnum> | null
        checkIn: string | null
        checkOut: string | null
        minBedCount: number | null
        minBedroomCount: number | null
        minToiletCount: number | null
        householdSize: number | null
        minPricePerNight: number | null
        maxPricePerNight: number | null
        seniorHome: boolean | null
        shared: boolean | null
        studentHome: boolean | null
        wheelchairAccessible: boolean | null
        hasKitchen: boolean | null
        searchAreas: Array<{
          __typename: 'SearchArea'
          name: string
          city: string | null
          countryCode: string | null
        }> | null
      }
    }
  } | null
}

export type UpdateSavedSearchMutationVariables = Exact<{
  savedSearchId: Scalars['ID']['input']
  input: UpdateSavedSearchInput
}>

export type UpdateSavedSearchMutation = {
  __typename: 'MutationRoot'
  updateSavedSearch: {
    __typename: 'UpdateSavedSearchPayload'
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
    savedSearch: {
      __typename: 'SavedSearch'
      id: string
      title: string
      monitoring: boolean
      visibility: SavedSearchVisibilityEnum
      searchParams: {
        __typename: 'HomeSearchParams'
        corporateHome: boolean | null
        firsthand: boolean | null
        furniture: boolean | null
        homeType: Array<HomeTypeEnum> | null
        minMonthlyCost: number | null
        maxMonthlyCost: number | null
        minRentalLength: number | null
        maxRoomCount: number | null
        minRoomCount: number | null
        minSquareMeters: number | null
        maxSquareMeters: number | null
        moveInEarliest: string | null
        pets: boolean | null
        smoker: boolean | null
        traits: Array<HomeTraitEnum> | null
        checkIn: string | null
        checkOut: string | null
        minBedCount: number | null
        minBedroomCount: number | null
        minToiletCount: number | null
        householdSize: number | null
        minPricePerNight: number | null
        maxPricePerNight: number | null
        seniorHome: boolean | null
        shared: boolean | null
        studentHome: boolean | null
        wheelchairAccessible: boolean | null
        hasKitchen: boolean | null
        searchAreas: Array<{
          __typename: 'SearchArea'
          name: string
          city: string | null
          countryCode: string | null
        }> | null
      }
    } | null
  } | null
}

export type DeleteSavedSearchMutationVariables = Exact<{
  savedSearchId: Scalars['ID']['input']
}>

export type DeleteSavedSearchMutation = {
  __typename: 'MutationRoot'
  destroySavedSearch: { __typename: 'DestroySavedSearchPayload'; id: string } | null
}

export type HomeContextFragment = {
  __typename: 'Home'
  id: string
  origin: string | null
  canBePublished: boolean
  status: HomeStatusEnum
  matterportEmbedUrl: string | null
  description: string | null
  descriptionBuilding: string | null
  descriptionContract: string | null
  descriptionFeatures: string | null
  descriptionLayout: string | null
  descriptionTransportation: string | null
  housingAssociation: string | null
  energyClass: string | null
  firsthand: boolean
  seniorHome: boolean
  corporateHome: boolean
  studentHome: boolean
  applicationDeadline: string | null
  buildYear: number | null
  bathroomRenovationYear: number | null
  kitchenRenovationYear: number | null
  tenantBaseFee: number | null
  rent: number | null
  currency: CurrencyEnum
  minRent: number | null
  maxRent: number | null
  external: boolean
  externalInfoPage: string | null
  externalApplicationUrl: string | null
  externalEmail: string | null
  publishedAt: string | null
  adPaidAt: string | null
  numberOfHomes: number | null
  rentalType: HomeRentalTypeEnum
  tenantCount: number | null
  minTenantCount: number | null
  maxTenantCount: number | null
  openForSigning: boolean
  title: string | null
  landlordUnitId: string | null
  applicationSortingType: ApplicationSortingTypeEnum | null
  applicationSelectionType: ApplicationSelectionTypeEnum | null
  displayStreetNumber: boolean
  shared: boolean | null
  type: HomeTypeEnum
  roomCount: number | null
  squareMeters: number | null
  bedroomCount: number | null
  bedCount: number | null
  toiletCount: number | null
  hasKitchen: boolean
  favoriteMarkedByUser: boolean
  minRoomCount: number | null
  maxRoomCount: number | null
  minSquareMeters: number | null
  maxSquareMeters: number | null
  floor: number | null
  buildingFloors: number | null
  user: {
    __typename: 'User'
    uid: string
    firstName: string | null
    proAgent: boolean
    professional: boolean
    premium: boolean
    companyName: string | null
    proPilot: boolean
    currentIdentification: {
      __typename: 'Identification'
      identityConfirmed: boolean
      identityConfirmedAt: string | null
    } | null
    bio: { __typename: 'UserBio'; intro: string | null }
    profilePicture: { __typename: 'Upload'; url: string } | null
  }
  location: {
    __typename: 'Location'
    streetNumber: string | null
    id: string
    route: string | null
    locality: string | null
    latitude: number | null
    longitude: number | null
  }
  duration: {
    __typename: 'Duration'
    id: string
    startAsap: boolean
    endOptimal: string | null
    endUfn: boolean
    startOptimal: string | null
    possibilityOfExtension: boolean
    durationPreference: { __typename: 'DurationPreference'; startLatest: string | null } | null
  }
  uploads: Array<{
    __typename: 'Upload'
    id: string
    url: string
    type: UploadTypeEnum
    metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
  }>
  tenantFees: {
    __typename: 'HomeTenantFees'
    base: {
      __typename: 'Fee'
      availability: FeeAvailabilityEnum
      cost: { __typename: 'Cost'; factor: number | null; total: number }
    }
    waterFeePerTenant: {
      __typename: 'Fee'
      availability: FeeAvailabilityEnum
      cost: { __typename: 'Cost'; isFree: boolean; fixed: number | null }
    }
    monthlyElectricityFee: {
      __typename: 'Fee'
      availability: FeeAvailabilityEnum
      cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
    } | null
  }
  electricityFee: {
    __typename: 'ElectricityFeeType'
    paymentPlan: ElectricityFeePaymentOptionEnum
    monthlyFee: number | null
  } | null
  market: { __typename: 'Market'; id: string; name: MarketNameEnum }
  traits: Array<{ __typename: 'Trait'; id: string; type: TraitTypeEnum }>
  shortcut: { __typename: 'Shortcut'; id: string; homePitch: string | null } | null
  instantSignSetting: {
    __typename: 'InstantSignSetting'
    id: string
    enabled: boolean
    landlordHoursToSign: number
  } | null
  landlord: {
    __typename: 'User'
    uid: string
    firstName: string | null
    profilePicture: { __typename: 'Upload'; url: string } | null
  }
  landlordFees: {
    __typename: 'HomeLandlordFees'
    base: { __typename: 'Fee'; cost: { __typename: 'Cost'; total: number } }
    listingPublicationFee: {
      __typename: 'Fee'
      availability: FeeAvailabilityEnum
      cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
    }
  }
  pricingModel: { __typename: 'PricingModel'; tenantBaseFeeFactor: number }
  homeTemplates: Array<{
    __typename: 'HomeTemplate'
    id: string
    apartmentNumber: string | null
    rent: number | null
    squareMeters: number | null
    roomCount: number | null
    description: string | null
    floor: number | null
    type: HomeTypeEnum
    traits: Array<{ __typename: 'Trait'; id: string; type: TraitTypeEnum }>
    homeLayoutPictures: Array<{ __typename: 'Upload'; id: string; url: string }>
  }>
  rentalRequirement: {
    __typename: 'RentalRequirement'
    approvedCreditCheck: boolean | null
    rentMultiplier: number | null
    studentVerification: boolean | null
    verifiedIdNumber: boolean | null
    verifiedIncome: boolean | null
  } | null
}

export type HomeContextExistingApplicationFragment = {
  __typename: 'HomeApplication'
  id: string
  conversation: { __typename: 'Conversation'; id: string } | null
  contract: { __typename: 'Contract'; id: string } | null
}

export type HomeContextInstantSignExistingContractFragment = {
  __typename: 'Contract'
  id: string
  signatories: Array<{
    __typename: 'Signatory'
    id: string
    role: SignatoryRoleEnum
    signedAt: string | null
    user: { __typename: 'User'; uid: string }
  }>
}

export type HomeqApplicationsFragment = {
  __typename: 'ConnectHubHomeQHomeApplicationsType'
  limit: number
  applications: Array<{
    __typename: 'ConnectHubHomeQHomeApplicationType'
    id: string
    interests: Array<{
      __typename: 'ConnectHubHomeQInterestType'
      id: string
      home: { __typename: 'Home'; id: string } | null
    }>
  }>
}

export type AmenitiesFragment = {
  __typename: 'Home'
  traits: Array<{ __typename: 'Trait'; id: string; type: TraitTypeEnum }>
}

export type ApartmentIdFragment = { __typename: 'Home'; landlordUnitId: string | null }

export type AdditionalDurationInfoComponentFragment = {
  __typename: 'Duration'
  id: string
  possibilityOfExtension: boolean
  durationPreference: { __typename: 'DurationPreference'; startLatest: string | null } | null
}

export type InstantSignLeaseDurationInfoComponentFragment = {
  __typename: 'Home'
  instantSignSetting: { __typename: 'InstantSignSetting'; id: string; enabled: boolean } | null
  duration: {
    __typename: 'Duration'
    id: string
    startAsap: boolean
    durationPreference: { __typename: 'DurationPreference'; startLatest: string | null } | null
  }
}

export type LongTermContractInfoFragment = {
  __typename: 'Home'
  tenantBaseFee: number | null
  rent: number | null
  currency: CurrencyEnum
  maxTenantCount: number | null
  minTenantCount: number | null
  tenantCount: number | null
  tenantFees: {
    __typename: 'HomeTenantFees'
    base: {
      __typename: 'Fee'
      availability: FeeAvailabilityEnum
      cost: { __typename: 'Cost'; factor: number | null; total: number }
    }
    waterFeePerTenant: {
      __typename: 'Fee'
      availability: FeeAvailabilityEnum
      cost: { __typename: 'Cost'; isFree: boolean; fixed: number | null }
    }
    monthlyElectricityFee: {
      __typename: 'Fee'
      availability: FeeAvailabilityEnum
      cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
    } | null
  }
  electricityFee: {
    __typename: 'ElectricityFeeType'
    paymentPlan: ElectricityFeePaymentOptionEnum
    monthlyFee: number | null
  } | null
  duration: {
    __typename: 'Duration'
    id: string
    startAsap: boolean
    endOptimal: string | null
    endUfn: boolean
    startOptimal: string | null
    possibilityOfExtension: boolean
    durationPreference: { __typename: 'DurationPreference'; startLatest: string | null } | null
  }
  market: { __typename: 'Market'; id: string; name: MarketNameEnum }
  traits: Array<{ __typename: 'Trait'; id: string; type: TraitTypeEnum }>
  rentalRequirement: {
    __typename: 'RentalRequirement'
    approvedCreditCheck: boolean | null
    rentMultiplier: number | null
    studentVerification: boolean | null
    verifiedIdNumber: boolean | null
    verifiedIncome: boolean | null
  } | null
  instantSignSetting: { __typename: 'InstantSignSetting'; id: string; enabled: boolean } | null
}

export type ContractInfoRulesFragment = {
  __typename: 'Home'
  maxTenantCount: number | null
  minTenantCount: number | null
  tenantCount: number | null
  traits: Array<{ __typename: 'Trait'; id: string; type: TraitTypeEnum }>
}

export type CostBoxFragment = {
  __typename: 'Home'
  rent: number | null
  minRent: number | null
  maxRent: number | null
  external: boolean
  externalInfoPage: string | null
  externalApplicationUrl: string | null
  externalEmail: string | null
  currency: CurrencyEnum
  duration: {
    __typename: 'Duration'
    id: string
    endOptimal: string | null
    endUfn: boolean
    startAsap: boolean
    startOptimal: string | null
  }
}

export type CostBoxExistingApplicationFragment = {
  __typename: 'HomeApplication'
  id: string
  conversation: { __typename: 'Conversation'; id: string } | null
  contract: { __typename: 'Contract'; id: string } | null
}

export type CostBoxExistingInstantSignContractFragment = {
  __typename: 'Contract'
  id: string
  signatories: Array<{
    __typename: 'Signatory'
    id: string
    role: SignatoryRoleEnum
    signedAt: string | null
    user: { __typename: 'User'; uid: string }
  }>
}

export type CostboxHomeqApplicationsFragment = {
  __typename: 'ConnectHubHomeQHomeApplicationsType'
  applications: Array<{
    __typename: 'ConnectHubHomeQHomeApplicationType'
    id: string
    interests: Array<{
      __typename: 'ConnectHubHomeQInterestType'
      id: string
      home: { __typename: 'Home'; id: string } | null
    }>
  }>
}

export type DescriptionFragment = {
  __typename: 'Home'
  id: string
  description: string | null
  descriptionBuilding: string | null
  descriptionContract: string | null
  descriptionFeatures: string | null
  descriptionLayout: string | null
  descriptionTransportation: string | null
  housingAssociation: string | null
  energyClass: string | null
  firsthand: boolean
  seniorHome: boolean
  corporateHome: boolean
  studentHome: boolean
  applicationDeadline: string | null
  buildYear: number | null
  bathroomRenovationYear: number | null
  kitchenRenovationYear: number | null
  location: { __typename: 'Location'; id: string; route: string | null; locality: string | null }
}

export type HomeqUserQueryVariables = Exact<{ [key: string]: never }>

export type HomeqUserQuery = {
  __typename: 'QueryRoot'
  homeqUser: {
    __typename: 'ConnectHubHomeQUserType'
    id: string
    points: { __typename: 'ConnectHubHomeQPointsType'; queuePoints: number }
  } | null
}

export type HomeApplicationSortingInfoFragment = {
  __typename: 'Home'
  applicationSortingType: ApplicationSortingTypeEnum | null
  publishedAt: string | null
  applicationSelectionType: ApplicationSelectionTypeEnum | null
}

export type HomeApplicationSortingInfoUserFragment = {
  __typename: 'User'
  private: { __typename: 'UserPrivate'; id: string; homeqUid: string | null }
}

export type HomeCardFragment = {
  __typename: 'Home'
  id: string
  displayStreetNumber: boolean
  type: HomeTypeEnum
  numberOfHomes: number | null
  roomCount: number | null
  minRoomCount: number | null
  maxRoomCount: number | null
  squareMeters: number | null
  minSquareMeters: number | null
  maxSquareMeters: number | null
  studentHome: boolean
  seniorHome: boolean
  corporateHome: boolean
  shared: boolean | null
  floor: number | null
  buildingFloors: number | null
  tenantBaseFee: number | null
  rent: number | null
  currency: CurrencyEnum
  maxTenantCount: number | null
  minTenantCount: number | null
  tenantCount: number | null
  location: {
    __typename: 'Location'
    id: string
    route: string | null
    locality: string | null
    streetNumber: string | null
  }
  uploads: Array<{
    __typename: 'Upload'
    id: string
    url: string
    metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
  }>
  traits: Array<{ __typename: 'Trait'; id: string; type: TraitTypeEnum }>
  tenantFees: {
    __typename: 'HomeTenantFees'
    base: {
      __typename: 'Fee'
      availability: FeeAvailabilityEnum
      cost: { __typename: 'Cost'; factor: number | null; total: number }
    }
    waterFeePerTenant: {
      __typename: 'Fee'
      availability: FeeAvailabilityEnum
      cost: { __typename: 'Cost'; isFree: boolean; fixed: number | null }
    }
    monthlyElectricityFee: {
      __typename: 'Fee'
      availability: FeeAvailabilityEnum
      cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
    } | null
  }
  electricityFee: {
    __typename: 'ElectricityFeeType'
    paymentPlan: ElectricityFeePaymentOptionEnum
    monthlyFee: number | null
  } | null
  duration: {
    __typename: 'Duration'
    id: string
    startAsap: boolean
    endOptimal: string | null
    endUfn: boolean
    startOptimal: string | null
    possibilityOfExtension: boolean
    durationPreference: { __typename: 'DurationPreference'; startLatest: string | null } | null
  }
  market: { __typename: 'Market'; id: string; name: MarketNameEnum }
  rentalRequirement: {
    __typename: 'RentalRequirement'
    approvedCreditCheck: boolean | null
    rentMultiplier: number | null
    studentVerification: boolean | null
    verifiedIdNumber: boolean | null
    verifiedIncome: boolean | null
  } | null
  instantSignSetting: { __typename: 'InstantSignSetting'; id: string; enabled: boolean } | null
}

export type SimilarHomesQueryVariables = Exact<{
  similarHomesId: Scalars['ID']['input']
  count?: InputMaybe<Scalars['Int']['input']>
}>

export type SimilarHomesQuery = {
  __typename: 'QueryRoot'
  similarHomes: {
    __typename: 'SimilarHomes'
    similarHomes: Array<{
      __typename: 'Home'
      id: string
      origin: string | null
      canBePublished: boolean
      status: HomeStatusEnum
      matterportEmbedUrl: string | null
      description: string | null
      descriptionBuilding: string | null
      descriptionContract: string | null
      descriptionFeatures: string | null
      descriptionLayout: string | null
      descriptionTransportation: string | null
      housingAssociation: string | null
      energyClass: string | null
      firsthand: boolean
      seniorHome: boolean
      corporateHome: boolean
      studentHome: boolean
      applicationDeadline: string | null
      buildYear: number | null
      bathroomRenovationYear: number | null
      kitchenRenovationYear: number | null
      tenantBaseFee: number | null
      rent: number | null
      currency: CurrencyEnum
      minRent: number | null
      maxRent: number | null
      external: boolean
      externalInfoPage: string | null
      externalApplicationUrl: string | null
      externalEmail: string | null
      publishedAt: string | null
      adPaidAt: string | null
      numberOfHomes: number | null
      rentalType: HomeRentalTypeEnum
      tenantCount: number | null
      minTenantCount: number | null
      maxTenantCount: number | null
      openForSigning: boolean
      title: string | null
      landlordUnitId: string | null
      applicationSortingType: ApplicationSortingTypeEnum | null
      applicationSelectionType: ApplicationSelectionTypeEnum | null
      displayStreetNumber: boolean
      shared: boolean | null
      type: HomeTypeEnum
      roomCount: number | null
      squareMeters: number | null
      bedroomCount: number | null
      bedCount: number | null
      toiletCount: number | null
      hasKitchen: boolean
      favoriteMarkedByUser: boolean
      minRoomCount: number | null
      maxRoomCount: number | null
      minSquareMeters: number | null
      maxSquareMeters: number | null
      floor: number | null
      buildingFloors: number | null
      user: {
        __typename: 'User'
        uid: string
        firstName: string | null
        proAgent: boolean
        professional: boolean
        premium: boolean
        companyName: string | null
        proPilot: boolean
        currentIdentification: {
          __typename: 'Identification'
          identityConfirmed: boolean
          identityConfirmedAt: string | null
        } | null
        bio: { __typename: 'UserBio'; intro: string | null }
        profilePicture: { __typename: 'Upload'; url: string } | null
      }
      location: {
        __typename: 'Location'
        streetNumber: string | null
        id: string
        route: string | null
        locality: string | null
        latitude: number | null
        longitude: number | null
      }
      duration: {
        __typename: 'Duration'
        id: string
        startAsap: boolean
        endOptimal: string | null
        endUfn: boolean
        startOptimal: string | null
        possibilityOfExtension: boolean
        durationPreference: { __typename: 'DurationPreference'; startLatest: string | null } | null
      }
      uploads: Array<{
        __typename: 'Upload'
        id: string
        url: string
        type: UploadTypeEnum
        metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
      }>
      tenantFees: {
        __typename: 'HomeTenantFees'
        base: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: { __typename: 'Cost'; factor: number | null; total: number }
        }
        waterFeePerTenant: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: { __typename: 'Cost'; isFree: boolean; fixed: number | null }
        }
        monthlyElectricityFee: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
        } | null
      }
      electricityFee: {
        __typename: 'ElectricityFeeType'
        paymentPlan: ElectricityFeePaymentOptionEnum
        monthlyFee: number | null
      } | null
      market: { __typename: 'Market'; id: string; name: MarketNameEnum }
      traits: Array<{ __typename: 'Trait'; id: string; type: TraitTypeEnum }>
      shortcut: { __typename: 'Shortcut'; id: string; homePitch: string | null } | null
      instantSignSetting: {
        __typename: 'InstantSignSetting'
        id: string
        enabled: boolean
        landlordHoursToSign: number
      } | null
      landlord: {
        __typename: 'User'
        uid: string
        firstName: string | null
        profilePicture: { __typename: 'Upload'; url: string } | null
      }
      landlordFees: {
        __typename: 'HomeLandlordFees'
        base: { __typename: 'Fee'; cost: { __typename: 'Cost'; total: number } }
        listingPublicationFee: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
        }
      }
      pricingModel: { __typename: 'PricingModel'; tenantBaseFeeFactor: number }
      homeTemplates: Array<{
        __typename: 'HomeTemplate'
        id: string
        apartmentNumber: string | null
        rent: number | null
        squareMeters: number | null
        roomCount: number | null
        description: string | null
        floor: number | null
        type: HomeTypeEnum
        traits: Array<{ __typename: 'Trait'; id: string; type: TraitTypeEnum }>
        homeLayoutPictures: Array<{ __typename: 'Upload'; id: string; url: string }>
      }>
      rentalRequirement: {
        __typename: 'RentalRequirement'
        approvedCreditCheck: boolean | null
        rentMultiplier: number | null
        studentVerification: boolean | null
        verifiedIdNumber: boolean | null
        verifiedIncome: boolean | null
      } | null
    }>
  }
}

export type HomeBaseInfoFragment = {
  __typename: 'Home'
  displayStreetNumber: boolean
  shared: boolean | null
  type: HomeTypeEnum
  roomCount: number | null
  squareMeters: number | null
  bedroomCount: number | null
  bedCount: number | null
  toiletCount: number | null
  hasKitchen: boolean
  tenantCount: number | null
  title: string | null
  numberOfHomes: number | null
  minRoomCount: number | null
  maxRoomCount: number | null
  minSquareMeters: number | null
  maxSquareMeters: number | null
  studentHome: boolean
  seniorHome: boolean
  corporateHome: boolean
  floor: number | null
  buildingFloors: number | null
  location: {
    __typename: 'Location'
    route: string | null
    streetNumber: string | null
    id: string
    locality: string | null
  }
  traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
}

export type LongTermBaseInfoFragment = {
  __typename: 'Home'
  displayStreetNumber: boolean
  type: HomeTypeEnum
  numberOfHomes: number | null
  roomCount: number | null
  minRoomCount: number | null
  maxRoomCount: number | null
  squareMeters: number | null
  minSquareMeters: number | null
  maxSquareMeters: number | null
  studentHome: boolean
  seniorHome: boolean
  corporateHome: boolean
  shared: boolean | null
  floor: number | null
  buildingFloors: number | null
  location: {
    __typename: 'Location'
    id: string
    locality: string | null
    route: string | null
    streetNumber: string | null
  }
  traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
}

export type VacationBaseInfoFragment = {
  __typename: 'Home'
  shared: boolean | null
  type: HomeTypeEnum
  roomCount: number | null
  squareMeters: number | null
  bedroomCount: number | null
  bedCount: number | null
  toiletCount: number | null
  hasKitchen: boolean
  tenantCount: number | null
  title: string | null
  location: { __typename: 'Location'; id: string; locality: string | null }
}

export type HomeMapFragment = {
  __typename: 'Home'
  location: { __typename: 'Location'; id: string; latitude: number | null; longitude: number | null }
}

export type HomePitchFragment = {
  __typename: 'Home'
  shortcut: { __typename: 'Shortcut'; id: string; homePitch: string | null } | null
  instantSignSetting: { __typename: 'InstantSignSetting'; id: string; enabled: boolean } | null
}

export type HomeSeoLinksFragment = { __typename: 'Home'; rentalType: HomeRentalTypeEnum }

export type HomeTemplateDetailsDialogBodyFragment = {
  __typename: 'HomeTemplate'
  id: string
  description: string | null
  floor: number | null
  homeLayoutPictures: Array<{ __typename: 'Upload'; id: string; url: string }>
  traits: Array<{ __typename: 'Trait'; id: string; type: TraitTypeEnum }>
}

export type HomeTemplateDetailsDialogFragment = {
  __typename: 'HomeTemplate'
  id: string
  apartmentNumber: string | null
  description: string | null
  floor: number | null
  homeLayoutPictures: Array<{ __typename: 'Upload'; id: string; url: string }>
  traits: Array<{ __typename: 'Trait'; id: string; type: TraitTypeEnum }>
}

export type HomeTemplateListRowFragment = {
  __typename: 'HomeTemplate'
  id: string
  apartmentNumber: string | null
  rent: number | null
  squareMeters: number | null
  roomCount: number | null
  description: string | null
  floor: number | null
  type: HomeTypeEnum
  traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
}

export type HomeTemplateListFragment = {
  __typename: 'Home'
  id: string
  numberOfHomes: number | null
  homeTemplates: Array<{
    __typename: 'HomeTemplate'
    id: string
    apartmentNumber: string | null
    rent: number | null
    squareMeters: number | null
    roomCount: number | null
    description: string | null
    floor: number | null
    type: HomeTypeEnum
    traits: Array<{ __typename: 'Trait'; id: string; type: TraitTypeEnum }>
    homeLayoutPictures: Array<{ __typename: 'Upload'; id: string; url: string }>
  }>
}

export type ImageGalleryFragment = {
  __typename: 'Home'
  uploads: Array<{
    __typename: 'Upload'
    id: string
    url: string
    metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
  }>
}

export type ImageSectionFragment = {
  __typename: 'Home'
  status: HomeStatusEnum
  matterportEmbedUrl: string | null
  id: string
  favoriteMarkedByUser: boolean
  uploads: Array<{
    __typename: 'Upload'
    id: string
    url: string
    type: UploadTypeEnum
    metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
  }>
  instantSignSetting: { __typename: 'InstantSignSetting'; id: string; enabled: boolean } | null
  location: { __typename: 'Location'; id: string; latitude: number | null; longitude: number | null }
}

export type HomeInteractionOverlayFragment = {
  __typename: 'Home'
  id: string
  matterportEmbedUrl: string | null
  favoriteMarkedByUser: boolean
  instantSignSetting: { __typename: 'InstantSignSetting'; id: string; enabled: boolean } | null
}

export type MultimediaActionsFragment = {
  __typename: 'Home'
  matterportEmbedUrl: string | null
  uploads: Array<{ __typename: 'Upload'; id: string; url: string; type: UploadTypeEnum }>
}

export type ImageViewingDialogFragment = {
  __typename: 'Home'
  matterportEmbedUrl: string | null
  uploads: Array<{ __typename: 'Upload'; id: string; url: string }>
}

export type InstantSignFragment = { __typename: 'Home'; id: string; openForSigning: boolean }

export type InstantSignSummaryHomeCardFragment = {
  __typename: 'Home'
  id: string
  title: string | null
  rentalType: HomeRentalTypeEnum
  tenantCount: number | null
  bedCount: number | null
  bedroomCount: number | null
  roomCount: number | null
  squareMeters: number | null
  type: HomeTypeEnum
  displayStreetNumber: boolean
  landlord: {
    __typename: 'User'
    uid: string
    firstName: string | null
    profilePicture: { __typename: 'Upload'; url: string } | null
  }
  location: {
    __typename: 'Location'
    route: string | null
    streetNumber: string | null
    id: string
    locality: string | null
  }
  uploads: Array<{
    __typename: 'Upload'
    id: string
    url: string
    metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
  }>
}

export type InstantSignHomeRulesFragment = {
  __typename: 'Home'
  tenantCount: number | null
  minTenantCount: number | null
  maxTenantCount: number | null
  numberOfHomes: number | null
  traits: Array<{ __typename: 'Trait'; id: string; type: TraitTypeEnum }>
}

export type LandlordCardFragment = {
  __typename: 'Home'
  user: {
    __typename: 'User'
    uid: string
    firstName: string | null
    proAgent: boolean
    professional: boolean
    premium: boolean
    companyName: string | null
    proPilot: boolean
    currentIdentification: {
      __typename: 'Identification'
      identityConfirmed: boolean
      identityConfirmedAt: string | null
    } | null
    bio: { __typename: 'UserBio'; intro: string | null }
    profilePicture: { __typename: 'Upload'; url: string } | null
  }
  landlord: { __typename: 'User'; uid: string }
}

export type LandlordPreviewFragment = {
  __typename: 'Home'
  id: string
  publishedAt: string | null
  adPaidAt: string | null
  status: HomeStatusEnum
  landlordFees: {
    __typename: 'HomeLandlordFees'
    listingPublicationFee: {
      __typename: 'Fee'
      availability: FeeAvailabilityEnum
      cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
    }
  }
}

export type LocationSectionFragment = {
  __typename: 'Home'
  id: string
  location: { __typename: 'Location'; id: string; latitude: number | null; longitude: number | null }
}

export type OfferInfoFragment = {
  __typename: 'Home'
  user: { __typename: 'User'; uid: string; proAgent: boolean; proPilot: boolean }
  landlordFees: {
    __typename: 'HomeLandlordFees'
    base: { __typename: 'Fee'; cost: { __typename: 'Cost'; total: number } }
  }
  pricingModel: { __typename: 'PricingModel'; tenantBaseFeeFactor: number }
  instantSignSetting: { __typename: 'InstantSignSetting'; landlordHoursToSign: number } | null
}

export type PublishDateFragment = { __typename: 'Home'; publishedAt: string | null }

export type RentalRequirementsFragment = {
  __typename: 'Home'
  rent: number | null
  tenantBaseFee: number | null
  rentalRequirement: {
    __typename: 'RentalRequirement'
    approvedCreditCheck: boolean | null
    rentMultiplier: number | null
    studentVerification: boolean | null
    verifiedIdNumber: boolean | null
    verifiedIncome: boolean | null
  } | null
}

export type VirtualTourDialogFragment = { __typename: 'Home'; matterportEmbedUrl: string | null; id: string }

export type PublishHomeAppMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type PublishHomeAppMutation = {
  __typename: 'MutationRoot'
  publishHome: {
    __typename: 'PublishHomePayload'
    home: {
      __typename: 'Home'
      id: string
      publishedAt: string | null
      adPaidAt: string | null
      status: HomeStatusEnum
      landlordFees: {
        __typename: 'HomeLandlordFees'
        listingPublicationFee: {
          __typename: 'Fee'
          availability: FeeAvailabilityEnum
          cost: { __typename: 'Cost'; fixed: number | null; isFree: boolean }
        }
      }
    } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type InboxCardBottomRowFragment = {
  __typename: 'Conversation'
  createdAt: string
  id: string
  read: boolean
  role: ConversationRoleTypeEnum
  initiator: boolean
  booking: {
    __typename: 'Booking'
    id: string
    bookingOffers: Array<{ __typename: 'BookingOffer'; id: string }>
  } | null
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    role: ConversationRoleTypeEnum
    user: { __typename: 'User'; uid: string; firstName: string | null }
  }>
  lastMessage: {
    __typename: 'ConversationMessage'
    id: string
    updatedAt: string
    message: string | null
    messageType: ConversationMessageTypeEnum
    user: { __typename: 'User'; uid: string; firstName: string | null }
    reference:
      | { __typename: 'BookingOffer'; id: string; createdFromBooking: boolean }
      | { __typename: 'ContractTenantInvitation'; recipientEmail: string | null }
      | { __typename: 'HomeApplication' }
      | { __typename: 'Shortcut' }
      | null
  } | null
  home: {
    __typename: 'Home'
    status: HomeStatusEnum
    landlord: {
      __typename: 'User'
      uid: string
      professional: boolean
      companyName: string | null
      firstName: string | null
    }
  }
  homeApplication: {
    __typename: 'HomeApplication'
    status: HomeApplicationStatusEnum
    contract: { __typename: 'Contract'; status: ContractStatusEnum } | null
  } | null
}

export type InboxCardImageFragment = {
  __typename: 'Conversation'
  id: string
  starMarked: boolean
  role: ConversationRoleTypeEnum
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      professional: boolean
      companyName: string | null
      profilePicture: { __typename: 'Upload'; id: string; url: string } | null
    }
  }>
  home: {
    __typename: 'Home'
    id: string
    uploads: Array<{
      __typename: 'Upload'
      id: string
      url: string
      metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
    }>
  }
}

export type FavoriteConversationMutationVariables = Exact<{
  isFavorited: Scalars['Boolean']['input']
  conversationId: Scalars['ID']['input']
}>

export type FavoriteConversationMutation = {
  __typename: 'MutationRoot'
  starMarkConversation: {
    __typename: 'StarMarkConversationPayload'
    conversation: { __typename: 'Conversation'; id: string; starMarked: boolean }
  } | null
}

export type InboxCardNotificationFragment = {
  __typename: 'Conversation'
  id: string
  createdAt: string
  read: boolean
  unreadMessagesCount: number
  initiator: boolean
  lastMessage: {
    __typename: 'ConversationMessage'
    id: string
    updatedAt: string
    createdAt: string
    user: { __typename: 'User'; uid: string }
  } | null
  homeApplication: {
    __typename: 'HomeApplication'
    id: string
    status: HomeApplicationStatusEnum
    contract: { __typename: 'Contract'; id: string; status: ContractStatusEnum } | null
  } | null
  home: { __typename: 'Home'; id: string; status: HomeStatusEnum }
}

export type HomeApplicationUnansweredFragment = {
  __typename: 'Conversation'
  homeApplication: {
    __typename: 'HomeApplication'
    id: string
    status: HomeApplicationStatusEnum
    contract: { __typename: 'Contract'; id: string; status: ContractStatusEnum } | null
  } | null
  home: { __typename: 'Home'; id: string; status: HomeStatusEnum }
}

export type InboxCardMiddleRowFragment = {
  __typename: 'Conversation'
  id: string
  createdAt: string
  read: boolean
  unreadMessagesCount: number
  initiator: boolean
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      professional: boolean
      companyName: string | null
    }
  }>
  lastMessage: {
    __typename: 'ConversationMessage'
    id: string
    updatedAt: string
    createdAt: string
    user: { __typename: 'User'; uid: string }
  } | null
  homeApplication: {
    __typename: 'HomeApplication'
    id: string
    status: HomeApplicationStatusEnum
    contract: { __typename: 'Contract'; id: string; status: ContractStatusEnum } | null
  } | null
  home: { __typename: 'Home'; id: string; status: HomeStatusEnum }
}

export type HomeMetaDataListFragment = {
  __typename: 'Home'
  id: string
  rentalType: HomeRentalTypeEnum
  tenantCount: number | null
  bedCount: number | null
  bedroomCount: number | null
  roomCount: number | null
  squareMeters: number | null
  type: HomeTypeEnum
  displayStreetNumber: boolean
  location: {
    __typename: 'Location'
    id: string
    route: string | null
    streetNumber: string | null
    locality: string | null
  }
  landlord: { __typename: 'User'; uid: string }
}

export type InboxCardTopRowFragment = {
  __typename: 'Conversation'
  id: string
  home: {
    __typename: 'Home'
    id: string
    rentalType: HomeRentalTypeEnum
    tenantCount: number | null
    bedCount: number | null
    bedroomCount: number | null
    roomCount: number | null
    squareMeters: number | null
    type: HomeTypeEnum
    displayStreetNumber: boolean
    location: {
      __typename: 'Location'
      id: string
      route: string | null
      streetNumber: string | null
      locality: string | null
    }
    landlord: { __typename: 'User'; uid: string }
  }
}

export type UseConversationPartnerUserNameFragment = {
  __typename: 'Conversation'
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      professional: boolean
      companyName: string | null
    }
  }>
}

export type InboxCardFragment = {
  __typename: 'Conversation'
  id: string
  starMarked: boolean
  role: ConversationRoleTypeEnum
  createdAt: string
  read: boolean
  initiator: boolean
  unreadMessagesCount: number
  conversationUserRelations: Array<{
    __typename: 'ConversationUserRelation'
    id: string
    role: ConversationRoleTypeEnum
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
      profilePicture: { __typename: 'Upload'; id: string; url: string } | null
    }
  }>
  home: {
    __typename: 'Home'
    id: string
    status: HomeStatusEnum
    rentalType: HomeRentalTypeEnum
    tenantCount: number | null
    bedCount: number | null
    bedroomCount: number | null
    roomCount: number | null
    squareMeters: number | null
    type: HomeTypeEnum
    displayStreetNumber: boolean
    uploads: Array<{
      __typename: 'Upload'
      id: string
      url: string
      metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
    }>
    landlord: {
      __typename: 'User'
      uid: string
      firstName: string | null
      companyName: string | null
      professional: boolean
    }
    location: {
      __typename: 'Location'
      id: string
      route: string | null
      streetNumber: string | null
      locality: string | null
    }
  }
  booking: {
    __typename: 'Booking'
    id: string
    bookingOffers: Array<{ __typename: 'BookingOffer'; id: string }>
  } | null
  lastMessage: {
    __typename: 'ConversationMessage'
    id: string
    updatedAt: string
    message: string | null
    messageType: ConversationMessageTypeEnum
    createdAt: string
    user: { __typename: 'User'; uid: string; firstName: string | null }
    reference:
      | { __typename: 'BookingOffer'; id: string; createdFromBooking: boolean }
      | { __typename: 'ContractTenantInvitation'; recipientEmail: string | null }
      | { __typename: 'HomeApplication' }
      | { __typename: 'Shortcut' }
      | null
  } | null
  homeApplication: {
    __typename: 'HomeApplication'
    status: HomeApplicationStatusEnum
    id: string
    contract: { __typename: 'Contract'; status: ContractStatusEnum; id: string } | null
  } | null
}

export type FilterByHomeCardFragment = {
  __typename: 'Home'
  id: string
  status: HomeStatusEnum
  rentalType: HomeRentalTypeEnum
  tenantCount: number | null
  bedCount: number | null
  bedroomCount: number | null
  roomCount: number | null
  squareMeters: number | null
  type: HomeTypeEnum
  displayStreetNumber: boolean
  location: {
    __typename: 'Location'
    id: string
    route: string | null
    streetNumber: string | null
    locality: string | null
  }
  landlord: { __typename: 'User'; uid: string }
}

export type FilterableHomesForUserQueryVariables = Exact<{ [key: string]: never }>

export type FilterableHomesForUserQuery = {
  __typename: 'QueryRoot'
  me: {
    __typename: 'User'
    uid: string
    homes: Array<{
      __typename: 'Home'
      id: string
      displayStreetNumber: boolean
      roomCount: number | null
      squareMeters: number | null
      status: HomeStatusEnum
      type: HomeTypeEnum
      rentalType: HomeRentalTypeEnum
      bedCount: number | null
      bedroomCount: number | null
      tenantCount: number | null
      location: {
        __typename: 'Location'
        id: string
        route: string | null
        streetNumber: string | null
        locality: string | null
      }
      landlord: { __typename: 'User'; uid: string }
    }>
  } | null
}

export type InboxListFragment = {
  __typename: 'ConversationConnection'
  edges: Array<{
    __typename: 'ConversationEdge'
    cursor: string
    node: {
      __typename: 'Conversation'
      id: string
      starMarked: boolean
      role: ConversationRoleTypeEnum
      createdAt: string
      read: boolean
      initiator: boolean
      unreadMessagesCount: number
      home: {
        __typename: 'Home'
        id: string
        status: HomeStatusEnum
        rentalType: HomeRentalTypeEnum
        tenantCount: number | null
        bedCount: number | null
        bedroomCount: number | null
        roomCount: number | null
        squareMeters: number | null
        type: HomeTypeEnum
        displayStreetNumber: boolean
        uploads: Array<{
          __typename: 'Upload'
          id: string
          url: string
          metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
        }>
        landlord: {
          __typename: 'User'
          uid: string
          firstName: string | null
          companyName: string | null
          professional: boolean
        }
        location: {
          __typename: 'Location'
          id: string
          route: string | null
          streetNumber: string | null
          locality: string | null
        }
      }
      conversationUserRelations: Array<{
        __typename: 'ConversationUserRelation'
        id: string
        role: ConversationRoleTypeEnum
        user: {
          __typename: 'User'
          uid: string
          firstName: string | null
          companyName: string | null
          professional: boolean
          profilePicture: { __typename: 'Upload'; id: string; url: string } | null
        }
      }>
      booking: {
        __typename: 'Booking'
        id: string
        bookingOffers: Array<{ __typename: 'BookingOffer'; id: string }>
      } | null
      lastMessage: {
        __typename: 'ConversationMessage'
        id: string
        updatedAt: string
        message: string | null
        messageType: ConversationMessageTypeEnum
        createdAt: string
        user: { __typename: 'User'; uid: string; firstName: string | null }
        reference:
          | { __typename: 'BookingOffer'; id: string; createdFromBooking: boolean }
          | { __typename: 'ContractTenantInvitation'; recipientEmail: string | null }
          | { __typename: 'HomeApplication' }
          | { __typename: 'Shortcut' }
          | null
      } | null
      homeApplication: {
        __typename: 'HomeApplication'
        status: HomeApplicationStatusEnum
        id: string
        contract: { __typename: 'Contract'; status: ContractStatusEnum; id: string } | null
      } | null
    }
  }>
  pageInfo: { __typename: 'PageInfo'; endCursor: string | null; hasNextPage: boolean }
}

export type NewInboxQueryVariables = Exact<{
  archived?: InputMaybe<Scalars['Boolean']['input']>
  cursor?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  homeIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  onlyActiveConversations?: InputMaybe<Scalars['Boolean']['input']>
  starMarked?: InputMaybe<Scalars['Boolean']['input']>
  searchString?: InputMaybe<Scalars['String']['input']>
}>

export type NewInboxQuery = {
  __typename: 'QueryRoot'
  newInbox: {
    __typename: 'ConversationConnection'
    edges: Array<{
      __typename: 'ConversationEdge'
      cursor: string
      node: {
        __typename: 'Conversation'
        id: string
        starMarked: boolean
        role: ConversationRoleTypeEnum
        createdAt: string
        read: boolean
        initiator: boolean
        unreadMessagesCount: number
        home: {
          __typename: 'Home'
          id: string
          status: HomeStatusEnum
          rentalType: HomeRentalTypeEnum
          tenantCount: number | null
          bedCount: number | null
          bedroomCount: number | null
          roomCount: number | null
          squareMeters: number | null
          type: HomeTypeEnum
          displayStreetNumber: boolean
          uploads: Array<{
            __typename: 'Upload'
            id: string
            url: string
            metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
          }>
          landlord: {
            __typename: 'User'
            uid: string
            firstName: string | null
            companyName: string | null
            professional: boolean
          }
          location: {
            __typename: 'Location'
            id: string
            route: string | null
            streetNumber: string | null
            locality: string | null
          }
        }
        conversationUserRelations: Array<{
          __typename: 'ConversationUserRelation'
          id: string
          role: ConversationRoleTypeEnum
          user: {
            __typename: 'User'
            uid: string
            firstName: string | null
            companyName: string | null
            professional: boolean
            profilePicture: { __typename: 'Upload'; id: string; url: string } | null
          }
        }>
        booking: {
          __typename: 'Booking'
          id: string
          bookingOffers: Array<{ __typename: 'BookingOffer'; id: string }>
        } | null
        lastMessage: {
          __typename: 'ConversationMessage'
          id: string
          updatedAt: string
          message: string | null
          messageType: ConversationMessageTypeEnum
          createdAt: string
          user: { __typename: 'User'; uid: string; firstName: string | null }
          reference:
            | { __typename: 'BookingOffer'; id: string; createdFromBooking: boolean }
            | { __typename: 'ContractTenantInvitation'; recipientEmail: string | null }
            | { __typename: 'HomeApplication' }
            | { __typename: 'Shortcut' }
            | null
        } | null
        homeApplication: {
          __typename: 'HomeApplication'
          status: HomeApplicationStatusEnum
          id: string
          contract: { __typename: 'Contract'; status: ContractStatusEnum; id: string } | null
        } | null
      }
    }>
    pageInfo: { __typename: 'PageInfo'; endCursor: string | null; hasNextPage: boolean }
  }
}

export type UseHomeVariantMetaDataArrayFragment = {
  __typename: 'Home'
  rentalType: HomeRentalTypeEnum
  tenantCount: number | null
  bedCount: number | null
  bedroomCount: number | null
  roomCount: number | null
  squareMeters: number | null
  type: HomeTypeEnum
  displayStreetNumber: boolean
  location: {
    __typename: 'Location'
    id: string
    route: string | null
    streetNumber: string | null
    locality: string | null
  }
  landlord: { __typename: 'User'; uid: string }
}

export type HomeExistingApplicationQueryQueryVariables = Exact<{
  homeId?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  status?: InputMaybe<Array<HomeApplicationStatusEnum> | HomeApplicationStatusEnum>
}>

export type HomeExistingApplicationQueryQuery = {
  __typename: 'QueryRoot'
  homeApplications: {
    __typename: 'HomeApplicationConnection'
    nodes: Array<{
      __typename: 'HomeApplication'
      id: string
      conversation: { __typename: 'Conversation'; id: string } | null
      contract: { __typename: 'Contract'; id: string } | null
    }>
  }
}

export type HomeApplicationsDataQueryVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type HomeApplicationsDataQuery = {
  __typename: 'QueryRoot'
  homeqHomeApplications: {
    __typename: 'ConnectHubHomeQHomeApplicationsType'
    limit: number
    applications: Array<{
      __typename: 'ConnectHubHomeQHomeApplicationType'
      id: string
      interests: Array<{
        __typename: 'ConnectHubHomeQInterestType'
        id: string
        home: { __typename: 'Home'; id: string } | null
      }>
    }>
  }
  homeqHomeApplicationPreconditions: {
    __typename: 'ConnectHubHomeQHomeApplicationPreconditions'
    applicantGroupExceedLimit: boolean
  } | null
}

export type InstantSignExistingContractsAppQueryVariables = Exact<{
  status?: InputMaybe<Array<ContractStatusEnum> | ContractStatusEnum>
  homeId?: InputMaybe<Scalars['ID']['input']>
}>

export type InstantSignExistingContractsAppQuery = {
  __typename: 'QueryRoot'
  contracts: {
    __typename: 'ContractConnection'
    nodes: Array<{
      __typename: 'Contract'
      id: string
      signatories: Array<{
        __typename: 'Signatory'
        id: string
        role: SignatoryRoleEnum
        signedAt: string | null
        user: { __typename: 'User'; uid: string }
      }>
    }>
  }
}

export type HomeqInterestFieldsFragment = {
  __typename: 'ConnectHubHomeQInterestType'
  id: string
  responseDeadline: string | null
  street: string
  city: string
  rooms: string
  area: string
  rent: number
  displayImage: string
  dateAccess: string
  countsToLimit: boolean
  status: ConnectHubHomeqInterestStatusEnum
  accessOnAgreement: boolean
  home: { __typename: 'Home'; id: string } | null
}

export type HomeqApplicationFieldsFragment = {
  __typename: 'ConnectHubHomeQHomeApplicationType'
  interests: Array<{
    __typename: 'ConnectHubHomeQInterestType'
    id: string
    responseDeadline: string | null
    street: string
    city: string
    rooms: string
    area: string
    rent: number
    displayImage: string
    dateAccess: string
    countsToLimit: boolean
    status: ConnectHubHomeqInterestStatusEnum
    accessOnAgreement: boolean
    home: { __typename: 'Home'; id: string } | null
  }>
  viewing: {
    __typename: 'ConnectHubHomeQViewingType'
    viewingDatetime: string | null
    rsvpStatus: ConnectHubHomeqViewingResponseStatusEnum
  } | null
}

export type UseHomeqHomeApplicationsQueryVariables = Exact<{ [key: string]: never }>

export type UseHomeqHomeApplicationsQuery = {
  __typename: 'QueryRoot'
  homeqHomeApplications: {
    __typename: 'ConnectHubHomeQHomeApplicationsType'
    limit: number
    applications: Array<{
      __typename: 'ConnectHubHomeQHomeApplicationType'
      interests: Array<{
        __typename: 'ConnectHubHomeQInterestType'
        id: string
        responseDeadline: string | null
        street: string
        city: string
        rooms: string
        area: string
        rent: number
        displayImage: string
        dateAccess: string
        countsToLimit: boolean
        status: ConnectHubHomeqInterestStatusEnum
        accessOnAgreement: boolean
        home: { __typename: 'Home'; id: string } | null
      }>
      viewing: {
        __typename: 'ConnectHubHomeQViewingType'
        viewingDatetime: string | null
        rsvpStatus: ConnectHubHomeqViewingResponseStatusEnum
      } | null
    }>
  }
}

export type UserUnreadConversationsCountQueryVariables = Exact<{ [key: string]: never }>

export type UserUnreadConversationsCountQuery = {
  __typename: 'QueryRoot'
  me: {
    __typename: 'User'
    uid: string
    private: { __typename: 'UserPrivate'; id: string; unreadConversationsCount: number }
  } | null
}

export type CreateUploadMutationVariables = Exact<{
  input: CreateUploadInput
}>

export type CreateUploadMutation = {
  __typename: 'MutationRoot'
  createUpload: {
    __typename: 'CreateUploadPayload'
    upload: { __typename: 'CreateUpload'; action: string; fields: Record<string, unknown>; id: string } | null
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
  } | null
}

export type FinalizeUploadMutationVariables = Exact<{
  etag: Scalars['String']['input']
  id: Scalars['ID']['input']
}>

export type FinalizeUploadMutation = {
  __typename: 'MutationRoot'
  finalizeUpload: {
    __typename: 'FinalizeUploadPayload'
    errors: Array<{ __typename: 'Validation'; codes: Array<string>; field: string }> | null
    upload: {
      __typename: 'Upload'
      fileType: UploadFileTypeEnum
      id: string
      title: string
      type: UploadTypeEnum
      url: string
      metadata: {
        __typename: 'UploadMetadata'
        order: number | null
        primary: boolean | null
        rotation: number | null
      } | null
    } | null
  } | null
}

export type SearchAreaKeywordQueryVariables = Exact<{
  searchString: Scalars['String']['input']
  countryCodes?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
}>

export type SearchAreaKeywordQuery = {
  __typename: 'QueryRoot'
  searchAreaKeyword: Array<{
    __typename: 'SearchAreaMatcher'
    name: string
    city: string | null
    countryCode: string | null
  }>
}

export type BigSearchLongtermCardFragment = {
  __typename: 'Home'
  id: string
  rent: number | null
  minRent: number | null
  currency: CurrencyEnum
  maxRent: number | null
  tenantBaseFee: number | null
  displayStreetNumber: boolean
  type: HomeTypeEnum
  shared: boolean | null
  roomCount: number | null
  squareMeters: number | null
  minSquareMeters: number | null
  maxSquareMeters: number | null
  minRoomCount: number | null
  maxRoomCount: number | null
  numberOfHomes: number | null
  firsthand: boolean
  seniorHome: boolean
  studentHome: boolean
  corporateHome: boolean
  favoriteMarkedByUser: boolean
  floor: number | null
  buildingFloors: number | null
  location: {
    __typename: 'Location'
    id: string
    locality: string | null
    route: string | null
    streetNumber: string | null
  }
  uploads: Array<{
    __typename: 'Upload'
    id: string
    url: string
    metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
  }>
  user: { __typename: 'User'; uid: string; proAgent: boolean }
  instantSignSetting: { __typename: 'InstantSignSetting'; id: string; enabled: boolean } | null
  duration: {
    __typename: 'Duration'
    id: string
    endOptimal: string | null
    endUfn: boolean
    startAsap: boolean
    startOptimal: string | null
  }
  traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
}

export type BigSearchVacationCardFragment = {
  __typename: 'Home'
  id: string
  title: string | null
  currency: CurrencyEnum
  averagePricePerNight: number | null
  tenantCount: number | null
  bedCount: number | null
  bedroomCount: number | null
  minimumPricePerNight: number | null
  maximumPricePerNight: number | null
  favoriteMarkedByUser: boolean
  location: { __typename: 'Location'; id: string; locality: string | null }
  uploads: Array<{
    __typename: 'Upload'
    id: string
    url: string
    metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
  }>
}

export type UpdateUserMutationVariables = Exact<{
  uid: Scalars['ID']['input']
  input: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename: 'MutationRoot'
  updateUser: {
    __typename: 'UpdateUserPayload'
    user: {
      __typename: 'User'
      uid: string
      firstName: string | null
      private: { __typename: 'UserPrivate'; familyName: string | null; phoneNumber: string | null }
    } | null
    errors: Array<{ __typename: 'Validation'; field: string; codes: Array<string> }> | null
  } | null
}

export type FavoriteHomeButtonFragment = { __typename: 'Home'; id: string; favoriteMarkedByUser: boolean }

export type FavoriteMarkHomeAppMutationVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type FavoriteMarkHomeAppMutation = {
  __typename: 'MutationRoot'
  favoriteMarkHome: {
    __typename: 'FavoriteMarkHomePayload'
    home: { __typename: 'Home'; id: string; favoriteMarkedByUser: boolean }
  } | null
}

export type UnmarkFavoriteHomeAppMutationVariables = Exact<{
  homeId: Scalars['ID']['input']
}>

export type UnmarkFavoriteHomeAppMutation = {
  __typename: 'MutationRoot'
  unmarkFavoriteHome: {
    __typename: 'UnmarkFavoriteHomePayload'
    home: { __typename: 'Home'; id: string; favoriteMarkedByUser: boolean }
  } | null
}

export type HomeqUserTokenStateQueryVariables = Exact<{ [key: string]: never }>

export type HomeqUserTokenStateQuery = { __typename: 'QueryRoot'; homeqUserTokenState: string }

export type MatchingProfileCardFragment = {
  __typename: 'User'
  firstName: string | null
  showAge: boolean | null
  age: number | null
  professional: boolean
  profilePicture: { __typename: 'Upload'; id: string; url: string } | null
  tenantAd: {
    __typename: 'TenantAd'
    searchPreference: {
      __typename: 'SearchPreference'
      id: string
      maxMonthlyCost: number | null
      maxMonthlyCostCents: number | null
      currency: CurrencyEnum | null
      minRoomCount: number | null
      minSquareMeters: number | null
      householdSize: number | null
      furniture: boolean | null
      endOptimal: string | null
      endUfn: boolean
      startOptimal: string | null
      startAsap: boolean
    }
  } | null
  bio: { __typename: 'UserBio'; title: string | null }
  currentIdentification: {
    __typename: 'Identification'
    identityConfirmed: boolean
    identityConfirmedAt: string | null
  } | null
  housingSituation: { __typename: 'HousingSituation'; id: string; hasReference: boolean } | null
}

export type NameAndVerificationsFragment = {
  __typename: 'User'
  firstName: string | null
  showAge: boolean | null
  age: number | null
  professional: boolean
  bio: { __typename: 'UserBio'; title: string | null }
  currentIdentification: {
    __typename: 'Identification'
    identityConfirmed: boolean
    identityConfirmedAt: string | null
  } | null
  housingSituation: { __typename: 'HousingSituation'; id: string; hasReference: boolean } | null
}

export type TenantPreferencesHomeFragment = {
  __typename: 'Home'
  id: string
  rent: number | null
  tenantBaseFee: number | null
  monthlyCostCents: number | null
  roomCount: number | null
  squareMeters: number | null
  tenantCount: number | null
  type: HomeTypeEnum
  shared: boolean | null
  traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
  duration: {
    __typename: 'Duration'
    startAsap: boolean
    startOptimal: string | null
    endUfn: boolean
    endOptimal: string | null
  }
}

export type TenantPreferencesUserFragment = {
  __typename: 'User'
  tenantAd: {
    __typename: 'TenantAd'
    searchPreference: {
      __typename: 'SearchPreference'
      id: string
      maxMonthlyCost: number | null
      maxMonthlyCostCents: number | null
      currency: CurrencyEnum | null
      minRoomCount: number | null
      minSquareMeters: number | null
      householdSize: number | null
      furniture: boolean | null
      endOptimal: string | null
      endUfn: boolean
      startOptimal: string | null
      startAsap: boolean
    }
  } | null
}

export type PrivacyControllerByPlatformQueryVariables = Exact<{ [key: string]: never }>

export type PrivacyControllerByPlatformQuery = {
  __typename: 'QueryRoot'
  privacyControllerByPlatform: {
    __typename: 'PrivacyControllerByPlatform'
    isTransactionCustomer: boolean | null
    marketplaceController: BrandControllerEnum
    transactionTerms: string
    marketplaceTerms: string
    transactionController: BrandControllerEnum
  }
}

export type SmallHomeCardInfoFragment = {
  __typename: 'Home'
  id: string
  displayStreetNumber: boolean
  rent: number | null
  currency: CurrencyEnum
  type: HomeTypeEnum
  numberOfHomes: number | null
  roomCount: number | null
  minRoomCount: number | null
  maxRoomCount: number | null
  squareMeters: number | null
  minSquareMeters: number | null
  maxSquareMeters: number | null
  studentHome: boolean
  seniorHome: boolean
  corporateHome: boolean
  shared: boolean | null
  floor: number | null
  buildingFloors: number | null
  location: {
    __typename: 'Location'
    id: string
    route: string | null
    streetNumber: string | null
    locality: string | null
  }
  landlord: { __typename: 'User'; uid: string }
  traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
}

export type SmallHomeCardFragment = {
  __typename: 'Home'
  id: string
  displayStreetNumber: boolean
  rent: number | null
  currency: CurrencyEnum
  type: HomeTypeEnum
  numberOfHomes: number | null
  roomCount: number | null
  minRoomCount: number | null
  maxRoomCount: number | null
  squareMeters: number | null
  minSquareMeters: number | null
  maxSquareMeters: number | null
  studentHome: boolean
  seniorHome: boolean
  corporateHome: boolean
  shared: boolean | null
  floor: number | null
  buildingFloors: number | null
  uploads: Array<{
    __typename: 'Upload'
    id: string
    url: string
    metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
  }>
  location: {
    __typename: 'Location'
    id: string
    route: string | null
    streetNumber: string | null
    locality: string | null
  }
  landlord: { __typename: 'User'; uid: string }
  traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
}

export type UseHomeBaseInfoFragment = {
  __typename: 'Home'
  type: HomeTypeEnum
  numberOfHomes: number | null
  roomCount: number | null
  minRoomCount: number | null
  maxRoomCount: number | null
  squareMeters: number | null
  minSquareMeters: number | null
  maxSquareMeters: number | null
  studentHome: boolean
  seniorHome: boolean
  corporateHome: boolean
  shared: boolean | null
  floor: number | null
  buildingFloors: number | null
  traits: Array<{ __typename: 'Trait'; type: TraitTypeEnum }>
}

export type GetSortedUploadsFragment = {
  __typename: 'Home'
  uploads: Array<{
    __typename: 'Upload'
    id: string
    url: string
    metadata: { __typename: 'UploadMetadata'; order: number | null } | null
  }>
}

export type GetPrimaryUploadFragment = {
  __typename: 'Home'
  uploads: Array<{
    __typename: 'Upload'
    id: string
    url: string
    metadata: { __typename: 'UploadMetadata'; primary: boolean | null; order: number | null } | null
  }>
}

export const FinancePayoutsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinancePayouts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'financePayouts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paidAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                { kind: 'Field', name: { kind: 'Name', value: 'periodStart' } },
                { kind: 'Field', name: { kind: 'Name', value: 'periodEnd' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'receipt' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'charges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vatPercent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vat' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fractional' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FinancePayoutsFragment, unknown>
export const FinanceRentInvoicesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinanceRentInvoices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'financeRentInvoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'periodStart' } },
                { kind: 'Field', name: { kind: 'Name', value: 'periodEnd' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overdue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'receipt' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upcomingPayout' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'periodEnd' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'periodStart' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sumAmount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'charges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vat' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fractional' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'vatPercent' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vatPercent' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vat' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fractional' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sumAmount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentAction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'StripeSepaTransferMoneyAction' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FinanceRentInvoicesFragment, unknown>
export const FinanceGenericInvoiceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinanceGenericInvoice' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'financeInvoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FinanceGenericInvoice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'periodStart' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'periodEnd' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'overdue' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'receipt' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'vatPercent' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vat' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fractional' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sumAmount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentAction' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'StripeSepaTransferMoneyAction' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'amount' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FinanceGenericInvoiceFragment, unknown>
export const FinanceDepositInvoicesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinanceDepositInvoices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'financeDepositInvoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overdue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'receipt' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vatPercent' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vat' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fractional' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sumAmount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentAction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'StripeSepaTransferMoneyAction' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FinanceDepositInvoicesFragment, unknown>
export const BaseHomeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseHome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'googleMapsPlaceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricingModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCostFactor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCostFactor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electricityFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseHomeFragment, unknown>
export const BaseContractFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseContract' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'terminationNotice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenureType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptsCosignatures' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordHasKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceResponsibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inventory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payoutDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentIncludes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentExcludes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subletception' } },
          { kind: 'Field', name: { kind: 'Name', value: 'renovations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payoutDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preCleaning' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postCleaning' } },
          { kind: 'Field', name: { kind: 'Name', value: 'temporaryInstallations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smallRepairs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smoker' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payoutDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overnightStay' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalContractTakeover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'currentState' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUserSignatory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canSign' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signatories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signingDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canSign' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentIdentification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'signingProcess' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rentalInsuranceMilaRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documentRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cosigningTenants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantInvitations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'claimKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'claimable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseHome' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inspection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'observations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'section' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'market' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ownHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenureType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'waterFeePerTenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fixed' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthlyElectricityFee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instantSignSetting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'landlordHoursToSign' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'tenantFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantFamilyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantPhoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'legalType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bindingTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canBeTerminated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'terminationNoticeMonths' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shorterTerminationExplanation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'knownDamages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keySpecification' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codeOfConduct' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cadastralReference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'municipality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bathroomShared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kitchenShared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentedAsIs' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extraCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'costFor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'depositFree' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ifInsurancePromotion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'signedUp' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseHome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'googleMapsPlaceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricingModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCostFactor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCostFactor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electricityFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseContractFragment, unknown>
export const BaseUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentIdentification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeIdentifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'incomeVerifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employmentVerification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studentVerification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'landlord' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
          { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seenAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notificationSpan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendMonitoringEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doNotContact' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doNotSms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doNotVacationSms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showAge' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'housingSituation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'agreementType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'landlordCity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasReference' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'movingReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseTimeHours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nrFirsthandHousingUnits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentingOutFirsthandHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseUserFragment, unknown>
export const BaseHomeApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseHomeApplication' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeApplication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matchInterest' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseHome' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'origin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseUser' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseHome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'googleMapsPlaceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricingModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCostFactor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCostFactor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electricityFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentIdentification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeIdentifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'incomeVerifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employmentVerification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studentVerification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'landlord' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
          { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seenAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notificationSpan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendMonitoringEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doNotContact' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doNotSms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doNotVacationSms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showAge' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'housingSituation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'agreementType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'landlordCity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasReference' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'movingReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseTimeHours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nrFirsthandHousingUnits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentingOutFirsthandHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseHomeApplicationFragment, unknown>
export const UserBioFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intro' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lived' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserBioFragment, unknown>
export const BankAccountFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BankAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clearingNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BankAccountFieldsFragment, unknown>
export const UserPrivateFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserPrivateFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentIdentification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identificationType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeIdentifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identificationType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'housingSituation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'landlordPhoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'landlordName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'private' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOnfidoReverificationAvailable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeqUid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'schibstedAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedAccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'familyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orgNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organisationNumberCountryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amplitudeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceAdsFrom' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeBankAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BankAccountFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bankAccounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BankAccountFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stripeAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactLocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'smoochParams' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'smoochJwt' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BankAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clearingNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserPrivateFieldsFragment, unknown>
export const ContractFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'contractFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentIncludes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentExcludes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inventory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordHasKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subletception' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preCleaning' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postCleaning' } },
          { kind: 'Field', name: { kind: 'Name', value: 'renovations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'temporaryInstallations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smallRepairs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceResponsibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overnightStay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smoker' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfTenants' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptsCosignatures' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinlandMarketDetails' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minimumStayMonths' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rentIndexation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixedPercentage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'indexationOption' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'waterFeePerTenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthlyElectricityFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electricityFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseHome' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentDay' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'tenantFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantFamilyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantPhoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'legalType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'knownDamages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keySpecification' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codeOfConduct' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cadastralReference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'municipality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bathroomShared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kitchenShared' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extraCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'costFor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseHome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'googleMapsPlaceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricingModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCostFactor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCostFactor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electricityFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContractFragmentFragment, unknown>
export const OccupationFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OccupationFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Occupation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'current' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationScheduleType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OccupationFragmentFragment, unknown>
export const DashboardAttachmentsSectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardAttachmentsSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inspection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'observations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'section' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardAttachmentsSectionFragment, unknown>
export const TenantCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentIdentification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TenantCardFragment, unknown>
export const DashboardHeaderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardHeader' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeDeleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeTemplates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'route' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeContract' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantCard' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentIdentification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardHeaderFragment, unknown>
export const StatusCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StatusCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastBumpedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeContract' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'duration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StatusCardFragment, unknown>
export const MatchingTenantsCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatchingTenantsCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matchingTenantsCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatchingTenantsCardFragment, unknown>
export const UnreadMessagesCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UnreadMessagesCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messagesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unreadMessagesCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnreadMessagesCardFragment, unknown>
export const ViewsCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewsCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageViews' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ViewsCardFragment, unknown>
export const LikesCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LikesCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LikesCardFragment, unknown>
export const ApplicationsCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApplicationsCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationStats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'tenantApplicationCount' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationForecast' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApplicationsCardFragment, unknown>
export const DashboardRentalTimelineFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardRentalTimeline' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardRentalTimelineFragment, unknown>
export const DashboardOverviewSectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardOverviewSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DashboardHeader' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StatusCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MatchingTenantsCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UnreadMessagesCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewsCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LikesCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApplicationsCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DashboardRentalTimeline' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentIdentification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardHeader' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeDeleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeTemplates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'route' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeContract' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantCard' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StatusCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastBumpedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeContract' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'duration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatchingTenantsCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matchingTenantsCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UnreadMessagesCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messagesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unreadMessagesCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewsCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageViews' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LikesCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApplicationsCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationStats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'tenantApplicationCount' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationForecast' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardRentalTimeline' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardOverviewSectionFragment, unknown>
export const RentCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RentCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeContract' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rent' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RentCardFragment, unknown>
export const DepositCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DepositCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DepositCardFragment, unknown>
export const BankAccountCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccountCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } }],
      },
    },
  ],
} as unknown as DocumentNode<BankAccountCardFragment, unknown>
export const DashboardRentSectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardRentSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardStatus' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RentCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DepositCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BankAccountCard' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RentCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeContract' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rent' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DepositCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccountCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } }],
      },
    },
  ],
} as unknown as DocumentNode<DashboardRentSectionFragment, unknown>
export const InsuranceCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InsuranceCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'availability' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InsuranceCardFragment, unknown>
export const RentGuaranteeCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RentGuaranteeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'availability' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RentGuaranteeCardFragment, unknown>
export const SupportCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SupportCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SupportCardFragment, unknown>
export const EvictionCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EvictionCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EvictionCardFragment, unknown>
export const DashboardSafetySectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardSafetySection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardStatus' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InsuranceCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RentGuaranteeCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SupportCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EvictionCard' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InsuranceCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'availability' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RentGuaranteeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'availability' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SupportCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EvictionCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardSafetySectionFragment, unknown>
export const FavoriteHomeButtonFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteHomeButton' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedByUser' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FavoriteHomeButtonFragment, unknown>
export const MapHomeCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapHomeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'minimumPricePerNight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maximumPricePerNight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averagePricePerNight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FavoriteHomeButton' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteHomeButton' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedByUser' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MapHomeCardFragment, unknown>
export const ListingImageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingImage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingImageFragment, unknown>
export const ListingInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingInfoFragment, unknown>
export const ListingStatusDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingStatusDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }],
      },
    },
  ],
} as unknown as DocumentNode<ListingStatusDetailFragment, unknown>
export const ListingExtendedStatusFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingExtendedStatus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archiveReason' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingExtendedStatusFragment, unknown>
export const NewUploadRefFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewUploadRef' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewUploadRefFragment, unknown>
export const UserProfileCompletenessFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserProfileCompleteness' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantProfileCompleteness' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'completenessScore' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantProfileFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioLived' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'idNumberConfirmed' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'matchingProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'movingReason' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioPets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioTitle' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentAgreementType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserProfileCompletenessFragment, unknown>
export const HomeApplicationSortingInfoUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeApplicationSortingInfoUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'private' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeqUid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeApplicationSortingInfoUserFragment, unknown>
export const CurrentUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentIdentification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identificationType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeIdentifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identificationType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'landlord' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'private' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'familyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceAdsFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedAccess' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeBankAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserProfileCompleteness' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeApplicationSortingInfoUser' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserProfileCompleteness' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantProfileCompleteness' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'completenessScore' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantProfileFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioLived' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'idNumberConfirmed' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'matchingProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'movingReason' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioPets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioTitle' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentAgreementType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeApplicationSortingInfoUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'private' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeqUid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentUserFragment, unknown>
export const OfferingActionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Offering' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OfferingActionsFragment, unknown>
export const EditBookingFormHomeAvailabilityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditBookingFormHomeAvailability' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeAvailability' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'booking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conversation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditBookingFormHomeAvailabilityFragment, unknown>
export const ChatHeaderProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatHeaderProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatHeaderProfileFragment, unknown>
export const AdditionalOptionsMenuFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdditionalOptionsMenu' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statusChanger' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdditionalOptionsMenuFragment, unknown>
export const LandlordAddonsHomeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordAddonsHome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LandlordAddonsHomeFragment, unknown>
export const LandlordViewApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordViewApplication' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'market' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlordFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'base' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'waterFeePerTenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlordServices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qasaGuarantee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insurance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordAddonsHome' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfTenants' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlordFees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'base' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cost' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenantFees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'waterFeePerTenant' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cost' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordAddonsHome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LandlordViewApplicationFragment, unknown>
export const HeaderActionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeaderActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instantSignSetting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'enabled' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signatories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordViewApplication' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordAddonsHome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordViewApplication' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'market' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlordFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'base' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'waterFeePerTenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlordServices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qasaGuarantee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insurance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordAddonsHome' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfTenants' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlordFees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'base' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cost' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenantFees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'waterFeePerTenant' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cost' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HeaderActionsFragment, unknown>
export const ChatHeaderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatHeader' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatHeaderProfile' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdditionalOptionsMenu' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HeaderActions' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordAddonsHome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordViewApplication' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'market' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlordFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'base' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'waterFeePerTenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlordServices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qasaGuarantee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insurance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordAddonsHome' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfTenants' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlordFees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'base' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cost' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenantFees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'waterFeePerTenant' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cost' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatHeaderProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdditionalOptionsMenu' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statusChanger' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeaderActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instantSignSetting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'enabled' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signatories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordViewApplication' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatHeaderFragment, unknown>
export const UseHomeBaseInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseHomeBaseInfoFragment, unknown>
export const ChatHomeDetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatHomeDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uploads' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeBaseInfo' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'homeTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatHomeDetailsFragment, unknown>
export const BookingSystemMessageConversationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookingSystemMessageConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentCancellationPolicy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'policyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundFactor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullRefundDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordFullRefundDays' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'responsibleForCleaning' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'booking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canceledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refundAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'actionDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookingOffers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdFromBooking' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricingInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'tenantFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'landlordFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalTenantPrice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rentPrice' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'checkIn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'checkOut' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricingInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rentPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tenantFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalTenantPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'averagePricePerNight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cleaningFee' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoices' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'paidAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BookingSystemMessageConversationFragment, unknown>
export const ChatEditContractActionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatEditContractAction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'market' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlord' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatEditContractActionFragment, unknown>
export const ChatActionsConversationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatActionsConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offering' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offeringStatus' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extensionContract' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'signatories' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'market' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatEditContractAction' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatEditContractAction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'market' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlord' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatActionsConversationFragment, unknown>
export const ChatSystemMessageConversationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatSystemMessageConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offering' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offeringStatus' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatActionsConversation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatEditContractAction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'market' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlord' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatActionsConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offering' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offeringStatus' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extensionContract' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'signatories' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'market' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatEditContractAction' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatSystemMessageConversationFragment, unknown>
export const ChatImageMessageConversationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatImageMessageConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatImageMessageConversationFragment, unknown>
export const ChatMessageReadNoticeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageReadNotice' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastSeenMessageIds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'messageId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatMessageReadNoticeFragment, unknown>
export const ChatWarningMessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatWarningMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatWarningMessageFragment, unknown>
export const ChatMessageConversationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BookingSystemMessageConversation' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatSystemMessageConversation' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatImageMessageConversation' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatMessageReadNotice' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatWarningMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatEditContractAction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'market' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlord' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatActionsConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offering' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offeringStatus' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extensionContract' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'signatories' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'market' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatEditContractAction' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookingSystemMessageConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentCancellationPolicy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'policyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundFactor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullRefundDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordFullRefundDays' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'responsibleForCleaning' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'booking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canceledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refundAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'actionDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookingOffers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdFromBooking' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricingInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'tenantFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'landlordFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalTenantPrice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rentPrice' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'checkIn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'checkOut' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricingInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rentPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tenantFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalTenantPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'averagePricePerNight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cleaningFee' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoices' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'paidAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatSystemMessageConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offering' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offeringStatus' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatActionsConversation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatImageMessageConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageReadNotice' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastSeenMessageIds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'messageId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatWarningMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatMessageConversationFragment, unknown>
export const ChatProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'booking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatProfileFragment, unknown>
export const GetSortedUploadsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSortedUploads' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'order' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSortedUploadsFragment, unknown>
export const GetPrimaryUploadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetPrimaryUpload' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSortedUploads' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'primary' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSortedUploads' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'order' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPrimaryUploadFragment, unknown>
export const SmallHomeCardInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SmallHomeCardInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeBaseInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SmallHomeCardInfoFragment, unknown>
export const SmallHomeCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SmallHomeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetPrimaryUpload' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SmallHomeCardInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSortedUploads' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'order' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetPrimaryUpload' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSortedUploads' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'primary' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SmallHomeCardInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeBaseInfo' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SmallHomeCardFragment, unknown>
export const ChatHomeIntroMessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatHomeIntroMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SmallHomeCard' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSortedUploads' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'order' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetPrimaryUpload' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSortedUploads' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'primary' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SmallHomeCardInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeBaseInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SmallHomeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetPrimaryUpload' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SmallHomeCardInfo' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatHomeIntroMessageFragment, unknown>
export const ChatIntroMessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatIntroMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'origin' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatProfile' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatHomeIntroMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSortedUploads' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'order' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetPrimaryUpload' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSortedUploads' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'primary' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SmallHomeCardInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeBaseInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SmallHomeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetPrimaryUpload' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SmallHomeCardInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'booking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatHomeIntroMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SmallHomeCard' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatIntroMessageFragment, unknown>
export const ChatImageMessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatImageMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatImageMessageFragment, unknown>
export const BookingSystemMessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookingSystemMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BookingOffer' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdFromBooking' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BookingSystemMessageFragment, unknown>
export const ChatActionsMessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatActionsMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeApplication' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatActionsMessageFragment, unknown>
export const ChatSystemMessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatSystemMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ContractTenantInvitation' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'recipientEmail' } }],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatActionsMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatActionsMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeApplication' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatSystemMessageFragment, unknown>
export const ChatMessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatImageMessage' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BookingSystemMessage' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatSystemMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatActionsMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeApplication' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatImageMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookingSystemMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BookingOffer' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdFromBooking' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatSystemMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ContractTenantInvitation' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'recipientEmail' } }],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatActionsMessage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatMessageFragment, unknown>
export const FloatingActionButtonsConversationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FloatingActionButtonsConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'acceptsCosignatures' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FloatingActionButtonsConversationFragment, unknown>
export const ChatMessageListFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatHomeDetails' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatMessageConversation' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatIntroMessage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'origin' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatMessage' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FloatingActionButtonsConversation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookingSystemMessageConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentCancellationPolicy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'policyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundFactor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullRefundDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordFullRefundDays' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'responsibleForCleaning' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'booking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canceledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refundAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'actionDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookingOffers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdFromBooking' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricingInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'tenantFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'landlordFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalTenantPrice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rentPrice' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'checkIn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'checkOut' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricingInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rentPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tenantFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalTenantPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'averagePricePerNight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cleaningFee' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoices' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'paidAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatEditContractAction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'market' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlord' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatActionsConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offering' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offeringStatus' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extensionContract' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'signatories' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'market' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatEditContractAction' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatSystemMessageConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offering' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offeringStatus' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatActionsConversation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatImageMessageConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageReadNotice' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastSeenMessageIds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'messageId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatWarningMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'booking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSortedUploads' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'order' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetPrimaryUpload' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSortedUploads' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'primary' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SmallHomeCardInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeBaseInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SmallHomeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetPrimaryUpload' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SmallHomeCardInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatHomeIntroMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SmallHomeCard' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatImageMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookingSystemMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BookingOffer' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdFromBooking' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatActionsMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeApplication' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatSystemMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ContractTenantInvitation' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'recipientEmail' } }],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatActionsMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatHomeDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uploads' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeBaseInfo' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'homeTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BookingSystemMessageConversation' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatSystemMessageConversation' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatImageMessageConversation' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatMessageReadNotice' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatWarningMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatIntroMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'origin' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatProfile' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatHomeIntroMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatImageMessage' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BookingSystemMessage' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatSystemMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FloatingActionButtonsConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'acceptsCosignatures' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatMessageListFragment, unknown>
export const TenantCostBreakdownTableFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantCostBreakdownTable' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firsthand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TenantCostBreakdownTableFragment, unknown>
export const LandlordProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
          { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
          { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentIdentification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseTimeHours' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LandlordProfileFragment, unknown>
export const TenantViewApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantViewApplication' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantCostBreakdownTable' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordProfile' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantCostBreakdownTable' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firsthand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
          { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
          { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentIdentification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseTimeHours' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TenantViewApplicationFragment, unknown>
export const EditBookingFormConversationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditBookingFormConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricingModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFeeFactor' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditBookingFormConversationFragment, unknown>
export const EditBookingDialogFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditBookingDialog' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'booking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookingOffers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'checkIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkOut' } },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EditBookingFormConversation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditBookingFormConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricingModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFeeFactor' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditBookingDialogFragment, unknown>
export const LandlordViewBookingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordViewBooking' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EditBookingDialog' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditBookingFormConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricingModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFeeFactor' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditBookingDialog' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'booking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookingOffers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'checkIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkOut' } },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EditBookingFormConversation' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LandlordViewBookingFragment, unknown>
export const MarkLastChatMessageAsReadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarkLastChatMessageAsRead' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'read' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarkLastChatMessageAsReadFragment, unknown>
export const SearchAreaFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchAreaFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchArea' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'geojson' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchAreaFieldsFragment, unknown>
export const SavedSearchFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SavedSearchFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SavedSearch' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monitoring' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchParams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchAreas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firsthand' } },
                { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minMonthlyCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minRentalLength' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moveInEarliest' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
                { kind: 'Field', name: { kind: 'Name', value: 'smoker' } },
                { kind: 'Field', name: { kind: 'Name', value: 'traits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minBedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minBedroomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minToiletCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'householdSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minPricePerNight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxPricePerNight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wheelchairAccessible' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasKitchen' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SavedSearchFragmentFragment, unknown>
export const HomeInteractionOverlayFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeInteractionOverlay' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FavoriteHomeButton' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteHomeButton' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedByUser' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeInteractionOverlayFragment, unknown>
export const HomeMapFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeMap' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeMapFragment, unknown>
export const MultimediaActionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MultimediaActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MultimediaActionsFragment, unknown>
export const ImageSectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeInteractionOverlay' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeMap' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MultimediaActions' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteHomeButton' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedByUser' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeInteractionOverlay' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FavoriteHomeButton' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeMap' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MultimediaActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImageSectionFragment, unknown>
export const LongTermBaseInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LongTermBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeBaseInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LongTermBaseInfoFragment, unknown>
export const VacationBaseInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VacationBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'toiletCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasKitchen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VacationBaseInfoFragment, unknown>
export const HomeBaseInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LongTermBaseInfo' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'VacationBaseInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LongTermBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeBaseInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VacationBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'toiletCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasKitchen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeBaseInfoFragment, unknown>
export const DescriptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Description' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionBuilding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionContract' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionFeatures' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionLayout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionTransportation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'housingAssociation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firsthand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bathroomRenovationYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kitchenRenovationYear' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DescriptionFragment, unknown>
export const ContractInfoRulesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContractInfoRules' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'maxTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContractInfoRulesFragment, unknown>
export const RentalRequirementsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RentalRequirements' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rentalRequirement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'approvedCreditCheck' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentMultiplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'studentVerification' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedIdNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedIncome' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RentalRequirementsFragment, unknown>
export const DurationComponentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DurationComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Duration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DurationComponentFragment, unknown>
export const AdditionalDurationInfoComponentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdditionalDurationInfoComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Duration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'possibilityOfExtension' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'durationPreference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startLatest' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdditionalDurationInfoComponentFragment, unknown>
export const InstantSignLeaseDurationInfoComponentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSignLeaseDurationInfoComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'durationPreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startLatest' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstantSignLeaseDurationInfoComponentFragment, unknown>
export const LongTermContractInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LongTermContractInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContractInfoRules' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RentalRequirements' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'waterFeePerTenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthlyElectricityFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electricityFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DurationComponent' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdditionalDurationInfoComponent' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstantSignLeaseDurationInfoComponent' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContractInfoRules' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'maxTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RentalRequirements' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rentalRequirement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'approvedCreditCheck' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentMultiplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'studentVerification' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedIdNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedIncome' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DurationComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Duration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdditionalDurationInfoComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Duration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'possibilityOfExtension' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'durationPreference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startLatest' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSignLeaseDurationInfoComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'durationPreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startLatest' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LongTermContractInfoFragment, unknown>
export const AmenitiesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amenities' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AmenitiesFragment, unknown>
export const HomePitchFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomePitch' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortcut' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homePitch' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomePitchFragment, unknown>
export const LandlordCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentIdentification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'intro' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LandlordCardFragment, unknown>
export const LocationSectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LocationSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeMap' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeMap' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocationSectionFragment, unknown>
export const CostBoxFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostBox' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'external' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalInfoPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalApplicationUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalEmail' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DurationComponent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DurationComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Duration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CostBoxFragment, unknown>
export const OfferInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricingModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFeeFactor' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'landlordHoursToSign' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OfferInfoFragment, unknown>
export const LandlordPreviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordPreview' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adPaidAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listingPublicationFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LandlordPreviewFragment, unknown>
export const ImageViewingDialogFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageViewingDialog' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImageViewingDialogFragment, unknown>
export const HomeTemplateListRowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateListRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeTemplateListRowFragment, unknown>
export const HomeTemplateDetailsDialogBodyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateDetailsDialogBody' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeLayoutPictures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeTemplateDetailsDialogBodyFragment, unknown>
export const HomeTemplateDetailsDialogFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateDetailsDialog' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeTemplateDetailsDialogBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateDetailsDialogBody' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeLayoutPictures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeTemplateDetailsDialogFragment, unknown>
export const HomeTemplateListFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeTemplates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeTemplateListRow' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeTemplateDetailsDialog' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateDetailsDialogBody' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeLayoutPictures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateListRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateDetailsDialog' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeTemplateDetailsDialogBody' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeTemplateListFragment, unknown>
export const HomeSeoLinksFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeSeoLinks' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rentalType' } }],
      },
    },
  ],
} as unknown as DocumentNode<HomeSeoLinksFragment, unknown>
export const PublishDateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublishDate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }],
      },
    },
  ],
} as unknown as DocumentNode<PublishDateFragment, unknown>
export const InstantSignHomeRulesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSignHomeRules' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstantSignHomeRulesFragment, unknown>
export const InstantSignFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSign' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openForSigning' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstantSignFragment, unknown>
export const UseHomeVariantMetaDataArrayFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseHomeVariantMetaDataArrayFragment, unknown>
export const HomeMetaDataListFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeMetaDataList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeMetaDataListFragment, unknown>
export const InstantSignSummaryHomeCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSignSummaryHomeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetPrimaryUpload' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeMetaDataList' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSortedUploads' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'order' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetPrimaryUpload' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSortedUploads' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'primary' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeMetaDataList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstantSignSummaryHomeCardFragment, unknown>
export const ApartmentIdFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApartmentId' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'landlordUnitId' } }],
      },
    },
  ],
} as unknown as DocumentNode<ApartmentIdFragment, unknown>
export const HomeApplicationSortingInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeApplicationSortingInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'applicationSortingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationSelectionType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeApplicationSortingInfoFragment, unknown>
export const HomeContextFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeContext' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'origin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'durationPreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startLatest' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'canBePublished' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageSection' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeBaseInfo' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Description' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LongTermContractInfo' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Amenities' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomePitch' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LocationSection' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostBox' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OfferInfo' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordPreview' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageViewingDialog' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeTemplateList' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeSeoLinks' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PublishDate' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstantSignHomeRules' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeInteractionOverlay' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstantSign' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstantSignSummaryHomeCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApartmentId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeApplicationSortingInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteHomeButton' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedByUser' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeInteractionOverlay' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FavoriteHomeButton' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeMap' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MultimediaActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LongTermBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeBaseInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VacationBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'toiletCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasKitchen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContractInfoRules' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'maxTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RentalRequirements' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rentalRequirement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'approvedCreditCheck' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentMultiplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'studentVerification' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedIdNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedIncome' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DurationComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Duration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdditionalDurationInfoComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Duration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'possibilityOfExtension' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'durationPreference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startLatest' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSignLeaseDurationInfoComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'durationPreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startLatest' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateListRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateDetailsDialogBody' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeLayoutPictures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateDetailsDialog' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeTemplateDetailsDialogBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSortedUploads' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'order' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetPrimaryUpload' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSortedUploads' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'primary' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeMetaDataList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeInteractionOverlay' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeMap' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MultimediaActions' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LongTermBaseInfo' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'VacationBaseInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Description' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionBuilding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionContract' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionFeatures' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionLayout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionTransportation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'housingAssociation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firsthand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bathroomRenovationYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kitchenRenovationYear' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LongTermContractInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContractInfoRules' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RentalRequirements' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'waterFeePerTenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthlyElectricityFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electricityFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DurationComponent' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdditionalDurationInfoComponent' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstantSignLeaseDurationInfoComponent' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amenities' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomePitch' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortcut' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homePitch' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentIdentification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'intro' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LocationSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeMap' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostBox' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'external' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalInfoPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalApplicationUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalEmail' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DurationComponent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricingModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFeeFactor' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'landlordHoursToSign' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordPreview' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adPaidAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listingPublicationFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageViewingDialog' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeTemplates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeTemplateListRow' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeTemplateDetailsDialog' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeSeoLinks' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rentalType' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublishDate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSignHomeRules' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSign' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openForSigning' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSignSummaryHomeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetPrimaryUpload' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeMetaDataList' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApartmentId' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'landlordUnitId' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeApplicationSortingInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'applicationSortingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationSelectionType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeContextFragment, unknown>
export const CostBoxExistingApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostBoxExistingApplication' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeApplication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contract' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CostBoxExistingApplicationFragment, unknown>
export const HomeContextExistingApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeContextExistingApplication' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeApplication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostBoxExistingApplication' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostBoxExistingApplication' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeApplication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contract' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeContextExistingApplicationFragment, unknown>
export const CostBoxExistingInstantSignContractFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostBoxExistingInstantSignContract' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signatories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CostBoxExistingInstantSignContractFragment, unknown>
export const HomeContextInstantSignExistingContractFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeContextInstantSignExistingContract' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostBoxExistingInstantSignContract' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostBoxExistingInstantSignContract' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signatories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeContextInstantSignExistingContractFragment, unknown>
export const CostboxHomeqApplicationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostboxHomeqApplications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectHubHomeQHomeApplicationsType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'interests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CostboxHomeqApplicationsFragment, unknown>
export const HomeqApplicationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeqApplications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectHubHomeQHomeApplicationsType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostboxHomeqApplications' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostboxHomeqApplications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectHubHomeQHomeApplicationsType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'interests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeqApplicationsFragment, unknown>
export const HomeCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetPrimaryUpload' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeBaseInfo' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LongTermContractInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSortedUploads' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'order' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContractInfoRules' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'maxTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RentalRequirements' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rentalRequirement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'approvedCreditCheck' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentMultiplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'studentVerification' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedIdNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedIncome' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DurationComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Duration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdditionalDurationInfoComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Duration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'possibilityOfExtension' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'durationPreference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startLatest' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSignLeaseDurationInfoComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'durationPreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startLatest' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetPrimaryUpload' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSortedUploads' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'primary' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LongTermContractInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContractInfoRules' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RentalRequirements' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'waterFeePerTenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthlyElectricityFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electricityFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DurationComponent' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdditionalDurationInfoComponent' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstantSignLeaseDurationInfoComponent' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeCardFragment, unknown>
export const ImageGalleryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageGallery' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImageGalleryFragment, unknown>
export const VirtualTourDialogFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VirtualTourDialog' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VirtualTourDialogFragment, unknown>
export const FilterByHomeCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FilterByHomeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FilterByHomeCardFragment, unknown>
export const InboxCardImageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardImage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'starMarked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uploads' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InboxCardImageFragment, unknown>
export const InboxCardTopRowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardTopRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeMetaDataList' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeMetaDataList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InboxCardTopRowFragment, unknown>
export const UseConversationPartnerUserNameFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseConversationPartnerUserName' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseConversationPartnerUserNameFragment, unknown>
export const HomeApplicationUnansweredFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeApplicationUnanswered' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeApplicationUnansweredFragment, unknown>
export const InboxCardNotificationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardNotification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'read' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unreadMessagesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeApplicationUnanswered' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeApplicationUnanswered' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InboxCardNotificationFragment, unknown>
export const InboxCardMiddleRowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardMiddleRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseConversationPartnerUserName' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCardNotification' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeApplicationUnanswered' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseConversationPartnerUserName' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardNotification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'read' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unreadMessagesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeApplicationUnanswered' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InboxCardMiddleRowFragment, unknown>
export const InboxCardBottomRowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardBottomRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'booking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookingOffers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BookingOffer' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdFromBooking' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ContractTenantInvitation' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'recipientEmail' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'read' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InboxCardBottomRowFragment, unknown>
export const InboxCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCardImage' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCardTopRow' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCardMiddleRow' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCardBottomRow' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeMetaDataList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseConversationPartnerUserName' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeApplicationUnanswered' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardNotification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'read' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unreadMessagesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeApplicationUnanswered' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardImage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'starMarked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uploads' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardTopRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeMetaDataList' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardMiddleRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseConversationPartnerUserName' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCardNotification' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardBottomRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'booking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookingOffers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BookingOffer' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdFromBooking' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ContractTenantInvitation' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'recipientEmail' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'read' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InboxCardFragment, unknown>
export const InboxListFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationConnection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCard' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardImage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'starMarked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uploads' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeMetaDataList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardTopRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeMetaDataList' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseConversationPartnerUserName' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeApplicationUnanswered' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardNotification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'read' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unreadMessagesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeApplicationUnanswered' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardMiddleRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseConversationPartnerUserName' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCardNotification' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardBottomRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'booking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookingOffers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BookingOffer' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdFromBooking' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ContractTenantInvitation' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'recipientEmail' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'read' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCardImage' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCardTopRow' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCardMiddleRow' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCardBottomRow' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InboxListFragment, unknown>
export const HomeqInterestFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeqInterestFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConnectHubHomeQInterestType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateAccess' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countsToLimit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accessOnAgreement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeqInterestFieldsFragment, unknown>
export const HomeqApplicationFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeqApplicationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectHubHomeQHomeApplicationType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeqInterestFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'viewingDatetime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rsvpStatus' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeqInterestFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConnectHubHomeQInterestType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateAccess' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countsToLimit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accessOnAgreement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeqApplicationFieldsFragment, unknown>
export const BigSearchLongtermCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BigSearchLongtermCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firsthand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FavoriteHomeButton' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeBaseInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DurationComponent' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteHomeButton' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedByUser' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DurationComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Duration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BigSearchLongtermCardFragment, unknown>
export const BigSearchVacationCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BigSearchVacationCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FavoriteHomeButton' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averagePricePerNight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minimumPricePerNight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maximumPricePerNight' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteHomeButton' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedByUser' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BigSearchVacationCardFragment, unknown>
export const TenantPreferencesUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantPreferencesUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantAd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchPreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCostCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'householdSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TenantPreferencesUserFragment, unknown>
export const NameAndVerificationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NameAndVerifications' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showAge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentIdentification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'housingSituation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasReference' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NameAndVerificationsFragment, unknown>
export const MatchingProfileCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatchingProfileCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantPreferencesUser' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameAndVerifications' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantPreferencesUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantAd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchPreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCostCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'householdSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NameAndVerifications' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showAge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentIdentification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'housingSituation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasReference' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatchingProfileCardFragment, unknown>
export const TenantPreferencesHomeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantPreferencesHome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyCostCents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TenantPreferencesHomeFragment, unknown>
export const MeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Me' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'mainPlatform' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentIdentification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'landlord' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenant' } },
                { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CurrentUser' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserProfileCompleteness' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantProfileCompleteness' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'completenessScore' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantProfileFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioLived' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'idNumberConfirmed' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'matchingProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'movingReason' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioPets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioTitle' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentAgreementType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeApplicationSortingInfoUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'private' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeqUid' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentIdentification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identificationType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeIdentifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identificationType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'landlord' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'private' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'familyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceAdsFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedAccess' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeBankAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserProfileCompleteness' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeApplicationSortingInfoUser' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>
export const UpdateExpoPushTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateExpoPushToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'expoPushToken' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateExpoPushTokenMutation, UpdateExpoPushTokenMutationVariables>
export const HomeQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeContext' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteHomeButton' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedByUser' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeInteractionOverlay' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FavoriteHomeButton' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeMap' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MultimediaActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeInteractionOverlay' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeMap' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MultimediaActions' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LongTermBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeBaseInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VacationBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'toiletCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasKitchen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LongTermBaseInfo' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'VacationBaseInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Description' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionBuilding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionContract' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionFeatures' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionLayout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionTransportation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'housingAssociation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firsthand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bathroomRenovationYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kitchenRenovationYear' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContractInfoRules' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'maxTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RentalRequirements' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rentalRequirement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'approvedCreditCheck' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentMultiplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'studentVerification' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedIdNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedIncome' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DurationComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Duration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdditionalDurationInfoComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Duration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'possibilityOfExtension' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'durationPreference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startLatest' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSignLeaseDurationInfoComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'durationPreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startLatest' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LongTermContractInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContractInfoRules' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RentalRequirements' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'waterFeePerTenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthlyElectricityFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electricityFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DurationComponent' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdditionalDurationInfoComponent' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstantSignLeaseDurationInfoComponent' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amenities' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomePitch' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortcut' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homePitch' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentIdentification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'intro' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LocationSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeMap' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostBox' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'external' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalInfoPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalApplicationUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalEmail' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DurationComponent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricingModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFeeFactor' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'landlordHoursToSign' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordPreview' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adPaidAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listingPublicationFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageViewingDialog' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateListRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateDetailsDialogBody' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeLayoutPictures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateDetailsDialog' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeTemplateDetailsDialogBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeTemplates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeTemplateListRow' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeTemplateDetailsDialog' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeSeoLinks' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rentalType' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublishDate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSignHomeRules' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSign' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openForSigning' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSortedUploads' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'order' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetPrimaryUpload' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSortedUploads' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'primary' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeMetaDataList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSignSummaryHomeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetPrimaryUpload' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeMetaDataList' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApartmentId' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'landlordUnitId' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeApplicationSortingInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'applicationSortingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationSelectionType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeContext' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'origin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'durationPreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startLatest' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'canBePublished' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageSection' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeBaseInfo' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Description' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LongTermContractInfo' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Amenities' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomePitch' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LocationSection' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostBox' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OfferInfo' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordPreview' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageViewingDialog' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeTemplateList' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeSeoLinks' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PublishDate' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstantSignHomeRules' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeInteractionOverlay' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstantSign' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstantSignSummaryHomeCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApartmentId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeApplicationSortingInfo' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeQueryQuery, HomeQueryQueryVariables>
export const ActiveListingsIdsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ActiveListingsIdsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'market' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketNameTypeEnum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'market' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'market' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActiveListingsIdsQueryQuery, ActiveListingsIdsQueryQueryVariables>
export const SearchAreaHierarchyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchAreaHierarchy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'countryCodes' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'CountryCodeEnum' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'childrenMinimumViewCount' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchAreaHierarchy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'countryCodes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'countryCodes' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'leastTimesShown' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'childrenMinimumViewCount' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchAreaHierarchyQuery, SearchAreaHierarchyQueryVariables>
export const CancelContractSigningDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelContractSigning' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelContractSigning' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelContractSigningMutation, CancelContractSigningMutationVariables>
export const CreateHomeMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateHomeMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateHomeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createHome' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricingModel' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'insuranceCostFactor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCostFactor' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateHomeMutationMutation, CreateHomeMutationMutationVariables>
export const CreateUploadMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUploadMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateUploadInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upload' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUploadMutationMutation, CreateUploadMutationMutationVariables>
export const DestroyUploadMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyUploadMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DestroyUploadMutationMutation, DestroyUploadMutationMutationVariables>
export const DestroyUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DestroyUserMutation, DestroyUserMutationVariables>
export const ExtendContractMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExtendContractMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endOptimal' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rent' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extendContract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endOptimal' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endOptimal' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rent' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rent' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extensionContract' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExtendContractMutationMutation, ExtendContractMutationMutationVariables>
export const FinalizeUploadMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FinalizeUploadMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'etag' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finalizeUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'etag' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'etag' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upload' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FinalizeUploadMutationMutation, FinalizeUploadMutationMutationVariables>
export const IdentifyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Identify' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IdentifyInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identify' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IdentifyMutation, IdentifyMutationVariables>
export const InitiateContractSignMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InitiateContractSignMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'landlordInvitationMethod' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ScriveInvitationOptionEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantInvitationMethod' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ScriveInvitationOptionEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendInitialInvitation' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiateContractSigning' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'landlordInvitationMethod' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'landlordInvitationMethod' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenantInvitationMethod' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantInvitationMethod' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sendInitialInvitation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sendInitialInvitation' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InitiateContractSignMutationMutation,
  InitiateContractSignMutationMutationVariables
>
export const ReportUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReportUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportTypeEnum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reason' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reportType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userReport' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportUserMutation, ReportUserMutationVariables>
export const SchibstedLoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SchibstedLogin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SchibstedLoginInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'schibstedLogin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payload' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseUser' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserPrivateFields' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'affiliateCode' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'discountFor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'discountType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'discountValue' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'affiliateCodeType' } },
                                ],
                              },
                            },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserBio' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'UserProfileCompleteness' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'smoker' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'creditReport' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fetchStatus' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applicationStats' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'inContactCount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locales' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'roomMax' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'roomMin' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BankAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clearingNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentIdentification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeIdentifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'incomeVerifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employmentVerification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studentVerification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'landlord' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
          { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seenAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notificationSpan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendMonitoringEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doNotContact' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doNotSms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doNotVacationSms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showAge' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'housingSituation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'agreementType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'landlordCity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasReference' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'movingReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseTimeHours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nrFirsthandHousingUnits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentingOutFirsthandHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserPrivateFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentIdentification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identificationType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeIdentifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identificationType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'housingSituation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'landlordPhoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'landlordName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'private' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOnfidoReverificationAvailable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeqUid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'schibstedAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedAccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'familyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orgNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organisationNumberCountryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amplitudeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceAdsFrom' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeBankAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BankAccountFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bankAccounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BankAccountFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stripeAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactLocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'smoochParams' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'smoochJwt' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intro' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lived' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserProfileCompleteness' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantProfileCompleteness' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'completenessScore' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantProfileFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioLived' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'idNumberConfirmed' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'matchingProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'movingReason' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioPets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioTitle' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentAgreementType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SchibstedLoginMutation, SchibstedLoginMutationVariables>
export const UpdateUploadMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUploadMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateUploadInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upload' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUploadMutationMutation, UpdateUploadMutationMutationVariables>
export const UpdateUserMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseUser' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserBio' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserPrivateFields' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'smoker' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'affiliateCode' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'discountFor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'discountType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'discountValue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'affiliateCodeType' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BankAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clearingNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentIdentification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeIdentifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'incomeVerifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employmentVerification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studentVerification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'landlord' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
          { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seenAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notificationSpan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendMonitoringEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doNotContact' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doNotSms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doNotVacationSms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showAge' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'housingSituation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'agreementType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'landlordCity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasReference' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'movingReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseTimeHours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nrFirsthandHousingUnits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentingOutFirsthandHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intro' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lived' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserPrivateFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentIdentification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identificationType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeIdentifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identificationType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'housingSituation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'landlordPhoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'landlordName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'private' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOnfidoReverificationAvailable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeqUid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'schibstedAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedAccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'familyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orgNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organisationNumberCountryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amplitudeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceAdsFrom' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeBankAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BankAccountFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bankAccounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BankAccountFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stripeAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactLocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'smoochParams' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'smoochJwt' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutationMutation, UpdateUserMutationMutationVariables>
export const UpsertBankAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertBankAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertBankAccountInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertBankAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bankAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BankAccountFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BankAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clearingNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertBankAccountMutation, UpsertBankAccountMutationVariables>
export const UpsertContractMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertContractMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertContractInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertContract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'contractFragment' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseHome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'googleMapsPlaceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricingModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCostFactor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCostFactor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electricityFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'contractFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentIncludes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentExcludes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inventory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordHasKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subletception' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preCleaning' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postCleaning' } },
          { kind: 'Field', name: { kind: 'Name', value: 'renovations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'temporaryInstallations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smallRepairs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceResponsibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overnightStay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smoker' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfTenants' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptsCosignatures' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinlandMarketDetails' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minimumStayMonths' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rentIndexation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixedPercentage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'indexationOption' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'waterFeePerTenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthlyElectricityFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electricityFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseHome' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentDay' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'tenantFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantFamilyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantPhoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'legalType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'knownDamages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keySpecification' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codeOfConduct' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cadastralReference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'municipality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bathroomShared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kitchenShared' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extraCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'costFor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertContractMutationMutation, UpsertContractMutationMutationVariables>
export const UpsertFinnishContractDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertFinnishContract' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FinlandUpsertContractInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finlandUpsertContract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'contractFragment' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseHome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'googleMapsPlaceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricingModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCostFactor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCostFactor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electricityFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'contractFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentIncludes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentExcludes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inventory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordHasKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subletception' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preCleaning' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postCleaning' } },
          { kind: 'Field', name: { kind: 'Name', value: 'renovations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'temporaryInstallations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smallRepairs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceResponsibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overnightStay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smoker' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfTenants' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptsCosignatures' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinlandMarketDetails' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minimumStayMonths' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rentIndexation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixedPercentage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'indexationOption' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'waterFeePerTenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthlyElectricityFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electricityFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseHome' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentDay' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'tenantFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantFamilyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantPhoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'legalType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'knownDamages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keySpecification' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codeOfConduct' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cadastralReference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'municipality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bathroomShared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kitchenShared' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extraCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'costFor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertFinnishContractMutation, UpsertFinnishContractMutationVariables>
export const ContractQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContractQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseContract' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extensionContract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signatories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'motherContract' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conversation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FinlandMarketDetails' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minimumStayMonths' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rentIndexation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixedPercentage' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'indexationOption' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inspectionProtocol' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'termsUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'depositFree' } },
                { kind: 'Field', name: { kind: 'Name', value: 'depositFreeCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'depositPayoutApprovedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'depositPayoutDeniedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availablePreviewLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfTenants' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pendingTerminationEndDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'waterFeePerTenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthlyElectricityFee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'electricityFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlordFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'base' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FinancePayouts' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FinanceDepositInvoices' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FinanceRentInvoices' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FinanceGenericInvoice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantPostSignDocuments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlordPostSignDocuments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FinlandMarketDetails' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rentIndexation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixedPercentage' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'indexationOption' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guaranteed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'periodEndsAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'periodStartsAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paidAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'creditedAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'batch' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoiceEntries' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'costType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'entryType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'market' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseHome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'googleMapsPlaceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricingModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCostFactor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCostFactor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electricityFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseContract' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'terminationNotice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenureType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptsCosignatures' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordHasKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceResponsibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inventory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payoutDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentIncludes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentExcludes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subletception' } },
          { kind: 'Field', name: { kind: 'Name', value: 'renovations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payoutDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preCleaning' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postCleaning' } },
          { kind: 'Field', name: { kind: 'Name', value: 'temporaryInstallations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smallRepairs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smoker' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payoutDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overnightStay' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalContractTakeover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'currentState' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUserSignatory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canSign' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signatories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signingDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canSign' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentIdentification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'signingProcess' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rentalInsuranceMilaRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documentRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cosigningTenants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantInvitations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'claimKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'claimable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseHome' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inspection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'observations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'section' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'market' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ownHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenureType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'waterFeePerTenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fixed' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthlyElectricityFee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instantSignSetting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'landlordHoursToSign' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'tenantFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantFamilyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantPhoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'legalType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bindingTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canBeTerminated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'terminationNoticeMonths' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shorterTerminationExplanation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'knownDamages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keySpecification' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codeOfConduct' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cadastralReference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'municipality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bathroomShared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kitchenShared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentedAsIs' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extraCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'costFor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'depositFree' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ifInsurancePromotion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'signedUp' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinancePayouts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'financePayouts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paidAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                { kind: 'Field', name: { kind: 'Name', value: 'periodStart' } },
                { kind: 'Field', name: { kind: 'Name', value: 'periodEnd' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'receipt' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'charges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vatPercent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vat' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fractional' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinanceDepositInvoices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'financeDepositInvoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overdue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'receipt' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vatPercent' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vat' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fractional' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sumAmount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentAction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'StripeSepaTransferMoneyAction' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinanceRentInvoices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'financeRentInvoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'periodStart' } },
                { kind: 'Field', name: { kind: 'Name', value: 'periodEnd' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overdue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'receipt' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upcomingPayout' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'periodEnd' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'periodStart' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sumAmount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'charges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vat' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fractional' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'vatPercent' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vatPercent' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vat' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fractional' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sumAmount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentAction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'StripeSepaTransferMoneyAction' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinanceGenericInvoice' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'financeInvoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FinanceGenericInvoice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'periodStart' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'periodEnd' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'overdue' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'receipt' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'vatPercent' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vat' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fractional' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sumAmount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentAction' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'StripeSepaTransferMoneyAction' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'amount' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'humanized' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContractQueryQuery, ContractQueryQueryVariables>
export const ContractsCountsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContractsCountsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractsCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vacation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'canceled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'draft' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'extended' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ended' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'finalDraft' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pendingAprilaApplication' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pendingAprilaRentalAgreement' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pendingTenantCreditCheck' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rejected' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timedout' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'longTerm' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'canceled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'draft' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'awaitingInsuranceApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'extended' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ended' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'finalDraft' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pendingAprilaApplication' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pendingAprilaRentalAgreement' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pendingTenantCreditCheck' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rejected' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timedout' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContractsCountsQueryQuery, ContractsCountsQueryQueryVariables>
export const ContractsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContractsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContractStatusEnum' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'signingProcess' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SigningProcessEnum' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contracts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'signingProcess' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'signingProcess' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseContract' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseHome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'googleMapsPlaceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricingModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCostFactor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCostFactor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electricityFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseContract' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'terminationNotice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenureType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptsCosignatures' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordHasKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceResponsibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inventory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payoutDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentIncludes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentExcludes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subletception' } },
          { kind: 'Field', name: { kind: 'Name', value: 'renovations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payoutDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preCleaning' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postCleaning' } },
          { kind: 'Field', name: { kind: 'Name', value: 'temporaryInstallations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smallRepairs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smoker' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payoutDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overnightStay' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalContractTakeover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'currentState' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUserSignatory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canSign' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signatories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signingDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canSign' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentIdentification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'signingProcess' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rentalInsuranceMilaRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documentRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cosigningTenants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantInvitations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'claimKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'claimable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseHome' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inspection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'observations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'section' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'market' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ownHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenureType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'waterFeePerTenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fixed' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthlyElectricityFee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instantSignSetting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'landlordHoursToSign' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'tenantFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantFamilyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantPhoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'legalType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bindingTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canBeTerminated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'terminationNoticeMonths' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shorterTerminationExplanation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'knownDamages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keySpecification' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codeOfConduct' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cadastralReference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'municipality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bathroomShared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kitchenShared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentedAsIs' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extraCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'costFor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'depositFree' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ifInsurancePromotion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'signedUp' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContractsQueryQuery, ContractsQueryQueryVariables>
export const HomeMatchingProfileQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeMatchingProfileQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyCostCents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'traits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'duration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'locality' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeMatchingProfileQueryQuery, HomeMatchingProfileQueryQueryVariables>
export const HomesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Homes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'homes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uploads' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomesQuery, HomesQueryVariables>
export const IdentificationStatusQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IdentificationStatusQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'externalId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identificationStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'externalId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'externalId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IdentificationStatusQueryQuery, IdentificationStatusQueryQueryVariables>
export const MeQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MeQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseUser' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserPrivateFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserBio' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserProfileCompleteness' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creditReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fetchStatus' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationStats' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inContactCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locales' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roomMax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roomMin' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affiliateCode' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountFor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'affiliateCodeType' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'smoker' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BankAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clearingNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentIdentification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeIdentifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'incomeVerifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employmentVerification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studentVerification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'landlord' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
          { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seenAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notificationSpan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendMonitoringEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doNotContact' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doNotSms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doNotVacationSms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showAge' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'housingSituation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'agreementType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'landlordCity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasReference' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'movingReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseTimeHours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nrFirsthandHousingUnits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentingOutFirsthandHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserPrivateFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentIdentification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identificationType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeIdentifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identificationType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'housingSituation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'landlordPhoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'landlordName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'private' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOnfidoReverificationAvailable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeqUid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'schibstedAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedAccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'familyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orgNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organisationNumberCountryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amplitudeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceAdsFrom' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeBankAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BankAccountFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bankAccounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BankAccountFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stripeAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactLocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'smoochParams' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'smoochJwt' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intro' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lived' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserProfileCompleteness' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantProfileCompleteness' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'completenessScore' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantProfileFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioLived' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'idNumberConfirmed' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'matchingProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'movingReason' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioPets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioTitle' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentAgreementType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQueryQuery, MeQueryQueryVariables>
export const SearchAreaQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchAreaQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'areaParams' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'AreaParamsInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchArea' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'areaParams' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'areaParams' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'geojson' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchAreaQueryQuery, SearchAreaQueryQueryVariables>
export const RemoveCosigningTenantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveCosigningTenant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contractId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeCosigningTenant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contractId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contractId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveCosigningTenantMutation, RemoveCosigningTenantMutationVariables>
export const UpdateHomeAddonsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateHomeAddons' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateHomeInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateHome' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateHomeAddonsMutation, UpdateHomeAddonsMutationVariables>
export const UpsertContractAddonsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertContractAddons' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertContractInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertContract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertContractAddonsMutation, UpsertContractAddonsMutationVariables>
export const FetchContractAddonsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchContractAddons' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchContractAddonsQuery, FetchContractAddonsQueryVariables>
export const HomeqUserClaimsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeqUserClaims' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeqUserClaims' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentClaims' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'file' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'classification' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'renewed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateCreated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'orgType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalClaimId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'incomeSource' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'income' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'allowedClaimTypes' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeqUserClaimsQuery, HomeqUserClaimsQueryVariables>
export const CreateHomeqClaimDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateHomeqClaim' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ConnectHubHomeQClaimTypeEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orgType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ConnectHubHomeQClaimOrgTypeEnum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createHomeqClaim' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orgType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orgType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'claim' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateHomeqClaimMutation, CreateHomeqClaimMutationVariables>
export const UpdateHomeqClaimDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateHomeqClaim' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'claimId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateHomeqClaimInput' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ConnectHubInputObjectsUpdateClaimInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'renewed' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateHomeqClaim' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'claimId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'claimId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updateHomeqClaimInput' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'renewed' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'renewed' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateHomeqClaimMutation, UpdateHomeqClaimMutationVariables>
export const CreateHomeqFileReferenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateHomeqFileReference' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ConnectHubInputObjectsCreateFileReferenceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createHomeqFileReference' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fileReference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fileReference' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'contentMd5' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'xAmzAcl' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'resourceUri' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateHomeqFileReferenceMutation, CreateHomeqFileReferenceMutationVariables>
export const HomeqLandlordsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeqLandlords' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeqLandlordCompanies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeqLandlordsQuery, HomeqLandlordsQueryVariables>
export const OnfidoSdkTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OnfidoSdkToken' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'onfidoSdkToken' } }],
      },
    },
  ],
} as unknown as DocumentNode<OnfidoSdkTokenQuery, OnfidoSdkTokenQueryVariables>
export const InitiateOnfidoCheckDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InitiateOnfidoCheck' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reverify' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiateOnfidoCheck' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reverify' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reverify' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitiateOnfidoCheckMutation, InitiateOnfidoCheckMutationVariables>
export const ActiveIdentificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ActiveIdentifications' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeIdentifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identificationStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identificationType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActiveIdentificationsQuery, ActiveIdentificationsQueryVariables>
export const UpsertOccupationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertOccupation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertOccupationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertOccupation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'occupation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OccupationFragment' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OccupationFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Occupation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'current' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationScheduleType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertOccupationMutation, UpsertOccupationMutationVariables>
export const DestroyOccupationMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyOccupationMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'destroyOccupationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyOccupation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'destroyOccupationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DestroyOccupationMutationMutation, DestroyOccupationMutationMutationVariables>
export const CreateBlobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBlob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateBlobInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBlob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'headers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateBlobMutation, CreateBlobMutationVariables>
export const AdminAccessTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminAccessToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminAccessToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccessToken' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'impersonatedByAdmin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthorizationError' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NotFoundError' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ValidationError' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributeErrors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'attribute' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminAccessTokenQuery, AdminAccessTokenQueryVariables>
export const ConfirmEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'codesWithMessage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfirmEmailMutation, ConfirmEmailMutationVariables>
export const ContractPreviewQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContractPreviewQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContractLanguageEnum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateContractPreviewPdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'previewSource' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContractPreviewQueryQuery, ContractPreviewQueryQueryVariables>
export const SignatoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Signatories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contractId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contractId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signatories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignatoriesQuery, SignatoriesQueryVariables>
export const EmploymentVerificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EmploymentVerification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employmentVerification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'employerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'upload' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmploymentVerificationQuery, EmploymentVerificationQueryVariables>
export const UpsertEmploymentVerificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertEmploymentVerification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertEmploymentVerificationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertEmploymentVerification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employmentVerification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'employerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertEmploymentVerificationMutation,
  UpsertEmploymentVerificationMutationVariables
>
export const FavoritesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FavoritesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeSearchOrderEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeSearchOrderByEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rentalTypes' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeRentalTypeEnum' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'favoriteHomes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rentalTypes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rentalTypes' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filterHomesOffset' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'offset' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'pagesCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firsthand' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'averagePricePerNight' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minimumPricePerNight' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maximumPricePerNight' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedByUser' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'landlord' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profilePicture' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sublocality' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'links' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'duration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uploads' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profilePicture' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'instantSignSetting' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'traits' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FavoritesQueryQuery, FavoritesQueryQueryVariables>
export const HomePublishSuccessQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomePublishSuccessQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlordServices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insurance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'availability' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qasaGuarantee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'availability' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'market' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomePublishSuccessQueryQuery, HomePublishSuccessQueryQueryVariables>
export const HomeViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'landlordUnitId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'origin' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'market' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shortcut' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'homePitch' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentIdentification' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseRate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlordApplicationResponseTimeHours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'intro' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instantSignSetting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordHoursToSign' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'openForSigning' } },
                { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'external' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedByUser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentIdentification' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseRate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlordApplicationResponseTimeHours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contactLocation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'intro' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'seenAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'externalInfoPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalApplicationUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalEmail' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uploads' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'traits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'duration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'possibilityOfExtension' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'durationPreference' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startLatest' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'homeTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'homeLayoutPictures' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'traits' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionBuilding' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionContract' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionFeatures' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionLayout' } },
                { kind: 'Field', name: { kind: 'Name', value: 'descriptionTransportation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'housingAssociation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'energyClass' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kitchenRenovationYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bathroomRenovationYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buildYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'base' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'waterFeePerTenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthlyElectricityFee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'electricityFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firsthand' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'property' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'adPaidAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rentalRequirement' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedCreditCheck' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rentMultiplier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'studentVerification' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'verifiedIdNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'verifiedIncome' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'minTenantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxTenantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlordServices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insurance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qasaGuarantee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlordFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'base' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listingPublicationFee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricingModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceCostFactor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCostFactor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFeeFactor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'houseRules' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasKitchen' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toiletCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'responsibleForCleaning' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cleaningFee' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentCancellationPolicy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'policyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundFactor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullRefundDays' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicationDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicationSortingType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicationSelectionType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canBePublished' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeViewQuery, HomeViewQueryVariables>
export const ApplicationForHomeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ApplicationForHome' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'home_id' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeApplicationStatusEnum' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'home_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'homeTemplates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'homeTemplates' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contract' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApplicationForHomeQuery, ApplicationForHomeQueryVariables>
export const DuplicateHomeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DuplicateHome' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endOptimal' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startOptimal' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duplicateHome' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'duration' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'endOptimal' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'endOptimal' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'startOptimal' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'startOptimal' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DuplicateHomeMutation, DuplicateHomeMutationVariables>
export const PublishHomeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PublishHome' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishHome' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublishHomeMutation, PublishHomeMutationVariables>
export const ValidateInviteCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ValidateInviteCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'claimKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractTenantInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'claimKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'claimKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'claimable' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ValidateInviteCodeQuery, ValidateInviteCodeQueryVariables>
export const LandlordPreSignContractDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LandlordPreSignContract' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseContract' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conversation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'termsUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantContactInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'familyName' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlordFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'base' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instantSignSetting' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'landlordHoursToSign' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FranceMarketDetails' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'recoverableCharges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'monthlyCost' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseHome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'googleMapsPlaceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricingModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCostFactor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCostFactor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electricityFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseContract' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'terminationNotice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenureType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptsCosignatures' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordHasKeys' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceResponsibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inventory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payoutDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentIncludes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentExcludes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subletception' } },
          { kind: 'Field', name: { kind: 'Name', value: 'renovations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payoutDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preCleaning' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postCleaning' } },
          { kind: 'Field', name: { kind: 'Name', value: 'temporaryInstallations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smallRepairs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smoker' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payoutDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overnightStay' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalContractTakeover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'currentState' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUserSignatory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canSign' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signatories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signingDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canSign' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentIdentification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'safeRental' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'signingProcess' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rentalInsuranceMilaRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documentRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cosigningTenants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantInvitations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'claimKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'claimable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseHome' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inspection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'observations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'section' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'market' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ownHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenureType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'waterFeePerTenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fixed' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthlyElectricityFee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instantSignSetting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'landlordHoursToSign' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'tenantFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantFamilyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantPhoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'legalType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bindingTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canBeTerminated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'terminationNoticeMonths' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shorterTerminationExplanation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'knownDamages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keySpecification' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codeOfConduct' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cadastralReference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'municipality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'titleNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bathroomShared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kitchenShared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentedAsIs' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extraCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'costFor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'depositFree' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ifInsurancePromotion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'signedUp' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LandlordPreSignContractQuery, LandlordPreSignContractQueryVariables>
export const UpsertInspectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertInspection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'observations' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'InspectionObservationInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertInspection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'observations' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'observations' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inspection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'observations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'section' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertInspectionMutation, UpsertInspectionMutationVariables>
export const DashboardQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DashboardQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dashboardStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeContract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listingBumpPayment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DashboardOverviewSection' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DashboardRentSection' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DashboardSafetySection' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DashboardAttachmentsSection' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentIdentification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardHeader' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeDeleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeTemplates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'route' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeContract' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantCard' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StatusCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastBumpedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeContract' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'duration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatchingTenantsCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matchingTenantsCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UnreadMessagesCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messagesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unreadMessagesCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ViewsCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageViews' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LikesCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApplicationsCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationStats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'tenantApplicationCount' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationForecast' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardRentalTimeline' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RentCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeContract' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rent' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DepositCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccountCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InsuranceCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'availability' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RentGuaranteeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'availability' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SupportCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EvictionCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardOverviewSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DashboardHeader' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StatusCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MatchingTenantsCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UnreadMessagesCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ViewsCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LikesCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApplicationsCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DashboardRentalTimeline' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardRentSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardStatus' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RentCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DepositCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BankAccountCard' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardSafetySection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardStatus' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InsuranceCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RentGuaranteeCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SupportCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EvictionCard' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardAttachmentsSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dashboardStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'simplifiedStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inspection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'observations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'section' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardQueryQuery, DashboardQueryQueryVariables>
export const ListingDashboardVacationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListingDashboardVacation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canBeDeleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dashboardStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastBumpedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listingBumpPayment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ListingImage' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ListingInfo' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ListingExtendedStatus' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ListingStatusDetail' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingImage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingExtendedStatus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archiveReason' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingStatusDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }],
      },
    },
  ],
} as unknown as DocumentNode<ListingDashboardVacationQuery, ListingDashboardVacationQueryVariables>
export const DeleteListingMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteListingMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteHome' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'homeId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteListingMutationMutation, DeleteListingMutationMutationVariables>
export const ReservationSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReservationSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentIdentification' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseRate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlordApplicationResponseTimeHours' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uploads' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'responsibleForCleaning' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cleaningFee' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentCancellationPolicy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'policyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundFactor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullRefundDays' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'market' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReservationSummaryQuery, ReservationSummaryQueryVariables>
export const StudentCredentialsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StudentCredentials' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studentVerification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'educationType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'extent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expectedFinishDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'program' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'school' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'upload' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StudentCredentialsQuery, StudentCredentialsQueryVariables>
export const UpsertStudentCredentialsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertStudentCredentials' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertStudentVerificationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertStudentVerification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studentVerification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'educationType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'extent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'school' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'program' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expectedFinishDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertStudentCredentialsMutation, UpsertStudentCredentialsMutationVariables>
export const ContractSignLandingQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContractSignLandingQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'termsUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'market' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlordServices' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'qasaGuarantee' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'insurance' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'duration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContractSignLandingQueryQuery, ContractSignLandingQueryQueryVariables>
export const VerifyBankAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VerifyBankAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'verificationTokenId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyBankAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verificationTokenId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'verificationTokenId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bankAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyBankAccountMutation, VerifyBankAccountMutationVariables>
export const HomeqHomeApplicationRequirementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeqHomeApplicationRequirements' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeqHomeApplicationPreconditions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activeAd' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allowsWarrantor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicantGroupAlreadyApplied' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicantGroupContainsLocked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicantGroupExceedLimit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicantIncompleteProfile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicantMissingOccupation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canApply' } },
                { kind: 'Field', name: { kind: 'Name', value: 'claimedWarrantor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coApplicantMissingInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isMainApplicant' } },
                { kind: 'Field', name: { kind: 'Name', value: 'restrictedDueToSeniorStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'restrictedDueToStudentStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'restrictedDueToYouthStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'warrantorMissingInfo' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  HomeqHomeApplicationRequirementsQuery,
  HomeqHomeApplicationRequirementsQueryVariables
>
export const HomeqApplicationUserDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeqApplicationUserData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'private' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'familyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'homeqUid' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'intro' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creditReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fetchStatus' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentIdentification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identificationType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identificationStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'idNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'familyName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'householdMembersBirthYears' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'housingSituation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'agreementType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordPhoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordCity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalLandlordReference' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeqApplicationUserDataQuery, HomeqApplicationUserDataQueryVariables>
export const HomeqApplicationUserClaimsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeqApplicationUserClaims' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeqUserClaims' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentClaims' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'file' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'classification' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'income' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'allowedClaimTypes' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeqApplicationUserClaimsQuery, HomeqApplicationUserClaimsQueryVariables>
export const FindHomeQUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindHomeQUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeqUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindHomeQUserQuery, FindHomeQUserQueryVariables>
export const SendEmailConfirmationTokenPayloadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendEmailConfirmationTokenPayload' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'redirectPath' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendEmailConfirmationToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirectPath' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'redirectPath' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendEmailConfirmationTokenPayloadMutation,
  SendEmailConfirmationTokenPayloadMutationVariables
>
export const ConnectHomeQUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConnectHomeQUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectHomeqUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'codes' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConnectHomeQUserMutation, ConnectHomeQUserMutationVariables>
export const CreateApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'home_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'counter_offer' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeTemplateIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'start_optimal' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'message' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTenantHomeApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'homeId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'home_id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'counterOffer' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'counter_offer' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'homeTemplateIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'homeTemplateIds' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startOptimal' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'start_optimal' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'message' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'message' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'homeApplication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateApplicationMutation, CreateApplicationMutationVariables>
export const CreateHomeqApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateHomeqApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createHomeqApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'homeApplication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateHomeqApplicationMutation, CreateHomeqApplicationMutationVariables>
export const CreateCreditReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCreditReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'authToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCreditReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'authToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'authToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creditReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fetchStatus' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCreditReportMutation, CreateCreditReportMutationVariables>
export const GetCreditReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCreditReport' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creditReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'fetchStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moveInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'years' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberPaymentRemarks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalDebt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'yearlyIncomeStatements' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'guardianship' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'seizureAttempt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'debtSanitization' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCreditReportQuery, GetCreditReportQueryVariables>
export const DestroyCreditReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyCreditReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creditReportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyCreditReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'creditReportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creditReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DestroyCreditReportMutation, DestroyCreditReportMutationVariables>
export const HouseholdMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HouseholdMembers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'private' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'familyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'householdMembersBirthYears' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HouseholdMembersQuery, HouseholdMembersQueryVariables>
export const SendSignInCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendSignInCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'platform' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlatformEnum' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendSignInCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'platform' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'platform' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendSignInCodeMutation, SendSignInCodeMutationVariables>
export const ExchangeSignInCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExchangeSignInCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exchangeSignInCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'codes' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExchangeSignInCodeMutation, ExchangeSignInCodeMutationVariables>
export const ImportTinkBankAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImportTinkBankAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'importTinkBankAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountVerificationReportId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bankAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BankAccountFields' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isIdNumberMismatch' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BankAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clearingNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImportTinkBankAccountMutation, ImportTinkBankAccountMutationVariables>
export const ContractInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContractInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'claimKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractTenantInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'claimKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'claimKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'claimKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'claimable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recipientEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uploads' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContractInvitationQuery, ContractInvitationQueryVariables>
export const ClaimContractInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ClaimContractInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'claimKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'claimContractInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'claimKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'claimKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClaimContractInvitationMutation, ClaimContractInvitationMutationVariables>
export const UpdateTenantPostSignDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTenantPostSignDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateTenantPostSignDocumentsId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateTenantPostSignDocumentsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTenantPostSignDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updateTenantPostSignDocumentsId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenantPostSignDocuments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTenantPostSignDocumentsMutation,
  UpdateTenantPostSignDocumentsMutationVariables
>
export const UpdateLandlordPostSignDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLandlordPostSignDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateLandlordPostSignDocumentsId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateLandlordPostSignDocumentsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLandlordPostSignDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateLandlordPostSignDocumentsId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlordPostSignDocuments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateLandlordPostSignDocumentsMutation,
  UpdateLandlordPostSignDocumentsMutationVariables
>
export const TerminateContractDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TerminateContract' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endAt' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'terminateContractId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terminateContract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endAt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endAt' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'terminateContractId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pendingTerminationEndDate' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TerminateContractMutation, TerminateContractMutationVariables>
export const IfInsurancePromotionSignUpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'IfInsurancePromotionSignUp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contractId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'signedUp' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ifInsurancePromotionSignUp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contractId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contractId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'signedUp' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'signedUp' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ifInsurancePromotion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'signedUp' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IfInsurancePromotionSignUpMutation, IfInsurancePromotionSignUpMutationVariables>
export const SetDepositFreeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetDepositFree' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setDepositFree' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'depositFree' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'depositFreeCost' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoices' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'invoiceEntries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'costType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetDepositFreeMutation, SetDepositFreeMutationVariables>
export const ApproveDepositPayoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApproveDepositPayout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approveDepositPayout' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'depositPayoutApprovedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApproveDepositPayoutMutation, ApproveDepositPayoutMutationVariables>
export const DenyDepositPayoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DenyDepositPayout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'denyDepositPayout' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'depositPayoutDeniedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DenyDepositPayoutMutation, DenyDepositPayoutMutationVariables>
export const CheckoutTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckoutToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contractId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkoutToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contractId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contractId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutTokenQuery, CheckoutTokenQueryVariables>
export const ClaimCheckoutContractInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ClaimCheckoutContractInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'claimKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'claimContractInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'claimKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'claimKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClaimCheckoutContractInvitationMutation,
  ClaimCheckoutContractInvitationMutationVariables
>
export const ProfileForCreateTenantListingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProfileForCreateTenantListing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'housingSituation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'agreementType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordCity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordPhoneNumber' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'movingReason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'occupations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OccupationFragment' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'intro' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lived' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                { kind: 'Field', name: { kind: 'Name', value: 'showAge' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'private' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'familyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantAd' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchPreference' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'searchAreas' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'geojson' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'homeType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'balcony' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bathtub' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bikeRoom' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dishWasher' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'householdSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inhomeSauna' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'storage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'recycling' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'smoker' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'washingMachine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tumbleDryer' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'wheelchairAccessible' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'parking' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'homeType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCost' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OccupationFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Occupation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'current' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationScheduleType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileForCreateTenantListingQuery, ProfileForCreateTenantListingQueryVariables>
export const CreateCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCertificate' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCertificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'certificate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCertificateMutation, CreateCertificateMutationVariables>
export const HomeSearchCoordsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeSearchCoordsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'market' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketNameTypeEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchParams' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeSearchParamsInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterOnArea' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeSearchCoords' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'market' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'market' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchParams' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchParams' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterOnArea' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filterOnArea' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'filterHomesRaw' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeSearchCoordsQueryQuery, HomeSearchCoordsQueryQueryVariables>
export const MapPopupContentHomeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MapPopupContentHome' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MapHomeCard' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteHomeButton' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedByUser' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MapHomeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'minimumPricePerNight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maximumPricePerNight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averagePricePerNight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FavoriteHomeButton' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MapPopupContentHomeQuery, MapPopupContentHomeQueryVariables>
export const BlockListingDataForHomeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BlockListingDataForHome' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlockListingDataForHomeQuery, BlockListingDataForHomeQueryVariables>
export const FindTenantAdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindTenantAds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'platform' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlatformEnum' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sorting' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TenantAdSortingEnum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantAds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'platform' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'platform' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorting' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sorting' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pagesCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'showAge' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profilePicture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bio' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currentIdentification' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'housingSituation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasReference' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchPreference' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCost' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCostCents' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'householdSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'matchingAreaMetadata' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'osmId' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindTenantAdsQuery, FindTenantAdsQueryVariables>
export const HomeSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeSelect' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeSearchOrderByEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeSearchOrderEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeStatuses' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeStatusEnum' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeStatuses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeStatuses' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'archiveReason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'landlord' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'traits' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uploads' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeSelectQuery, HomeSelectQueryVariables>
export const HomeStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeStatusQuery, HomeStatusQueryVariables>
export const CreateHomeFromTemplateInHomeViewMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateHomeFromTemplateInHomeViewMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeTemplateId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createHomeFromTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeTemplateId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateHomeFromTemplateInHomeViewMutationMutation,
  CreateHomeFromTemplateInHomeViewMutationMutationVariables
>
export const InitiateListingPublicationFeePaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InitiateListingPublicationFeePayment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InitiateListingPublicationFeePaymentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiateListingPublicationFeePayment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sessionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InitiateListingPublicationFeePaymentMutation,
  InitiateListingPublicationFeePaymentMutationVariables
>
export const ExistingBookingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ExistingBookings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'BookingStatusEnum' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bookings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conversation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkOut' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricingInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rentPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tenantFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalTenantPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cleaningFee' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExistingBookingsQuery, ExistingBookingsQueryVariables>
export const HomeAvailabilitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeAvailabilities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeAvailabilities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'booking' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profilePicture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricingModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'landlordBaseFeeFactor' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeAvailabilitiesQuery, HomeAvailabilitiesQueryVariables>
export const VacationPricingPreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VacationPricingPreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checkIn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checkOut' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricePreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'checkIn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'checkIn' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'checkOut' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'checkOut' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'averagePricePerNight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'landlordFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalTenantPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPrice' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VacationPricingPreviewQuery, VacationPricingPreviewQueryVariables>
export const InitiateTinkIncomeCheckMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InitiateTinkIncomeCheckMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tinkId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiateTinkIncomeCheck' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tinkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tinkId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InitiateTinkIncomeCheckMutationMutation,
  InitiateTinkIncomeCheckMutationMutationVariables
>
export const IncomeVerificationQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IncomeVerificationQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'incomeVerification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meanTotalIncome' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transactions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'time' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IncomeVerificationQueryQuery, IncomeVerificationQueryQueryVariables>
export const TenantAdsInstantMatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TenantAdsInstantMatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scoreThreshold' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'platform' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlatformEnum' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantAds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scoreThreshold' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scoreThreshold' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'platform' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'platform' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'showAge' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profilePicture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bio' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currentIdentification' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'housingSituation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasReference' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchPreference' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCost' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCostCents' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'householdSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'matchingAreaMetadata' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'osmId' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TenantAdsInstantMatchQuery, TenantAdsInstantMatchQueryVariables>
export const InstantSignContractAlreadySignedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstantSignContractAlreadySigned' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContractStatusEnum' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'signingProcess' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SigningProcessEnum' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contracts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'signingProcess' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'signingProcess' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'openForSigning' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InstantSignContractAlreadySignedQuery,
  InstantSignContractAlreadySignedQueryVariables
>
export const InstantSignContractPreviewQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstantSignContractPreviewQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContractLanguageEnum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateContractPreviewPdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'previewSource' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availablePreviewLanguages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InstantSignContractPreviewQueryQuery,
  InstantSignContractPreviewQueryQueryVariables
>
export const InstantSignExistingContractsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstantSignExistingContracts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContractStatusEnum' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contracts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'signingProcess' },
                value: { kind: 'ListValue', values: [{ kind: 'EnumValue', value: 'instant' }] },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'signingProcess' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'termsUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signatories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstantSignExistingContractsQuery, InstantSignExistingContractsQueryVariables>
export const CreateInstantSignContractDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateInstantSignContract' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contractInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateInstantSignContractInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInstantSignContract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contractInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'termsUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateInstantSignContractMutation, CreateInstantSignContractMutationVariables>
export const UpdateInstantSignContractDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateInstantSignContract' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateInstantSignContractId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contractInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateInstantSignContractInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInstantSignContract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updateInstantSignContractId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contractInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'termsUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateInstantSignContractMutation, UpdateInstantSignContractMutationVariables>
export const CityHomeCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CityHomeCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchParams' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeSearchParamsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'homeSearch' },
            name: { kind: 'Name', value: 'homeIndexSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchParams' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CityHomeCountQuery, CityHomeCountQueryVariables>
export const MeetYourTenantQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MeetYourTenantQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentIdentification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantAd' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchPreference' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'householdSize' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'intro' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lived' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'occupations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'occupationScheduleType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'certificate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'certifiedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'housingSituation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'agreementType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordCity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasReference' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'movingReason' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeetYourTenantQueryQuery, MeetYourTenantQueryQueryVariables>
export const ArchiveListingMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ArchiveListingMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeArchiveReasonEnum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archiveHome' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archiveReason' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ListingExtendedStatus' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingExtendedStatus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archiveReason' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArchiveListingMutationMutation, ArchiveListingMutationMutationVariables>
export const BumpListingVacationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BumpListingVacation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bumpVacationAd' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BumpListingVacationMutation, BumpListingVacationMutationVariables>
export const BumpListingForPremiumUserMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BumpListingForPremiumUserMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bumpListingForPremiumUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BumpListingForPremiumUserMutationMutation,
  BumpListingForPremiumUserMutationMutationVariables
>
export const BumpListingPaymentMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BumpListingPaymentMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InitiateListingBumpPaymentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiateListingBumpPayment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sessionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BumpListingPaymentMutationMutation, BumpListingPaymentMutationMutationVariables>
export const ListingStatusDetailQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListingStatusDetailQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ListingStatusDetail' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingStatusDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }],
      },
    },
  ],
} as unknown as DocumentNode<ListingStatusDetailQueryQuery, ListingStatusDetailQueryQueryVariables>
export const PublishListingMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PublishListingMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishHome' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ListingExtendedStatus' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ListingStatusDetail' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingExtendedStatus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archiveReason' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingStatusDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }],
      },
    },
  ],
} as unknown as DocumentNode<PublishListingMutationMutation, PublishListingMutationMutationVariables>
export const ListingExtendedStatusQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListingExtendedStatusQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ListingExtendedStatus' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingExtendedStatus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archiveReason' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingExtendedStatusQueryQuery, ListingExtendedStatusQueryQueryVariables>
export const VacationListingStatisticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VacationListingStatistics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'BookingStatusEnum' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeStatsQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'cumulativePageViews' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedCount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bookings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VacationListingStatisticsQuery, VacationListingStatisticsQueryVariables>
export const ListingAvailabilitySettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListingAvailabilitySettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeAvailabilitySettingsQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'minNightsStay' } },
                { kind: 'Field', name: { kind: 'Name', value: 'noCheckinDays' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentOnlyWeekly' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingAvailabilitySettingsQuery, ListingAvailabilitySettingsQueryVariables>
export const ListingAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListingAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeAvailabilities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingAvailabilityQuery, ListingAvailabilityQueryVariables>
export const UpsertListingAvailabilitySettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertListingAvailabilitySettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertHomeAvailabilitySettingsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertHomeAvailabilitySettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'homeAvailabilitySettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'minNightsStay' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'noCheckinDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rentOnlyWeekly' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertListingAvailabilitySettingsMutation,
  UpsertListingAvailabilitySettingsMutationVariables
>
export const EditListingMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditListingMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateHomeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateHome' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recommendedRentNew' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'forceSync' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditListingMutationMutation, EditListingMutationMutationVariables>
export const EvaluateHomeForShortcutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EvaluateHomeForShortcut' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'evaluateHomeForShortcut' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EvaluateHomeForShortcutMutation, EvaluateHomeForShortcutMutationVariables>
export const HomeDraftsAndPreviouslySignedHomesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeDraftsAndPreviouslySignedHomesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rentalTypes' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeRentalTypeEnum' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'homeDrafts' },
            name: { kind: 'Name', value: 'homes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeStatuses' },
                value: { kind: 'ListValue', values: [{ kind: 'EnumValue', value: 'pending' }] },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rentalTypes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rentalTypes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uploads' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'previouslySignedHomes' },
            name: { kind: 'Name', value: 'homes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeStatuses' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'EnumValue', value: 'contract_ended' },
                    { kind: 'EnumValue', value: 'rented_out' },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rentalTypes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rentalTypes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uploads' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'homeTemplates' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  HomeDraftsAndPreviouslySignedHomesQueryQuery,
  HomeDraftsAndPreviouslySignedHomesQueryQueryVariables
>
export const CreateHomeFromTemplateInCreateListingMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateHomeFromTemplateInCreateListingMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeTemplateId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createHomeFromTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeTemplateId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateHomeFromTemplateInCreateListingMutationMutation,
  CreateHomeFromTemplateInCreateListingMutationMutationVariables
>
export const ListingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Listing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'market' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'tenureType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'formattedAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uploads' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'traits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'duration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'possibilityOfExtension' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ownHome' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlordServices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qasaGuarantee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insurance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlordFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'base' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommendedRentNew' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'forceSync' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firsthand' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'base' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'factor' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'waterFeePerTenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fixed' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthlyElectricityFee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'electricityFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'property' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'energyClass' } },
                { kind: 'Field', name: { kind: 'Name', value: 'housingAssociation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kitchenRenovationYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bathroomRenovationYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buildYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'external' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalApplicationUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalInfoPage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricingModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceCostFactor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCostFactor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'houseRules' } },
                { kind: 'Field', name: { kind: 'Name', value: 'responsibleForCleaning' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cleaningFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasKitchen' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toiletCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentCancellationPolicy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'policyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundFactor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullRefundDays' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cancellationOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'policyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundFactor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullRefundDays' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'origin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingQuery, ListingQueryVariables>
export const CancellationPolicyOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CancellationPolicyOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cancellationOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'policyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundFactor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullRefundDays' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancellationPolicyOptionsQuery, CancellationPolicyOptionsQueryVariables>
export const LandlordHomeAvailabilitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LandlordHomeAvailabilities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeAvailabilities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'booking' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profilePicture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricingModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'landlordBaseFeeFactor' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LandlordHomeAvailabilitiesQuery, LandlordHomeAvailabilitiesQueryVariables>
export const UpsertHomeAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertHomeAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'availability' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertHomeAvailabilityInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertHomeAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'availability' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'availability' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upserted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertHomeAvailabilityMutation, UpsertHomeAvailabilityMutationVariables>
export const DestroyHomeAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyHomeAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dates' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyHomeAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dates' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dates' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dates' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DestroyHomeAvailabilityMutation, DestroyHomeAvailabilityMutationVariables>
export const HomeAvailabilitySettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeAvailabilitySettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeAvailabilitySettingsQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'minNightsStay' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentOnlyWeekly' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeAvailabilitySettingsQuery, HomeAvailabilitySettingsQueryVariables>
export const HomesListQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomesListQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeStatuses' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeStatusEnum' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeSearchOrderEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeSearchOrderByEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchString' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rentalTypes' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeRentalTypeEnum' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeStatuses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeStatuses' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchString' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchString' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rentalTypes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rentalTypes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uploads' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'archiveReason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'duration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomesListQueryQuery, HomesListQueryQueryVariables>
export const ProContactFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ProContactForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProContactFormInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'proContactForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProContactFormMutation, ProContactFormMutationVariables>
export const ProPilotUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProPilotUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'proPilotUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'publishedHomes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bio' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'intro' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profilePicture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProPilotUsersQuery, ProPilotUsersQueryVariables>
export const CreateFindTenantLandlordApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFindTenantLandlordApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateLandlordHomeApplicationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createLandlordHomeApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'homeApplication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFindTenantLandlordApplicationMutation,
  CreateFindTenantLandlordApplicationMutationVariables
>
export const HousingSituationExtraDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HousingSituationExtraData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'housingSituation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordPhoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HousingSituationExtraDataQuery, HousingSituationExtraDataQueryVariables>
export const UpsertTenantAdMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertTenantAdMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TenantAdInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertTenantAd' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantAd' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'publishedUntil' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchPreference' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'searchAreas' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'geojson' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'balcony' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bathtub' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bikeRoom' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dishWasher' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'householdSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inhomeSauna' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'storage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'recycling' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'smoker' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'washingMachine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tumbleDryer' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'wheelchairAccessible' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'parking' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'homeType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCost' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertTenantAdMutationMutation, UpsertTenantAdMutationMutationVariables>
export const PublishTenantAdMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PublishTenantAdMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishTenantAd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantAd' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublishTenantAdMutationMutation, PublishTenantAdMutationMutationVariables>
export const UnpublishTenantAdMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnpublishTenantAdMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unpublishTenantAd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantAd' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'publishedUntil' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnpublishTenantAdMutationMutation, UnpublishTenantAdMutationMutationVariables>
export const ConversationForHomeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ConversationForHome' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conversationForHome' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'homeId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'homeApplication' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'conversation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConversationForHomeQuery, ConversationForHomeQueryVariables>
export const ProPilotConnectedUidsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProPilotConnectedUids' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'proPilotConnectedUids' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProPilotConnectedUidsQuery, ProPilotConnectedUidsQueryVariables>
export const ProfileQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProfileQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'landlord' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenant' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seenAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'occupations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OccupationFragment' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseRate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'housingSituation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'agreementType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordCity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasReference' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'movingReason' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantAd' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'publishedUntil' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchPreference' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'searchAreas' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'geojson' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'balcony' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bathtub' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bikeRoom' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dishWasher' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'householdSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inhomeSauna' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'storage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'recycling' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'smoker' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'washingMachine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tumbleDryer' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'wheelchairAccessible' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'parking' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'homeType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCost' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCostCents' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantProfileCompleteness' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'completenessScore' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenantProfileFields' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bioLived' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bio' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'idNumberConfirmed' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'occupations' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'matchingProfile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'movingReason' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profilePicture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bioPets' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bioTitle' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currentAgreementType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseTimeHours' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'certificate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'intro' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lived' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentIdentification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                { kind: 'Field', name: { kind: 'Name', value: 'showAge' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'publishedHomes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firsthand' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedByUser' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlord' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profilePicture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sublocality' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'traits' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profilePicture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instantSignSetting' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uploads' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'averagePricePerNight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minimumPricePerNight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maximumPricePerNight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'links' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'homeApplicationsToMyHomes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'showAge' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'landlord' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'seenAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profilePicture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'certificate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currentIdentification' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'googleMapsPlaceId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uploads' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'origin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'matchInterest' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationStats' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locales' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roomMin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roomMax' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OccupationFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Occupation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'current' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationScheduleType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileQueryQuery, ProfileQueryQueryVariables>
export const CreateBookingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBooking' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertBookingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertBooking' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'booking' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'landlord' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateBookingMutation, CreateBookingMutationVariables>
export const StripeAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StripeAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'refreshUrl' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'returnUrl' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'private' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stripeAccount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'refreshUrl' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'refreshUrl' } },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'returnUrl' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'returnUrl' } },
                                },
                              ],
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StripeAccountQuery, StripeAccountQueryVariables>
export const ResendConfirmBankAccountEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResendConfirmBankAccountEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'activeBankAccountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendConfirmBankAccountEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'activeBankAccountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emailQueued' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResendConfirmBankAccountEmailMutation,
  ResendConfirmBankAccountEmailMutationVariables
>
export const HomeqUserPointsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeqUserPoints' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeqUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'points' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queuePoints' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bonusPoints' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'points' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeqUserPointsQuery, HomeqUserPointsQueryVariables>
export const UserTaxReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserTaxReports' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'private' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxReports' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxYear' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'home' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'daysRentedOut' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'familyName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'idNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'taxYear' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'orgNo' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'freeFormatAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bankAccount' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'quarters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'compensation' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fees' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'numberOfTransactions' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'quarter' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'taxes' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserTaxReportsQuery, UserTaxReportsQueryVariables>
export const DeleteHomeqClaimDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteHomeqClaim' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'claimId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'renewed' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteHomeqClaim' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'claimId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'claimId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'renewed' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'renewed' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteHomeqClaimMutation, DeleteHomeqClaimMutationVariables>
export const RenewHomeqClaimDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RenewHomeqClaim' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'claimId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renewHomeqClaim' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'claimId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'claimId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'claim' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RenewHomeqClaimMutation, RenewHomeqClaimMutationVariables>
export const DiscardIdentificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DiscardIdentification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'identificationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discardIdentification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'identificationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DiscardIdentificationMutation, DiscardIdentificationMutationVariables>
export const VerificationsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VerificationsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'private' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'homeqUid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'certificate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentIdentification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identificationType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identificationStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'idNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'familyName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employmentVerification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'employerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'upload' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creditReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fetchStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studentVerification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'school' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'program' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'upload' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'housingSituation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'agreementType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordPhoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordCity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalLandlordReference' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerificationsQueryQuery, VerificationsQueryQueryVariables>
export const IncomeVerificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IncomeVerification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'incomeVerification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meanTotalIncome' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'humanized' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IncomeVerificationQuery, IncomeVerificationQueryVariables>
export const DestroyIncomeVerificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DestroyIncomeVerification' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroyIncomeVerification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DestroyIncomeVerificationMutation, DestroyIncomeVerificationMutationVariables>
export const CurrentHomeqClaimsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CurrentHomeqClaims' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeqUserClaims' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentClaims' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'file' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'classification' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'renewed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateCreated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'orgType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalClaimId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'incomeSource' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'income' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentHomeqClaimsQuery, CurrentHomeqClaimsQueryVariables>
export const OptInShortcutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OptInShortcut' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptShortcutProposal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'homeId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OptInShortcutMutation, OptInShortcutMutationVariables>
export const OptOutShortcutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OptOutShortcut' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'declineReason' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ShortcutProposalDeclineReasonEnum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'declineShortcutProposal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'declineReason' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'declineReason' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'homeId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OptOutShortcutMutation, OptOutShortcutMutationVariables>
export const GetHomeStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHomeStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'market' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHomeStatusQuery, GetHomeStatusQueryVariables>
export const UpdatePhoneNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePhoneNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'private' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePhoneNumberMutation, UpdatePhoneNumberMutationVariables>
export const FavoriteMarkHomeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FavoriteMarkHome' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'favoriteMarkHome' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FavoriteMarkHomeMutation, FavoriteMarkHomeMutationVariables>
export const UnmarkFavoriteHomeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnmarkFavoriteHome' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unmarkFavoriteHome' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnmarkFavoriteHomeMutation, UnmarkFavoriteHomeMutationVariables>
export const DeclineRevokeBookingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeclineRevokeBooking' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'declineOrRevokeBooking' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'booking' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeclineRevokeBookingMutation, DeclineRevokeBookingMutationVariables>
export const DeclineHomeApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeclineHomeApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'declineHomeApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'homeApplication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeclineHomeApplicationMutation, DeclineHomeApplicationMutationVariables>
export const CreateTenantInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTenantInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateTenantInvitationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTenantInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantInvitation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'claimKey' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTenantInvitationMutation, CreateTenantInvitationMutationVariables>
export const AcceptOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offering' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'offeringStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AcceptOfferingMutation, AcceptOfferingMutationVariables>
export const DeclineOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeclineOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'declineOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offering' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'offeringStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeclineOfferingMutation, DeclineOfferingMutationVariables>
export const SwishPaymentInvoicesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SwishPaymentInvoices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'swishPayments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'paymentReferenceId' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SwishPaymentInvoicesQuery, SwishPaymentInvoicesQueryVariables>
export const TransitionBookingOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TransitionBookingOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransitionBookingOfferInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transitionBookingOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookingOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransitionBookingOfferMutation, TransitionBookingOfferMutationVariables>
export const GetBookingInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBookingInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paidAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBookingInvoiceQuery, GetBookingInvoiceQueryVariables>
export const InitiateBookingCardPaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InitiateBookingCardPayment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InitiateBookingCardPaymentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiateBookingCardPayment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sessionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitiateBookingCardPaymentMutation, InitiateBookingCardPaymentMutationVariables>
export const InitiateBookingSwishPaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InitiateBookingSwishPayment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InitiateSwishPaymentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiateSwishPayment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'swishPayment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentReferenceId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InitiateBookingSwishPaymentMutation,
  InitiateBookingSwishPaymentMutationVariables
>
export const ArchiveConversationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ArchiveConversation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archiveConversation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conversation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArchiveConversationMutation, ArchiveConversationMutationVariables>
export const OpenConversationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OpenConversation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'openConversation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conversation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'codes' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OpenConversationMutation, OpenConversationMutationVariables>
export const UpsertContractDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertContract' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertContractInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertContract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertContractMutation, UpsertContractMutationVariables>
export const CreateBookingOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBookingOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateBookingOfferInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBookingOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookingOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateBookingOfferMutation, CreateBookingOfferMutationVariables>
export const CreateBookingOfferFromBookingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBookingOfferFromBooking' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBookingOfferFromBooking' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookingOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBookingOfferFromBookingMutation,
  CreateBookingOfferFromBookingMutationVariables
>
export const HomeAvailabilitiesAppDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeAvailabilitiesApp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeAvailabilities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EditBookingFormHomeAvailability' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditBookingFormHomeAvailability' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeAvailability' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'booking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conversation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeAvailabilitiesAppQuery, HomeAvailabilitiesAppQueryVariables>
export const DeclineHomeApplicationForConversationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeclineHomeApplicationForConversation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'declineHomeApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'homeApplication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeclineHomeApplicationForConversationMutation,
  DeclineHomeApplicationForConversationMutationVariables
>
export const RevokeSentApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RevokeSentApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revokeHomeApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'homeApplication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RevokeSentApplicationMutation, RevokeSentApplicationMutationVariables>
export const UnarchiveConversationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnarchiveConversation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unarchiveConversation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conversation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnarchiveConversationMutation, UnarchiveConversationMutationVariables>
export const AcceptHomeApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptHomeApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptHomeApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'homeApplication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AcceptHomeApplicationMutation, AcceptHomeApplicationMutationVariables>
export const CreateMessageForChatDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMessageForChat' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateConversationMessageInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createConversationMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'upload' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateMessageForChatMutation, CreateMessageForChatMutationVariables>
export const ChatProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ChatProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenant' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MatchingProfileCard' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserProfileCompleteness' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantPreferencesHome' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantPreferencesUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantAd' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchPreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCostCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'householdSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NameAndVerifications' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showAge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentIdentification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'housingSituation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasReference' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatchingProfileCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantPreferencesUser' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameAndVerifications' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserProfileCompleteness' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantProfileCompleteness' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'completenessScore' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantProfileFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioLived' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'idNumberConfirmed' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'matchingProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'movingReason' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioPets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioTitle' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentAgreementType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'completed' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantPreferencesHome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyCostCents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatProfileQuery, ChatProfileQueryVariables>
export const MatchingScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MatchingScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantUid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matchingScoreAws' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenantUid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantUid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'score' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatchingScoreQuery, MatchingScoreQueryVariables>
export const CancelBookingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelBooking' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelBooking' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'booking' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canceledAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'refundAction' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelBookingMutation, CancelBookingMutationVariables>
export const ChatDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Chat' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'read' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatMessageList' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatHeader' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatProfile' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MarkLastChatMessageAsRead' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordViewApplication' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantViewApplication' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordViewBooking' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatHomeDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uploads' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeBaseInfo' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'homeTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookingSystemMessageConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentCancellationPolicy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'policyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundFactor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partialRefundDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullRefundDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordFullRefundDays' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'responsibleForCleaning' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'booking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canceledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refundAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'actionDeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookingOffers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdFromBooking' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricingInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'tenantFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'landlordFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalTenantPrice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rentPrice' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'checkIn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'checkOut' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricingInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'landlordFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rentPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tenantFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalTenantPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'averagePricePerNight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cleaningFee' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoices' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'paidAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatEditContractAction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'market' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlord' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatActionsConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offering' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offeringStatus' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extensionContract' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signUrl' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'signatories' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'market' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatEditContractAction' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatSystemMessageConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offering' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offeringStatus' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatActionsConversation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatImageMessageConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageReadNotice' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastSeenMessageIds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'messageId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatWarningMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BookingSystemMessageConversation' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatSystemMessageConversation' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatImageMessageConversation' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatMessageReadNotice' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatWarningMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'booking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSortedUploads' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'order' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetPrimaryUpload' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSortedUploads' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'primary' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SmallHomeCardInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeBaseInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SmallHomeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetPrimaryUpload' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SmallHomeCardInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatHomeIntroMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SmallHomeCard' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatIntroMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'origin' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatProfile' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatHomeIntroMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatImageMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookingSystemMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BookingOffer' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdFromBooking' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatActionsMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeApplication' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatSystemMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ContractTenantInvitation' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'recipientEmail' } }],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatActionsMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatImageMessage' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BookingSystemMessage' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatSystemMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FloatingActionButtonsConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'acceptsCosignatures' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatHeaderProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdditionalOptionsMenu' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statusChanger' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordAddonsHome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qasaGuarantee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordViewApplication' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'market' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlordFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'base' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'waterFeePerTenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlordServices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qasaGuarantee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insurance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordAddonsHome' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qasaGuarantee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qasaGuaranteeCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfTenants' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlordFees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'base' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cost' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenantFees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'waterFeePerTenant' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cost' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeaderActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instantSignSetting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'enabled' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signatories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordViewApplication' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantCostBreakdownTable' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firsthand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
          { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
          { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentIdentification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landlordApplicationResponseTimeHours' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditBookingFormConversation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricingModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFeeFactor' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditBookingDialog' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'booking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookingOffers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentState' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'checkIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkOut' } },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EditBookingFormConversation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatHomeDetails' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatMessageConversation' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatIntroMessage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'origin' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatMessage' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FloatingActionButtonsConversation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatHeader' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatHeaderProfile' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdditionalOptionsMenu' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HeaderActions' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarkLastChatMessageAsRead' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'read' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantViewApplication' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantCostBreakdownTable' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordProfile' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordViewBooking' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EditBookingDialog' } }],
      },
    },
  ],
} as unknown as DocumentNode<ChatQuery, ChatQueryVariables>
export const UnreadMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'UnreadMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'conversationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messagesSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'conversationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'conversationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unreadMessages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatMessage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatImageMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookingSystemMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BookingOffer' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdFromBooking' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatActionsMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeApplication' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatSystemMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ContractTenantInvitation' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'recipientEmail' } }],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatActionsMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatImageMessage' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BookingSystemMessage' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatSystemMessage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnreadMessagesSubscription, UnreadMessagesSubscriptionVariables>
export const MarkLastChatMessageAsReadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkLastChatMessageAsRead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarkConversationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markConversation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conversation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'read' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unreadMessagesCount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastSeenMessageIds' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'messageId' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarkLastChatMessageAsReadMutation, MarkLastChatMessageAsReadMutationVariables>
export const HomeSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'market' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketNameTypeEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeSearchOrderEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeSearchOrderByEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchParams' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeSearchParamsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'market' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'market' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchParams' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchParams' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filterHomesOffset' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'offset' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'pagesCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firsthand' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minimumPricePerNight' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maximumPricePerNight' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'averagePricePerNight' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedByUser' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'landlord' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profilePicture' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profilePicture' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'instantSignSetting' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sublocality' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'links' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'traits' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'duration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uploads' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeSearchQuery, HomeSearchQueryVariables>
export const HomeIndexSearchQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeIndexSearchQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeIndexSearchOrderInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'params' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeSearchParamsInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeIndexSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'params' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documents' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'offset' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'blockListing' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rentalLengthSeconds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'householdSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstHand' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'furnished' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'homeType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'instantSign' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'market' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastBumpedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'monthlyCost' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'petsAllowed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'platform' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'publishedOrBumpedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortcutHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'smokingAllowed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sortingScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'wheelchairAccessible' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'point' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uploads' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'pagesCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeIndexSearchQueryQuery, HomeIndexSearchQueryQueryVariables>
export const GetUserFavoriteHomesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserFavoriteHomes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'favoriteHomes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rentalTypes' },
                value: { kind: 'ListValue', values: [{ kind: 'EnumValue', value: 'long_term' }] },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'PUBLISHED_AT' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filterHomesOffset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserFavoriteHomesQuery, GetUserFavoriteHomesQueryVariables>
export const SearchAreaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchArea' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'areaParams' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'AreaParamsInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchArea' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'areaParams' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'areaParams' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchAreaFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchAreaFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchArea' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'geojson' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchAreaQuery, SearchAreaQueryVariables>
export const SavedSearchesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SavedSearches' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rentalType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeRentalTypeEnum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'savedSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rentalType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rentalType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SavedSearchFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SavedSearchFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SavedSearch' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monitoring' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchParams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchAreas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firsthand' } },
                { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minMonthlyCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minRentalLength' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moveInEarliest' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
                { kind: 'Field', name: { kind: 'Name', value: 'smoker' } },
                { kind: 'Field', name: { kind: 'Name', value: 'traits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minBedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minBedroomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minToiletCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'householdSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minPricePerNight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxPricePerNight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wheelchairAccessible' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasKitchen' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SavedSearchesQuery, SavedSearchesQueryVariables>
export const CreateSavedSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSavedSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateSavedSearchInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSavedSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedSearch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SavedSearchFragment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SavedSearchFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SavedSearch' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monitoring' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchParams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchAreas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firsthand' } },
                { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minMonthlyCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minRentalLength' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moveInEarliest' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
                { kind: 'Field', name: { kind: 'Name', value: 'smoker' } },
                { kind: 'Field', name: { kind: 'Name', value: 'traits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minBedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minBedroomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minToiletCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'householdSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minPricePerNight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxPricePerNight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wheelchairAccessible' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasKitchen' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSavedSearchMutation, CreateSavedSearchMutationVariables>
export const UpdateSavedSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSavedSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'savedSearchId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSavedSearchInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSavedSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'savedSearchId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedSearch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SavedSearchFragment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SavedSearchFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SavedSearch' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monitoring' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchParams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchAreas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firsthand' } },
                { kind: 'Field', name: { kind: 'Name', value: 'furniture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minMonthlyCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minRentalLength' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moveInEarliest' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pets' } },
                { kind: 'Field', name: { kind: 'Name', value: 'smoker' } },
                { kind: 'Field', name: { kind: 'Name', value: 'traits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minBedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minBedroomCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minToiletCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'householdSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minPricePerNight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxPricePerNight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wheelchairAccessible' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasKitchen' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSavedSearchMutation, UpdateSavedSearchMutationVariables>
export const DeleteSavedSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSavedSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'savedSearchId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destroySavedSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'savedSearchId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables>
export const HomeqUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeqUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeqUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'points' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'queuePoints' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeqUserQuery, HomeqUserQueryVariables>
export const SimilarHomesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SimilarHomes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'similarHomesId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'count' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'similarHomes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'similarHomesId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'count' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'count' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'similarHomes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeContext' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteHomeButton' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedByUser' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeInteractionOverlay' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FavoriteHomeButton' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeMap' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MultimediaActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeInteractionOverlay' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeMap' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MultimediaActions' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRoomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSquareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildingFloors' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LongTermBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeBaseInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VacationBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'toiletCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasKitchen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeBaseInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LongTermBaseInfo' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'VacationBaseInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Description' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionBuilding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionContract' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionFeatures' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionLayout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionTransportation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'housingAssociation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firsthand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seniorHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'corporateHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studentHome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buildYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bathroomRenovationYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kitchenRenovationYear' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContractInfoRules' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'maxTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RentalRequirements' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rentalRequirement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'approvedCreditCheck' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentMultiplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'studentVerification' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedIdNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedIncome' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DurationComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Duration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endOptimal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endUfn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOptimal' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdditionalDurationInfoComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Duration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'possibilityOfExtension' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'durationPreference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startLatest' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSignLeaseDurationInfoComponent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAsap' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'durationPreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startLatest' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LongTermContractInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContractInfoRules' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RentalRequirements' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'waterFeePerTenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthlyElectricityFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electricityFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyFee' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DurationComponent' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdditionalDurationInfoComponent' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstantSignLeaseDurationInfoComponent' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amenities' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomePitch' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortcut' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homePitch' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentIdentification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identityConfirmedAt' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'intro' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LocationSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeMap' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostBox' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'external' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalInfoPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalApplicationUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalEmail' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DurationComponent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proAgent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proPilot' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'base' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricingModel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'tenantBaseFeeFactor' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instantSignSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'landlordHoursToSign' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordPreview' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adPaidAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listingPublicationFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageViewingDialog' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'matterportEmbedUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateListRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateDetailsDialogBody' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeLayoutPictures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateDetailsDialog' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeTemplateDetailsDialogBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeTemplateList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeTemplates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeTemplateListRow' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeTemplateDetailsDialog' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeSeoLinks' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rentalType' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublishDate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSignHomeRules' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'traits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxTenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfHomes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSign' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openForSigning' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetSortedUploads' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'order' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetPrimaryUpload' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetSortedUploads' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'primary' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeMetaDataList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstantSignSummaryHomeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetPrimaryUpload' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeMetaDataList' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApartmentId' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'landlordUnitId' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeApplicationSortingInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'applicationSortingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationSelectionType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeContext' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'origin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'durationPreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startLatest' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'canBePublished' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageSection' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeBaseInfo' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Description' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LongTermContractInfo' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Amenities' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomePitch' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LocationSection' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostBox' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OfferInfo' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordPreview' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageViewingDialog' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeTemplateList' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeSeoLinks' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PublishDate' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstantSignHomeRules' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeInteractionOverlay' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstantSign' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstantSignSummaryHomeCard' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApartmentId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeApplicationSortingInfo' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SimilarHomesQuery, SimilarHomesQueryVariables>
export const PublishHomeAppDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PublishHomeApp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishHome' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LandlordPreview' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandlordPreview' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adPaidAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlordFees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listingPublicationFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fixed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFree' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublishHomeAppMutation, PublishHomeAppMutationVariables>
export const FavoriteConversationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FavoriteConversation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isFavorited' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'conversationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'starMarkConversation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'starMarked' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isFavorited' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'conversationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conversation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'starMarked' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FavoriteConversationMutation, FavoriteConversationMutationVariables>
export const FilterableHomesForUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FilterableHomesForUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'homes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlord' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FilterableHomesForUserQuery, FilterableHomesForUserQueryVariables>
export const NewInboxDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NewInbox' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'onlyActiveConversations' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'starMarked' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchString' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newInbox' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'onlyActiveConversations' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'onlyActiveConversations' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'starMarked' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'starMarked' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchString' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchString' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxList' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardImage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'starMarked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uploads' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tenantCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'squareMeters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'route' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStreetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'landlord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeMetaDataList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseHomeVariantMetaDataArray' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardTopRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeMetaDataList' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseConversationPartnerUserName' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeApplicationUnanswered' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardNotification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'read' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unreadMessagesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeApplicationUnanswered' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardMiddleRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UseConversationPartnerUserName' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCardNotification' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCardBottomRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'booking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookingOffers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUserRelations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BookingOffer' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdFromBooking' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ContractTenantInvitation' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'recipientEmail' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contract' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'read' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conversation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCardImage' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCardTopRow' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCardMiddleRow' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCardBottomRow' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InboxList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationConnection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InboxCard' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rentalType' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewInboxQuery, NewInboxQueryVariables>
export const HomeExistingApplicationQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeExistingApplicationQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeApplicationStatusEnum' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeApplications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'HomeContextExistingApplication' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostBoxExistingApplication' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeApplication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contract' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeContextExistingApplication' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeApplication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostBoxExistingApplication' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeExistingApplicationQueryQuery, HomeExistingApplicationQueryQueryVariables>
export const HomeApplicationsDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeApplicationsData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeqHomeApplications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeqApplications' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeqHomeApplicationPreconditions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'applicantGroupExceedLimit' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostboxHomeqApplications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectHubHomeQHomeApplicationsType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'interests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeqApplications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectHubHomeQHomeApplicationsType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostboxHomeqApplications' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeApplicationsDataQuery, HomeApplicationsDataQueryVariables>
export const InstantSignExistingContractsAppDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstantSignExistingContractsApp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContractStatusEnum' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contracts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'signingProcess' },
                value: { kind: 'ListValue', values: [{ kind: 'EnumValue', value: 'instant' }] },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'HomeContextInstantSignExistingContract' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostBoxExistingInstantSignContract' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signatories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uid' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeContextInstantSignExistingContract' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contract' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostBoxExistingInstantSignContract' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InstantSignExistingContractsAppQuery,
  InstantSignExistingContractsAppQueryVariables
>
export const UseHomeqHomeApplicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'useHomeqHomeApplications' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'homeqHomeApplications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeqApplicationFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeqInterestFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConnectHubHomeQInterestType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateAccess' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countsToLimit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accessOnAgreement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomeqApplicationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectHubHomeQHomeApplicationType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomeqInterestFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'viewingDatetime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rsvpStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseHomeqHomeApplicationsQuery, UseHomeqHomeApplicationsQueryVariables>
export const UserUnreadConversationsCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserUnreadConversationsCount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'private' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unreadConversationsCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserUnreadConversationsCountQuery, UserUnreadConversationsCountQueryVariables>
export const CreateUploadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUpload' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateUploadInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upload' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUploadMutation, CreateUploadMutationVariables>
export const FinalizeUploadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FinalizeUpload' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'etag' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finalizeUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'etag' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'etag' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upload' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FinalizeUploadMutation, FinalizeUploadMutationVariables>
export const SearchAreaKeywordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchAreaKeyword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchString' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'countryCodes' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchAreaKeyword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchString' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchString' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'countryCodes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'countryCodes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchAreaKeywordQuery, SearchAreaKeywordQueryVariables>
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'private' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'familyName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>
export const FavoriteMarkHomeAppDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FavoriteMarkHomeApp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'favoriteMarkHome' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FavoriteHomeButton' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteHomeButton' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedByUser' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FavoriteMarkHomeAppMutation, FavoriteMarkHomeAppMutationVariables>
export const UnmarkFavoriteHomeAppDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnmarkFavoriteHomeApp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unmarkFavoriteHome' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FavoriteHomeButton' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteHomeButton' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Home' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favoriteMarkedByUser' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnmarkFavoriteHomeAppMutation, UnmarkFavoriteHomeAppMutationVariables>
export const HomeqUserTokenStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomeqUserTokenState' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'homeqUserTokenState' } }],
      },
    },
  ],
} as unknown as DocumentNode<HomeqUserTokenStateQuery, HomeqUserTokenStateQueryVariables>
export const PrivacyControllerByPlatformDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PrivacyControllerByPlatform' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privacyControllerByPlatform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isTransactionCustomer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceController' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionTerms' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceTerms' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionController' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrivacyControllerByPlatformQuery, PrivacyControllerByPlatformQueryVariables>
